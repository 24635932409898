import React, { useState } from 'react'
import SkateboardingIcon from '@mui/icons-material/Skateboarding';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { useAppSelector } from '../../hooks'
import store from '../../stores'
import {  setSkating } from '../../stores/PrefStore'
import MenuOption from '../../components/menu/MenuOption';

export default function SkatingButton() {
  const skatingState = useAppSelector((state) => state.pref.autoSkating)
  const [currentState, setCurrentState] = useState(skatingState)

  return (
    <MenuOption
      onClick={() => {
        store.dispatch(setSkating(!currentState))
        setCurrentState(!currentState)
      }}
      icon={currentState ? <SkateboardingIcon /> : <DirectionsWalkIcon />}
      text={currentState ? 'Auto Skating' : 'Manual Skating'}
    />
  )
}
