import { ItemType } from '../../../types/Items'
import isTouchScreen from '../utils/isTouchScreen'
import Item from './Item'

export default class Unlockable extends Item {
  isOpen?: boolean
  isUnlocked?: boolean
  inMinigame?: boolean

  constructor(scene: Phaser.Scene, x: number, y: number, texture: string, frame?: string | number) {
    super(scene, x, y, texture, frame)

    this.itemType = ItemType.UNLOCKABLE
    this.inMinigame = false
  }

  onOverlapDialog() {
    if (this.isUnlocked) {
      if (isTouchScreen()) {
        this.setDialogBox('Tap button to close')
      } else {
        this.setDialogBox('Press E to close')
      }
    } else {
      if (isTouchScreen()) {
        this.setDialogBox('Press button to open')
      } else {
        this.setDialogBox('Press E to open')
      }
    }
  }
}
