import styled from 'styled-components'
import TouchAppIcon from '@mui/icons-material/TouchApp'
import { useAppSelector } from '../../hooks'
import isTouchScreen from '../../utils/isTouchScreen'
import phaserGame from '../../PhaserGame'
import Game from '../../scenes/Game'
import Border from '../../components/shared/Border'

const Backdrop = styled.div`
  margin-top: auto;
  margin-bottom: 0px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  opacity: 0.9;
`

const MenuButton = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  z-index: 99;
  width: 100%;
  padding: 13px 15px;
  background-color: #fff;
  color: #000;
  :hover {
    background-color: #ddd;
    cursor: pointer;
  }
`

const Body = styled.div`
  background: rgb(248, 248, 248) none repeat scroll 0% 0%;
  border-bottom: 4px solid rgb(216, 213, 237);
  padding: 0px;

  @media (max-width: 700px) {
    padding: 0px;
  }
`

export default function InteractionButton() {
  const touchEnabled = useAppSelector((state) => state.user.touchEnabled)
  const activeMenu = useAppSelector((state) => state.menu.activeMenu)
  const game = phaserGame.scene.keys.game as Game

  if (touchEnabled && isTouchScreen() && !['chat','mini-game'].includes(activeMenu)) {
    return (
      <Backdrop>
        <Border>
          <Body>
            <MenuButton
              color="secondary"
              aria-label="showMenu"
              onClick={() => {
                game.playerInteraction = true
              }}
            >
              <TouchAppIcon />
            </MenuButton>
          </Body>
        </Border>
      </Backdrop>
    )
  } else {
    return <></>
  }
}
