import { useCallback, useState } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { utils } from 'ethers'
import detectProvider from '@metamask/detect-provider'

import { injectedConnector } from '../utils/connectors'
import { METHODS, ERROR_METAMASK } from '../constants/metamask'

import {
  CUSTOM_ERRORS,
  SUPPORTED_HARMONY_CHAIN_ID,
  NETWORK,
  RPC_PROVIDER_URL,
} from '../constants/chain'
import { isValidBrowser } from '../utils/bowser'

const handleError = (activate, setError) => async (error) => {
  if (error instanceof UnsupportedChainIdError) {
    setError(CUSTOM_ERRORS.UNSUPPORTED_NETWORK)
    try {
      await window.ethereum.request({
        method: METHODS.WALLET_SWITCH_CHAIN,
        params: [{ chainId: utils.hexlify(SUPPORTED_HARMONY_CHAIN_ID) }],
      })
      setError(null)
      activate(injectedConnector, handleError(activate, setError))
    } catch (switchError: any) {
      if (switchError.code === ERROR_METAMASK.NOT_FOUND_CHAIN) {
        try {
          await window.ethereum.request({
            method: METHODS.WALLET_ADD_CHAIN,
            params: [
              {
                chainId: utils.hexlify(SUPPORTED_HARMONY_CHAIN_ID),
                chainName: `Harmony ${NETWORK}`,
                rpcUrls: [RPC_PROVIDER_URL],
                nativeCurrency: {
                  name: 'ONE',
                  symbol: 'ONE',
                  decimals: 18,
                },
              },
            ],
          })
          setError(null)
          activate(injectedConnector, handleError(activate, setError))
        } catch (addError) {
          console.error(addError)
        }
      }
    }
  }
}

export const useWallet = () => {
  const [error, setError] = useState<string | null>(null)
  const { account, active, activate, deactivate: logout } = useWeb3React()
  const isDisabled = process.env.REACT_APP_DEMO === 'TRUE'

  const login = useCallback(async () => {
    if (isValidBrowser()) {
      const isMetamaskProvider = await detectProvider({ mustBeMetaMask: true })
      if (isMetamaskProvider) {
        if (isMetamaskProvider !== window.ethereum) {
          setError(CUSTOM_ERRORS.MULTIPLE_WALLETS)
        } else {
          await activate(injectedConnector, handleError(activate, setError))
        }
      } else {
        setError(CUSTOM_ERRORS.NO_METAMASK)
      }
    } else {
      setError(CUSTOM_ERRORS.UNSUPPORTED_BROWSER)
    }
  }, [activate])

  const resetError = useCallback(() => {
    setError(null)
  }, [setError])

  return {
    account,
    active,
    login,
    logout,
    error,
    isDisabled,
    resetError,
  }
}
