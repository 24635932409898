import { useAppSelector } from '../../hooks'
import store from '../../stores'
import { setZoom } from '../../stores/PrefStore'
import MenuOption from '../../components/menu/MenuOption'
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'

export default function CameraZoomButton() {
  const cameraZoom = useAppSelector((state) => state.pref.zoom)

  return (
    <MenuOption
      onClick={() => {
        store.dispatch(setZoom(!cameraZoom))
      }}
      icon={cameraZoom ? <ZoomOutMapIcon /> : <ZoomInMapIcon />}
      text={'Camera Zoom'}
    />
  )
}
