export class WeatherDisplayManager {
  scene: any
  raindropTimer: number
  rainFrequency: number
  rainAmount: number
  rainEffects: any
  bounds: { top: number; bottom: number; right: number; left: number }
  fog: any
  // responsible for displaying the weather effects, part of each overworld Scene
  constructor(scene) {
    this.scene = scene
    this.scene.isRaining = false
    this.raindropTimer = 1
    this.rainFrequency = 0
    this.rainAmount = 0

    // group that contains all the weather-related visual objects
    this.rainEffects = this.scene.add.group()
    this.rainEffects.runChildUpdate = true

    // define the bounds that extend the viewport, where the drops are spawned
    this.bounds = {
      top: 100,
      bottom: 0,
      right: 20,
      left: 20,
    }
  }

  update(delta) {
    this.raindropTimer += delta

    // spawn rain droplets
    if (this.raindropTimer >= this.rainFrequency) {
      this.raindropTimer -= this.rainFrequency

      let numDrops = Math.floor(this.rainAmount * 5)
      for (let i = 0; i < numDrops; i++) {
        let pos = this.getRandomPos()
        let droplet = new RainDroplet(this, pos.x, pos.y)
        this.rainEffects.add(droplet)
      }
    }
  }

  getRandomPos() {
    let cam = this.scene.cameras.main
    return {
      x: Phaser.Math.Between(
        cam.worldView.x - this.bounds.left,
        cam.worldView.x + cam.worldView.width + this.bounds.right
      ),
      y: Phaser.Math.Between(
        cam.worldView.y - this.bounds.top,
        cam.worldView.y + cam.worldView.height + this.bounds.bottom
      ),
    }
  }

  startRaining(rainFrequency: number, rainAmount: number, onComplete?: any) {
    this.scene.isRaining = true

    this.rainFrequency = rainFrequency
    this.rainAmount = rainAmount
  }

  stopRaining() {
    this.scene.isRaining = false
    this.rainFrequency = 0
    this.rainAmount = 0
  }
}

export class RainDroplet extends Phaser.GameObjects.GameObject {
  manager: any
  x: any
  y: any
  speed: { x: number; y: number }
  line: Phaser.GameObjects.Graphics
  height: any
  alpha: any
  constructor(manager, x, y) {
    super(manager.scene, 'rain')

    this.manager = manager

    this.x = x
    this.y = y

    this.speed = {
      x: 0.3 * 1,
      y: 1.5,
    }

    this.line = this.scene.add.graphics().setDepth(12)

    if (this.manager && this.manager.rainEffects && this.line) {
      this.manager.rainEffects.add(this.line)
    }

    this.height = this.manager.bounds.top
  }

  update(time, delta) {
    this.height -= this.speed.y * delta
    if (this.height <= 0) {
      this.manager.rainEffects.add(createSplash(this.scene, this.x, this.y))
      this.destroy()
    }

    let newX = this.x + this.speed.x * delta
    let newY = this.y + this.speed.y * delta

    // draw a line
    this.line.clear()
    this.line.lineStyle(1, 0xddddff, this.alpha)
    this.line.moveTo(this.x, this.y)
    this.line.lineTo(newX, newY)
    this.line.strokePath()

    this.x = newX
    this.y = newY
  }

  destroy() {
    super.destroy()
    this.line.destroy()
  }
}

const createSplash = function (scene, x, y) {
  let splash = scene.add.sprite(x, y, 'raindrop', 0).setDepth(20)

  splash.anims.create({
    key: 'default',
    frames: 'raindrop',
    frameRate: 24,
  })
  splash.anims.play('default')

  splash.on('animationcomplete', () => {
    splash.destroy()
  })

  return splash
}
