import styled from 'styled-components'
import { MenuWrapper } from '../Menu'
import Border from '../../components/shared/Border'
import {  useAppSelector } from '../../hooks'
import CloseIcon from '@mui/icons-material/Close'

const Body = styled.div`
  background: rgb(248, 248, 248) none repeat scroll 0% 0%;
  border-bottom: 4px solid rgb(216, 213, 237);
  padding: 20px;
  height: 100%;

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
  }
  h1 {
    text-align: center;
  }
  .control {
    display: flex;
    font-size: 24px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #000;
    padding: 5px 10px;
    margin-bottom: 10px;
    .type {
      text-align: left;
      width: 50%;
    }
    .value {
      text-align: right;
      width: 50%;
    }
    .value.with-option {
      cursor: pointer;
    }
  }
`

export default function RoomMenu({ selectedMenu, setSelectedMenu }: any) {
  const activeRoomNumber = useAppSelector((state) => state.game.roomNumber)

  return (
    <MenuWrapper>
      <Border>
        <Body>
          <div className="close-button" onClick={() => setSelectedMenu('')}>
            <CloseIcon />
          </div>
          <h1>Rooms</h1>
          {[1, 2, 3].map((roomNumber) => (
            <div
              className="control"
              // onClick={() => {
              //   if (activeRoomNumber !== roomNumber) {
              //     Network.leave()
              //     Network.joinColyseusLobbyRoom().then(() => {
              //       store.dispatch(setServerConnected(true))
              //     })
              //     Network.joinOrCreatePublic(`room-${roomNumber}`)
              //       .then(() => {
              //         bootstrapHandler.launchGame()
              //         dispatch(setRoomNumber(roomNumber))
              //       })
              //       .then(()=>{
              //         console.log(name,texture,walletAddress)
              //         dispatch(setMyPlayerProps({ name, texture, walletAddress}))
              //         dispatch(setLoggedIn(true))
              //         Network.updatePlayerName(name)
              //         dispatch(logIn())
              //         dispatch(setActiveMenu(''))
              //         dispatch(setFocused(false))
              //         dispatch(setTouch(true))
              //       })
              //       .catch((error) => console.error(error))
              //   }

              // }}
              key={roomNumber}
            >
              <div className="type">Room {roomNumber}</div>
              <div className="value with-option">
                {activeRoomNumber === roomNumber ? 'Active' : ''}
              </div>
            </div>
          ))}
        </Body>
      </Border>
    </MenuWrapper>
  )
}
