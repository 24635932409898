import Phaser from 'phaser'
import store from '../stores';
import { getValuesClosestToPointFive, get_fromToDirectionValues } from '../util';
import MyPlayer from './MyPlayer';
import NPC from './NPC'

enum PathFindingStates {
  none = "none",
  pathing = "pathing",
}

export interface IPathFindingRoute {
  x: number;
  y: number;
  onDestinationReachedWaitTime_ms?: number;
}
const ROUTE_MAX_DISTANCE_FOR_DESTINATION_ARRIVAL = 2;
const WALKING_SPEED = 0.6;
const CONTINUE_PATHING_WAIT_TIMER_MS = 2000;

export default class NPCPathFinder {
  private npcReference!: NPC; 
  private current_pathFindingState: PathFindingStates = PathFindingStates.none;
  private current_pathFindingRouteIndex = 0;
  private pathFindingRoutes!: IPathFindingRoute[];
  private lastPathFindingRoute!: IPathFindingRoute;
  private isWaitingForDialogueToClose = false;
  private current_routeDestinationReachedWaitTimer: Phaser.Time.TimerEvent | null = null;
  private current_continuePathingWaitTimer: Phaser.Time.TimerEvent | null = null;
  private current_playerRef: MyPlayer | null = null;
  constructor(
    npcReference: NPC,
    pathFindingRoutes: IPathFindingRoute[],
  ) {
    this.npcReference = npcReference;
    this.pathFindingRoutes = pathFindingRoutes;
    this.lastPathFindingRoute = this.pathFindingRoutes[0];
    this.set_pathFindingState(PathFindingStates.pathing);
  }

  preUpdate(t: number, dt: number): void {
    this.maybe_pathFind();
    this.npcReference.updatePlayerNamePosition();
    this.maybe_checkIfDialogueIsClosed();
  }

  maybe_checkIfDialogueIsClosed(): void {
    if(!this.isWaitingForDialogueToClose) {
      return;
    }

    const { messageDialogOpen } = store.getState().message;
    if(!messageDialogOpen) {
      this.isWaitingForDialogueToClose = false;
      this.onClosePlayerDialogue();
    }
  }

  set_pathFindingState(newState: PathFindingStates): void {
    this.current_pathFindingState = newState;
  }

  maybe_pathFind(): void {
    if(this.current_pathFindingState !== PathFindingStates.pathing) {
      return;
    }

    const xDir = this.npcReference.currentContainerX - this.npcReference.lastContainerX;
    const yDir = this.npcReference.currentContainerY - this.npcReference.lastContainerY;
    this.setAnimation(xDir, yDir);

    const currentRoute = this.pathFindingRoutes[this.current_pathFindingRouteIndex];
    const fromPos = new Phaser.Math.Vector2(this.npcReference.x, this.npcReference.y);
    const toPos = new Phaser.Math.Vector2(currentRoute.x, currentRoute.y);
    const [, , npcToPlayerDirection] = get_fromToDirectionValues(fromPos, toPos);
    const xDirBySpeed = npcToPlayerDirection.x * WALKING_SPEED;
    const yDirBySpeed = npcToPlayerDirection.y * WALKING_SPEED;
    this.npcReference.updateNPC("x", this.npcReference.x + xDirBySpeed);
    this.npcReference.updateNPC("y", this.npcReference.y + yDirBySpeed);

    if(this.isWithinPathFindingRouteDestination()) {
      this.on_reachedCurrentRouteDestination();
    }
  }

  isWithinPathFindingRouteDestination(): boolean {
    const currentRoute = this.pathFindingRoutes[this.current_pathFindingRouteIndex];
    const npcToRouteDestinationDistance = Phaser.Math.Distance.Between(this.npcReference.x, this.npcReference.y, currentRoute.x, currentRoute.y);
    if(npcToRouteDestinationDistance <= ROUTE_MAX_DISTANCE_FOR_DESTINATION_ARRIVAL) {
      return true;
    } else {
      return false;
    }
  }

  on_reachedCurrentRouteDestination(): void {
    this.lastPathFindingRoute = this.pathFindingRoutes[this.current_pathFindingRouteIndex];
    this.current_pathFindingRouteIndex += 1;
    if(this.current_pathFindingRouteIndex === this.pathFindingRoutes.length) {
      this.current_pathFindingRouteIndex = 0;
    }
    this.set_pathFindingState(PathFindingStates.none);

    this.maybe_createRouteDestinationReachedWaitTimer();
    this.playIdleAnimation();
  }

  playIdleAnimation(): void {
    const parts = this.npcReference.anims.currentAnim.key.split('_')
    parts[1] = 'idle'
    const newAnim = parts.join('_')
    // this prevents idle animation keeps getting called
    if (this.npcReference.anims.currentAnim.key !== newAnim) {
      this.npcReference.play(parts.join('_'), true)
    }
  }

  maybe_createRouteDestinationReachedWaitTimer(): void {
    const currentRoute = this.lastPathFindingRoute;
    if(currentRoute.onDestinationReachedWaitTime_ms) {
      this.current_routeDestinationReachedWaitTimer = this.npcReference.scene.time.delayedCall(currentRoute.onDestinationReachedWaitTime_ms, () => {
        this.set_pathFindingState(PathFindingStates.pathing);
      });
    } else {
      this.set_pathFindingState(PathFindingStates.pathing);
    }
  }

  setAnimation(vx: number, vy: number): void {
    const [rvx, rvy] = getValuesClosestToPointFive(vx, vy);
    if (rvx > 0) {
      this.npcReference.play(`${this.npcReference.playerTexture}_run_right`, true)
    } else if (rvx < 0) {
      this.npcReference.play(`${this.npcReference.playerTexture}_run_left`, true)
    } else if (rvy > 0) {
      this.npcReference.play(`${this.npcReference.playerTexture}_run_down`, true)
    } else if (rvy < 0) {
      this.npcReference.play(`${this.npcReference.playerTexture}_run_up`, true)
    } else {
      this.playIdleAnimation();
    }
  }

  onOpenPlayerDialogue(myPlayer: MyPlayer): void {
    this.current_playerRef = myPlayer;
    this.set_pathFindingState(PathFindingStates.none);
    this.isWaitingForDialogueToClose = true;

    if(this.current_routeDestinationReachedWaitTimer) {
      this.current_routeDestinationReachedWaitTimer.destroy();
    }
    if(this.current_continuePathingWaitTimer) {
      this.current_continuePathingWaitTimer.destroy();
    }
    
  }

  onClosePlayerDialogue(): void {
    this.createContinuePathingWaitTimer();
  }

  createContinuePathingWaitTimer(): void {
    this.current_continuePathingWaitTimer = this.npcReference.scene.time.delayedCall(CONTINUE_PATHING_WAIT_TIMER_MS, () => {
      this.set_pathFindingState(PathFindingStates.pathing);
    });
  }
}