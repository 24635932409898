import { enableMapSet } from 'immer'
import { configureStore } from '@reduxjs/toolkit'
import userReducer from './UserStore'
import bankReducer from './Bank'
import computerReducer from './ComputerStore'
import chatReducer from './ChatStore'
import roomReducer from './RoomStore'
import messageReducer from './MessageStore'
import menuReducer from './MenuStore'
import tabletReducer from './TabletStore'
import notificationSlice from './NotificationStore'
import whiteboardSlice from './WhiteboardStore'
import questSlice from './QuestStore'
import prefSlice from './PrefStore'
import timerSlice from './TimerStore'
import warningTextSlice from './WarningTextStore'
import gameSlice from './GameStore'
import screenFlashSlice from './ScreenFlashStore'

import { save, load } from 'redux-localstorage-simple'

const PERSISTED_KEYS: string[] = ['bank.user', 'bank.transactions', 'bank.lists']

enableMapSet()

const store = configureStore({
  reducer: {
    user: userReducer,
    bank: bankReducer,
    computer: computerReducer,
    chat: chatReducer,
    room: roomReducer,
    message: messageReducer,
    menu: menuReducer,
    notification: notificationSlice,
    tablet: tabletReducer,
    pref: prefSlice,
    whiteboard: whiteboardSlice,
    quest: questSlice,
    timer: timerSlice,
    warningText: warningTextSlice,
    game: gameSlice,
    screenFlash: screenFlashSlice,
  },
  // Temporary disable serialize check for redux as we store MediaStream in ComputerStore.
  // https://stackoverflow.com/a/63244831
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    save({ states: PERSISTED_KEYS }),
  ],
  preloadedState: load({ states: PERSISTED_KEYS }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
