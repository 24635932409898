import { Client, Room } from 'colyseus.js'
import { IComputer, IOfficeState, IPlayer, IWhiteboard } from '../../../types/IOfficeState'
import { Message } from '../../../types/Messages'
import { PartialPlayer, RoomType } from '../../../types/Rooms'
import { ItemType } from '../../../types/Items'

import { Event } from '../events/EventCenter'
import store from '../stores'
import { setServerConnected, setSessionId } from '../stores/UserStore'
import {
  setJoinedRoomData,
  setAvailableRooms,
  addAvailableRooms,
  removeAvailableRooms,
} from '../stores/RoomStore'
import { pushChatMessage } from '../stores/ChatStore'
import { gameConfig } from '../assets/game-data'
import { setWhiteboardUrls } from '../stores/WhiteboardStore'
import { removePlayer, setPlayerMap, setPlayerName } from '../stores/GameStore'

class Network {
  private client: Client
  lobby!: Room
  private events: Phaser.Events.EventEmitter
  room!: Room<IOfficeState>
  mySessionId: string | null = null
  // webRTC!: WebRTC
  webRTC!: any
  webRTCId!: string
  tempUserDetails!: any

  constructor() {
    const protocol = window.location.protocol.replace('http', 'ws')

    const endpoint =
      process.env.NODE_ENV === 'production'
        ? `wss://${process.env.REACT_APP_GAME_BACKEND}`
        : `${protocol}//${window.location.hostname}:2567`

    this.client = new Client(endpoint)
    this.events = new Phaser.Events.EventEmitter()

    this.joinColyseusLobbyRoom().then(() => {
      store.dispatch(setServerConnected(true))
      // this.webRTCId = this.lobby.sessionId
      // this.webRTC = new WebRTC(this.webRTCId)
    })
  }

  /**
   * method to join Colyseus' built-in LobbyRoom, which automatically notifies
   * connected clients whenever rooms with "realtime listing" have updates
   */
  async joinColyseusLobbyRoom() {
    this.lobby = await this.client.joinOrCreate(RoomType.COLYSEUS_LOBBYROOM)

    this.lobby.onMessage('rooms', (rooms) => {
      store.dispatch(setAvailableRooms(rooms))
    })

    this.lobby.onMessage('+', ([roomId, room]) => {
      store.dispatch(addAvailableRooms({ roomId, room }))
    })

    this.lobby.onMessage('-', (roomId) => {
      store.dispatch(removeAvailableRooms(roomId))
    })
  }

  // method to join the public lobby
  async joinOrCreatePublic(key: string) {
    this.room = await this.client.joinOrCreate(RoomType.PUBLIC, {
      name: key,
      roomId: key,
      roomNumber: key,
      description: key,
      password: null,
    })
    this.initialize()
  }

  // method to join an office
  async joinOffice(mapName: string, x: number, y: number) {
    const { name, texture, sessionId, loggedIn } = store.getState().user
    const { roomNumber } = store.getState().game
    if (!sessionId) {
      const player: PartialPlayer = {
        // webRTCId: this.webRTCId,
        webRTCId: undefined,
        readyToConnect: loggedIn,
        name,
        x,
        map: mapName,
        y,
        anim: `${texture}_idle_down`,
      }
      this.room = await this.client.joinOrCreate(RoomType.CUSTOM, {
        player,
        name: `room-${roomNumber}`,
        roomId: `room-${roomNumber}`,
        roomNumber: `room-${roomNumber}`,
        description: `room-${roomNumber}`,
        password: null,
      })
      this.initialize()
    } else {
      this.update()
      this.updatePlayer(x, y, `${texture}_idle_down`, mapName)
    }
  }

  async players() {
    if (!this.room) return
    return this.room.state.players
  }

  // method to leave current room and remove all event listeners and reset webRTC
  async leave() {
    // this.webRTC.reset()
    this.events.removeAllListeners()
    await this.room?.leave()
    this.mySessionId = null
  }

  // method to join a custom room
  async joinCustomById(roomId: string, password: string | null) {
    this.room = await this.client.joinById(roomId, { password })
    this.initialize()
  }

  // set up all network listeners before the game starts
  initialize() {
    const { loggedIn } = store.getState().user
    if (!this.room) return
    this.lobby.leave()
    this.mySessionId = this.room.sessionId
    this.tempUserDetails = {
      // webRTCId: this.webRTCId,
      webRTCId: undefined,
      readyToConnect: loggedIn,
      x: 0,
      y: 0,
      anim: 'none_idle_down',
      map: '',
    }
    store.dispatch(setSessionId(this.room.sessionId))
    // new instance added to the players MapSchema
    this.update()
  }

  update() {
    if (!this.room) return

    this.room.state.players.onAdd = (player: IPlayer, key: string) => {
      if (key === this.mySessionId) return

      // track changes on every child object inside the players MapSchema
      player.onChange = (changes) => {
        changes.forEach((change) => {
          const { field, value } = change
          this.events.emit(Event.PLAYER_UPDATED, field, value, key)

          // when a new player finished setting up player name
          if (field === 'name' && value !== '') {
            store.dispatch(setPlayerName({ id: key, name: value }))
          }
          if (field === 'map' && value !== '') {
            if (value === gameConfig.maps[store.getState().game.currentMap].name) {
              this.events.emit(Event.PLAYER_JOINED, player, key)
            } else {
              this.events.emit(Event.PLAYER_CHANGED_MAP, player, key)
            }
            store.dispatch(setPlayerMap({ id: key, map: value }))
          }
        })
      }
    }

    // an instance removed from the players MapSchema
    this.room.state.players.onRemove = (player: IPlayer, key: string) => {
      this.events.emit(Event.PLAYER_LEFT, key)
      // this.webRTC?.deleteVideoStream(player.webRTCId)
      // this.webRTC?.deleteOnCalledVideoStream(player.webRTCId)
      store.dispatch(removePlayer(key))
    }

    // new instance added to the computers MapSchema
    this.room.state.computers.onAdd = (computer: IComputer, key: string) => {
      // track changes on every child object's connectedWebRTCId
      computer.connectedWebRTCId.onAdd = (item, index) => {
        this.events.emit(Event.ITEM_USER_ADDED, item, key, ItemType.COMPUTER)
      }
      computer.connectedWebRTCId.onRemove = (item, index) => {
        this.events.emit(Event.ITEM_USER_REMOVED, item, key, ItemType.COMPUTER)
      }
    }

    // new instance added to the whiteboards MapSchema
    this.room.state.whiteboards.onAdd = (whiteboard: IWhiteboard, key: string) => {
      store.dispatch(
        setWhiteboardUrls({
          whiteboardId: key,
          roomId: whiteboard.roomId,
        })
      )
      // track changes on every child object's connectedUser
      whiteboard.connectedUser.onAdd = (item, index) => {
        this.events.emit(Event.ITEM_USER_ADDED, item, key, ItemType.WHITEBOARD)
      }
      whiteboard.connectedUser.onRemove = (item, index) => {
        this.events.emit(Event.ITEM_USER_REMOVED, item, key, ItemType.WHITEBOARD)
      }
    }

    // new instance added to the chatMessages ArraySchema
    this.room.state.chatMessages.onAdd = (item, index) => {
      store.dispatch(pushChatMessage(item))
    }

    // when the server sends room data
    this.room.onMessage(Message.SEND_ROOM_DATA, (content) => {
      store.dispatch(setJoinedRoomData(content))
    })

    // when a user sends a message
    this.room.onMessage(Message.ADD_CHAT_MESSAGE, ({ clientId, content }) => {
      this.events.emit(Event.UPDATE_DIALOG_BUBBLE, clientId, content)
    })

    this.room.onMessage(Message.CHANGE_WEATHER, ({ value }) => {
      this.events.emit(Event.WEATHER_UPDATED, value)
    })

    // when a peer disconnects with myPeer
    this.room.onMessage(Message.DISCONNECT_STREAM, (webRTCId: string) => {
      //   this.webRTC?.deleteOnCalledVideoStream(webRTCId)
    })

    // when a computer user stops sharing screen
    this.room.onMessage(Message.STOP_SCREEN_SHARE, (webRTCId: string) => {
      const computerState = store.getState().computer
      computerState.shareScreenManager?.onUserLeft(webRTCId)
    })
  }

  // method to register event listener and call back function when a item user added
  onChatMessageAdded(callback: (playerId: string, content: string) => void, context?: any) {
    this.events.on(Event.UPDATE_DIALOG_BUBBLE, callback, context)
  }

  // method to register event listener and call back function when a item user added
  onItemUserAdded(
    callback: (playerId: string, key: string, itemType: ItemType) => void,
    context?: any
  ) {
    this.events.on(Event.ITEM_USER_ADDED, callback, context)
  }

  // method to register event listener and call back function when a item user removed
  onItemUserRemoved(
    callback: (playerId: string, key: string, itemType: ItemType) => void,
    context?: any
  ) {
    this.events.on(Event.ITEM_USER_REMOVED, callback, context)
  }

  // method to register event listener and call back function when a player joined
  onPlayerJoined(callback: (Player: IPlayer, key: string) => void, context?: any) {
    this.events.on(Event.PLAYER_JOINED, callback, context)
  }

  // method to register event listener and call back function when a player left
  onPlayerLeft(callback: (key: string) => void, context?: any) {
    this.events.on(Event.PLAYER_LEFT, callback, context)
  }

  // method to register event listener and call back function when a player changed map
  onPlayerChangeMap(callback: (Player: IPlayer, key: string) => void, context?: any) {
    this.events.on(Event.PLAYER_CHANGED_MAP, callback, context)
  }

  // method to register event listener and call back function when myPlayer is ready to connect
  onMyPlayerReady(callback: (key: string) => void, context?: any) {
    this.events.on(Event.MY_PLAYER_READY, callback, context)
  }

  // method to register event listener and call back function when my video is connected
  onMyPlayerVideoConnected(callback: (key: string) => void, context?: any) {
    this.events.on(Event.MY_PLAYER_VIDEO_CONNECTED, callback, context)
  }

  // method to register event listener and call back function when a player updated
  onPlayerUpdated(
    callback: (field: string, value: number | string, key: string) => void,
    context?: any
  ) {
    this.events.on(Event.PLAYER_UPDATED, callback, context)
  }

  // method to register event listener and call back function when a player updated
  onWeatherChange(callback: (value: boolean) => void, context?: any) {
    this.events.on(Event.WEATHER_UPDATED, callback, context)
  }

  // method to send player updates to Colyseus server
  updatePlayer(currentX: number, currentY: number, currentAnim: string, map: string) {
    if (
      this.tempUserDetails.x !== currentX ||
      this.tempUserDetails.y !== currentY ||
      this.tempUserDetails.anim !== currentAnim ||
      this.tempUserDetails.map !== map
    ) {
      this.tempUserDetails.x = currentX
      this.tempUserDetails.y = currentY
      this.tempUserDetails.anim = currentAnim
      this.tempUserDetails.map = map

      this.room?.send(Message.UPDATE_PLAYER, {
        x: currentX,
        y: currentY,
        anim: currentAnim,
        map: map,
      })
    }
  }

  // method to send player name to Colyseus server
  updatePlayerName(currentName: string) {
    this.room?.send(Message.UPDATE_PLAYER_NAME, { name: currentName })
  }

  // method to send ready-to-connect signal to Colyseus server
  readyToConnect() {
    this.room?.send(Message.READY_TO_CONNECT, {
      webRTCId: undefined,
      //this.webRTCId
    })
    this.events.emit(Event.MY_PLAYER_READY)
  }

  // method to send ready-to-connect signal to Colyseus server
  videoConnected() {
    this.room?.send(Message.VIDEO_CONNECTED)
    this.events.emit(Event.MY_PLAYER_VIDEO_CONNECTED)
  }

  // method to send stream-disconnection signal to Colyseus server
  playerStreamDisconnect(id: string, webRTCId: string) {
    this.room?.send(Message.DISCONNECT_STREAM, { clientId: id })
    //  this.webRTC?.deleteVideoStream(webRTCId)
  }

  connectToComputer(id: string) {
    this.room?.send(Message.CONNECT_TO_COMPUTER, { computerId: id })
  }

  disconnectFromComputer(id: string) {
    this.room?.send(Message.DISCONNECT_FROM_COMPUTER, { computerId: id })
  }

  connectToWhiteboard(id: string) {
    this.room?.send(Message.CONNECT_TO_WHITEBOARD, { whiteboardId: id })
  }

  disconnectFromWhiteboard(id: string) {
    this.room?.send(Message.DISCONNECT_FROM_WHITEBOARD, { whiteboardId: id })
  }

  onStopScreenShare(id: string) {
    this.room?.send(Message.STOP_SCREEN_SHARE, { computerId: id })
  }

  addChatMessage(content: string) {
    this.room?.send(Message.ADD_CHAT_MESSAGE, { content: content })
  }
}

export default new Network()
