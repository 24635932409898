import { BattleDefimon, Defimon } from "./defimons";
import { BattleManager } from "./battle-manager";
import { Item } from "./items";

class Inventory {
  readonly items: Map<Item<any>, number> = new Map<Item<any>, number>()

  constructor(...items: Item<any>[]) {
    this.addAll(items)
  }

  addAll(items: Item<any>[]) {
    items.forEach(obj => this.add(obj))
  }

  add(item: Item<any>, amount ?: number) {
    if (amount && amount <= 0) return
    const prevAmount = this.items.get(item) ?? 0;
    this.items.set(item, prevAmount + (amount ?? 1))
  }

  forEach(action: (item: Item<any>, amount: number) => void) {
    this.items.forEach((value, item, _) => action(item, value))
  }

  distinctSize() {
    return this.items.size;
  }

  remove(item: Item<any>, amount: number) {
    if (amount <= 0) return
    const prevAmount = this.items.get(item) ?? 0;
    if (prevAmount > 0)
      this.items.set(item, prevAmount - amount)
  }

  removeOne(item: Item<any>) {
    this.remove(item, 1)
  }
}

export class Team {
  readonly name: string;
  readonly defimons: Defimon[] = []
  readonly inventory: Inventory = new Inventory()

  constructor(name: string, ...defimons: Defimon[]) {
    this.name = name;
    defimons.forEach(d => this.defimons.push(d))
  }

  // addAllToInventory(...items: Item<any>[]) {
  //   this.inventory.addAll(items)
  // }

  addToInventory(item: Item<any>, amount?: number) {
    this.inventory.add(item, amount)
  }
}

function takeRandomFrom(array: any[]): any {
  return array[Math.floor(Math.random() * array.length)]
}

export class BattleTeam {
  readonly defimons: BattleDefimon[] = []
  readonly inventory: Inventory
  readonly name: string
  currentActiveDefimon: BattleDefimon

  constructor(team: Team) {
    const defimonList = team.defimons.map(d => new BattleDefimon(d))
    if (defimonList.length === 0) throw new Error("Defimon team can't be empty")

    this.currentActiveDefimon = defimonList[0]

    for (let i = 0; i < Math.min(BattleManager.MAX_DEFIMONS_PER_TEAM, defimonList.length); i++)
      this.defimons.push(defimonList[i])

    this.name = team.name
    this.inventory = team.inventory
  }

  swapWithRandomDefimon(excluded ?: BattleDefimon) {
    const candidates = this.defimons.filter(d => d !== excluded).filter(d => !d.isDead())
    this.swap(takeRandomFrom(candidates))
  }

  swap(defimon: BattleDefimon) {
    this.currentActiveDefimon = defimon
  }

  // attack(targetTeam: BattleTeam) {
  //   const skill = takeRandomFrom(this.currentActiveDefimon.skills);
  //
  //   console.log(this.currentActiveDefimon.name + " used " + skill.name + " on " + targetTeam.currentActiveDefimon.name + " !")
  //   targetTeam.currentActiveDefimon.takeAttack(skill)
  // }

  // chooseMove(targetTeam: BattleTeam): Action {
  //   // TODO : implement choice
  //   return {
  //     type: ActionType.ATTACK,
  //     actionFunction: () => this.attack(targetTeam)
  //   }
  // }
}
