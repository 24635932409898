import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { QUESTS_LIST } from '../quests'

export interface IQuest {
  id: number
  startedAt?: string | null
  endsAt?: string | null
}

const initialStateOfCurrentQuest: IQuest = {
  id: 0,
}

export const questSlice = createSlice({
  name: 'quest',
  initialState: {
    currentQuest: initialStateOfCurrentQuest,
    checkpoints: new Array<{ name: string; status: boolean }>(),
    completedQuests: new Array<number>(),
  },
  reducers: {
    startQuest: (state, action: PayloadAction<IQuest>) => {
      state.currentQuest = action.payload

      // Check if quest exists
      const quest = QUESTS_LIST.find((quest: any) => quest.id === action.payload.id)

      if (quest) {
        // Add Checkpoints
        if (quest.checkpoints) {
          state.checkpoints = new Array<{ name: string; status: boolean }>()

          for (let i = 0; i < quest.checkpoints.length; i++) {
            state.checkpoints.push({ name: quest.checkpoints[i], status: false })
          }
        }
      }
    },
    createCheckpoint: (state, action: PayloadAction<{ name: string; status: boolean }>) => {
      state.checkpoints.push(action.payload)
    },
    completeCheckpoint: (state, action: PayloadAction<string>) => {
      const currentCheckpoint = state.checkpoints.find(
        (checkpoint: any) => checkpoint.name === action.payload
      )

      if (currentCheckpoint) {
        currentCheckpoint.status = true
      }

      storeQuests(state)
    },
    resetCheckpoints: (state) => {
      state.checkpoints = new Array<{ name: string; status: boolean }>()
    },
    completeQuest: (state, action: PayloadAction<number>) => {
      state.currentQuest = initialStateOfCurrentQuest
      state.checkpoints = new Array<{ name: string; status: boolean }>()
      const completedQuests = getCompletedQuests()
      
      // check if quest is already saved (incase react component was triggered twice)
      if (!state.completedQuests.find((quest: number) => quest === action.payload)) {
        state.completedQuests.push(action.payload)
      }

      if (completedQuests && !completedQuests.find((quest: number) => quest === action.payload)) {
        storeQuests(state)
      }
    },
    exitQuest: (state, action: PayloadAction<number>) => {
      state.currentQuest = initialStateOfCurrentQuest
      state.checkpoints = new Array<{ name: string; status: boolean }>()
    },
    setCompletedQuests: (state, action: PayloadAction<number[]>) => {
      state.completedQuests = action.payload
    },
  },
})

export const {
  startQuest,
  completeQuest,
  createCheckpoint,
  completeCheckpoint,
  resetCheckpoints,
  exitQuest,
  setCompletedQuests
} = questSlice.actions

export default questSlice.reducer

function storeQuests(state) {
  const QuestsObject = {
    completedQuests: state.completedQuests,
  }
  localStorage.setItem('defimons_quests', btoa(JSON.stringify(QuestsObject)))
}

export function getCompletedQuests() {
  const codedPrefs = localStorage.getItem('defimons_quests')
  // Check if preferences exist
  if (codedPrefs) {
    return JSON.parse(atob(codedPrefs)).completedQuests
  } else return []
}
