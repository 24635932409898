export const MonstersList = {
  KAHI: {
    id: 2,
    name: 'Kahi',
    level: 25,
    size: 75,
    maxHealth: 90,
    frameSize: 75,
    nbFrames: 30,
  },
  MARUBEE: {
    id: 5,
    name: 'Marubee',
    level: 12,
    size: 75,
    maxHealth: 41,
    frameSize: 75,
    nbFrames: 76,
  },
  TOADLE: {
    id: 7,
    name: 'Toadle',
    level: 12,
    size: 50,
    maxHealth: 41,
    frameSize: 50,
    nbFrames: 23,
  },
  FROGGE: {
    id: 8,
    name: 'Frogge',
    level: 12,
    size: 75,
    scale: 0.35,
    maxHealth: 41,
    frameSize: 75,
    nbFrames: 70,
  },
  SKITTEN: {
    id: 13,
    name: 'Skitten',
    level: 15,
    size: 50,
    maxHealth: 41,
    frameSize: 50,
    nbFrames: 15,
  },
  SPWIDER: {
    id: 19,
    name: 'Spwider',
    level: 12,
    size: 50,
    maxHealth: 43,
    frameSize: 50,
    nbFrames: 97,
  },
  CAIMAN: {
    id: 22,
    name: 'Caiman',
    level: 12,
    size: 50,
    maxHealth: 43,
    frameSize: 50,
    nbFrames: 16,
  },
  PANTHEOR: {
    id: 25,
    name: 'Pantheor',
    level: 13,
    size: 50,
    maxHealth: 46,
    frameSize: 50,
    nbFrames: 73,
  },
  SNETAIL: {
    id: 28,
    name: 'Snetail',
    level: 13,
    size: 50,
    maxHealth: 46,
    frameSize: 50,
    nbFrames: 65,
  },
  PANDORO: {
    id: 31,
    name: 'Pandoro',
    level: 13,
    size: 50,
    maxHealth: 46,
    frameSize: 50,
    nbFrames: 16,
  },
  FROXEN: {
    id: 34,
    name: 'Froxen',
    level: 13,
    size: 50,
    maxHealth: 46,
    frameSize: 50,
    nbFrames: 19,
  },
  GOOP: {
    id: 46,
    name: 'Goop',
    level: 13,
    size: 50,
    maxHealth: 46,
    frameSize: 50,
    nbFrames: 62,
  },
  ARMADRIL: {
    id: 56,
    name: 'Armadril',
    level: 12,
    size: 50,
    maxHealth: 43,
    frameSize: 50,
    nbFrames: 13,
  },
  KAIJU: {
    id: 68,
    name: 'Kaiju',
    level: 12,
    size: 50,
    maxHealth: 43,
    frameSize: 50,
    nbFrames: 17,
  },
  PADDLE: {
    id: 71,
    name: 'Paddle',
    level: 12,
    size: 50,
    maxHealth: 43,
    frameSize: 50,
    nbFrames: 13,
  },
  YETI2: {
    id: 75,
    name: 'Yeti 2',
    level: 12,
    size: 75,
    maxHealth: 43,
    frameSize: 50,
    nbFrames: 13,
  },
}
