import Container = Phaser.GameObjects.Container;
import {Scene} from "phaser";
import {BattleButton} from "./battle-button";

export class PlayerControlsUI extends Container {
    private static readonly BUTTONS_WIDTH = 200;
    private static readonly BUTTONS_HEIGHT = 75;
    private static readonly BUTTONS_GAP = 20;

    private readonly attackButton: BattleButton;
    private readonly itemButton: BattleButton;
    private readonly switchButton: BattleButton;

    constructor(scene: Scene) {
        super(scene);

        this.attackButton = new BattleButton(PlayerControlsUI.BUTTONS_WIDTH, PlayerControlsUI.BUTTONS_HEIGHT, "ATTACK", 0xffffff, "#000000", scene)
        this.itemButton = new BattleButton(PlayerControlsUI.BUTTONS_WIDTH, PlayerControlsUI.BUTTONS_HEIGHT, "USE ITEM", 0xffffff, "#000000", scene)
        this.itemButton.x = this.attackButton.width + PlayerControlsUI.BUTTONS_GAP;
        this.switchButton = new BattleButton(PlayerControlsUI.BUTTONS_WIDTH, PlayerControlsUI.BUTTONS_HEIGHT, "CHANGE DEFIMON", 0xffffff, "#000000", scene)
        this.switchButton.x = this.itemButton.x + this.switchButton.width + PlayerControlsUI.BUTTONS_GAP;

        this.add(this.attackButton);
        this.add(this.itemButton);
        this.add(this.switchButton);

        this.setSize(PlayerControlsUI.BUTTONS_WIDTH * 3 + PlayerControlsUI.BUTTONS_GAP * 2, PlayerControlsUI.BUTTONS_HEIGHT);

        scene.add.existing(this);
    }

    registerAttackCallback(onAttackClicked: () => void) {
        this.attackButton.registerCallback(() => onAttackClicked());
    }

    registerItemCallback(onItemClicked: () => void) {
        this.itemButton.registerCallback(() => onItemClicked());
    }

    registerSwitchCallback(onSwitchClicked: () => void) {
        this.switchButton.registerCallback(() => onSwitchClicked());
    }

    unregisterAllCallbacks() {
        this.attackButton.unregisterCallbacks();
        this.itemButton.unregisterCallbacks();
        this.switchButton.unregisterCallbacks();
    }
}