import React, { useEffect, useRef, useState } from 'react'
import { useWallet } from '../../hooks'
import { CharactersList } from '../../constants/player-sprites'
import { useAppSelector } from '../../hooks'
import { Swiper, SwiperSlide } from 'swiper/react'
import styled from 'styled-components'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import { shortenAddress } from '../../helpers'

const Container = styled.div`
  padding: 30px 0;
  font-family: 'VT323';
`

const CharacterSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 0px;
  margin-bottom: 10px;
  padding: 0 10px;
`

const CharacterProfilePictureSection = styled.div`
  display: flex;
  flex-direction: column;
  --swiper-navigation-size: 24px;

  .swiper-container {
    width: 180px;
    height: 180px;
    border: 4px solid rgb(57, 57, 59);
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .swiper-slide {
    width: 100%;
    height: 100%;
    background: #dbdbe0;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0 auto;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: #000;
    font-weight: 800;
  }
`

const Cover = styled.div`
  width: 180px;
  height: 180px;
  border: 4px solid rgb(57, 57, 59);
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 10px;
  position: absolute;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  text-align: center;
  font-weight: 800;
  color: #fff;
  cursor: pointer;
`

const CharacterInfoSection = styled.div`
  padding-left: 0px;
  padding-top: 20px;
  text-transform: none;
  h3 {
    font-size: 30px;
    line-height: 30px;
  }
`

export default function Profile({ active, setActive, options }: any) {
  const inputRef = useRef<HTMLInputElement>(null)
  const focused = useAppSelector((state) => state.user.focused)
  const name = useAppSelector((state) => state.user.name)
  const { account } = useWallet()

  useEffect(() => {
    if (focused) {
      inputRef.current?.focus()
    }
  }, [focused])
  return (
    <Container>
      <CharacterSection>
        <ProfilePicture active={active} setActive={setActive} options={options} />
        <CharacterInfoSection>
          <h3>Name: {name}</h3>
          <h3>Wallet Address: {account ? shortenAddress(account) : 'Wallet not connected'}</h3>
          <h3>Achievements: Coming Soon</h3>
        </CharacterInfoSection>
      </CharacterSection>
    </Container>
  )
}

function ProfilePicture({ active, setActive, options }) {
  const [showEditScreen, setshowEditScreen] = useState(false)
  const texture = useAppSelector((state) => state.user.texture)
  return (
    <CharacterProfilePictureSection
      onMouseEnter={() => setshowEditScreen(true)}
      onMouseLeave={() => setshowEditScreen(false)}
      onClick={() => setActive(options.find((option) => option.value === 'outfit-selector'))}
    >
      <Swiper navigation={false} spaceBetween={0} slidesPerView={1}>
        <SwiperSlide key={texture}>
          <img
            src={
              '/assets/character/profile-pictures/' +
              CharactersList.find((outfit) => outfit.name === texture)?.fileName +
              '.png'
            }
            alt={texture}
          />
        </SwiperSlide>
      </Swiper>
      {showEditScreen ? <Cover>Change Outfit</Cover> : <></>}
      {/* <Button
      className="outfit-button"
       >
      Change Outfit
    </Button> */}
    </CharacterProfilePictureSection>
  )
}
