import { Currency } from '@slothfi/bank-sdk'

import baseCurrencies from '../utils/baseCurrencies'

export const ETH = {
  ACCOUNTS: 'eth_accounts',
  CHAIN_ID: 'eth_chainId',
  REQ_ACCOUNTS: 'eth_requestAccounts',
  CHAIN_CHANGED: 'chainChanged',
  ACCOUNTS_CHANGED: 'accountsChanged',
}

export const CHAIN_IDS = {
  MAINNET: 1,
  ROPSTEN: 3,
  RINKEBY: 4,
  GOERLI: 5,
  KOVAN: 42,
}

export const CHAIN_NETWORKS = {
  [CHAIN_IDS.MAINNET]: 'mainnet',
  [CHAIN_IDS.ROPSTEN]: 'ropsten',
  [CHAIN_IDS.RINKEBY]: 'rinkeby',
  [CHAIN_IDS.GOERLI]: 'goerli',
  [CHAIN_IDS.KOVAN]: 'kovan',
}
export const CUSTOM_ERRORS = {
  NO_METAMASK: 'No Metamask detected',
  MULTIPLE_WALLETS: 'Multiple wallets detected',
  NO_CONNECTED: 'Please connect to MetaMask.',
  UNSUPPORTED_NETWORK: 'Unsupported network',
  UNSUPPORTED_BROWSER: 'Unsupported browser',
  LUCKY_FAILED: 'Good luck for next time! Try again later.',
}

export const SUPPORTED_CHAIN_ID = CHAIN_IDS.RINKEBY

// HARMONY
export enum NETWORKS {
  MAINNET = 'MAINNET',
  TESTNET = 'TESTNET'
}

export const HARMONY_CHAIN_IDS = {
  [NETWORKS.MAINNET]: 1666600000,
  [NETWORKS.TESTNET]: 1666700000,
}

export const HARMONY_RPC_URLS = {
  [NETWORKS.MAINNET]: 'https://api.harmony.one',
  [NETWORKS.TESTNET]: 'https://api.s0.b.hmny.io',
}

export const NETWORK = process.env.REACT_APP_HARMONY_NETWORK || NETWORKS.MAINNET

export const SUPPORTED_HARMONY_CHAIN_ID = HARMONY_CHAIN_IDS[NETWORK]
export const RPC_PROVIDER_URL = HARMONY_RPC_URLS[NETWORK]

const generatedBaseCurrencies = baseCurrencies(SUPPORTED_HARMONY_CHAIN_ID)
export const BASE_CURRENCY: Currency = generatedBaseCurrencies[0]
export const BASE_WRAPPED_CURRENCY: Currency = generatedBaseCurrencies[1]

// ⛽️ Setting gas limit
export const GAS_LIMIT = 300000
