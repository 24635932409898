import Phaser from 'phaser'
import Player, { sittingShiftData } from './Player'
import network from '../services/Network'
import Game, { IPlayerMovement } from '../scenes/Game'
import phaserGame from '../PhaserGame'
import NPC from './NPC'
import { get_fromToDirectionValues, getValuesClosestToPointFive } from '../util'
import store from '../stores'
import PlayerSelector from './PlayerSelector'
import Computer from '../items/Computer'
import Whiteboard from '../items/Whiteboard'
import { ItemType } from '../../../types/Items'
import { PlayerBehavior } from '../../../types/PlayerBehavior'
import Chair from '../items/Chair'
import Unlockable from '../items/Unlockable'
import isTouchScreen from '../utils/isTouchScreen'
import { getCanvasSpaceCoordinates } from '../utils/camera'
import { setInGamePosition } from '../stores/UserStore'
import { handleUnlockMiniGame } from '../scenes/methods'
import Info from '../items/Info'

export default class MyPlayer extends Player {
  playContainerBody: Phaser.Physics.Arcade.Body
  speed: number
  npcSelector!: Phaser.GameObjects.Container
  skate: number
  npcSelectorBody!: Phaser.Physics.Arcade.Body
  private chairOnSit?: Chair
  escalatorOnTouch?: Phaser.GameObjects.Sprite

  private current_npcDialoguingWith!: NPC

  constructor(
    scene: Game,
    x: number,
    y: number,
    texture: string,
    id: string,
    webRTCId: string,
    name: string,
    readyToConnect: boolean,
    videoConnected: boolean,
    map: string,
    frame?: string | number
  ) {
    super(scene, x, y, texture, id, webRTCId, name, readyToConnect, videoConnected, map, frame)
    this.playContainerBody = this.playerContainer.body as Phaser.Physics.Arcade.Body
    this.speed = 200
    this.skate = 300
  }

  setPlayerName(name: string) {
    this.name = name
    this.playerName.setText(name)
    network.updatePlayerName(name)
  }

  setPlayerMap(map: string) {
    this.map = map
  }

  setPlayerTexture(texture: string) {
    this.playerTexture = texture
    const parts = this.anims.currentAnim.key.split('_')
    parts[0] = this.playerTexture
    this.play(parts.join('_'), true)
    network.updatePlayer(this.x, this.y, this.anims.currentAnim.key, this.map)
  }

  onDialogueOpened(npc: NPC): void {
    this.current_npcDialoguingWith = npc
    const currentScene = phaserGame.scene.keys.game as Game
    if (currentScene.cursors) {
      this.setCursorsIsDownToFalse()
    }
    this.faceNPC()
  }

  update(
    playerMovement: IPlayerMovement,
    cursors: any,
    playerSelector: PlayerSelector,
    playerInteraction: boolean,
    keyShift: Phaser.Input.Keyboard.Key,
    keyE: Phaser.Input.Keyboard.Key,
    keyR: Phaser.Input.Keyboard.Key
  ) {
    const currentScene = phaserGame.scene.keys.game as Game
    const activeMenu = store.getState().menu.activeMenu
    const autoSkating = store.getState().pref.autoSkating
    if (!cursors) return
    if (!['', 'chat'].includes(activeMenu)) return

    const item = playerSelector.selectedItem

    if (Phaser.Input.Keyboard.JustDown(keyR)) {
      switch (item?.itemType) {
        case ItemType.COMPUTER:
          const computer = item as Computer
          if (this.webRTCId) {
            computer.openDialog(this.webRTCId)
          }
          break
        case ItemType.WHITEBOARD:
          const whiteboard = item as Whiteboard
          whiteboard.openDialog()
          break
      }
    }

    switch (this.playerBehavior) {
      case PlayerBehavior.IDLE:
        let vx = 0
        let vy = 0

        // if press E in front of selected object
        this.handleObjectInteraction(playerSelector, keyE, playerInteraction, item)

        // update velocity according to keys pressed
        if (cursors.left?.isDown || playerMovement.left) {
          if ((keyShift.isDown || autoSkating) && currentScene.isOutside) {
            vx -= this.skate
          } else {
            vx -= this.speed
          }
        }
        if (cursors.right?.isDown || playerMovement.right) {
          if ((keyShift.isDown || autoSkating) && currentScene.isOutside) {
            vx += this.skate
          } else {
            vx += this.speed
          }
        }
        if (cursors.up?.isDown || playerMovement.up) {
          if ((keyShift.isDown || autoSkating) && currentScene.isOutside) {
            vy -= this.skate
          } else {
            vy -= this.speed
          }
        }
        if (cursors.down?.isDown || playerMovement.down) {
          if ((keyShift.isDown || autoSkating) && currentScene.isOutside) {
            vy += this.skate
          } else {
            vy += this.speed
          }
        }

        if ((keyShift.isDown || autoSkating) && currentScene.isOutside) {
          // update character velocity
          this.setVelocity(vx, vy)
          this.body.velocity.setLength(this.skate)
          // also update playerNameContainer velocity
          this.playContainerBody.setVelocity(vx, vy)
          this.playContainerBody.velocity.setLength(this.skate)
        } else {
          // update character velocity
          this.setVelocity(vx, vy)
          this.body.velocity.setLength(this.speed)
          // also update playerNameContainer velocity
          this.playContainerBody.setVelocity(vx, vy)
          this.playContainerBody.velocity.setLength(this.speed)
        }

        //while currently on an escalator
        //if myPlayer and the current escalatorOnTouch stop overlapping, clear the escalatorOnTouch
        if (this.escalatorOnTouch) {
          if (!this.scene.physics.overlap(this, this.escalatorOnTouch)) {
            this.escalatorOnTouch = undefined
          } else {
            const escalatorDirection = this.escalatorOnTouch.anims.currentAnim.key.split('_')[1]
            const escalatorSpeed = escalatorDirection === 'up' ? -100 : 100
            this.setVelocityY(vy + escalatorSpeed)
            this.playerContainerBody.setVelocityY(vy + escalatorSpeed)
          }
        }
        // update animation according to velocity and send new location and anim to server
        this.handlePlayerAnims(vx, vy, playerMovement, keyShift)

        break

      case PlayerBehavior.SITTING:
        // back to idle if player press E while sitting
        if (Phaser.Input.Keyboard.JustDown(keyE) || playerInteraction) {
          currentScene.handlePlayerInteraction(false)
          const parts = this.anims.currentAnim.key.split('_')
          parts[1] = 'idle'
          this.play(parts.join('_'), true)
          this.playerBehavior = PlayerBehavior.IDLE
          this.chairOnSit?.clearDialogBox()
          playerSelector.setPosition(this.x, this.y)
          playerSelector.update(this, playerMovement, cursors, keyShift)
        }
        break

      default:
        break
    }

    const canvasSpaceCoordinates = getCanvasSpaceCoordinates(
      this,
      currentScene.cameras.main,
      phaserGame.canvas
    )
    store.dispatch(setInGamePosition(canvasSpaceCoordinates))

    // send new location and anim to server
    network.updatePlayer(this.x, this.y, this.anims.currentAnim.key, this.map)
    this.playerName.x = this.x
    this.playerName.y = this.y - 32
  }

  setCursorsIsDownToFalse(): void {
    const currentScene = phaserGame.scene.keys.game as Game
    const cursorKeys = Object.keys(currentScene.cursors)
    cursorKeys.forEach((cursorKey) => {
      const cursor = currentScene.cursors[cursorKey]
      cursor.isDown = false
    })
    const altCursorKeys = Object.keys(currentScene.altCursors)
    altCursorKeys.forEach((cursorKey) => {
      const cursor = currentScene.altCursors[cursorKey]
      cursor.isDown = false
    })
  }

  faceNPC(): void {
    if (!this.current_npcDialoguingWith) {
      return
    }
    const fromPos = new Phaser.Math.Vector2(this.x, this.y)
    const toPos_npc = new Phaser.Math.Vector2(
      this.current_npcDialoguingWith.x,
      this.current_npcDialoguingWith.y
    )
    const [, , playerToNPCDirection] = get_fromToDirectionValues(fromPos, toPos_npc)

    const [rvx, rvy] = getValuesClosestToPointFive(playerToNPCDirection.x, playerToNPCDirection.y)
    if (rvx > 0) {
      this.play(`${this.playerTexture}_idle_right`, true)
    } else if (rvx < 0) {
      this.play(`${this.playerTexture}_idle_left`, true)
    } else if (rvy > 0) {
      this.play(`${this.playerTexture}_idle_down`, true)
    } else if (rvy < 0) {
      this.play(`${this.playerTexture}_idle_up`, true)
    }
  }

  handlePlayerAnims(
    vx: number,
    vy: number,
    playerMovement: IPlayerMovement,
    keyShift: Phaser.Input.Keyboard.Key
  ): void {
    const currentScene = phaserGame.scene.keys.game as Game
    const { messageDialogOpen } = store.getState().message
    const autoSkating = store.getState().pref.autoSkating
    if (messageDialogOpen) {
      return
    }

    if (vx > 0 || playerMovement.angle === 'right') {
      if ((keyShift.isDown || autoSkating) && currentScene.isOutside) {
        this.play(`${this.playerTexture}_skate_right`, true)
      } else {
        this.play(`${this.playerTexture}_run_right`, true)
      }
    } else if (vx < 0 || playerMovement.angle === 'left') {
      if ((keyShift.isDown || autoSkating) && currentScene.isOutside) {
        this.play(`${this.playerTexture}_skate_left`, true)
      } else {
        this.play(`${this.playerTexture}_run_left`, true)
      }
    } else if (vy > 0 || playerMovement.angle === 'down') {
      if ((keyShift.isDown || autoSkating) && currentScene.isOutside) {
        this.play(`${this.playerTexture}_skate_down`, true)
      } else {
        this.play(`${this.playerTexture}_run_down`, true)
      }
    } else if (vy < 0 || playerMovement.angle === 'up') {
      if ((keyShift.isDown || autoSkating) && currentScene.isOutside) {
        this.play(`${this.playerTexture}_skate_up`, true)
      } else {
        this.play(`${this.playerTexture}_run_up`, true)
      }
    } else {
      const parts = this.anims.currentAnim.key.split('_')
      parts[1] = 'idle'
      const newAnim = parts.join('_')
      // this prevents idle animation keeps getting called
      if (this.anims.currentAnim.key !== newAnim) {
        this.play(parts.join('_'), true)
        // send new location and anim to server
      }
    }
  }

  handleObjectInteraction(
    playerSelector: PlayerSelector,
    keyE: Phaser.Input.Keyboard.Key,
    playerInteraction: boolean,
    item: any
  ) {
    const currentScene = phaserGame.scene.keys.game as Game
    if (Phaser.Input.Keyboard.JustDown(keyE) || playerInteraction) {
      currentScene.handlePlayerInteraction(false)
      currentScene.resetNotification()

      if (item?.itemType === ItemType.CHAIR) {
        this.setCursorsIsDownToFalse()

        const chairItem = item as Chair
        /**
         * move player to the chair and play sit animation
         * a delay is called to wait for player movement (from previous velocity) to end
         * as the player tends to move one more frame before sitting down causing player
         * not sitting at the center of the chair
         */
        this.scene.time.addEvent({
          delay: 10,
          callback: () => {
            // update character velocity and position
            this.setVelocity(0, 0)
            if (chairItem.itemDirection) {
              this.setPosition(
                chairItem.x + sittingShiftData[chairItem.itemDirection][0],
                chairItem.y + sittingShiftData[chairItem.itemDirection][1]
              ).setDepth(chairItem.depth + sittingShiftData[chairItem.itemDirection][2])
              // also update playerNameContainer velocity and position
              this.playerContainerBody.setVelocity(0, 0)
              this.playerContainer.setPosition(
                chairItem.x + sittingShiftData[chairItem.itemDirection][0],
                chairItem.y +
                  sittingShiftData[chairItem.itemDirection][1] +
                  this.playerContainerOffsetY
              )
            }

            this.play(`${this.playerTexture}_sit_${chairItem.itemDirection}`, true)
            playerSelector.selectedItem = undefined
            if (chairItem.itemDirection === 'up') {
              playerSelector.setPosition(this.x, this.y - this.height)
            } else {
              playerSelector.setPosition(0, 0)
            }
          },
          loop: false,
        })
        // set up new dialog as player sits down
        chairItem.clearDialogBox()

        if (isTouchScreen()) {
          chairItem.setDialogBox('Tap button to leave')
        } else {
          chairItem.setDialogBox('Press E to leave')
        }
        this.chairOnSit = chairItem
        this.playerBehavior = PlayerBehavior.SITTING
        return
      } else if (item?.itemType === ItemType.UNLOCKABLE) {
        this.setCursorsIsDownToFalse()
        const dumpsterItem = item as Unlockable

        if (dumpsterItem.isOpen) {
          dumpsterItem.setFrame(0)
          dumpsterItem.isOpen = false
        } else {
          if (dumpsterItem.isUnlocked) {
            dumpsterItem.setFrame(6)
            dumpsterItem.isOpen = true
          } else {
            if (isTouchScreen()) {
              dumpsterItem.setDialogBox('Tap anywhere to unlock')
            } else {
              dumpsterItem.setDialogBox('Press Space to unlock')
            }

            dumpsterItem.inMinigame = true
            handleUnlockMiniGame(
              dumpsterItem.name + `-unlock`,
              5,
              () => {
                dumpsterItem.setFrame(6)
                dumpsterItem.isOpen = true
                dumpsterItem.isUnlocked = true
                dumpsterItem.inMinigame = false
              },
              () => {
                dumpsterItem.inMinigame = false
              }
            )
          }
        }
        return
      } else if (item?.itemType === ItemType.INFO) {
        this.setCursorsIsDownToFalse()
        const infoItem = item as Info
        infoItem.onInteraction()
        return
      }
      // else {
      //     const data: IDefimonBattleSceneData = {
      //         playerName: this.name
      //     };
      //     currentScene.scene.launch(DefimonBattle.KEY, data);
      // }
    }
  }
}
