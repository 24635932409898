import React from 'react'
import styled from 'styled-components'

const Background1 = styled.div`
  z-index: 1;
  position: absolute;
  inset: 0px -4px;
  background: rgb(34, 32, 52) none repeat scroll 0% 0%;
`

const Background2 = styled.div`
  z-index: 1;
  position: absolute;
  inset: -4px 0px;
  background: rgb(34, 32, 52) none repeat scroll 0% 0%;
`

const Main = styled.div`
  position: relative;
  z-index: 2;
  background: rgb(229, 228, 231) none repeat scroll 0% 0%;
  height: 100%;
`

export default function Border(props: any) {
  return (
    <>
      <Background1 />
      <Background2 />
      <Main>{props.children}</Main>
    </>
  )
}
