import styled from 'styled-components'
import { useAppSelector } from '../hooks'
import store from '../stores'
import { setNotification } from '../stores/NotificationStore'

const DialogWindow = styled.div`
  image-rendering: pixelated;
  border: solid;
  position: absolute;
  font-family: 'VT323';
  width: 400px;
  height: 80px;
  display: flex;
  flex-direction: row;
  z-index: 99;
  padding: 10px;
  border-radius: 0.5rem;
  left: 0%;
  right: 0%;
  border: single 0.5rem #000;
  box-shadow: inset 0 0.05rem 0 #000, 0 0.05rem 0 #000;
  background-color: #ffffff;
  color: #000;
  bottom: 20px;
  margin: 0 auto;
  pointer-events: auto;
  cursor: pointer;
  img {
    width: 56px;
    margin-right: 10px;
  }
  h1 {
    font-size: 30px;
    line-height: 32px;
    margin: 0px;
  }
  h2 {
    font-size: 20px;
    line-height: 22px;
    margin: 0px;
  }

  @media (max-width: 700px) {
    max-width: 340px;
    width: 100%;
    height: 70px;
    bottom: 90px;
    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
    h1 {
      font-size: 24px;
      line-height: 26px;
      margin: 0px;
    }
    h2 {
      font-size: 16px;
      line-height: 18px;
      margin: 0px;
    }
  }
`

export default function NotificationModal() {
  const content = useAppSelector((state) => state.notification.content)
  const type = useAppSelector((state) => state.notification.type)
  const imageURI = useAppSelector((state) => state.notification.imageURI)
  //const timer = useAppSelector((state) => state.notification.timer)

  let typeHeader = ''
  let typeImage = ''

  const onClick = () => {
    console.log('clicks', content)
    store.dispatch(
      setNotification({
        content: '',
        type: '',
        timer: 0,
        imageURI: '',
      })
    )
  }

  // useEffect(() => {
  //   if (timer > 0 && content) {
  //       setTimeout(function () {
  //         store.dispatch(setNotification({
  //           timer: timer - 1
  //         }))
  //       }, 1000)
  //   }

  //   if (timer === 0) {
  //     store.dispatch(
  //       setNotification({
  //         content: '',
  //         type: '',
  //         timer: 0,
  //         imageURI: ''
  //       })
  //     )
  //   }
  // }, [content, timer])

  // Handle Notification Type
  if (['new'].includes(type)) {
    typeHeader = 'New Mission Started'
    typeImage = '/assets/achievements/red.png'
  } else if (['error', 'disappearing-error'].includes(type)) {
    typeHeader = 'Error encountered'
  } else if (['hint'].includes(type)) {
    typeHeader = 'Hint'
    typeImage = '/assets/achievements/hint.png'
  } else if (['failed'].includes(type)) {
    typeHeader = 'Failed'
    typeImage = '/assets/achievements/red.png'
  } else if (['item'].includes(type)) {
    typeHeader = 'Item Found'
  } else {
    typeHeader = 'Mission Completed'
    typeImage = '/assets/achievements/purple.png'
  }

  if (imageURI !== '') {
    typeImage = imageURI
  }

  return (
    <>
      {content ? (
        <DialogWindow onClick={onClick}>
          {typeImage ? <img src={typeImage} alt="scroll" /> : <></>}
          <div>
            <h2>{typeHeader}</h2>
            <h1>{content}</h1>
          </div>
        </DialogWindow>
      ) : (
        <></>
      )}
    </>
  )
}
