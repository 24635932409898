import { npcPathFindingDatas } from '../characters/NPCPathFIndingDatas'
import { NPCdata } from '../dialogue/npc-data'

export const gameConfig = {
  defaultMap: 'yorokobiTown',
  maps: {
    intro: {
      name: 'intro',
      json: 'assets/map/forest.json',
      isOutside: true,
      tilesets: [
        {
          name: 'forest',
          key: 'Forest',
        },
      ],
      music: [
        {
          name: 'yorokobi-score',
          key: 'yorokobi-score',
        },
      ],
      anims: [],
      npcs: [],
      spawnPoints: {
        default: {
          x: 270,
          y: 195,
          frame: 'up',
        },
      },
    },
    yorokobiTown: {
      name: 'yorokobi-town',
      json: 'assets/map/yorokobi-town.json',
      isOutside: true,
      tilesets: [
        {
          name: 'Modern_Exteriors_Complete_Tileset_32x32',
          key: 'TilesTown',
        },
        {
          name: 'custom_tileset',
          key: 'Custom',
        },
        {
          name: 'custom_animations',
          key: 'CustomAnimations',
        },
        {
          name: 'Early_Birds_Tileset_32x32',
          key: 'EarlyBird',
        },
      ],
      music: [
        {
          name: 'yorokobi-score',
          key: 'yorokobi-score',
        },
      ],
      anims: [],
      npcs: [
        {
          name: NPCdata.find((npc) => npc.id === 'uncle-tony')?.name,
          texture: 'old-man-npc',
          id: 'uncle-tony',
          type: 'normal',
          spawnPoint: {
            x: 290,
            y: 1632,
            frame: 'right',
          },
          pathFindingData: npcPathFindingDatas['uncle-tony'],
        },
        {
          name: NPCdata.find((npc) => npc.id === 'lost-pirate')?.name,
          texture: 'pirate-npc',
          id: 'lost-pirate',
          type: 'normal',
          spawnPoint: {
            x: 2030,
            y: 554,
            frame: 'left',
          },
          pathFindingData: npcPathFindingDatas['lost-pirate'],
        },
        {
          name: NPCdata.find((npc) => npc.id === 'young-hunter')?.name,
          texture: 'teenager-w-cellphone-npc',
          id: 'young-hunter',
          type: 'normal',
          spawnPoint: {
            x: 3862,
            y: 1987,
            frame: 'down',
          },
          //pathFindingData: npcPathFindingDatas['young-hunter'],
        },
        {
          name: NPCdata.find((npc) => npc.id === 'fireman-goro')?.name,
          texture: 'firefighter-npc',
          id: 'fireman-goro',
          type: 'normal',
          spawnPoint: {
            x: 3153,
            y: 1236,
            frame: 'down',
          },
          pathFindingData: npcPathFindingDatas['fireman-goro'],
        },
        {
          name: NPCdata.find((npc) => npc.id === 'helper-akira')?.name,
          texture: 'store-helper-npc',
          id: 'helper-akira',
          type: 'normal',
          spawnPoint: {
            x: 2231,
            y: 767,
            frame: 'down',
          },
        },
      ],
      spawnPoints: {
        default: {
          x: 2177,
          y: 2457,
          frame: 'up',
        },
        outsideYorokobiTown: {
          x: 4000,
          y: 450,
          frame: 'left',
        },
        yorokobiBank: {
          x: 960,
          y: 750,
          frame: 'down',
        },
        yorokobiMarket: {
          x: 2403,
          y: 872,
          frame: 'down',
        },
        yorokobiHospital: {
          x: 3390,
          y: 1587,
          frame: 'down',
        },
        yorokobiPetStore: {
          x: 3494,
          y: 803,
          frame: 'down',
        },
      },
    },
    outsideYorokobiTown: {
      name: 'outside-yorokobi-town',
      json: 'assets/map/outside-yorokobi-town.json',
      isOutside: true,
      tilesets: [
        {
          name: 'Modern_Exteriors_Complete_Tileset_32x32',
          key: 'TilesTown',
        },

        {
          name: 'outside-yorokobi-town',
          key: 'OutsideYorokobiTown',
        },
        {
          name: 'outside-yorokobi-town-animations',
          key: 'OutsideYorokobiTownAnimations',
        },
        {
          name: 'worksite-door',
          key: 'WorksiteDoor',
        },
      ],
      music: [
        {
          name: 'yorokobi-score',
          key: 'yorokobi-score',
        },
      ],
      anims: [],
      npcs: [
        {
          name: NPCdata.find((npc) => npc.id === 'construction-worker-tommy')?.name,
          texture: 'construction-worker-npc',
          id: 'construction-worker-tommy',
          type: 'normal',
          spawnPoint: {
            x: 2722,
            y: 750,
            frame: 'down',
          },
        },
      ],
      spawnPoints: {
        default: {
          x: 70,
          y: 450,
          frame: 'right',
        },
        yorokobiTown: {
          x: 70,
          y: 450,
          frame: 'right',
        },
        yorokobiCafe: {
          x: 643,
          y: 1000,
          frame: 'down',
        },
        yorokobiClothingStore: {
          x: 1420,
          y: 990,
          frame: 'down',
        },
        yorokobiPropertyStore: {
          x: 2337,
          y: 940,
          frame: 'down',
        },
      },
    },
    yorokobiHospital: {
      name: 'yorokobi-hospital',
      json: 'assets/map/yorokobi-hospital.json',
      isOutside: false,
      tilesets: [
        {
          name: '1_Generic_32x32',
          key: 'Generic',
        },
        {
          name: '19_Hospital_32x32',
          key: 'Hospital',
        },
        {
          name: 'Room_Builder_32x32',
          key: 'RoomBuilder',
        },
      ],
      music: [
        {
          name: 'honky-tonk',
          key: 'honky-tonk',
        },
      ],
      anims: [],
      npcs: [
        {
          name: NPCdata.find((npc) => npc.id === 'nurse-mary')?.name,
          texture: 'nurse-npc',
          id: 'nurse-mary',
          type: 'normal',
          spawnPoint: {
            x: 371,
            y: 485,
            frame: 'down',
          },
        },
        {
          name: NPCdata.find((npc) => npc.id === 'doctor-keisuke')?.name,
          texture: 'doctor-npc',
          id: 'doctor-keisuke',
          type: 'normal',
          spawnPoint: {
            x: 822,
            y: 313,
            frame: 'left',
          },
        },
      ],
      spawnPoints: {
        default: {
          x: 436,
          y: 711,
          frame: 'up',
        },
        yorokobiTown: {
          x: 436,
          y: 711,
          frame: 'up',
        },
        yorokobiHospitalSecond: {
          x: 640,
          y: 220,
          frame: 'down',
        },
      },
    },
    yorokobiHospitalSecond: {
      name: 'yorokobi-hospital-second',
      json: 'assets/map/yorokobi-hospital-second.json',
      isOutside: false,
      tilesets: [
        {
          name: '1_Generic_32x32',
          key: 'Generic',
        },
        {
          name: '19_Hospital_32x32',
          key: 'Hospital',
        },
        {
          name: 'Room_Builder_32x32',
          key: 'RoomBuilder',
        },
        {
          name: 'xrayscreen_arm',
          key: 'XRayArm',
        },
        {
          name: 'xrayscreen_head',
          key: 'XRayHead',
        },
      ],
      music: [
        {
          name: 'honky-tonk',
          key: 'honky-tonk',
        },
      ],
      anims: [],
      npcs: [],
      spawnPoints: {
        default: {
          x: 639,
          y: 219,
          frame: 'down',
        },
        yorokobiHospital: {
          x: 639,
          y: 219,
          frame: 'down',
        },
      },
    },
    yorokobiMarket: {
      name: 'yorokobi-market',
      json: 'assets/map/yorokobi-market.json',
      isOutside: false,
      tilesets: [
        {
          name: '1_Generic_32x32',
          key: 'Generic',
        },
        {
          name: '16_Grocery_store_32x32',
          key: 'Grocery',
        },
        {
          name: '19_Hospital_32x32',
          key: 'Hospital',
        },
        {
          name: 'Room_Builder_32x32',
          key: 'RoomBuilder',
        },
      ],
      music: [
        {
          name: 'dreamy-flute',
          key: 'dreamy-flute',
        },
      ],
      anims: [],
      npcs: [
        {
          name: NPCdata.find((npc) => npc.id === 'grocer-haruka')?.name,
          texture: 'fruit-store-reception-npc',
          id: 'grocer-haruka',
          type: 'normal',
          spawnPoint: {
            x: 779,
            y: 136,
            frame: 'down',
          },
        },
      ],
      spawnPoints: {
        default: {
          x: 577,
          y: 483,
          frame: 'up',
        },
        yorokobiTown: {
          x: 577,
          y: 483,
          frame: 'up',
        },
      },
    },
    yorokobiBank: {
      name: 'yorokobi-bank',
      json: 'assets/map/yorokobi-bank.json',
      isOutside: false,
      tilesets: [
        {
          name: '1_Generic_32x32',
          key: 'Generic',
        },
        {
          name: '14_Basement_32x32',
          key: 'Basement',
        },
        {
          name: '19_Hospital_32x32',
          key: 'Hospital',
        },
        {
          name: 'Room_Builder_32x32',
          key: 'RoomBuilder',
        },
        {
          name: 'Modern_Office_Black_Shadow_32x32',
          key: 'ModernOffice',
        },
        {
          name: 'Room_Builder_Office_32x32',
          key: 'RoomBuilderOffice',
        },
      ],
      music: [
        {
          name: 'bouncy-waltz',
          key: 'bouncy-waltz',
        },
      ],
      anims: [],
      npcs: [
        {
          name: NPCdata.find((npc) => npc.id === 'banker-masahi')?.name,
          texture: 'banker-npc',
          type: 'normal',
          id: 'banker-masahi',
          spawnPoint: {
            x: 283,
            y: 262,
            frame: 'down',
          },
        },
        {
          name: NPCdata.find((npc) => npc.id === 'banker-megumi')?.name,
          texture: 'female-banker-npc',
          type: 'normal',
          id: 'banker-megumi',
          spawnPoint: {
            x: 475,
            y: 262,
            frame: 'down',
          },
        },
        {
          name: NPCdata.find((npc) => npc.id === 'officer-ken')?.name,
          texture: 'police-npc',
          type: 'normal',
          id: 'officer-ken',
          spawnPoint: {
            x: 892,
            y: 175,
            frame: 'down',
          },
        },
      ],
      spawnPoints: {
        default: {
          x: 892,
          y: 210,
          frame: 'up',
        },
        yorokobiTown: {
          x: 640,
          y: 515,
          frame: 'up',
        },
      },
    },
    yorokobiBankLocked: {
      name: 'yorokobi-bank-closed',
      json: 'assets/map/yorokobi-bank-locked.json',
      isOutside: false,
      tilesets: [
        {
          name: 'Room_Builder_32x32',
          key: 'RoomBuilder',
        },
      ],
      music: [],
      anims: [],
      npcs: [],
      spawnPoints: {
        default: {
          x: 1300,
          y: 650,
          frame: 'down',
        },
        yorokobiTown: {
          x: 640,
          y: 515,
          frame: 'up',
        },
      },
    },
    yorokobiPetStore: {
      name: 'yorokobi-pet-store',
      json: 'assets/map/yorokobi-pet-store.json',
      isOutside: false,
      tilesets: [
        {
          name: '1_Generic_32x32',
          key: 'Generic',
        },
        {
          name: '18_Jail_32x32',
          key: 'Jail',
        },
        {
          name: 'Pet_Store',
          key: 'PetStore',
        },
        {
          name: 'Room_Builder_32x32',
          key: 'RoomBuilder',
        },
      ],
      music: [
        {
          name: 'bubbley-dreamscape',
          key: 'bubbley-dreamscape',
        },
      ],
      anims: [],
      npcs: [
        {
          name: NPCdata.find((npc) => npc.id === 'doctor-yamato')?.name,
          texture: 'pet-store-vet-npc',
          type: 'normal',
          id: 'doctor-yamato',
          spawnPoint: {
            x: 610,
            y: 518,
            frame: 'down',
          },
        },
      ],
      spawnPoints: {
        default: {
          x: 639,
          y: 704,
          frame: 'up',
        },
        yorokobiTown: {
          x: 639,
          y: 704,
          frame: 'up',
        },
      },
    },
    yorokobiPropertyStore: {
      name: 'yorokobi-property-store',
      json: 'assets/map/yorokobi-property-store.json',
      isOutside: false,
      tilesets: [
        {
          name: '19_Hospital_32x32',
          key: 'Hospital',
        },
        {
          name: 'Modern_Office_Black_Shadow_32x32',
          key: 'ModernOfficeBlack',
        },
        {
          name: 'Room_Builder_32x32',
          key: 'RoomBuilder',
        },
      ],
      music: [
        {
          name: 'dreamy-flute',
          key: 'dreamy-flute',
        },
      ],
      anims: [],
      npcs: [
        {
          name: NPCdata.find((npc) => npc.id === 'salesman-eticha')?.name,
          texture: 'property-salesman-npc',
          id: 'salesman-eticha',
          type: 'normal',
          spawnPoint: {
            x: 660,
            y: 200,
            frame: 'down',
          },
        },
      ],
      spawnPoints: {
        default: {
          x: 657,
          y: 540,
          frame: 'up',
        },
        outsideYorokobiTown: {
          x: 657,
          y: 540,
          frame: 'up',
        },
      },
    },
    yorokobiCafe: {
      name: 'yorokobi-cafe',
      json: 'assets/map/yorokobi-cafe.json',
      isOutside: false,
      tilesets: [
        {
          name: '12_Kitchen_32x32',
          key: 'Kitchen',
        },
        {
          name: '24_Ice_Cream_Shop_32x32',
          key: 'IceCreamShop',
        },
        {
          name: 'Room_Builder_32x32',
          key: 'RoomBuilder',
        },
      ],
      music: [
        {
          name: 'dreamy-flute',
          key: 'dreamy-flute',
        },
      ],
      anims: [],
      npcs: [
        {
          name: NPCdata.find((npc) => npc.id === 'baker-toro')?.name,
          texture: 'cafe-owner-npc',
          id: 'baker-toro',
          type: 'normal',
          spawnPoint: {
            x: 404,
            y: 490,
            frame: 'down',
          },
        },
        {
          name: NPCdata.find((npc) => npc.id === 'samurai-musashi')?.name,
          texture: 'samurai-npc',
          id: 'samurai-musashi',
          type: 'normal',
          spawnPoint: {
            x: 279,
            y: 757,
            frame: 'right',
          },
        },
      ],
      spawnPoints: {
        default: {
          x: 656,
          y: 760,
          frame: 'up',
        },
        outsideYorokobiTown: {
          x: 656,
          y: 760,
          frame: 'up',
        },
      },
    },
    yorokobiClothingStore: {
      name: 'yorokobi-clothing-store',
      json: 'assets/map/yorokobi-clothing-store.json',
      isOutside: false,
      tilesets: [
        {
          name: '21_Clothing_Store_32x32',
          key: 'ClothingStore',
        },
        {
          name: 'Room_Builder_32x32',
          key: 'RoomBuilder',
        },
      ],
      music: [
        {
          name: 'dreamy-flute',
          key: 'dreamy-flute',
        },
      ],
      anims: [],
      npcs: [
        {
          name: NPCdata.find((npc) => npc.id === 'salesman-kenechi')?.name,
          texture: 'cloth-salesman-npc',
          id: 'salesman-kenechi',
          type: 'normal',
          spawnPoint: {
            x: 764,
            y: 238,
            frame: 'down',
          },
        },
      ],
      spawnPoints: {
        default: {
          x: 629,
          y: 413,
          frame: 'up',
        },
        outsideYorokobiTown: {
          x: 629,
          y: 413,
          frame: 'up',
        },
      },
    },
    // yorokobiSchool: {
    //   name: 'yorokobi-school',
    //   json: 'assets/map/yorokobi-school.json',
    //   isOutside: false,
    //   tilesets: [
    //     {
    //       name: '5_Classroom_and_library_32x32',
    //       key: 'Classroom',
    //     },
    //     {
    //       name: '2_LivingRoom_32x32',
    //       key: 'LivingRoom',
    //     },
    //     {
    //       name: 'Room_Builder_32x32',
    //       key: 'RoomBuilder',
    //     },
    //     {
    //       name: '14_Basement_32x32',
    //       key: 'Basement',
    //     },
    //   ],
    //   music: [
    //     {
    //       name: 'yorokobi-score',
    //       key: 'yorokobi-score',
    //     },
    //   ],
    //   anims: [],
    //   npcs: [
    //     {
    //       name: 'Teacher',
    //       texture: 'old-man-npc',
    //       id: 'old-teacher',
    //       spawnPoint: {
    //         x: 1018,
    //         y: 435,
    //         frame: 'down',
    //       },
    //     },
    //   ],
    //   spawnPoints: {
    //     default: {
    //       x: 1278,
    //       y: 924,
    //       frame: 'up',
    //     },
    //   },
    // },
    // yorokobiApartment: {
    //   name: 'yorokobi-apartment',
    //   json: 'assets/map/yorokobi-apartment.json',
    //   isOutside: false,
    //   tilesets: [
    //     {
    //       name: '1_Generic_32x32',
    //       key: 'Generic',
    //     },
    //     {
    //       name: '14_Basement_32x32',
    //       key: 'Basement',
    //     },
    //     {
    //       name: '19_Hospital_32x32',
    //       key: 'Hospital',
    //     },
    //     {
    //       name: 'Room_Builder_32x32',
    //       key: 'RoomBuilder',
    //     },
    //     {
    //       name: 'Modern_Office_Black_Shadow_32x32',
    //       key: 'ModernOffice',
    //     },
    //     {
    //       name: 'Room_Builder_Office_32x32',
    //       key: 'RoomBuilderOffice',
    //     },
    //   ],
    //   music: [
    //     {
    //       name: 'bouncy-waltz',
    //       key: 'bouncy-waltz',
    //     },
    //   ],
    //   anims: [],
    //   npcs: [],
    //   spawnPoints: {
    //     default: {
    //       x: 640,
    //       y: 515,
    //       frame: 'up',
    //     },
    //     yorokobiTown: {
    //       x: 640,
    //       y: 515,
    //       frame: 'up',
    //     },
    //   },
    // },
  },
}
