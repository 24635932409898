import {BattleTeam, Team} from "./team"
import {ActionType} from "../scenes/DefimonBattle";
import {ActiveBattleDefimonUI} from "./ui/active-battle-defimon-ui";
import {PlayerControlsUI} from "./ui/player-controls-ui";
import {Scene} from "phaser";
import {DialogueManager} from "./ui/dialogue/DialogueManager";
import {StateMachine} from "../utils/states/stateMachine";
import {BattleStartingState} from "./states/battle-starting-state";
import {PlayerTurnState} from "./states/player-turn-state";
import {ResolutionState} from "./states/resolution-state";

export type Action = Readonly<{
    type: ActionType;
    text: string[];
    actionFunction: () => void;
}>

export class BattleManager {
    static readonly MAX_DEFIMONS_PER_TEAM = 6

    readonly playerTeam: BattleTeam
    readonly opponentTeam: BattleTeam

    public pickedAction?: Action;

    private stateMachine: StateMachine;

    constructor(player: Team, opponent: Team, readonly playerDefimonUI: ActiveBattleDefimonUI,
                readonly opponentDefimonUI: ActiveBattleDefimonUI, readonly playerControlsUI: PlayerControlsUI,
                readonly dialogueManager: DialogueManager, readonly scene: Scene) {
        this.playerTeam = new BattleTeam(player)
        this.opponentTeam = new BattleTeam(opponent)

        this.stateMachine = new StateMachine();
        const battleStartingState = new BattleStartingState(this);
        const playerTurnState = new PlayerTurnState(this);
        const resolutionState = new ResolutionState(this);

        this.stateMachine.addTransition(battleStartingState, playerTurnState, () => battleStartingState.isIntroOver);
        this.stateMachine.addTransition(playerTurnState, resolutionState, () => this.pickedAction !== undefined);
        this.stateMachine.addTransition(resolutionState, playerTurnState, () => resolutionState.isFinished);

        this.stateMachine.setState(battleStartingState);
    }

    tick(dt: number) {
        this.stateMachine.tick(dt);
    }
}