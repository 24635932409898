import React, { useEffect } from 'react'
import styled from 'styled-components'

import { useAppDispatch, useAppSelector, useWallet } from './hooks'

import RoomSelectionDialog from './interfaces/RoomSelectionDialog'
import LoginDialog from './interfaces/LoginDialog'
import MessageDialog from './interfaces/Dialogue'
import Menu from './interfaces/Menu'
import Tablet from './interfaces/tablet/Index'
import { setFocused, setLoggedIn, setMyPlayerProps } from './stores/UserStore'
import NotificationModal from './interfaces/NotificationModal'
import ErrorScreen from './interfaces/subcomponents/ErrorScreen'
import SunkenShipUI from './ui/sunkenship/UI'
import { setRoomJoined } from './stores/RoomStore'
import phaserGame from './PhaserGame'
import Game from './scenes/Game'
import GuessThatDefimonUI from './ui/guessthatdefimon/UI'
import OverworldUI from './ui/overworld/UI'
import Chat from './interfaces/Chat'
import { setActiveMenu } from './stores/MenuStore'
import Bank from './interfaces/bank'
import BattleSceneUI from './ui/battle/UI'
import Whiteboard from './components/whiteboard'
import Computer from './components/computer'
import { setTypingInChat } from './stores/ChatStore'
import { handleKeys } from './scenes/methods'

const Backdrop = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
`

function App() {
  const { account } = useWallet()
  const walletAddress = useAppSelector((state) => state.user.walletAddress)
  const messageDialogOpen = useAppSelector((state) => state.message.messageDialogOpen)
  const activeMenu = useAppSelector((state) => state.menu.activeMenu)
  const userError = useAppSelector((state) => state.game.error)
  const isMultiplayer = useAppSelector((state) => state.game.isMultiplayer)
  const roomJoined = useAppSelector((state) => state.room.roomJoined)
  const loggedIn = useAppSelector((state) => state.user.loggedIn)
  const focused = useAppSelector((state) => state.user.focused)
  const timerIsCounting = useAppSelector((state) => state.timer.isCounting)
  const isTyping = useAppSelector((state) => state.chat.isTyping)
  const dispatch = useAppDispatch()
  const currentScene = phaserGame.scene.keys.game as Game

  let externalElements: JSX.Element
  let menus: JSX.Element

  // Handle Key Press
  useEffect(() => {
    const onKeyDown = ({ key }) => {
      if (!messageDialogOpen && !timerIsCounting && loggedIn && isMultiplayer) {
        if (key === 'Escape' && !['mini-game'].includes(activeMenu)) {
          // move focus back to the game
          currentScene.resetNotification()
          dispatch(setActiveMenu(''))
          dispatch(setFocused(false))
        }

        if (key === 'm' && ['', 'chat'].includes(activeMenu) && !isTyping ) {
          dispatch(setActiveMenu('menu'))
          currentScene.resetNotification()
          dispatch(setFocused(true))
        }

        if (key === 't' && ['', 'chat'].includes(activeMenu) && !isTyping) {
          dispatch(setActiveMenu('tablet'))
          currentScene.resetNotification()
          dispatch(setFocused(true))
        }

        if (key === 'c' && activeMenu === '') {
          dispatch(setActiveMenu('chat'))
          currentScene.resetNotification()
        }

        if (key === 'Enter' && activeMenu === 'chat') {
          dispatch(setFocused(true))
          currentScene.resetNotification()
          dispatch(setTypingInChat(true))
        }
      }
    }

    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [activeMenu, currentScene, dispatch, focused, isMultiplayer, isTyping, loggedIn, messageDialogOpen, timerIsCounting])

  // Handle Wallet Connect
  useEffect(() => {
    if (account && walletAddress && walletAddress !== account) {
      dispatch(setLoggedIn(false))
      dispatch(setRoomJoined(true))
      dispatch(setMyPlayerProps({ name: '', texture: 'Blue Cap Boy', walletAddress: '' }))
      handleKeys(false)
      currentScene.restartScene()
    }
  }, [account, currentScene, dispatch, walletAddress])

  if (loggedIn) {
    if (messageDialogOpen) {
      externalElements = <MessageDialog />
    } else {
      externalElements = <></>
    }
  } else if (roomJoined) {
    externalElements = <LoginDialog />
  } else {
    externalElements = <RoomSelectionDialog />
  }

  if (activeMenu === 'menu') {
    menus = <Menu />
  } else if (activeMenu === 'tablet') {
    menus = <Tablet />
  } else if (activeMenu === 'chat') {
    menus = <Chat />
  } else if (activeMenu === 'bank') {
    menus = <Bank />
  } else if (activeMenu === 'whiteboard') {
    menus = <Whiteboard />
  } else if (activeMenu === 'computer') {
    menus = <Computer />
  } else {
    menus = <></>
  }

  if (userError) {
    return <ErrorScreen content={userError} />
  } else {
    return (
      <Backdrop>
        {externalElements}
        {menus}
        <OverworldUI />
        <GuessThatDefimonUI />
        <SunkenShipUI />
        <BattleSceneUI />
        <NotificationModal />
      </Backdrop>
    )
  }
}

export default App
