import { useMemo } from 'react'
import { Fraction } from '@slothfi/bank-sdk'

import { utils } from 'ethers'
import usePitToken from './usePitToken'
import { useTokenBalance } from '../../stores/Bank/wallet/hooks'
import useGovernanceToken from '../../hooks/bank/useGovernanceToken'
import { useTotalSupply } from '../../data/TotalSupply'

export default function usePitRatio(): Fraction | undefined {
  const govToken = useGovernanceToken()
  const pit = usePitToken()
  const pitTotalSupply = useTotalSupply(pit)
  const pitGovTokenBalance = useTokenBalance(pit?.address, govToken)
  const multiplier = utils.parseEther('1').toString()

  return useMemo(() => {
    return pitGovTokenBalance && pitTotalSupply
      ? pitGovTokenBalance?.divide(pitTotalSupply?.raw.toString()).multiply(multiplier)
      : undefined
  }, [multiplier, pitTotalSupply, pitGovTokenBalance])
}
