import phaserGame from '../PhaserGame'
import store from '../stores'
import Game from '../scenes/Game'

export class Coin extends Phaser.Physics.Arcade.Sprite {
  isDisabled!: boolean
  isVisible!: boolean
  isCollected!: boolean
  type!: string
  callback!: any

  constructor(scene: Game, x: number, y: number, frame: string, name: string, callback?: any) {
    super(scene, x, y, frame)
    scene.add.existing(this)
    scene.collectibleGroup.add(this)
    this.name = name
    this.callback = callback
    this.type = 'coin'
    this.play('coin_rotate')
  }

  setDisabled(isDisabled: boolean) {
    this.isDisabled = isDisabled

    if (isDisabled) {
      this.setTint(0x555555)
      this.setAlpha(0.9)
    } else {
      this.setTint(0xffffff)
      this.setAlpha(1)
    }
  }

  onOverlap(){
    const game = phaserGame.scene.keys.game as Game
    if(!this.isDisabled){
      this.scene.tweens.add({
        targets: this,
        alpha: 0,
        duration: 200,
        ease: 'Cubic.easeOut',
      })
  
      if(store.getState().pref.soundEffects){
        game.soundEffects = game.sound.add('collect-coin', {
          volume: 0.05,
        })
        game.soundEffects.play()
      }


      this.isCollected = true

    this.isVisible = false;
    this.visible = false

      if(this.callback){
        this.callback(this)
      }
    }
  }
}


export function getCoin(scene: Game, name: string):Coin {
  const coin = Object.values(scene.collectibleGroup.children.entries).find(
    (coin: any) => coin.name === name
  ) as Coin
 
  return coin
}
