import React, { useState } from 'react'
import styled from 'styled-components'
import MenuOptionsList from '../components/menu/MenuOptionsList'
import Border from '../components/shared/Border'
import { useAppDispatch } from '../hooks'
import { setActiveMenu } from '../stores/MenuStore'
import { setFocused, setTouch } from '../stores/UserStore'
import ControlsMenu from './menus/ControlsMenu'
import ReleaseNotesMenu from './menus/ReleaseNotesMenu'
import ControlCameraIcon from '@mui/icons-material/ControlCamera'
import MusicButton from './menus/MusicButton'
import CloseButton from './menus/CloseButton'
import TeleportButton from './menus/TeleportButton'
import MenuOption from '../components/menu/MenuOption'
import SkatingButton from './menus/SkatingButton'
import ResetProfileButton from './menus/ResetProfileButton'
import ResetQuestProgressButton from './menus/ResetQuestProgressButton'
import store from '../stores'
import RoomMenu from './menus/RoomMenu'
import CameraZoomButton from './menus/CameraZoomButton'
import SoundEffectsButton from './menus/SoundEffectsButton'
import StorageIcon from '@mui/icons-material/Storage'

const Backdrop = styled.div`
  font-family: 'VT323';
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
`

const FabWrapper = styled.div`
  margin-top: auto;
  margin-bottom: 0px;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
`

export const MenuWrapper = styled.div`
  position: fixed;
  padding: 0;
  margin: 0;
  height: calc(100% - 80px);
  width: 400px;
  opacity: 0.9;
  @media (max-width: 700px) {
    width: 100%;
    height: 100%;
  }
`

export const Option = styled.div`
  position: relative;
  display: flex;
  height: 48px;
  align-items: center;
  padding-left: 12px;
  font-size: 34px;
  background: #fff;
`

export const MenuTitle = styled.p`
  margin: 5px;
  font-size: 34px;
  color: rgb(34, 32, 52);
  text-align: center;
  height: 48px;
  margin: 0px 0px 16px;
`
const Body = styled.div`
  background: rgb(248, 248, 248) none repeat scroll 0% 0%;
  border-bottom: 4px solid rgb(216, 213, 237);
  padding: 40px 10px;
  height: 100%;

  @media (max-width: 700px) {
  }
`

export default function Menu() {
  const [selectedMenu, setSelectedMenu] = useState('')
  const currentRoom = store.getState().game.roomNumber
  const dispatch = useAppDispatch()

  return (
    <Backdrop>
      <FabWrapper
        onClick={() => {
          dispatch(setActiveMenu(''))
          dispatch(setFocused(false))
          dispatch(setTouch(true))
        }}
      />
      {selectedMenu === 'release-notes' ? (
        <ReleaseNotesMenu selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} />
      ) : selectedMenu === 'controls' ? (
        <ControlsMenu selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} />
      ) : selectedMenu === 'rooms' ? (
        <RoomMenu selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} />
      ) : (
        <MenuWrapper>
          <Border>
            <Body>
              <MenuOptionsList>
                <MenuOption
                  onClick={() => setSelectedMenu('controls')}
                  icon={<ControlCameraIcon />}
                  text={'Controls'}
                />
                <MenuOption
                  onClick={() => setSelectedMenu('rooms')}
                  icon={<StorageIcon />}
                  text={`Room ${currentRoom}`}
                />
                <MusicButton />
                <SoundEffectsButton />
                <SkatingButton />
                <CameraZoomButton />
                <ResetProfileButton setActiveMenu={setActiveMenu} />
                <ResetQuestProgressButton setActiveMenu={setActiveMenu} />
                <TeleportButton setActiveMenu={setActiveMenu} />
                <CloseButton setActiveMenu={setActiveMenu} />
              </MenuOptionsList>
            </Body>
          </Border>
        </MenuWrapper>
      )}
    </Backdrop>
  )
}
