import { getRandomNumberBetween } from '../../helpers/dataHelpers'
import store from '../../stores'
import { startConversation } from '../../stores/MessageStore'
import { getCompletedQuests } from '../../stores/QuestStore'

import { NPCdata } from '../npc-data'

export default function OfficerKen(npc, scene, dialogue?: number) {
  let completedQuests = getCompletedQuests()
  let currentQuest = store.getState().quest.currentQuest
  let checkpoints = store.getState().quest.checkpoints

  let conversation = 4 // STARTS 5TH QUEST
  
  if (completedQuests && completedQuests.includes(5)) {
    conversation = getRandomNumberBetween(0, 3)
  }


  if (currentQuest) {
    if (currentQuest.id === 5) {
      conversation = 5
      if (checkpoints.find((checkpoint: any) => checkpoint.name === `dream-ended`)?.status) {
        conversation = 6
      }
    }
  }

  if (npc.playerId === 'officer-ken') {
    store.dispatch(
      startConversation({
        npc: NPCdata.find((current) => current.id === npc.playerId),
        conversation: dialogue ? dialogue : conversation,
        messageNumber: 1,
      })
    )
  }
}
