import Container = Phaser.GameObjects.Container;
import {Scene} from "phaser";
import {StateMachine} from "../../../utils/states/stateMachine";
import {TextAppearingState} from "./states/text-appearing-state";
import {StaticState} from "./states/static-state";
import {WaitingForPlayerInputState} from "./states/waiting-for-player-input-state";

export class DialogueManager extends Container {
    public static readonly TEXT_SPEED = 100;
    private static readonly DIALOGUE_BOX_WIDTH_PERCENT = 0.5;
    private static readonly DIALOGUE_BOX_HEIGHT_PERCENT = 0.15;
    private static readonly DIALOGUE_BOX_BACKGROUND_COLOR = 0xeeeeee;

    public textsToDisplay: string[] = [''];
    public currentLineIndex: number = 0;
    public dialogueFinishedCallback: (lineIndex: number) => void = () => {
    };
    public readonly text: Phaser.GameObjects.Text;

    private readonly stateMachine: StateMachine;
    private readonly textAppearingState: TextAppearingState;

    constructor(scene: Scene) {
        super(scene);

        const canvas = scene.sys.canvas;
        const dialogueWidth = canvas.width * DialogueManager.DIALOGUE_BOX_WIDTH_PERCENT;
        const dialogueHeight = canvas.height * DialogueManager.DIALOGUE_BOX_HEIGHT_PERCENT;

        const background = scene.add.rectangle(0, 0, dialogueWidth, dialogueHeight, DialogueManager.DIALOGUE_BOX_BACKGROUND_COLOR);
        this.text = scene.add.text(0, 0, "", {
            fixedWidth: dialogueWidth,
            fixedHeight: dialogueHeight,
            color: '#000',
            fontSize: '3em',
            wordWrap: {width: dialogueWidth}
        });
        this.text.setPosition(-dialogueWidth / 2, -dialogueHeight / 2);

        this.setSize(dialogueWidth, dialogueHeight);

        this.add(background);
        this.add(this.text);

        scene.add.existing(this)

        this.stateMachine = new StateMachine();

        this.textAppearingState = new TextAppearingState(this);
        const waitingForPlayerInputState = new WaitingForPlayerInputState(this);
        const staticState = new StaticState(this);

        this.stateMachine.addTransition(this.textAppearingState, waitingForPlayerInputState, () => this.textAppearingState.isFinished && this.textsToDisplay.length > 0);
        this.stateMachine.addTransition(this.textAppearingState, staticState, () => this.textAppearingState.isFinished && this.textsToDisplay.length === 0);
        this.stateMachine.addTransition(staticState, this.textAppearingState, () => this.textsToDisplay.length > 0);
        this.stateMachine.addTransition(waitingForPlayerInputState, this.textAppearingState, () => waitingForPlayerInputState.hasSkipped && this.textsToDisplay.length > 0);

        this.stateMachine.setState(staticState);
    }

    async showDialogue(dialoguePieces: string[], dialogueFinishedCallback: (lineIndex: number) => void) {
        const promise = new Promise<void>(resolve => {
            this.dialogueFinishedCallback = i => {
                dialogueFinishedCallback(i);
                if (i === dialoguePieces.length - 1) resolve();
            }
        })
        this.currentLineIndex = 0;
        this.textsToDisplay = dialoguePieces.map(d => d);

        return promise;
    }

    preUpdate(t: number, dt: number) {
        this.stateMachine.tick(dt);
    }
}