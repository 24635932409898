import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ControlSelection } from '../../interfaces/battle'
import { MonsterState } from './Monster'
import Status from './Status'

const BattleControls = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
`

const Container = styled.div`
  border: 4px solid #506858;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  background: #dfdfc2;
  padding: 0px 10px;
  height: 150px;
  width: 100%;
`

const BattleOptions = styled.div`
  border-left: 0px;
  border-right: 0px;
  display: flex;
  font-size: 32px;
  flex-wrap: wrap;
  width: 100%;
`

const OptionContainer = styled.div`
  flex: 25%;
`

const Option = styled.div`
  font-size: 32px;
  margin: 10px;
  padding: 5px;
  border-radius: 10px;
  border: 4px solid #96a49b;
  background: #f8f8d8;
  color: #000;
  cursor: pointer;
`

export default function Controls({
  animation,
  setAnimation,
  status,
  setStatus,
  combatants,
  setCombatants,
  controlSelection,
  setControlSelection,
}: any) {
  useEffect(() => {
    if (animation) {
      setTimeout(function () {
        setAnimation('')
      }, 2000)
    }
  }, [animation, setAnimation])

  return (
    <BattleControls>
      <Container>
        <Status message={status} />

        {controlSelection === ControlSelection.Attacks ? (
          <BattleOptions>
            <OptionContainer>
              <Option
                onClick={() => {
                  setAnimation('blink')
                  setControlSelection(ControlSelection.Idle)
                  setStatus(`${combatants.player.name} used BLINK!`)
                  const temp = combatants
                  temp.enemy.health = combatants.enemy.health - 20
                  setCombatants(temp)
                }}
              >
                Blink
              </Option>
            </OptionContainer>
            <OptionContainer>
              <Option
                onClick={() => {
                  setAnimation('shake')
                  setControlSelection(ControlSelection.Idle)
                  setStatus(`${combatants.player.name} used SHAKE!`)
                  const temp = combatants
                  temp.enemy.health = combatants.enemy.health - 15
                  setCombatants(temp)
                }}
              >
                Shake
              </Option>
            </OptionContainer>
            <OptionContainer>
              <Option
                onClick={() => {
                  const temp = combatants
                  setAnimation('blink')
                  temp.enemy.health = combatants.enemy.health - 40
                  temp.player.state = MonsterState.Special1
                  setCombatants(temp)
                  setControlSelection(ControlSelection.Idle)
                  setStatus(`${combatants.player.name} used SPECIAL ATTACK 1!`)
                  setTimeout(function () {
                    const temp = combatants
                    temp.player.state = MonsterState.Idle
                    setCombatants(temp)
                    setStatus(`It was super effective!`)
                  }, 3000)
                }}
              >
                Special Attack 1
              </Option>
            </OptionContainer>
            <OptionContainer>
              <Option>-</Option>
            </OptionContainer>
          </BattleOptions>
        ) : controlSelection === ControlSelection.Main ? (
          <BattleOptions>
            <OptionContainer>
              <Option onClick={() => setControlSelection(ControlSelection.Attacks)}>Attack</Option>
            </OptionContainer>
            <OptionContainer>
              <Option>Bag</Option>
            </OptionContainer>
            <OptionContainer>
              <Option>Switch</Option>
            </OptionContainer>
            <OptionContainer>
              <Option>Run</Option>
            </OptionContainer>
          </BattleOptions>
        ) : (
          <></>
        )}
      </Container>
    </BattleControls>
  )
}
