import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sanitizeId } from '../util'
import { BackgroundMode } from '../../../types/BackgroundMode'

import { gameConfig } from '../assets/game-data'

interface IPlayerMap {
  name: string
  map: string
}

export enum GameState {
  Overworld = 'OVERWORLD',
  Intro = 'INTRO',
  SunkenShip = 'SUNKENSHIP',
  GuessThatDefimon = 'GUESSTHATDEFIMON',
  WildPvE = 'PVE_WILD',
}

export const gameSlice = createSlice({
  name: 'gameSlice',
  initialState: {
    backgroundMode: BackgroundMode.CLOUDS,
    gameLaunched: false,
    mapLoaded: false,
    playerMap: new Map<string, IPlayerMap>(),
    error: '',
    currentMap: gameConfig.defaultMap,
    isGameScene: false,
    isMultiplayer: true,
    gameState: GameState.Overworld,
    roomNumber: 0,
    loading: true,
  },
  reducers: {
    setGameLaunched: (state, action: PayloadAction<boolean>) => {
      state.gameLaunched = action.payload
    },
    setConnectionLost: (state, action: PayloadAction<boolean>) => {
      state.error = 'Connection Lost'
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload
    },
    setIsGameScene: (state, action: PayloadAction<boolean>) => {
      state.isGameScene = action.payload
    },
    setGameState: (state, action: PayloadAction<GameState>) => {
      state.gameState = action.payload
    },
    setMapLoaded: (state, action: PayloadAction<boolean>) => {
      state.mapLoaded = action.payload
    },
    setPlayerName: (state, action: PayloadAction<{ id: string; name: string }>) => {
      const value = state.playerMap.get(sanitizeId(action.payload.id))

      if (!value) {
        state.playerMap.set(sanitizeId(action.payload.id), {
          name: action.payload.name,
          map: '',
        })
      } else {
        state.playerMap.set(sanitizeId(action.payload.id), {
          name: action.payload.name,
          map: value.map,
        })
      }
    },
    setPlayerMap: (state, action: PayloadAction<{ id: string; map: string }>) => {
      const value = state.playerMap.get(sanitizeId(action.payload.id))

      if (!value) {
        state.playerMap.set(sanitizeId(action.payload.id), {
          name: '',
          map: action.payload.map,
        })
      } else {
        state.playerMap.set(sanitizeId(action.payload.id), {
          name: value.name,
          map: action.payload.map,
        })
      }
    },
    setCurrentMap: (state, action: PayloadAction<string>) => {
      state.currentMap = action.payload
    },
    removePlayer: (state, action: PayloadAction<string>) => {
      state.playerMap.delete(sanitizeId(action.payload))
    },
    removePlayerName: (state, action: PayloadAction<string>) => {
      const value = state.playerMap.get(sanitizeId(action.payload))
      if (value) {
        state.playerMap.set(sanitizeId(action.payload), {
          name: '',
          map: value.map,
        })
      }
    },
    removePlayerMap: (state, action: PayloadAction<string>) => {
      const value = state.playerMap.get(sanitizeId(action.payload))
      if (value) {
        state.playerMap.set(sanitizeId(action.payload), {
          name: value.map,
          map: '',
        })
      }
    },
    setRoomNumber: (state, action: PayloadAction<number>) => {
      state.roomNumber = action.payload
    },
    setGameLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setMultiplayer: (state, action: PayloadAction<boolean>) => {
      state.isMultiplayer = action.payload
    },
  },
})

export const {
  setPlayerMap,
  setGameState,
  setPlayerName,
  setCurrentMap,
  setConnectionLost,
  setIsGameScene,
  removePlayer,
  removePlayerMap,
  removePlayerName,
  setError,
  setMapLoaded,
  setGameLaunched,
  setRoomNumber,
  setGameLoading,
  setMultiplayer
} = gameSlice.actions

export default gameSlice.reducer
