import { createTheme } from '@mui/material/styles'

const muiTheme = createTheme({
  palette: {
    mode: 'dark',

    secondary: {
      main: '#555',
    },
  },
})

export default muiTheme
