import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAppSelector } from '../hooks'

const Backdrop = styled.div`
  margin-top: auto;
  margin-bottom: 0px;
  position: fixed;
  left:0px;
  right:0px;
  font-size:42px;
  color:#fff;
  font-family: 'Work Sans', sans-serif;
  text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
  z-index: 999;
  top:10%;
  text-align:center;
`

export default function WarningText() {
  
  const warningText = useAppSelector((state) => state.warningText.warningText)
  const [warningTextState, setWarningTextState] = useState(warningText)

  useEffect(() => {
    if(warningText !== warningTextState){
      setWarningTextState(warningText)
    }
  }, [warningText, warningTextState])
  
    return (
      <Backdrop>
         {warningTextState}
      </Backdrop>
    )
}
