import { combineReducers } from 'redux'
import application from './application/reducer'
import burn from './burn/reducer'
import lists from './lists/reducer'
import mint from './mint/reducer'
import multicall from './multicall/reducer'
import swap from './swap/reducer'
import transactions from './transactions/reducer'
import user from './user/reducer'

export default combineReducers({
  application,
  burn,
  lists,
  mint,
  multicall,
  swap,
  transactions,
  user
})