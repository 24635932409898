/**
 * Loads all sounds, based on passed Scene
 * called in Game scene's preload()
 */
// DEPRECIATED
import { gameConfig } from '../../assets/game-data'
import store from '../../stores'

export default class SoundLoader {
  scene: any
  sceneName: any
  constructor(scene: any) {
    const { currentMap } = store.getState().game
    this.scene = scene
    this.sceneName = currentMap
    this.loadBackgroundMusic(this.sceneName)
    this.loadBattleMusic()
    this.loadSoundEffects()
  }

  // loads sounds, based on passed scene name
  loadBackgroundMusic(sceneName) {
    if (gameConfig.maps[sceneName].music) {
      for (let i = 0; i < gameConfig.maps[sceneName].music.length; i++) {
        this.scene.load.audio(
          gameConfig.maps[sceneName].music[i].key,
          `assets/music/${gameConfig.maps[sceneName].music[i].name}.mp3`
        )
      }
    }

    // EXTRAS

    this.scene.load.audio('clock-ticking', 'assets/music/clock-ticking.mp3')
    this.scene.load.audio('graveyard-spook', 'assets/music/graveyard-spook.mp3')
    this.scene.load.audio('sneak-peek', 'assets/music/sneak-peek.mp3')
  }

  loadBattleMusic() {
    this.scene.load.audio('battle', `assets/music/battle.mp3`)
    this.scene.load.audio('battle-encounter', `assets/music/encounter.wav`)
  }

  loadSoundEffects() {
    this.scene.load.audio('collect-coin', `assets/sounds/coin.mp3`)
    this.scene.load.audio('unlock-correct', `assets/sounds/unlock-correct.mp3`)
    this.scene.load.audio('unlock-won', `assets/sounds/unlock-won.mp3`)
    this.scene.load.audio('unlock-lose', `assets/sounds/unlock-lose.mp3`)
    this.scene.load.audio('unlock-wrong', `assets/sounds/unlock-wrong.mp3`)
    this.scene.load.audio('disintegrate', `assets/sounds/disintegrate.mp3`)
    this.scene.load.audio('caught', `assets/sounds/caught.wav`)
  }
}
