import { getRandomNumberFromArray } from '../../helpers/dataHelpers'
import store from '../../stores'
import { startConversation } from '../../stores/MessageStore'
import { getCompletedQuests } from '../../stores/QuestStore'

import { NPCdata } from '../npc-data'

export default function UncleTony(npc, scene, dialogue?: number) {
  // RELATED QUESTS ARE  = [3]
  let completedQuests = getCompletedQuests()
  let currentQuest = store.getState().quest.currentQuest
  let checkpoints = store.getState().quest.checkpoints

  let conversation = 2 // STARTS 2ND QUEST

  if (completedQuests && completedQuests.includes(3)) {
    conversation = getRandomNumberFromArray([0, 1])
  }

  if (currentQuest) {
    if (currentQuest.id === 3) {
      conversation = 3
      if (checkpoints.find((checkpoint: any) => checkpoint.name === `scissors-hint`)?.status) {
        conversation = 4
      }
    }
  }

  if (npc.playerId === 'uncle-tony') {
    store.dispatch(
      startConversation({
        npc: NPCdata.find((current) => current.id === npc.playerId),
        conversation: dialogue ? dialogue : conversation,
        messageNumber: 1,
      })
    )
  }
}
