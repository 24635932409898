export const NPCList = [
  {
    name: 'police',
    fileName: '1',
  },
  {
    name: 'pirate',
    fileName: '2',
  },
  {
    name: 'banker',
    fileName: '3',
  },
  {
    name: 'doctor',
    fileName: '4',
  },
  {
    name: 'female-banker',
    fileName: '5',
  },
  {
    name: 'nurse',
    fileName: '6',
  },
  {
    name: 'old-man',
    fileName: '7',
  },
  {
    name: 'construction-worker',
    fileName: '8',
  },
  {
    name: 'wildlife-biologist',
    fileName: '9',
  },
  {
    name: 'fruit-store-reception',
    fileName: '10',
  },
  {
    name: 'pet-store-vet',
    fileName: '11',
  },
  {
    name: 'garbage-man',
    fileName: '12',
  },
  {
    name: 'teenager-w-cellphone',
    fileName: '13',
  },
  {
    name: 'satoshi-nakatomo',
    fileName: '14',
  },
  {
    name: 'store-helper',
    fileName: '15',
  },
  {
    name: 'firefighter',
    fileName: '16',
  },
  {
    name: 'inmate',
    fileName: '26',
  },
  {
    name: 'police-goop',
    fileName: '27',
  },
  {
    name: 'cafe-owner',
    fileName: '28',
  },
  {
    name: 'property-salesman',
    fileName: '29',
  },
  {
    name: 'cloth-salesman',
    fileName: '30',
  },
  {
    name: 'samurai',
    fileName: '31',
  },
]
