export const dialogueConfig = {
  constructionWorkerTommy: {
    0: {
      elements: {
        1: {
          npc: [
            `Every day it's something new. There are so many new builds going on I even got my brother a job and that guy should NOT be operating heavy machinery.`,
            'Ha ha ha. ',
            'But really, I have to get him off that excavator crew.',
          ],
        },
      },
    },
  },
  uncleTony: {
    0: {
      elements: {
        1: {
          npc: [`Such a good day, isn't it?`],
        },
      },
    },
    1: {
      elements: {
        1: {
          npc: [`Those pigeons are just about the only friends I have left these days...`],
        },
      },
    },
    2: {
      elements: {
        1: {
          npc: [
            'My scissors, my scissors, dammit. I put them down for one second and Dolores put everything into the trash.',
            'How am I supposed to cut out newspaper articles without my trusty scissors!!?',
          ],
          character: {
            1: {
              text: 'You sound like you could use some help.',
              followup: 2,
            },
          },
        },
        2: {
          npc: [
            `You have no idea.`,
            `The bin, it's locked and that garbage man takes his job so seriously and tomorrow is garbage day, my favorite pair of scissors.`,
            `You know how to pick a lock? I might have something to sweeten the deal, if you're willing to help.`,
          ],
          character: {
            1: {
              text: `I'm up for it.`,
              followup: {
                quest: 3,
              },
            },
            2: {
              text: `Your dumpster, your problem.`,
              followup: null,
            },
          },
        },
      },
    },
    3: {
      elements: {
        1: {
          npc: [
            `You unlocked the bin, yet? It's present behind the park, near the three buildings.`,
          ],
        },
      },
    },
    4: {
      elements: {
        1: {
          npc: [
            `You did it! 
            Here, have a raincoat as a reward, You're going to catch a chill out in this weather without one.`,
          ],
          character: {
            1: {
              text: 'It was pleasure doing business with you.',
              followup: {
                completeQuest: 3,
              },
            },
          },
        },
      },
    },
  },
  lostPirate: {
    0: {
      elements: {
        1: {
          npc: ['..and ..and ..the ship SANK!', 'Someone help MEEEEE!'],
          character: {
            1: {
              text: 'What happened?',
              followup: 3,
            },
            2: {
              text: "Why don't you go and scream somewhere else?",
              followup: 2,
            },
          },
        },
        2: {
          npc: ["You wouldn't know the pain..."],
        },
        3: {
          npc: ['If you see any other pirates looking for me, tell them I was a good sailor...'],
        },
      },
    },
  },
  youngHunter: {
    0: {
      elements: {
        1: {
          npc: [
            'You can check your phone by tapping on the profile picture present on top left of your screen!',
          ],
        },
      },
    },
    1: {
      elements: {
        1: {
          npc: ["Have you checked out the discord? It's a really great community!"],
          character: {
            1: {
              text: 'Open discord',
              followup: {
                link: 'https://discord.gg/defimons',
              },
            },
            2: {
              text: 'No, thanks!',
              followup: null,
            },
          },
        },
      },
    },
    2: {
      elements: {
        1: {
          npc: [
            `I love water monsters, they're the strongest in Yorokobi.`,
            `I bet you can't beat my monsters.`,
          ],
          character: {
            1: {
              text: 'Show me what you got',
              followup: {
                battle: 'enemy',
              },
            },
          },
        },
      },
    },
    3: {
      elements: {
        1: {
          npc: [`You seem like a cool kid, with your cap on. You know how to skate?`],
          character: {
            1: {
              text: 'Got my skateboard right here!',
              followup: 2,
            },
            2: {
              text: 'Not my type of thing, go away',
              followup: null,
            },
          },
        },
        2: {
          npc: [
            `Why don't you bring all those coins that Tony dropped all over the town.`,
            `But you do it within 30 seconds or you're a loser!`,
            `Haha!`,
          ],
          character: {
            1: {
              text: `I'm up for it`,
              followup: {
                quest: 2,
                timer: 30,
                wait: 2,
              },
            },
          },
        },
      },
    },
    4: {
      elements: {
        1: {
          npc: [
            `You were FAAST!`,
            `Ahem.. I mean, I could've easily done better. You did fine for your standards...`,
          ],
          character: {
            1: {
              text: 'Sure, mate!',
              followup: {
                completeQuest: 2,
              },
            },
          },
        },
      },
    },
    5: {
      elements: {
        1: {
          npc: [`We could've been skating buddies if you weren't so BORING!`],
        },
      },
    },
  },
  nurseMary: {
    0: {
      elements: {
        1: {
          npc: [
            `Hello and welcome to Yorokobi hospital. The best place to heal you and your family. How can I help you?`,
          ],
          character: {
            1: {
              text: 'I want to buy health packs',
              followup: 2,
            },
            2: {
              text: 'My pet is injured...',
              followup: 3,
            },
            3: {
              text: 'Nevermind.',
              followup: null,
            },
          },
        },
        2: {
          npc: ["Sorry, we're currently closed, please come back later..."],
        },
        3: {
          npc: ["You'll have to speak to doctor Keisuke about this!"],
        },
      },
    },
  },
  doctorKeisuke: {
    0: {
      elements: {
        1: {
          npc: ["I'm a bit busy right now, please book an appointment next time!"],
        },
      },
    },
    1: {
      elements: {
        1: {
          npc: [
            `I'm really busy, please come back later...`,
            `On another note, since you're here, why don't you help me out?`,
          ],
          character: {
            1: {
              text: 'What could I possibly help you with?',
              followup: 2,
            },
          },
        },
        2: {
          npc: [
            `I was treating an ignis yesterday, but I forgot to take the readings for the report.`,
            `I have to treat another patient right now, could you go up to the second floor and get those readings for me?`,
            `The machine has two blue monitors, you'll know when you see it.`,
            `I might have something to sweeten the deal, if you're willing to help.`,
            `But beware, don't let the other doctors know that you're there.`,
          ],
          character: {
            1: {
              text: `Okay, sounds simple enough.`,
              followup: {
                quest: 6,
              },
            },
            2: {
              text: `I'm not in the mood right now.`,
              followup: null,
            },
          },
        },
      },
    },
    2: {
      elements: {
        1: {
          npc: [
            `You got the readings, yet? The machine has two blue monitors, you'll know when you see it.`,
          ],
        },
      },
    },
    3: {
      elements: {
        1: {
          npc: [
            `You did it! 
            You're like an actual ghost. I might have an outfit that would fit perfectly on you!`,
          ],
          character: {
            1: {
              text: 'It looks great, thank you!',
              followup: {
                completeQuest: 6,
              },
            },
          },
        },
      },
    },
  },
  grocerHaruka: {
    0: {
      elements: {
        1: {
          npc: [
            "Hey, you're new around here? I'll give you some free advice.",
            'My prices are much better than any of those big chain grocery stores and my selection is top notch. Come take a look.',
          ],
          character: {
            1: {
              text: 'View Store',
              followup: 2,
            },
            2: {
              text: 'Nevermind.',
              followup: null,
            },
          },
        },
        2: {
          npc: ["Sorry, we're currently closed, please come back later..."],
        },
      },
    },
  },
  bankerMasahi: {
    0: {
      elements: {
        1: {
          npc: [
            "It's never too early to start investing in your future. How can I help you today?",
          ],
          character: {
            1: {
              text: 'Swap tokens',
              followup: 2,
              // followup: {
              //   interface: 'bank',
              //   page: 'swap',
              // },
            },
            2: {
              text: 'Provide Liquidity',
              followup: 2,
              // followup: {
              //   interface: 'bank',
              //   page: 'pool',
              // },
            },
            3: {
              text: 'Invest in xMON',
              followup: 2,
              // followup: {
              //   interface: 'bank',
              //   page: 'treasury',
              // },
            },
            4: {
              text: 'View Liquidity Providers',
              followup: 2,
              // followup: {
              //   interface: 'bank',
              //   page: 'farm',
              // },
            },
            5: {
              text: 'Nevermind',
              followup: null,
            },
          },
        },
        2: {
          npc: ['Sorry, our servers are currently down, please come back later...'],
        },
      },
    },
  },
  bankerMegumi: {
    0: {
      elements: {
        1: {
          npc: [
            "I'll help you find the next bitcoin but don't tell Satoshi!",
            'How can I help you today?',
          ],
          character: {
            1: {
              text: 'Need more information',
              followup: 2,
            },
            2: {
              text: 'Nevermind.',
              followup: null,
            },
          },
        },
        2: {
          npc: ['Sorry, our servers are currently down, please come back later...'],
        },
      },
    },
  },
  officerKen: {
    0: {
      elements: {
        1: {
          npc: ["Keeping this town safe... that's all I want!"],
        },
      },
    },
    1: {
      elements: {
        1: {
          npc: ["Don't keep lurking around here! Move along!"],
        },
      },
    },
    2: {
      elements: {
        1: {
          npc: ['My father always wanted me to be a police officer', "I'm just living his dream"],
        },
      },
    },
    3: {
      elements: {
        1: {
          npc: ['Is your name satsdart?'],
          character: {
            1: {
              text: 'No...',
              followup: 2,
            },
          },
        },
        2: {
          npc: ['Move along then...'],
        },
      },
    },
    4: {
      elements: {
        1: {
          npc: [`People are acting really weird lately.`],
          character: {
            1: {
              text: 'What do you mean?',
              followup: 2,
            },
            2: {
              text: `Yeah, you're one of them!`,
              followup: null,
            },
          },
        },
        2: {
          npc: [
            `It's like they're not human. 
            I've heard about some monster called goop, that can take shape of humans.
            But I never believed it...`,
          ],
          character: {
            1: {
              text: `Do you think they're actually goop?`,
              followup: {
                quest: 5,
              },
            },
          },
        },
      },
    },
    5: {
      elements: {
        1: {
          npc: [`Hey!`],
        },
      },
    },
    6: {
      elements: {
        1: {
          npc: [
            `Hey! Are you okay?`,
            `You started staring at the wall all of a sudden...`,
            `Nevertheless, you seem pretty interested in helping us out for catching this wild goop.`,
            `While I can't give you a police uniform, I can give you this coat and badge... It would look great on you!`,
            `Welcome to the force, officer!`,
          ],
          character: {
            1: {
              text: `Uh...I'm looking forward to helping you guys out`,
              followup: {
                completeQuest: 5,
              },
            },
          },
        },
      },
    },
  },
  doctorYamato: {
    0: {
      elements: {
        1: {
          npc: [
            `Animals are more than just pets or monsters.`,
            'They can be kind, gentle and noble creatures, you just have to prove yourself to them and you will have a friend for life.',
          ],
        },
      },
    },
  },
  firemanGoro: {
    0: {
      elements: {
        1: {
          npc: [
            `Seems like there fires just about every day ever since those animals started getting powers.`,
            'I just hope we can keep up.',
          ],
        },
      },
    },
  },
  prisoner: {
    0: {
      elements: {
        1: {
          npc: [`Who are you?! Why are you following me?`],
        },
      },
    },
  },
  helperAkira: {
    0: {
      elements: {
        1: {
          npc: [
            `I've spent the last 3 hours fixing this mess.`,
            'At this rate, Grocer Haruka will cut my salary!',
          ],
          character: {
            1: {
              text: 'Do you need some help with that?',
              followup: 2,
            },
          },
        },
        2: {
          npc: [
            `That would be amazing!
            Could you help sort out these fruits into their correct baskets?`,
            `Would be a great deal of help!`,
          ],
          character: {
            1: {
              text: `Yeah, I can help you out.`,
              followup: {
                quest: 4,
              },
            },
            2: {
              text: `Not really, I've got monsters to tame!`,
              followup: null,
            },
          },
        },
      },
    },
    1: {
      elements: {
        1: {
          npc: [
            `Forget it!
            I'll do it myself.`,
          ],
          character: {
            1: {
              text: `Okay...`,
              followup: {
                exitQuest: 4,
              },
            },
          },
        },
      },
    },
    2: {
      elements: {
        1: {
          npc: [
            `You did it! 
            I'm really thankful for your help!`,
            `Here have an Avacado outfit I got for Halloween, 
            would look great on you!`,
          ],
          character: {
            1: {
              text: 'This looks cute, thank you!',
              followup: {
                completeQuest: 4,
              },
            },
          },
        },
      },
    },
    3: {
      elements: {
        1: {
          npc: [`Such a great weather, isn't it? Wish I could just lay down and eat fruits!`],
        },
      },
    },
  },
  bakerToro: {
    0: {
      elements: {
        1: {
          npc: [`My cafe is the best place to meet new people!`],
        },
      },
    },
  },
  salesmanEticha: {
    0: {
      elements: {
        1: {
          npc: [`Limited Space. Unlimited Opportunities. Welcome to Eticha Property Store.`],
        },
      },
    },
    1: {
      elements: {
        1: {
          npc: [
            `Hey, you seem like a person who knows how to find things.`,
            `I was studying all the information about the initial settlers and the benefits. But seem to have misplaced the papers.`,
            `Do you think you'll be able to help me out?`,
          ],
          character: {
            1: {
              text: 'Seems important, yeah sure.',
              followup: 2,
            },
            2: {
              text: `I don't have the time for this.`,
              followup: null,
            },
          },
        },
        2: {
          npc: [
            `As much as I remember, I visted the cafe, then the clothing store and then the construction site.`,
            `Maybe you should look at these places and see if you can find something.`,
          ],
          character: {
            1: {
              text: `I'll get to it.`,
              followup: {
                quest: 7,
              },
            },
          },
        },
      },
    },
    2: {
      elements: {
        1: {
          npc: [
            `Were you able to find any papers yet? I visited the cafe, clothing store and the construction site, maybe you should start looking there.`,
          ],
        },
      },
    },
    3: {
      elements: {
        1: {
          npc: [
            `Perfect, not sure how you found all these. Looking forward to seeing you at the mint.`,
          ],
          character: {
            1: {
              text: `Looking forward to it!`,
              followup: {
                completeQuest: 7,
              },
            },
          },
        },
      },
    },
  },
  salesmanKenechi: {
    0: {
      elements: {
        1: {
          npc: [
            `Get the most fashionable outfits at my store, limited edition clothing for the cheapest prices!`,
          ],
        },
      },
    },
  },
  samuraiMusashi: {
    0: {
      elements: {
        1: {
          npc: [
            `The beast Pandoro is the strongest and bravest of all. With the power of land and the might of gods, he's capable of defeating any type of monsters.`,
            `You don't seem to have tamed a Pandoro. Prove yourself worthy, and go tame one.`,
            `Once you're successful, then we shall battle.`,
          ],
        },
      },
    },
    1: {
      elements: {
        1: {
          npc: [
            `The townsmen of Yorokobi are in certain danger.`,
            `A group of rogue ninjas and their Kaijus have been attacking random citizens.`,
            `It is our duty to protect these innocent people. Are you brave enough to take the challenge?`,
          ],
        },
      },
    },
  },
  goop: {
    0: {
      elements: {
        1: {
          npc: ['GOOP GOOP GOOP....'],
          character: {
            1: {
              text: 'huh?',
              followup: null,
            },
          },
        },
      },
    },
    1: {
      elements: {
        1: {
          npc: ['GOO...'],
        },
      },
    },
    2: {
      elements: {
        1: {
          npc: ['GOOP... GOOP...'],
        },
      },
    },
  },
}
