import getItem from '../helpers/game/getItem'
import Unlockable from '../items/Unlockable'
import phaserGame from '../PhaserGame'
import Game from '../scenes/Game'
import store from '../stores'
import { setNotification } from '../stores/NotificationStore'
import { completeCheckpoint } from '../stores/QuestStore'
import { setMyPlayerProps } from '../stores/UserStore'

// Start
export function startPickNLock() {
  const checkpoints = store.getState().quest.checkpoints

  const __trashBinHint = checkpoints.find(
    (checkpoint: any) => checkpoint.name === `trash-bin-hint`
  )?.status
  const dumpster = getItem('dumpster-1') as Unlockable

  if (dumpster.isUnlocked) {
    dumpster.isUnlocked = false
    dumpster.isOpen = false
    dumpster.setFrame(0)
  }

  if (!__trashBinHint) {
    store.dispatch(completeCheckpoint(`trash-bin-hint`))
    store.dispatch(
      setNotification({
        content: `Unlock the Trash Bin`,
        type: `hint`,
        timer: 3,
      })
    )
  }
}

// Update
export function updatePickNLock() {
  const game = phaserGame.scene.keys.game as Game
  const dumpsterGame = game.gameObjects.find(
    (gameObject: any) => gameObject.name === 'dumpster-1-unlock'
  )
  const checkpoints = store.getState().quest.checkpoints

  if (dumpsterGame) {
    dumpsterGame.on('success', () => {
      store.dispatch(completeCheckpoint(`completed-minigame`))

      if (!checkpoints.find((checkpoint: any) => checkpoint.name === `scissors-hint`)?.status) {
        store.dispatch(
          setNotification({
            content: `Scissors`,
            type: `item`,
            imageURI: `/assets/items/single-frame/scissors.png`,
            timer: 3,
          })
        )
        store.dispatch(completeCheckpoint(`scissors-hint`))
      }
    })
  }
}

// Complete
export function completePickNLock() {
  store.dispatch(
    setMyPlayerProps({
      texture: 'Raincoat',
    })
  )
}

// Utils
