export const npcPathFindingDatas = {
  'uncle-tony': [
    {
      x: 290,
      y: 1652,
      onDestinationReachedWaitTime_ms: 2000,
    },
    {
      x: 590,
      y: 1652,
      onDestinationReachedWaitTime_ms: 2000,
    },
    {
      x: 590,
      y: 1592,
      onDestinationReachedWaitTime_ms: 2000,
    },
    {
      x: 290,
      y: 1592,
      onDestinationReachedWaitTime_ms: 2000,
    },
  ],
  'young-hunter': [
    {
      x: 3883,
      y: 1948,
      onDestinationReachedWaitTime_ms: 2000,
    },
    {
      x: 3883,
      y: 2148,
      onDestinationReachedWaitTime_ms: 2000,
    },
  ],
  'fireman-goro': [
    {
      x: 3153,
      y: 1236,
      onDestinationReachedWaitTime_ms: 2000,
    },
    {
      x: 3153,
      y: 1636,
      onDestinationReachedWaitTime_ms: 2000,
    },
  ],
  'lost-pirate': [
    {
      x: 2030,
      y: 554,
      onDestinationReachedWaitTime_ms: 500,
    },
    {
      x: 1830,
      y: 554,
      onDestinationReachedWaitTime_ms: 500,
    },
  ],
  
}
