import {State} from "../../utils/states/state";
import {BattleManager} from "../battle-manager";
import {DefimonAttacksUI} from "../ui/defimon-attacks-ui";
import {ActionType} from "../../scenes/DefimonBattle";
import {DefimonItemsUI} from "../ui/defimon-items-ui";
import {DefimonSwitchUI} from "../ui/defimon-switch-ui";
import Container = Phaser.GameObjects.Container;

export class PlayerTurnState implements State {
    constructor(private readonly battleManager: BattleManager) {
    }

    onEnter(): void {
        console.log('player turn')
        this.battleManager.pickedAction = undefined;

        this.battleManager.playerControlsUI.visible = true;
        this.battleManager.playerControlsUI.registerAttackCallback(() => this.onAttackClicked());
        this.battleManager.playerControlsUI.registerItemCallback(() => this.onItemClicked());
        this.battleManager.playerControlsUI.registerSwitchCallback(() => this.onSwitchClicked());
    }

    private onChoiceCancelled(buttons: Container) {
        this.battleManager.playerControlsUI.visible = true;
        buttons.destroy();
    }

    private onAttackClicked() {
        const viewportWidth = this.battleManager.scene.cameras.main.width;
        const viewportHeight = this.battleManager.scene.cameras.main.height;

        this.battleManager.playerControlsUI.visible = false;
        const attackButtons = new DefimonAttacksUI(this.battleManager.playerTeam.currentActiveDefimon, attack => {
            this.battleManager.playerControlsUI.visible = true;
            attackButtons.destroy();
            this.battleManager.pickedAction = {
                type: ActionType.ATTACK,
                text: [`${this.battleManager.playerTeam.currentActiveDefimon.name} used ${attack.name}!`],
                actionFunction: () => {
                    const timeline = this.battleManager.scene.tweens.createTimeline()
                    timeline.add({
                        targets: this.battleManager.playerDefimonUI.currentDefimonSprite,
                        x: `+=70`,
                        ease: 'Circ.easeOut',
                        duration: 50,
                    })
                    timeline.add({
                        targets: this.battleManager.playerDefimonUI.currentDefimonSprite,
                        x: `-=70`,
                        ease: 'Circ.easeIn',
                        duration: 50,
                    })
                    timeline.play()
                    this.battleManager.opponentTeam.currentActiveDefimon.takeAttack(attack, this.battleManager.playerTeam.currentActiveDefimon);
                    this.battleManager.opponentDefimonUI.updateDefimonHealth(this.battleManager.opponentTeam.currentActiveDefimon.getHealthPercent())
                    this.battleManager.playerTeam.currentActiveDefimon.spendStamina(attack.spCost)
                    this.battleManager.playerDefimonUI.updateDefimonStamina(this.battleManager.playerTeam.currentActiveDefimon.getStaminaPercent())
                }
            }
        }, () => this.onChoiceCancelled(attackButtons), this.battleManager.scene);
        attackButtons.setPosition(viewportWidth - attackButtons.width, viewportHeight - attackButtons.height);
    }

    private onItemClicked() {
        const viewportWidth = this.battleManager.scene.cameras.main.width;
        const viewportHeight = this.battleManager.scene.cameras.main.height;

        this.battleManager.playerControlsUI.visible = false;
        const itemsButtons = new DefimonItemsUI(this.battleManager.playerTeam, item => {
            this.battleManager.playerControlsUI.visible = true;
            itemsButtons.destroy();
            this.battleManager.pickedAction = {
                type: ActionType.ITEM,
                text: [`${this.battleManager.playerTeam.name} used an ${item.simpleName}!`],
                actionFunction: () => {
                    this.battleManager.playerTeam.inventory.removeOne(item)
                    item.behaviour(this.battleManager.playerTeam.currentActiveDefimon)
                    this.battleManager.playerDefimonUI.updateDefimonHealth(this.battleManager.playerTeam.currentActiveDefimon.getHealthPercent())
                    this.battleManager.playerDefimonUI.updateDefimonStamina(this.battleManager.playerTeam.currentActiveDefimon.getStaminaPercent())
                }
            }
        }, () => this.onChoiceCancelled(itemsButtons), this.battleManager.scene);
        itemsButtons.setPosition(viewportWidth - itemsButtons.width, viewportHeight - itemsButtons.height);
    }

    private onSwitchClicked() {
        const viewportWidth = this.battleManager.scene.cameras.main.width;
        const viewportHeight = this.battleManager.scene.cameras.main.height;

        this.battleManager.playerControlsUI.visible = false;
        const switchButtons = new DefimonSwitchUI(this.battleManager.playerTeam, defimon => {
            this.battleManager.playerControlsUI.visible = true;
            switchButtons.destroy();
            this.battleManager.pickedAction = {
                type: ActionType.SWAP,
                text: [`${this.battleManager.playerTeam.currentActiveDefimon.name}, that's enough! Come back!`, `Go! ${defimon.name}!`],
                actionFunction: () => {
                    this.battleManager.playerTeam.swap(defimon);
                    this.battleManager.playerDefimonUI.switchDefimon(defimon);
                }
            }
        }, () => this.onChoiceCancelled(switchButtons), this.battleManager.scene);
        switchButtons.setPosition(viewportWidth - switchButtons.width, viewportHeight - switchButtons.height);
    }

    onExit(): void {
        this.battleManager.playerControlsUI.unregisterAllCallbacks();
        this.battleManager.playerControlsUI.visible = false;
    }

    tick(dt: number): void {
    }

}