import { BigNumber } from 'ethers';
import { useCallback, useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { CONTRACTS } from '../constants/contracts';
import NetworkBlockchain from '../services/NetworkBlockchain';
import IpfsService from '../utils/ipfs';
import { useContract } from './useContract';
import { parseNumber } from '../helpers';

export interface Outfit {
  name: string
  image: string
  properties: {
    collection: string
  }
  id: number
}

type OutfitId = BigNumber | number | string

const getOutfitsFromIpfs = async (URI: string, ids: OutfitId[]): Promise<Outfit[]> => {
  const data = await Promise.all(
    ids.map( id => 
      IpfsService.getIpfsDataFromId(URI, id, true)  
    )
  )
  return data
}

export const useOutfits = (fetchDefaultOutfits = false) => {
  const { account } = useWeb3React()
  const [URI, setURI] = useState()
  const [defaultOutfits, setDefaultOutfits] = useState<Outfit[]>([])
  const [loading, setLoading] = useState(false)
  const contractOutfits = useContract(CONTRACTS.OUTFITS)
  const contractOutfitFaucet = useContract(CONTRACTS.OUTFIT_FAUCET)

  useEffect(() =>  {
    if (!URI && contractOutfits) {
      const setInitData = async () => {
        setLoading(true)
        try {
          const uri = await contractOutfits.uri(0)
          setURI(uri)
          if (uri && fetchDefaultOutfits && contractOutfitFaucet) {
            const defaultIds = await contractOutfitFaucet.getFreeOutfits()
            const outfits = await getOutfitsFromIpfs(uri, defaultIds)
            setDefaultOutfits(outfits)
          }
        } catch (error) {
          console.log(error)
          console.log('Error Getting Outfits URL: ', error)
        }
        setLoading(false)
      }
      setInitData()
    }
  }, [contractOutfits, contractOutfitFaucet, fetchDefaultOutfits, URI])

  const getOutfitById = useCallback(async (id: OutfitId) => {
    if (URI) {
      setLoading(true)
      const data = await getOutfitsFromIpfs(URI, [id])
      setLoading(false)
      return data[0];
    }
  }, [URI])

  const getOutfitsByIds = useCallback(async (ids: OutfitId[]) => {
    if (URI) {
      setLoading(true)
      const data = await getOutfitsFromIpfs(URI, ids)
      setLoading(false)
      return data;
    }
  }, [URI])

  const mintOutfit = useCallback(async (id: OutfitId) => {
    if (contractOutfits && account) {
      try {
        await NetworkBlockchain.mintERC1155(contractOutfits.address, account, parseNumber(id))
      } catch (error) {
        // Manage error
        console.log(error)
      }
    }
  },[account, contractOutfits])

  const receiveFreeOutfit = useCallback(async (id: OutfitId) => {
    if (contractOutfitFaucet) {
      try {
        return contractOutfitFaucet.receiveOutfit(parseNumber(id))
      } catch (error) { 
        console.log(error)
      }
    }
  },[contractOutfitFaucet])

  return {
    contractOutfits,
    defaultOutfits,
    getOutfitById,
    getOutfitsByIds,
    loading,
    mintOutfit,
    outfitURI: URI,
    receiveFreeOutfit
  }
}