import {State} from "../../utils/states/state";
import {Action, BattleManager} from "../battle-manager";
import {ActionType} from "../../scenes/DefimonBattle";
import {BattleTeam} from "../team";

export enum BattleResult {
    NONE, VICTORY, DEFEAT
}

export class ResolutionState implements State {
    public isFinished: boolean = false;

    constructor(private readonly battleManager: BattleManager) {
    }

    async onEnter() {
        this.isFinished = false;

        const opponentAction = this.aiChooseAction()
        let firstAction: Action

        if (this.battleManager.pickedAction!.type === ActionType.ATTACK && opponentAction.type === ActionType.ATTACK) {
            const playerFirst = this.battleManager.playerTeam.currentActiveDefimon.getSpeed() < this.battleManager.opponentTeam.currentActiveDefimon.getSpeed()
            firstAction = playerFirst ? this.battleManager.pickedAction! : opponentAction
        } else if (this.battleManager.pickedAction!.type === ActionType.ATTACK) {
            firstAction = opponentAction
        } else {
            firstAction = this.battleManager.pickedAction!
        }

        const lastAction: Action = firstAction === this.battleManager.pickedAction! ? opponentAction : this.battleManager.pickedAction!

        await this.battleManager.dialogueManager.showDialogue(firstAction.text, () => {});
        firstAction.actionFunction();

        await new Promise(resolve => setTimeout(resolve, 1000));

        if (this.checkForBattleEnd() !== BattleResult.NONE) return;
        this.switchDefimonsIfNeeded();


        await this.battleManager.dialogueManager.showDialogue(lastAction.text, () => {});
        lastAction.actionFunction();

        await new Promise(resolve => setTimeout(resolve, 1000));

        if (this.checkForBattleEnd() !== BattleResult.NONE) return;
        this.switchDefimonsIfNeeded();

        this.battleManager.opponentTeam.currentActiveDefimon.tickEffects()
        if (this.checkForBattleEnd() !== BattleResult.NONE) return;
        this.switchDefimonsIfNeeded();

        this.battleManager.playerTeam.currentActiveDefimon.tickEffects()
        if (this.checkForBattleEnd() !== BattleResult.NONE) return;
        this.switchDefimonsIfNeeded();

        await this.battleManager.dialogueManager.showDialogue([`What will ${this.battleManager.playerTeam.currentActiveDefimon.name} do?`], () => {});

        this.isFinished = true;
    }

    private static swapDefimonIfDead(team: BattleTeam): boolean {
        if (ResolutionState.hasCurrentDefimonDied(team)) {
            this.swapDefimon(team)
            return true;
        }
        return false;
    }

    static swapDefimon(team: BattleTeam) {
        // TODO : replace by Defimon selection
        team.swapWithRandomDefimon()
    }

    private switchDefimonsIfNeeded() {
        if (ResolutionState.swapDefimonIfDead(this.battleManager.playerTeam)) this.battleManager.playerDefimonUI.switchDefimon(this.battleManager.playerTeam.currentActiveDefimon);
        if (ResolutionState.swapDefimonIfDead(this.battleManager.opponentTeam)) this.battleManager.opponentDefimonUI.switchDefimon(this.battleManager.opponentTeam.currentActiveDefimon);
    }

    private checkForBattleEnd(): BattleResult {
        if (ResolutionState.hasNoDefimonAlive(this.battleManager.opponentTeam)) return BattleResult.VICTORY
        if (ResolutionState.hasNoDefimonAlive(this.battleManager.playerTeam)) return BattleResult.DEFEAT
        return BattleResult.NONE
    }

    private static hasCurrentDefimonDied(team: BattleTeam): boolean {
        return team.currentActiveDefimon.isDead()
    }

    private static hasNoDefimonAlive(team: BattleTeam): boolean {
        return team.defimons.every(d => d.isDead())
    }

    private aiChooseAction(): Action {
        const skill = this.battleManager.opponentTeam.currentActiveDefimon.skills[0];
        return {
            type: ActionType.ATTACK,
            text: [`${this.battleManager.opponentTeam.currentActiveDefimon.name} used ${skill.name}!`],
            actionFunction: () => {
                const timeline = this.battleManager.scene.tweens.createTimeline()
                timeline.add({
                    targets: this.battleManager.opponentDefimonUI.currentDefimonSprite,
                    x: `-=70`,
                    ease: 'Circ.easeOut',
                    duration: 50,
                })
                timeline.add({
                    targets: this.battleManager.opponentDefimonUI.currentDefimonSprite,
                    x: `+=70`,
                    ease: 'Circ.easeIn',
                    duration: 50,
                })
                timeline.play()
                this.battleManager.playerTeam.currentActiveDefimon.takeAttack(skill, this.battleManager.opponentTeam.currentActiveDefimon);
                this.battleManager.playerDefimonUI.updateDefimonHealth(this.battleManager.playerTeam.currentActiveDefimon.getHealthPercent())
            }
        }
    }

    onExit(): void {
    }

    tick(dt: number): void {
    }

}