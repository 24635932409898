import { CharactersList } from '../../constants/player-sprites'
import { NPCList } from '../../constants/npc-sprites'

export function createCharacterAnims(anims: Phaser.Animations.AnimationManager) {
  const animsFrameRate = 15

  for (let i = 0; i < CharactersList.length; i++) {
    anims.create({
      key: `${CharactersList[i].name}_idle_right`,
      frames: anims.generateFrameNames(`${CharactersList[i].name}`, {
        start: 0,
        end: 5,
      }),
      repeat: -1,
      frameRate: animsFrameRate * 0.6,
    })

    anims.create({
      key: `${CharactersList[i].name}_idle_up`,
      frames: anims.generateFrameNames(`${CharactersList[i].name}`, {
        start: 6,
        end: 11,
      }),
      repeat: -1,
      frameRate: animsFrameRate * 0.6,
    })

    anims.create({
      key: `${CharactersList[i].name}_idle_left`,
      frames: anims.generateFrameNames(`${CharactersList[i].name}`, {
        start: 12,
        end: 17,
      }),
      repeat: -1,
      frameRate: animsFrameRate * 0.6,
    })

    anims.create({
      key: `${CharactersList[i].name}_idle_down`,
      frames: anims.generateFrameNames(`${CharactersList[i].name}`, {
        start: 18,
        end: 23,
      }),
      repeat: -1,
      frameRate: animsFrameRate * 0.6,
    })

    anims.create({
      key: `${CharactersList[i].name}_run_right`,
      frames: anims.generateFrameNames(`${CharactersList[i].name}`, {
        start: 24,
        end: 29,
      }),
      repeat: -1,
      frameRate: animsFrameRate,
    })

    anims.create({
      key: `${CharactersList[i].name}_run_up`,
      frames: anims.generateFrameNames(`${CharactersList[i].name}`, {
        start: 30,
        end: 35,
      }),
      repeat: -1,
      frameRate: animsFrameRate,
    })

    anims.create({
      key: `${CharactersList[i].name}_run_left`,
      frames: anims.generateFrameNames(`${CharactersList[i].name}`, {
        start: 36,
        end: 41,
      }),
      repeat: -1,
      frameRate: animsFrameRate,
    })

    anims.create({
      key: `${CharactersList[i].name}_run_down`,
      frames: anims.generateFrameNames(`${CharactersList[i].name}`, {
        start: 42,
        end: 47,
      }),
      repeat: -1,
      frameRate: animsFrameRate,
    })

    anims.create({
      key: `${CharactersList[i].name}_skate_right`,
      frames: anims.generateFrameNames(`${CharactersList[i].name}`, {
        start: 48,
        end: 53,
      }),
      repeat: -1,
      frameRate: animsFrameRate,
    })

    anims.create({
      key: `${CharactersList[i].name}_skate_up`,
      frames: anims.generateFrameNames(`${CharactersList[i].name}`, {
        start: 54,
        end: 59,
      }),
      repeat: -1,
      frameRate: animsFrameRate,
    })

    anims.create({
      key: `${CharactersList[i].name}_skate_left`,
      frames: anims.generateFrameNames(`${CharactersList[i].name}`, {
        start: 60,
        end: 65,
      }),
      repeat: -1,
      frameRate: animsFrameRate,
    })

    anims.create({
      key: `${CharactersList[i].name}_skate_down`,
      frames: anims.generateFrameNames(`${CharactersList[i].name}`, {
        start: 66,
        end: 71,
      }),
      repeat: -1,
      frameRate: animsFrameRate,
    })

    anims.create({
      key: `${CharactersList[i].name}_sit_right`,
      frames: anims.generateFrameNames(`${CharactersList[i].name}`, {
        start: 72,
        end: 72,
      }),
      repeat: 0,
      frameRate: animsFrameRate,
    })

    anims.create({
      key: `${CharactersList[i].name}_sit_up`,
      frames: anims.generateFrameNames(`${CharactersList[i].name}`, {
        start: 73,
        end: 73,
      }),
      repeat: 0,
      frameRate: animsFrameRate,
    })

    anims.create({
      key: `${CharactersList[i].name}_sit_left`,
      frames: anims.generateFrameNames(`${CharactersList[i].name}`, {
        start: 74,
        end: 74,
      }),
      repeat: 0,
      frameRate: animsFrameRate,
    })

    anims.create({
      key: `${CharactersList[i].name}_sit_down`,
      frames: anims.generateFrameNames(`${CharactersList[i].name}`, {
        start: 75,
        end: 75,
      }),
      repeat: 0,
      frameRate: animsFrameRate,
    })
  }
}

export function createNPCAnims(anims: Phaser.Animations.AnimationManager) {
  const animsFrameRate = 15

  for (let i = 0; i < NPCList.length; i++) {
    anims.create({
      key: `${NPCList[i].name}-npc_idle_right`,
      frames: anims.generateFrameNames(`${NPCList[i].name}-npc`, {
        start: 0,
        end: 5,
      }),
      repeat: -1,
      frameRate: animsFrameRate * 0.6,
    })

    anims.create({
      key: `${NPCList[i].name}-npc_idle_up`,
      frames: anims.generateFrameNames(`${NPCList[i].name}-npc`, {
        start: 6,
        end: 11,
      }),
      repeat: -1,
      frameRate: animsFrameRate * 0.6,
    })

    anims.create({
      key: `${NPCList[i].name}-npc_idle_left`,
      frames: anims.generateFrameNames(`${NPCList[i].name}-npc`, {
        start: 12,
        end: 17,
      }),
      repeat: -1,
      frameRate: animsFrameRate * 0.6,
    })

    anims.create({
      key: `${NPCList[i].name}-npc_idle_down`,
      frames: anims.generateFrameNames(`${NPCList[i].name}-npc`, {
        start: 18,
        end: 23,
      }),
      repeat: -1,
      frameRate: animsFrameRate * 0.6,
    })

    anims.create({
      key: `${NPCList[i].name}-npc_run_right`,
      frames: anims.generateFrameNames(`${NPCList[i].name}-npc`, {
        start: 24,
        end: 29,
      }),
      repeat: -1,
      frameRate: animsFrameRate,
    })

    anims.create({
      key: `${NPCList[i].name}-npc_run_up`,
      frames: anims.generateFrameNames(`${NPCList[i].name}-npc`, {
        start: 30,
        end: 35,
      }),
      repeat: -1,
      frameRate: animsFrameRate,
    })

    anims.create({
      key: `${NPCList[i].name}-npc_run_left`,
      frames: anims.generateFrameNames(`${NPCList[i].name}-npc`, {
        start: 36,
        end: 41,
      }),
      repeat: -1,
      frameRate: animsFrameRate,
    })

    anims.create({
      key: `${NPCList[i].name}-npc_run_down`,
      frames: anims.generateFrameNames(`${NPCList[i].name}-npc`, {
        start: 42,
        end: 47,
      }),
      repeat: -1,
      frameRate: animsFrameRate,
    })

    // anims.create({
    //   key: `${NPCList[i].name}-npc_skate_right`,
    //   frames: anims.generateFrameNames(`${NPCList[i].name}-npc`, {
    //     start: 48,
    //     end: 53,
    //   }),
    //   repeat: -1,
    //   frameRate: animsFrameRate,
    // })

    // anims.create({
    //   key: `${NPCList[i].name}-npc_skate_up`,
    //   frames: anims.generateFrameNames(`${NPCList[i].name}-npc`, {
    //     start: 54,
    //     end: 59,
    //   }),
    //   repeat: -1,
    //   frameRate: animsFrameRate,
    // })

    // anims.create({
    //   key: `${NPCList[i].name}-npc_skate_left`,
    //   frames: anims.generateFrameNames(`${NPCList[i].name}-npc`, {
    //     start: 60,
    //     end: 65,
    //   }),
    //   repeat: -1,
    //   frameRate: animsFrameRate,
    // })

    // anims.create({
    //   key: `${NPCList[i].name}-npc_skate_down`,
    //   frames: anims.generateFrameNames(`${NPCList[i].name}-npc`, {
    //     start: 66,
    //     end: 71,
    //   }),
    //   repeat: -1,
    //   frameRate: animsFrameRate,
    // })

    // anims.create({
    //   key: `${NPCList[i].name}-npc_sit_down`,
    //   frames: anims.generateFrameNames(`${NPCList[i].name}-npc`, {
    //     start: 48,
    //     end: 48,
    //   }),
    //   repeat: 0,
    //   frameRate: animsFrameRate,
    // })

    // anims.create({
    //   key: `${NPCList[i].name}-npc_sit_left`,
    //   frames: anims.generateFrameNames(`${NPCList[i].name}-npc`, {
    //     start: 49,
    //     end: 49,
    //   }),
    //   repeat: 0,
    //   frameRate: animsFrameRate,
    // })

    // anims.create({
    //   key: `${NPCList[i].name}-npc_sit_right`,
    //   frames: anims.generateFrameNames(`${NPCList[i].name}-npc`, {
    //     start: 50,
    //     end: 50,
    //   }),
    //   repeat: 0,
    //   frameRate: animsFrameRate,
    // })

    // anims.create({
    //   key: `${NPCList[i].name}-npc_sit_up`,
    //   frames: anims.generateFrameNames(`${NPCList[i].name}-npc`, {
    //     start: 51,
    //     end: 51,
    //   }),
    //   repeat: 0,
    //   frameRate: animsFrameRate,
    // })
  }


  anims.create({
    key: `police-goop-npc_goop`,
    frames: anims.generateFrameNames(`police-npc-goop`, {
      start: 0,
      end: 48,
    }),
    repeat: 0,
    frameRate: animsFrameRate * 0.6,
  })


}
