import Camera = Phaser.Cameras.Scene2D.Camera;
import Sprite = Phaser.Physics.Arcade.Sprite;
import phaserGame from "../PhaserGame";

export function getCanvasSpaceCoordinates(sprite: Sprite, camera: Camera, canvas: HTMLCanvasElement) {
    const canvasBoundingRect = phaserGame.canvas.getBoundingClientRect();
    return {
        x: (sprite.x - camera.worldView.x) * camera.zoom + canvasBoundingRect.left,
        y: (sprite.y - camera.worldView.y) * camera.zoom + canvasBoundingRect.top
    }
}