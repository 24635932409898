import Container = Phaser.GameObjects.Container;
import {Scene} from "phaser";

export class IGauge extends Container {

    currentPercent: number;

    constructor(startPercent: number, scene: Scene) {
        super(scene);

        this.currentPercent = startPercent;
    }

    setPercent(percent: number) {
        if (percent < 0) percent = 0;
        if (percent > 1) percent = 1;

        this.currentPercent = percent;

        this.updateGaugeSize();
    }

    modifyPercent(amountGained: number) {
        this.setPercent(this.currentPercent + amountGained);
    }

    getPercent(): number {
        return this.currentPercent;
    }

    updateGaugeSize() {
    }
}

export class Gauge extends IGauge {
    private readonly gaugeMask: Phaser.Display.Masks.GeometryMask;
    private readonly gaugeBgImg: Phaser.GameObjects.Image;
    private readonly gaugeFillImg: Phaser.GameObjects.Image;
    private shape: Phaser.GameObjects.Graphics;

    constructor(bgImagePath: string, fillImagePath: string, startPercent: number, scene: Scene) {
        super(startPercent, scene);

        this.gaugeBgImg = scene.add.image(0, 0, bgImagePath);
        this.gaugeFillImg = scene.add.image(0, 0, fillImagePath);

        this.add(this.gaugeBgImg);
        this.add(this.gaugeFillImg);

        scene.add.graphics();
        this.shape = scene.make.graphics({x: 0, y: 0});
        this.shape
            .beginPath()
            .fillRect(0, 0, this.gaugeFillImg.width, this.gaugeFillImg.height);

        this.gaugeMask = this.shape.createGeometryMask()
        this.gaugeFillImg.setMask(this.gaugeMask);
        this.setSize(this.gaugeBgImg.width, this.gaugeBgImg.height)
    }

    update() {
        this.updateGaugeSize();
    }

    updateGaugeSize() {
        this.shape.x =  this.x + this.parentContainer.x - this.gaugeFillImg.width / 2 - this.gaugeFillImg.width + this.gaugeFillImg.width * this.currentPercent;
        this.shape.y = this.y + this.parentContainer.y - this.gaugeFillImg.height / 2;
    }
}

export class DevGauge extends IGauge {
    private readonly gauge: Phaser.GameObjects.Rectangle;

    constructor(bgColor: number, fillColor: number, private readonly gaugeWidth: number, gaugeHeight: number, startPercent: number, scene: Scene) {
        super(startPercent, scene);

        const background = scene.add.rectangle(0, 0, gaugeWidth, gaugeHeight, bgColor);
        this.add(background);

        this.gauge = scene.add.rectangle(0, 0, gaugeWidth, gaugeHeight, fillColor);
        this.add(this.gauge);

        scene.add.existing(this);

        this.setSize(gaugeWidth, gaugeHeight);

        this.updateGaugeSize();
    }

    updateGaugeSize() {
        this.gauge.width = this.gaugeWidth * this.getPercent();
    }
}