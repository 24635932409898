import Container = Phaser.GameObjects.Container;
import {Scene} from "phaser";
import {KeyHint} from "./CorrectSeqMiniGameKeyHint";
import {CorrectSeqMiniGame} from "./CorrectSeqMiniGame";

export class CorrectSeqMiniGameMovingFruit extends Container {
    public currentPercent: number = 1;

    constructor(
        private readonly containerMinX: number,
        private readonly containerMaxX: number,
        y: number,
        private readonly speed: number,
        public readonly fruitHint: KeyHint,
        private readonly miniGame: CorrectSeqMiniGame,
        scene: Scene) {
        super(scene);

        // const keySymbol = scene.add.rectangle(0, 0, 30, 30, keyHint.keyColor);
        const keySymbol = scene.add.image(0, y, fruitHint.symbolImage);
        this.add(keySymbol);

        scene.add.existing(this);

        this.updatePosition();
    }

    update(delta: number) {
        this.currentPercent -= this.speed * (delta / 1000);
        this.updatePosition();

        if (this.currentPercent <= 0) this.miniGame.triggerKeyFail(this);
    }

    private updatePosition() {
        const barWidth = this.containerMaxX - this.containerMinX;
        const currentWidthPosition = barWidth * this.currentPercent;
        this.x = this.containerMinX + currentWidthPosition;
    }
}