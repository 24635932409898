import phaserGame from '../../PhaserGame'
import Game from '../../scenes/Game'

export default function getNPC(npcId: string) {
  const currentScene = phaserGame.scene.keys.game as Game

  const npc = Object.values(currentScene.npcs.children.entries).find(
    (npc: any) => npc.playerId === npcId
  )

  return npc
}
