import React, { useEffect } from 'react'
import styled from 'styled-components'
import Border from '../components/shared/Border'
import { useAppSelector } from '../hooks'
import store from '../stores'
import { setTime } from '../stores/TimerStore'

const Backdrop = styled.div`
  margin-top: auto;
  margin-bottom: 0px;
  position: fixed;
  top: 20px;
  right: 20px;
  opacity: 0.9;
  font-family: 'Aldrich';

  z-index: 999;
`

const Button = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  z-index: 99;
  padding: 12px 5px;
  height: 55px;
  width: 135px;
  background-color: #fff;
  font-family: 'Aldrich';
  color: #000;
  font-size: 40px;
  line-height: 40px;
  :hover {
    background-color: #ddd;
    cursor: pointer;
  }
`

const Body = styled.div`
  background: rgb(248, 248, 248) none repeat scroll 0% 0%;
  border-bottom: 4px solid rgb(216, 213, 237);
  padding: 0px;

  @media (max-width: 700px) {
    padding: 0px;
  }
`

export default function Timer() {
  const time = useAppSelector((state) => state.timer.time)
  const isCounting = useAppSelector((state) => state.timer.isCounting)


  useEffect(() => {
      if (time > 0) {
        setTimeout(function () {
          store.dispatch(setTime(time - 1))
        }, 1000)
      }
  }, [time])

    return (
      <Backdrop>
        {isCounting ? 
        <Border>
        <Body>
          <Button>00:{time < 10 ? FormattedNumber(time) : time}</Button>
        </Body>
      </Border> 
      : <></>}
      </Backdrop>
    )
}

function FormattedNumber(number: number) {
  return ('0' + number).slice(-2)
}
