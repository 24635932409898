import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import MenuOption from '../../components/menu/MenuOption'
import { setFocused, setTouch } from '../../stores/UserStore'
import { useAppDispatch } from '../../hooks'

export default function CloseButton({ setActiveMenu }) {
  const dispatch = useAppDispatch()

  return (
    <MenuOption
      onClick={() => {
        dispatch(setActiveMenu(''))
        dispatch(setFocused(false))
        dispatch(setTouch(true))
      }}
      icon={<CloseIcon />}
      text={'Close Menu'}
    />
  )
}
