import React from 'react'
import MenuOption from '../../components/menu/MenuOption'
import { useAppDispatch } from '../../hooks'
import { setFocused, setTouch } from '../../stores/UserStore'
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { setCompletedQuests } from '../../stores/QuestStore';

export default function ResetQuestProgressButton({ setActiveMenu }) {
  const dispatch = useAppDispatch()

  return (
    <MenuOption
      onClick={() => {
        
        localStorage.removeItem('defimons_quests');
        dispatch(setCompletedQuests([]))
        dispatch(setActiveMenu(''))
        dispatch(setFocused(false))
        dispatch(setTouch(true))
      }}
      icon={<SaveAsIcon />}
      text={'Reset Quests'}
    />
  )
}
