import styled from 'styled-components'
import { useAppSelector } from '../../hooks'
import { GameState } from '../../stores/GameStore'

const Backdrop = styled.div`
  bottom: 30px;
  left: 30px;
  height: 400px;
  width: 100%;
  font-family: 'VT323';
`

const HealthBar = styled.div`
  margin: 30px;
  display: flex;
  img {
    width: 90px;
    height: 90px;
    background-color: #ffffff;
    border: solid;
    border-radius: 0.5rem;
    border: double 10px #555;
    position: absolute;
    z-index: 11;
  }
  h2 {
    background-color: #ffffff;
    border: solid;
    border-radius: 0.5rem;
    border: double 0.5rem #555;
    left: 80px;
    height: 60px;
    padding: 3px 0 0 45px;
    position: absolute;
    width: 340px;
    font-size: 24px;
    margin: 0px;

    span {
      right: 10px;
      position: absolute;
      font-weight: 800;
      font-size: 26px;
      top: 0px;
    }
  }
  .health-bar {
    background-color: #ffffff;
    border: solid;
    border-radius: 0.5rem;
    border: single 0.75rem #555;
    left: 80px;
    top: 88px;
    height: 30px;
    padding: 0px 30px;
    position: absolute;
    width: 300px;
    font-size: 18px;
    background: #d34549;
    box-shadow: inset 0 3px 0 -1px #d3aa9a, inset 0 -4px 0 -2px #4d494d, inset 4px 0 0 -2px #4d494d,
      inset -4px 0 0 -2px #4d494d;
  }

  @media (max-width: 700px) {
    h2 {
      width: 250px;
    }
  }
`

export default function SunkenShipUI() {
  const gameState = useAppSelector((state) => state.game.gameState)
  const name = useAppSelector((state) => state.user.name)
  if (gameState === GameState.SunkenShip) {
    return (
      <Backdrop>
        <HealthBar>
          <img src="/assets/archive/profile-picture.png" alt="profile" />
          <h2>
            {name} <span>540</span>
          </h2>
          <div className="health-bar"></div>
        </HealthBar>
      </Backdrop>
    )
  } else return <></>
}
