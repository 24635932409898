import React, { useState } from 'react'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import MenuOption from '../../components/menu/MenuOption'
import MusicOffIcon from '@mui/icons-material/MusicOff'
import { useAppSelector } from '../../hooks'
import store from '../../stores'
import {  setSoundEffects } from '../../stores/PrefStore'

export default function SoundEffectsButton() {
  const musicState = useAppSelector((state) => state.pref.soundEffects)
  const [music, setmusic] = useState(musicState)

  return (
    <MenuOption
      onClick={() => {
        store.dispatch(setSoundEffects(!music))
        setmusic(!music)
      }}
      icon={music ? <MusicNoteIcon /> : <MusicOffIcon />}
      text={'Sound Effects'}
    />
  )
}
