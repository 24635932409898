import { setTimeout } from 'timers'
import { NPCdata, NPCType } from '../dialogue/npc-data'
import getNPC from '../helpers/game/getNPC'
import phaserGame from '../PhaserGame'
import Bootstrap from '../scenes/Bootstrap'
import Game from '../scenes/Game'
import { spawnNPC } from '../scenes/methods'
import { spawnGoop } from '../scenes/methods/spawn'
import store from '../stores'
import { setMultiplayer } from '../stores/GameStore'
import { MessageType, openMessageDialog } from '../stores/MessageStore'
import { setNotification } from '../stores/NotificationStore'
import { completeCheckpoint } from '../stores/QuestStore'
import { setMyPlayerProps } from '../stores/UserStore'

const policeOfficer = {
  name: NPCdata.find((npc) => npc.id === 'officer-ken')?.name || '',
  texture: 'police-npc',
  id: 'officer-ken',
  type: NPCType.NORMAL,
  spawnPoint: {
    x: 800,
    y: 750,

    frame: 'down',
  },
}

const goopFrames = [
  {
    x: 600,
    y: 450,
  },
  {
    x: 600,
    y: 550,
  },
  {
    x: 600,
    y: 650,
  },
  {
    x: 600,
    y: 750,
  },
  {
    x: 600,
    y: 850,
  },

  {
    x: 700,
    y: 450,
  },
  {
    x: 700,
    y: 550,
  },
  {
    x: 700,
    y: 650,
  },
  {
    x: 700,
    y: 750,
  },
  {
    x: 700,
    y: 850,
  },

  {
    x: 800,
    y: 450,
  },
  {
    x: 800,
    y: 550,
  },
  {
    x: 800,
    y: 650,
  },
  {
    x: 800,
    y: 850,
  },

  {
    x: 900,
    y: 450,
  },
  {
    x: 900,
    y: 550,
  },
  {
    x: 900,
    y: 650,
  },
  {
    x: 900,
    y: 750,
  },
  {
    x: 900,
    y: 850,
  },

  {
    x: 1000,
    y: 450,
  },
  {
    x: 1000,
    y: 550,
  },
  {
    x: 1000,
    y: 650,
  },
  {
    x: 1000,
    y: 750,
  },
  {
    x: 1000,
    y: 850,
  },

  {
    x: 1100,
    y: 450,
  },
  {
    x: 1100,
    y: 550,
  },
  {
    x: 1100,
    y: 650,
  },
  {
    x: 1100,
    y: 750,
  },
  {
    x: 1100,
    y: 850,
  },

  {
    x: 1200,
    y: 450,
  },
  {
    x: 1200,
    y: 550,
  },
  {
    x: 1200,
    y: 650,
  },
  {
    x: 1200,
    y: 750,
  },
  {
    x: 1200,
    y: 850,
  },

  {
    x: 1300,
    y: 450,
  },
  {
    x: 1300,
    y: 550,
  },
  {
    x: 1300,
    y: 750,
  },
  {
    x: 1300,
    y: 850,
  },

  {
    x: 1400,
    y: 450,
  },
  {
    x: 1400,
    y: 550,
  },
  {
    x: 1400,
    y: 650,
  },
  {
    x: 1400,
    y: 750,
  },
  {
    x: 1400,
    y: 850,
  },

  {
    x: 1500,
    y: 450,
  },
  {
    x: 1500,
    y: 550,
  },
  {
    x: 1500,
    y: 650,
  },
  {
    x: 1500,
    y: 750,
  },
  {
    x: 1500,
    y: 850,
  },

  {
    x: 1600,
    y: 450,
  },
  {
    x: 1600,
    y: 550,
  },
  {
    x: 1600,
    y: 650,
  },
  {
    x: 1600,
    y: 750,
  },
  {
    x: 1600,
    y: 850,
  },

  {
    x: 1700,
    y: 450,
  },
  {
    x: 1700,
    y: 550,
  },
  {
    x: 1700,
    y: 650,
  },
  {
    x: 1700,
    y: 750,
  },
  {
    x: 1700,
    y: 850,
  },

  {
    x: 1800,
    y: 450,
  },
  {
    x: 1800,
    y: 550,
  },
  {
    x: 1800,
    y: 650,
  },
  {
    x: 1800,
    y: 750,
  },
  {
    x: 1800,
    y: 850,
  },
]

// Start
export function startFindingGoop() {
  const bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap
  let userName = store.getState().user.name
  let game = phaserGame.scene.keys.game as Game

  game.cameras.main.shake(500, 0.01)

  setTimeout(() => {
    game.registry.destroy() // destroy registry
    bootstrap.handleSceneChange(game, null, 'yorokobiBankLocked')
    if (store.getState().pref.music) {
      game.music.stop()
      game.music = game.sound.add('graveyard-spook', {
        volume: 0.2,
        loop: true,
      })
      game.music.play()
    }
    store.dispatch(setMultiplayer(false))

    setTimeout(() => {
      game.fogOfWar.activateFogOfWar()
      for (let i = 0; i < goopFrames.length; i++) {
        spawnGoop({
          name: NPCdata.find((npc) => npc.id === 'officer-ken')?.name || '',
          texture: 'police-goop-npc',
          type: NPCType.GOOP,
          id: `goop-${i}`,
          spawnPoint: {
            x: goopFrames[i].x,
            y: goopFrames[i].y,
            frame: 'down',
          },
        })
      }

      spawnNPC(policeOfficer)

      setTimeout(() => {
        store.dispatch(
          openMessageDialog({
            messages: [`Oh, what just happened?`, ` Where am I?`],
            characterName: userName,
            onDialogEnded: {
              type: 'function',
              func: () => {
                store.dispatch(completeCheckpoint(`find-ken-hint`))

                setTimeout(() => {
                  store.dispatch(
                    setNotification({
                      content: `Find Officer Ken`,
                      type: `hint`,
                      timer: 3,
                    })
                  )
                }, 100)
              },
            },
            messageType: MessageType.single,
          })
        )
      }, 100)
    }, 1000)
  }, 500)
}

// Update
export function updateFindingGoop() {
  const checkpoints = store.getState().quest.checkpoints
  const messageDialogOpen = store.getState().message.messageDialogOpen
  const characterName = store.getState().message.characterName
  let game = phaserGame.scene.keys.game as Game
  const bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap

  // Interaction with goop
  if (
    checkpoints.find((checkpoint: any) => checkpoint.name === `first-interaction`)?.status ===
      false &&
    messageDialogOpen &&
    characterName === 'goop'
  ) {
    store.dispatch(completeCheckpoint(`first-interaction`))
  }
  // Interaction with officer ken
  if (
    checkpoints.find((checkpoint: any) => checkpoint.name === `dream-ended`)?.status === false &&
    messageDialogOpen &&
    characterName === 'Officer Ken'
  ) {
    store.dispatch(completeCheckpoint(`dream-ended`))
    game.registry.destroy() // destroy registry
    bootstrap.handleSceneChange(game, null, 'yorokobiBank')

    store.dispatch(setMultiplayer(true))
  }

  if (checkpoints.find((checkpoint: any) => checkpoint.name === `dream-ended`)?.status === true) {
    game.handleConversation(game.myPlayer, getNPC('officer-ken'), 6)
  }
}

// Complete
export function completeFindingGoop() {
  store.dispatch(
    setMyPlayerProps({
      texture: 'Detective',
    })
  )
}

// Utils
