/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAppSelector } from '../hooks'
import store from '../stores'
import { closeMessageDialog, MessageType, setMessageType } from '../stores/MessageStore'
import { setFocused, setTouch } from '../stores/UserStore'
import Text from '../components/dialogue/Text'
import Option from '../components/dialogue/Option'

// Components
const Background1 = styled.div`
  z-index: 1;
  position: absolute;
  inset: 0px -4px;
  background: rgb(34, 32, 52) none repeat scroll 0% 0%;
`

const Background2 = styled.div`
  z-index: 1;
  position: absolute;
  inset: -4px 0px;
  background: rgb(34, 32, 52) none repeat scroll 0% 0%;
`

const Main = styled.div`
  position: absolute;
  z-index: 2;
  background: rgb(229, 228, 231) none repeat scroll 0% 0%;
  height: 100%;
  width: 100%;
`

const DialogWindow = styled.div`
  image-rendering: pixelated;
  border: solid;
  position: absolute;
  font-family: 'VT323';
  top: ${(props) => props.messageBoxHeight < 150 ? 
    Math.ceil(props.screenHeight  - (150 + 150 * 0.1)) :
    Math.ceil(props.screenHeight - (props.messageBoxHeight + props.messageBoxHeight * 0.1))}px;
  width: ${(props) => Math.ceil(props.screenWidth * 0.9)}px;
  left: 50%;
  transform: translate(-50%, 0%);
  height: ${(props) => props.messageBoxHeight}px;
  min-height: 150px;
  z-index: 99;
  background-color: #fff;
  color: #555;
  pointer-events: auto;

  @media (max-width: 700px) {
    width: calc(100% - 20px);
    top: ${(props) => props.messageBoxHeight < 150 ? 
      Math.ceil(
        props.screenHeight - (150 + 150 * 0.1)
      ) : Math.ceil(
        props.screenHeight - (props.messageBoxHeight + props.messageBoxHeight * 0.1)
      )}px;
  }
`

const DialogFooter = styled.div`
  font-size: 24px;
  cursor: pointer;
  text-align: end;
  position: absolute;
  right: 20px;
  bottom: 20px;
  line-height: 30px;
  width: calc(100% - 40px);

  @media (max-width: 700px) {
    right: 10px;
    bottom: 10px;
  }
`

const DialogTitle = styled.div`
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 15px;
  font-weight: bold;
  image-rendering: pixelated;
  text-transform: uppercase;
  background-color: #e2b27e;
  border: solid;
  position: absolute;
  font-family: 'VT323';
  top: ${(props) => props.messageBoxHeight < 150 ? 
    Math.ceil(props.screenHeight - (60 + 150 + 150 * 0.1))
  : Math.ceil(props.screenHeight - (60 + props.messageBoxHeight + props.messageBoxHeight * 0.1))}px;
  width: ${(props) => Math.ceil(props.screenWidth * 0.9)}px;
  left: 50%;
  transform: translate(-50%, 0%);
  height: 50px;
  display: flex;
  flex-direction: column;
  z-index: 99;
  background-color: #fff;
  color: #555;
  z-index: 10;
  pointer-events: auto;

  @media (max-width: 700px) {
    width: calc(100% - 20px);
    top: ${(props) =>
      Math.ceil(
        props.screenHeight - (60 + props.messageBoxHeight + props.messageBoxHeight * 0.1)
      )}px;
    height: 50px;
  }
`

const Body = styled.div`
  background: rgb(248, 248, 248) none repeat scroll 0% 0%;
  border-bottom: 4px solid rgb(216, 213, 237);
  height: 100%;
  padding: 10px;
  overflow-y: auto;
  overflox-x: hidden;
`

const MessageDialog = () => {
  const [currentMessage, setCurrentMessage] = useState(0)
  const [messageEnded, setMessageEnded] = useState(false)
  const [forceShowFullMessage, setForceShowFullMessage] = useState(false)
  const [response, setResponse]: any = useState({})
  let messages = useAppSelector((state) => state.message.messages)
  let onDialogEnded = useAppSelector((state) => state.message.onDialogEnded)
  let characterName = useAppSelector((state) => state.message.characterName)
  let messageType = useAppSelector((state) => state.message.messageType)
  let userName = useAppSelector((state) => state.user.name)
  let screenHeight = window.innerHeight
  let screenWidth = window.innerWidth

  const messageBoxHeight = Math.ceil(screenHeight / 4)

  const handleClick = useCallback(() => {
    if (messageEnded) {
      setMessageEnded(false)
      setForceShowFullMessage(false)
      if (currentMessage < messages.length - 1) {
        setCurrentMessage(currentMessage + 1)
      } else {
        setCurrentMessage(0)
        if (onDialogEnded === null) {
          setMessageEnded(true)
          store.dispatch(setFocused(false))
          store.dispatch(setTouch(true))
          store.dispatch(closeMessageDialog())
        } else if(onDialogEnded && onDialogEnded.type){
          if(onDialogEnded.func){
            onDialogEnded.func()
          }

          setMessageEnded(true)
          store.dispatch(setFocused(false))
          store.dispatch(setTouch(true))
          store.dispatch(closeMessageDialog())
        } else {
          if (characterName !== userName) {
            setMessageEnded(true)
            setMessageType(MessageType.options)
            setResponse(onDialogEnded)
          }
        }
      }
    } else {
      setMessageEnded(true)
      setForceShowFullMessage(true)
    }
  }, [currentMessage, messageEnded, messages.length, onDialogEnded])

  useEffect(() => {
    const handleKeyPressed = (e) => {
      if (['Space'].includes(e.code)) {
        if (messageType === MessageType.single && response && Object.keys(response).length === 0) {
          handleClick()
        }
      }
    }
    window.addEventListener('keydown', handleKeyPressed)

    return () => window.removeEventListener('keydown', handleKeyPressed)
  }, [handleClick, messageType])

  if (response && Object.keys(response).length === 0) {
    return (
      <>
        <DialogTitle
          screenWidth={screenWidth}
          screenHeight={screenHeight}
          messageBoxHeight={messageBoxHeight}
        >
          <Background1 />
          <Background2 />
          <Main>
            <Body>{characterName}</Body>
          </Main>
        </DialogTitle>
        <DialogWindow
          screenWidth={screenWidth}
          screenHeight={screenHeight}
          messageBoxHeight={messageBoxHeight}
        >
          <Background1 />
          <Background2 />
          <Main>
            <Body onClick={handleClick}>
              <Text
                // action={messages[currentMessage].action}
                message={
                  messages[currentMessage] ? messages[currentMessage] : messages[currentMessage - 1]
                }
                key={messages[currentMessage] ? currentMessage : currentMessage - 1}
                forceShowFullMessage={forceShowFullMessage}
                onMessageEnded={() => {
                  setMessageEnded(true)
                }}
              />
              <DialogFooter>
                <div style={{ animation: 'blink 0.5s steps(2, start) infinite' }}>■</div>
              </DialogFooter>
            </Body>
          </Main>
        </DialogWindow>
      </>
    )
  } else {
    const responses: any = []

    if (response) {
      Object.values(response).forEach((messageObj: any) => responses.push(messageObj))
    }
    return (
      <>
        <DialogTitle
          screenWidth={screenWidth}
          screenHeight={screenHeight}
          messageBoxHeight={messageBoxHeight}
        >
          <Background1 />
          <Background2 />
          <Main>
            <Body>{userName}</Body>
          </Main>
        </DialogTitle>
        <DialogWindow
          screenWidth={screenWidth}
          screenHeight={screenHeight}
          messageBoxHeight={messageBoxHeight}
        >
          <Background1 />
          <Background2 />
          <Main>
            <Body>
              {responses.map((currentResponse, key) => (
                <Option
                  key={key}
                  currentKey={key}
                  response={currentResponse.text}
                  followup={currentResponse.followup}
                  setResponse={setResponse}
                />
              ))}
              {responses.length > 3 ? (
                <DialogFooter
                  style={{
                    pointerEvents: 'none',
                  }}
                >
                  <div style={{ animation: 'blink 0.5s steps(2, start) infinite' }}>▼</div>
                </DialogFooter>
              ) : (
                <></>
              )}
            </Body>
          </Main>
        </DialogWindow>
      </>
    )
  }
}

export default MessageDialog
