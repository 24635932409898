import InteractionButton from '../../interfaces/buttons/InteractionButton'
import MenuButton from '../../interfaces/buttons/MenuButton'
import TabletButton from '../../interfaces/buttons/TabletButton'
import { useAppSelector } from '../../hooks'
import { GameState } from '../../stores/GameStore'
import ChatButton from '../../interfaces/buttons/ChatButton'
import VirtualPad from '../../interfaces/VirtualPad'
import Timer from '../../interfaces/Timer'
import WarningText from '../../interfaces/WarningText'
import ScreenFlash from '../../interfaces/ScreenFlash'
import ProximityAudio from '../../interfaces/buttons/ProximityAudioButton'

export default function OverworldUI() {
  const gameState = useAppSelector((state) => state.game.gameState)
  const loggedIn = useAppSelector((state) => state.user.loggedIn)
  if (gameState === GameState.Overworld && loggedIn) {
    return (
      <>
        <MenuButton />
        <TabletButton />
        <InteractionButton />
        <ChatButton />
        <VirtualPad />
        <Timer/>
        <WarningText/>
        <ScreenFlash />
        { process.env.REACT_APP_ENV === 'TESTING' ? <ProximityAudio /> : <></>}
      </>
    )
  } else return <></>
}
