import store from '../../stores'
import {
  setAltCursors,
  setMusic,
  setSkating,
  setSoundEffects,
  setZoom,
} from '../../stores/PrefStore'
import { setCompletedQuests } from '../../stores/QuestStore'

export function handlePreferences() {
  const codedPrefs = localStorage.getItem('defimons_prefs')
  // Check if preferences exist
  if (codedPrefs) {
    const prefs = JSON.parse(atob(codedPrefs))
    // Set Music
    if (typeof prefs.music === 'boolean') {
      store.dispatch(setMusic(prefs.music))
    }
    // Set AltCursors
    if (typeof prefs.altCursors === 'boolean') {
      store.dispatch(setAltCursors(prefs.altCursors))
    }
    // Set Skating
    if (typeof prefs.autoSkating === 'boolean') {
      store.dispatch(setSkating(prefs.autoSkating))
    }
    // Set Camera
    if (typeof prefs.zoom === 'boolean') {
      store.dispatch(setZoom(prefs.zoom))
    }
    // Set Music
    if (typeof prefs.soundEffects === 'boolean') {
      store.dispatch(setSoundEffects(prefs.soundEffects))
    }
  }
}

export function handleCompletedQuests() {
  const codedQuests = localStorage.getItem('defimons_quests')
  // Check if preferences exist
  if (codedQuests) {
    const quests = JSON.parse(atob(codedQuests))
    // Set Quests
    store.dispatch(setCompletedQuests(quests.completedQuests))
  }
}
