import { getRandomNumberFromArray } from '../../helpers/dataHelpers'
import store from '../../stores'
import { startConversation } from '../../stores/MessageStore'
import { getCompletedQuests } from '../../stores/QuestStore'

import { NPCdata } from '../npc-data'

export default function YoungHunter(npc, scene, dialogue?: number) {
  // RELATED QUESTS ARE = [2]
  let completedQuests = getCompletedQuests()
  let conversation = 3 // STARTS 2ND QUEST

  if (completedQuests && completedQuests.includes(2)) {
    conversation = getRandomNumberFromArray([0, 1, 5])
  }

  if (npc.playerId === 'young-hunter') {
    store.dispatch(
      startConversation({
        npc: NPCdata.find((current) => current.id === npc.playerId),
        conversation: dialogue ? dialogue : conversation,
        messageNumber: 1,
      })
    )
  }
}
