import { NPCdata, NPCType } from '../dialogue/npc-data'
import phaserGame from '../PhaserGame'
import Game from '../scenes/Game'
import getDistance from '../helpers/game/getDistance'
import { getNPCPosition } from '../helpers/game/getPosition'
import { spawnNPC } from '../scenes/methods'

export function startPrisonersOfYorokobi(state?: any) {
  const prisonerNPC = {
    name: NPCdata.find((npc) => npc.id === 'prisoner')?.name || '',
    texture: 'inmate-npc',
    id: 'prisoner',
    type: NPCType.NORMAL,
    spawnPoint: {
      x: 956,
      y: 1153,
      frame: 'down',
    },
    pathFindingData: [
      {
        x: 956,
        y: 1153,
        onDestinationReachedWaitTime_ms: 500,
      },
      {
        x: 956,
        y: 1475,
        onDestinationReachedWaitTime_ms: 500,
      },
      {
        x: 223,
        y: 1460,
        onDestinationReachedWaitTime_ms: 500,
      },
    ],
  }

  spawnNPC(prisonerNPC)
}

export function updatePrisonersOfYorokobi() {
  const game = phaserGame.scene.keys.game as Game

  // Check if prisoner reached destination
  if (getNPCPosition('prisoner').x < 224) {
    const npc = Object.values(game.npcs.children.entries).find(
      (npc: any) => npc.playerId === 'prisoner'
    )

    // remove prisoner if reached
    if (npc) {
      game.npcs.remove(npc, true, true)
      console.log(`Looks like the prisoner hid the NFTs in the trash bin.`)
    }
  } else {
    console.log(getDistance('prisoner') < 150 ? `You're getting too close` : `You're safe`)
  }
}
