import Container = Phaser.GameObjects.Container;
import { Scene } from "phaser";
import { BattleButton } from "./battle-button";
import { BattleDefimon } from "../defimons";
import { BattleTeam } from "../team";

export class DefimonSwitchUI extends Container {
  private static readonly BUTTONS_WIDTH = 200;
  private static readonly BUTTONS_HEIGHT = 75;
  private static readonly BUTTONS_GAP = 20;

  constructor(team: BattleTeam, onDefimonPicked: (defimon: BattleDefimon) => void, onChoiceCancelled: () => void, scene: Scene) {
    super(scene);

    let currentX = 0;

    team.defimons.forEach(defimon => {
      if (defimon === team.currentActiveDefimon) return
      const button = new BattleButton(DefimonSwitchUI.BUTTONS_WIDTH, DefimonSwitchUI.BUTTONS_HEIGHT, defimon.name, defimon.isDead() ? 0x888888 : 0xffffff, "#000000", scene);
      if (!defimon.isDead()) button.registerCallback(() => onDefimonPicked(defimon));
      button.x = currentX;
      currentX += DefimonSwitchUI.BUTTONS_WIDTH + DefimonSwitchUI.BUTTONS_GAP;

      this.add(button);
    })

    const cancelButton = new BattleButton(DefimonSwitchUI.BUTTONS_HEIGHT, DefimonSwitchUI.BUTTONS_HEIGHT, "CANCEL", 0xff3333, "#ffffff", scene);
    cancelButton.registerCallback(() => onChoiceCancelled());
    cancelButton.x = -DefimonSwitchUI.BUTTONS_HEIGHT * 2 - DefimonSwitchUI.BUTTONS_GAP
    this.add(cancelButton);

    this.setSize(team.defimons.length * DefimonSwitchUI.BUTTONS_WIDTH + (team.defimons.length - 1) * DefimonSwitchUI.BUTTONS_GAP, DefimonSwitchUI.BUTTONS_HEIGHT);

    scene.add.existing(this);
  }
}