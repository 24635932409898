import MyPlayer from '../characters/MyPlayer'
import PlayerSelector from '../characters/PlayerSelector'
import OtherPlayer from '../characters/OtherPlayer'

import network from '../services/Network'
import store from '../stores'
import { setTouch } from '../stores/UserStore'
import NPC from '../characters/NPC'

import BaseMap from './classes/Map'

import { gameConfig } from '../assets/game-data'
import ImageLoader from './classes/ImageLoader'
import { createObjectAnims } from '../anims/ObjectAnims'
import AnimatedTile from './classes/AnimatedTiles'
import { WeatherDisplayManager } from './classes/Weather'
import handleConversation from '../dialogue/Index'
import Computer from '../items/Computer'
import Whiteboard from '../items/Whiteboard'
import { updateQuests } from '../quests'
import { setMapLoaded } from '../stores/GameStore'
import SoundLoader from './classes/SoundLoader'
import { setNotification } from '../stores/NotificationStore'
import {
  createCharacterAnims,
  createNPCAnims,
  handleChatMessageAdded,
  handleCompletedQuests,
  handleItemUserAdded,
  handleItemUserRemoved,
  handleKeys,
  handleMyPlayerReady,
  handleMyVideoConnected,
  handlePlayerChangedMap,
  handlePlayerJoined,
  handlePlayerLeft,
  handlePlayerUpdated,
  handlePreferences,
  handleWeatherChange,
  registerKeys,
  spawnMyPlayer,
  spawnNPCs,
  spawnOtherPlayers,
} from './methods'
import GameUI from './GameUI'
import GameObject = Phaser.GameObjects.GameObject
import { FogOfWarController } from '../map-effects/fog-of-war/FogOfWarController'

export interface ISceneData {
  currentMap: string
  onLeave: (scene: any, currentScene: any, newScene: any) => void
  previousMap: null | string
}

export interface IPlayerMovement {
  up: boolean
  down: boolean
  left: boolean
  right: boolean
  angle?: string
}

export default class Game extends Phaser.Scene {
  myPlayer!: MyPlayer
  playerMovement!: IPlayerMovement
  playerInteraction!: boolean
  map!: BaseMap
  playerSelector!: Phaser.GameObjects.Zone
  otherPlayers!: Phaser.Physics.Arcade.Group
  npcs!: Phaser.Physics.Arcade.Group
  otherPlayerMap = new Map<string, OtherPlayer>()
  keyE!: Phaser.Input.Keyboard.Key
  keyR!: Phaser.Input.Keyboard.Key
  cursors!: Phaser.Types.Input.Keyboard.CursorKeys
  altCursors!: any
  keyShift!: Phaser.Input.Keyboard.Key
  teleportZoneGroup!: Phaser.Physics.Arcade.StaticGroup
  chairGroup!: Phaser.Physics.Arcade.StaticGroup
  computerGroup!: Phaser.Physics.Arcade.StaticGroup
  whiteboardGroup!: Phaser.Physics.Arcade.StaticGroup
  unlockableGroup!: Phaser.Physics.Arcade.StaticGroup
  collectibleGroup!: Phaser.Physics.Arcade.Group
  infoGroup!: Phaser.Physics.Arcade.StaticGroup
  sceneData!: ISceneData
  currentTilemap!: Phaser.Tilemaps.Tilemap
  worldLayer: any
  mapName!: string
  currentMap!: string
  animatedTiles!: AnimatedTile[]
  music!: Phaser.Sound.BaseSound
  soundEffects!: Phaser.Sound.BaseSound
  weatherDisplayManager!: WeatherDisplayManager
  hasWeather!: boolean
  isRaining!: boolean
  computerMap = new Map<string, Computer>()
  whiteboardMap = new Map<string, Whiteboard>()
  isOutside!: boolean
  fogOfWar!: FogOfWarController

  gameObjects: GameObject[] = []

  constructor() {
    super('game')
  }

  init() {
    this.animatedTiles = []
  }

  preload() {
    new ImageLoader(this)
    new SoundLoader(this)
  }

  async create(data: ISceneData) {
    if (!network) {
      throw new Error('server instance missing')
    }

    this.sceneData = data

    const mapVars = gameConfig.maps[data.currentMap]
    this.currentMap = data.currentMap
    this.mapName = mapVars.name

    this.sceneReset()

    const spawnX = data.previousMap
      ? mapVars.spawnPoints[data.previousMap].x
      : mapVars.spawnPoints['default'].x
    const spawnY = data.previousMap
      ? mapVars.spawnPoints[data.previousMap].y
      : mapVars.spawnPoints['default'].y
    const frame = data.previousMap
      ? mapVars.spawnPoints[data.previousMap].frame
      : mapVars.spawnPoints['default'].frame

    this.map = new BaseMap({
      scene: this,
      sceneName: data.currentMap,
      key: mapVars.name,
      json: mapVars.json,
      tilesets: mapVars.tilesets,
      spawnPoint: {
        x: spawnX,
        y: spawnY,
      },
    })
    handlePreferences()
    handleCompletedQuests()

    this.playerMovement = { up: false, down: false, left: false, right: false, angle: '' }
    this.playerInteraction = false
    this.isOutside = mapVars.isOutside

    createCharacterAnims(this.anims)
    createNPCAnims(this.anims)
    createObjectAnims(data.currentMap, this.anims)

    registerKeys()

    this.playerSelector = new PlayerSelector(this, 0, 0, 16, 16)

    this.otherPlayers = this.physics.add.group({ classType: OtherPlayer })
    this.npcs = this.physics.add.group({ classType: NPC })

    this.cameras.main.zoom = store.getState().pref.zoom === true ? 1.4 : 1.2
    this.cameras.main.fadeIn(500)
    this.cameras.main.setBounds(
      0,
      0,
      this.currentTilemap.widthInPixels,
      this.currentTilemap.heightInPixels,
      true
    )

    spawnMyPlayer(spawnX, spawnY, frame)
    spawnNPCs()

    await network.joinOffice(mapVars.name, spawnX, spawnY)
    await spawnOtherPlayers()

    this.physics.add.collider([this.myPlayer, this.myPlayer.playerContainer], this.worldLayer)

    // weather manager object
    this.weatherDisplayManager = new WeatherDisplayManager(this)
    this.hasWeather = false //mapVars.isOutside

    this.scene.launch(GameUI.KEY, {})

    this.fogOfWar = new FogOfWarController(this.myPlayer, this)
    this.gameObjects.push(this.fogOfWar)

    // register network event listeners
    network.onPlayerJoined(handlePlayerJoined)
    network.onPlayerLeft(handlePlayerLeft)
    network.onPlayerChangeMap(handlePlayerChangedMap)
    network.onPlayerUpdated(handlePlayerUpdated)
    network.onMyPlayerReady(handleMyPlayerReady, this)
    network.onMyPlayerVideoConnected(handleMyVideoConnected, this)
    network.onChatMessageAdded(handleChatMessageAdded)
    network.onWeatherChange(handleWeatherChange)
    network.onItemUserAdded(handleItemUserAdded)
    network.onItemUserRemoved(handleItemUserRemoved)
  }

  update(time: number, delta: number) {
    if (this.myPlayer) {
      if (network.mySessionId) {
        const { weather } = store.getState().room
        const { altCursors } = store.getState().pref

        if (!weather && this.isRaining !== false) {
          this.weatherDisplayManager.stopRaining()
          this.isRaining = false
        } else if (weather && this.isRaining !== true) {
          this.weatherDisplayManager.startRaining(1, 3)
          this.isRaining = true
        }

        // Pass normal cursors if altCursors preference is false
        this.playerSelector.update(
          this.myPlayer,
          altCursors ? this.altCursors : this.cursors,
          this.keyShift
        )
        this.myPlayer.update(
          this.playerMovement,
          altCursors ? this.altCursors : this.cursors,
          this.playerSelector,
          this.playerInteraction,
          this.keyShift,
          this.keyE,
          this.keyR
        )
        this.gameObjects.forEach((go) => go.update(delta))

        updateQuests()
      }
    }

    this.animatedTiles.forEach((tile) => tile.update(delta))

    if (this.hasWeather) {
      this.weatherDisplayManager.update(delta)
    }
  }

  addGroupFromTiled(
    objectLayerName: string,
    key: string,
    tilesetName: string,
    collidable: boolean
  ) {
    const group = this.physics.add.staticGroup()
    const objectLayer = this.currentTilemap.getObjectLayer(objectLayerName)
    objectLayer.objects.forEach((object) => {
      const actualX = object.x! + object.width! * 0.5
      const actualY = object.y! - object.height! * 0.5
      group
        .get(
          actualX,
          actualY,
          key,
          object.gid! - this.currentTilemap.getTileset(tilesetName).firstgid
        )
        .setDepth(actualY)
    })
    if (this.myPlayer && collidable)
      this.physics.add.collider([this.myPlayer, this.myPlayer.playerContainer], group)
  }

  sceneReset() {
    store.dispatch(setTouch(false))
    store.dispatch(setTouch(true))
  }

  resetSceneLoad() {
    // Disable keys when not loggedIn and during the fade in animation
    // (to prevent rapid entering and leaving scenes)
    handleKeys(false)

    store.dispatch(setMapLoaded(true))
    if (store.getState().user.loggedIn) {
      this.cameras.main.once(Phaser.Cameras.Scene2D.Events.FADE_IN_COMPLETE, (cam, effect) => {
        handleKeys(true)
      })
    }
  }

  handlePlayerInteraction(newValue: boolean) {
    this.playerInteraction = newValue
  }

  resetNotification() {
    const notification = store.getState().notification.content

    if (notification) {
      store.dispatch(
        setNotification({
          content: '',
          type: '',
          imageURI: '',
          timer: 0,
        })
      )
    }
  }

  addObjectFromTiled(
    group: Phaser.Physics.Arcade.StaticGroup,
    object: Phaser.Types.Tilemaps.TiledObject,
    key: string,
    tilesetName: string
  ) {
    const actualX = object.x! + object.width! * 0.5
    const actualY = object.y! - object.height! * 0.5
    const obj = group.get(
      actualX,
      actualY,
      key,
      object.gid! - this.currentTilemap.getTileset(tilesetName).firstgid
    )
    return obj
  }

  restartScene() {
    this.registry.destroy() // destroy registry
    this.sceneData.onLeave(this, null, gameConfig.defaultMap)
  }

  handleConversation(myPlayer, npc, dialogue?: number) {
    this.handlePlayerInteraction(false)
    myPlayer.setCursorsIsDownToFalse()
    handleConversation(npc, this.mapName, dialogue)
    npc.onOpenPlayerDialogue(myPlayer)
    myPlayer.onDialogueOpened(npc)
  }

  removeGameObject(go: GameObject) {
    const index = this.gameObjects.indexOf(go)
    if (index !== -1) this.gameObjects.splice(index, 1)
  }
}
