import 'regenerator-runtime/runtime'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import BankThemeProvider from './themes/bank'
import { Web3ReactProvider } from '@web3-react/core'

import './index.scss'
import './PhaserGame'
import muiTheme from './MuiTheme'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './stores'
import { getLibraryProvider } from './utils/providers';


ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibraryProvider}>
      <Provider store={store}>
        <ThemeProvider theme={muiTheme}>
          <BankThemeProvider>
            <App />
          </BankThemeProvider>
        </ThemeProvider>
      </Provider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
