import Container = Phaser.GameObjects.Container;
import {Scene} from "phaser";
import {BattleDefimon} from "../defimons";
import {DevGauge} from "../../utils/gauge";
import Sprite = Phaser.GameObjects.Sprite;

export class ActiveBattleDefimonUI extends Container {
    public currentDefimonSprite?: Sprite;
    private readonly healthGauge: DevGauge;
    private readonly staminaGauge: DevGauge;
    private readonly defimonName: Phaser.GameObjects.Text;

    constructor(width: number, height: number, private readonly defimonX: number, private readonly defimonY: number, private readonly isFlipped: boolean, private readonly sceneScale: number, scene: Scene) {
        super(scene);

        const background = scene.add.rectangle(0, 0, width, height, 0xddeeff);
        this.healthGauge = new DevGauge(0x009900, 0x00ff00, width * 0.6, 30, 1, scene)
        this.healthGauge.x = width / 2 - this.healthGauge.width / 2 - width * 0.1;
        this.staminaGauge = new DevGauge(0x006699, 0x00ccff, width * 0.6, 10, 1, scene)
        this.staminaGauge.x = width / 2 - this.healthGauge.width / 2 - width * 0.1;
        this.staminaGauge.y = this.healthGauge.y + this.healthGauge.height + 5;
        this.defimonName = scene.add.text(0, 0, "", {
            color: "#000000",
            fontFamily: 'rubik',
            fontStyle: 'bold',
            fontSize: "16pt"
        });
        this.defimonName.setPosition(-width / 2 + this.defimonName.width + width * 0.1, -height / 2 + this.defimonName.height / 2 + height * 0.1 - 10);

        this.add(background);
        this.add(this.healthGauge);
        this.add(this.staminaGauge);
        this.add(this.defimonName);

        this.setSize(width, height);

        scene.add.existing(this);
    }

    switchDefimon(defimon: BattleDefimon) {
        this.defimonName.setText(defimon.name);
        this.healthGauge.setPercent(defimon.getHealthPercent());

        if (this.currentDefimonSprite !== undefined) this.currentDefimonSprite.destroy();

        this.currentDefimonSprite = this.scene.add.sprite(this.defimonX, this.defimonY, `defimon_${defimon.id}`);
        this.currentDefimonSprite.setScale(this.sceneScale);
        this.currentDefimonSprite.play(`defimon_${defimon.id}_idle`)
        this.currentDefimonSprite.flipX = this.isFlipped;
    }

    updateDefimonHealth(percent: number) {
        this.healthGauge.setPercent(percent);
    }

    updateDefimonStamina(percent: number) {
        this.staminaGauge.setPercent(percent);
    }
}