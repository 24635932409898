import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IChatMessage } from '../../../types/IOfficeState'

export enum MessageType {
  PLAYER_JOINED,
  PLAYER_LEFT,
  REGULAR_MESSAGE,
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    chatMessages: new Array<{ messageType: MessageType; chatMessage: IChatMessage }>(),
    focused: false,
    showChat: true,
    openChat: false,
    isTyping: false
  },
  reducers: {
    pushChatMessage: (state, action: PayloadAction<IChatMessage>) => {
      state.chatMessages.push({
        messageType: MessageType.REGULAR_MESSAGE,
        chatMessage: action.payload,
      })
    },
    setShowChat: (state, action: PayloadAction<boolean>) => {
      state.showChat = action.payload
    },
    setOpenChat: (state, action: PayloadAction<boolean>) => {
      state.openChat = action.payload
    },
    resetChat: (state) => {
      state.chatMessages = []
    },
    setFocusChat: (state, action: PayloadAction<boolean>) => {
      state.focused = action.payload
    },
    setTypingInChat: (state, action: PayloadAction<boolean>) => {
      state.isTyping = action.payload
    },
  },
})

export const {
  pushChatMessage,
  setShowChat,
  setOpenChat,
  resetChat,
  setFocusChat,
  setTypingInChat
} = chatSlice.actions

export default chatSlice.reducer
