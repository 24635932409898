import styled from 'styled-components'

const Container = styled.div`
  flex: 0 0 33.333333%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: auto;
  margin-bottom: 20px;
  font-size: 20px;
`

export const OptionWrapper = styled.div`
  flex: 0 0 33.333333%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px;
  width: 60px;
  height: 60px;
  border-radius:2px;
  background: #fff;
  box-shadow: #999 0px 0px 0px 1px, #555 0px 0px 0px 2px, #999 0px 0px 0px 3px;
  margin-bottom: 3px;
  @media (max-width: 700px) {
    min-width: auto;
  }
  :hover {
    background-color: #ddd;
    cursor: pointer;
  }
`

export default function MenuOption({ onClick, icon, text }: any) {
  return (
    <Container onClick={() => onClick()}>
      
      <OptionWrapper>{icon}</OptionWrapper>
      {text}
    </Container>
  )
}
