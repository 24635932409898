import { getRandomNumberFromArray } from '../../helpers/dataHelpers'
import store from '../../stores'
import { startConversation } from '../../stores/MessageStore'

export default function Goop(npc, scene, dialogue?: number) {
  // RELATED QUESTS ARE  = [5]
  let currentQuest = store.getState().quest.currentQuest
  let checkpoints = store.getState().quest.checkpoints

  let conversation = 0

  if (currentQuest) {
    if (currentQuest.id === 5) {
      conversation = 0
      if (checkpoints.find((checkpoint: any) => checkpoint.name === `first-interaction`)?.status) {
        conversation = getRandomNumberFromArray([1, 2])
      }
    }
  }

  if (npc.playerId.split('-')[0] === 'goop') {
    store.dispatch(
      startConversation({
        npc: {
          id: 'goop',
          key: 'goop',
          name: 'goop',
        },
        conversation: dialogue ? dialogue : conversation,
        messageNumber: 1,
      })
    )
  }
}
