import styled from 'styled-components'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { setActiveMenu } from '../../stores/MenuStore'
import Border from '../../components/shared/Border'
import phaserGame from '../../PhaserGame'
import Game from '../../scenes/Game'

const Button = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  z-index: 99;
  width: 100%;
  padding: 13px 15px;
  background-color: #fff;
  color: #000;
  :hover {
    background-color: #ddd;
    cursor: pointer;
  }

`

const FabWrapper = styled.div`
  margin-top: auto;
  margin-bottom: 0px;
  position: fixed;
  left: 20px;
  bottom: 20px;
  opacity: 0.9;

`

const Body = styled.div`
  background: rgb(248, 248, 248) none repeat scroll 0% 0%;
  border-bottom: 4px solid rgb(216, 213, 237);
  padding: 0px;

  @media (max-width: 700px) {
    padding: 0px;
  }
`

export default function ChatButton() {
  const dispatch = useAppDispatch()
  const activeMenu = useAppSelector((state) => state.menu.activeMenu)
  const messageDialogOpen = useAppSelector((state) => state.message.messageDialogOpen)
  const isMultiplayer = useAppSelector((state) => state.game.isMultiplayer)
  const currentScene = phaserGame.scene.keys.game as Game
 
  if (messageDialogOpen || !isMultiplayer) return <></>
  return (
    <FabWrapper>
      {!activeMenu ? (
        <>
          <Border>
            <Body>
              <Button
                color="secondary"
                onClick={() => {
                  dispatch(setActiveMenu('chat'))
                  currentScene.resetNotification()
                  // dispatch(setFocused(true))
                  // dispatch(setTouch(false))
                }}
              >
                <ChatBubbleOutlineIcon />
              </Button>
            </Body>
          </Border>
        </>
      ) : (
        <></>
      )}
    </FabWrapper>
  )
}
