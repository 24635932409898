import React from 'react'
import styled from 'styled-components'

const PlayerStatsDiv = styled.div`
  position: absolute;
  z-index: 1000000;
  top: 20px;
  left: 30px;
`

const EnemyStatsDiv = styled.div`
  position: absolute;
  z-index: 1000000;
  top: 20px;
  right: 30px;
`

const Stats = styled.div`
  background: #dfdfc2;
  width: 300px;
  border: 4px solid #506858;
  border-radius: 5px;
  padding: 0 10px 5px;
`

const StatsHeader = styled.div`
  font-size: 28px;
  color: #000;
  display: flex;
  .name {
    width: 50%;
    text-align: left;
  }
  .level {
    width: 50%;
    text-align: right;
  }
`
const HealthSection = styled.div`
  background: #506858;
  color: #f8ae3e;
  border-radius: 5px;
  padding: 7px;
  font-size: 20px;
  display: flex;
  .text {
    height: 10px;
    line-height: 10px;
  }
`
const HealthBar = styled.div`
  background: #70f8a8;
  width: ${(props) => (props.value ? props.value : 100)}%;
  height: 10px;
  border-radius: 5px;
  margin: 0px 5px;
  transition: width 1s;
`

const HealthStat = styled.div`
  text-align: right;
  font-size: 20px;
  color: #000;
  line-height: 20px;
  padding: 2px 0 0;
  height:20px;
`

const MonstersList = styled.div`
display:flex;
`

const ActiveMonster = styled.div`
width:10px;
height:10px;
background:#fff;
border-radius:50%;
`

const PassiveMonster = styled.div`
width:5px;
height:5px;
background:#fff;
border-radius:50%;
margin:2.5px;
`
const FinishedMonster = styled.div`
width:10px;
height:10px;
background:#fff;
:after {

display: inline-block;
content: "\00d7"; /* This will render the 'X' */
}
`

export function PlayerStats({ name, level, health, maxHealth }: any) {
  return (
    <PlayerStatsDiv>
      <Stats>
        <StatsHeader>
          <div className="name">{name}</div>
          <div className="level">Lv{level}</div>
        </StatsHeader>
        <HealthSection>
          <div className="text">HP:</div>
          <HealthBar value={Math.round((health/maxHealth)*100)} />
        </HealthSection>
        <HealthStat>
          {health}/{maxHealth}
        </HealthStat>
      </Stats>
      <MonstersList>
        <ActiveMonster />
        <PassiveMonster />
        <FinishedMonster />
      </MonstersList>
    </PlayerStatsDiv>
  )
}

export function EnemyStats({ name, level, health, maxHealth }: any) {
  return (
    <EnemyStatsDiv>
      <Stats>
        <StatsHeader>
          <div className="name">{name}</div>
          <div className="level">Lv{level}</div>
        </StatsHeader>
        <HealthSection>
          <div className="text">HP:</div>
          <HealthBar value={Math.round((health/maxHealth)*100)} />
        </HealthSection>
        <HealthStat>
          {health}/{maxHealth}
        </HealthStat>
      </Stats>
    </EnemyStatsDiv>
  )
}
