export const DEFAULT_COLLECTION = 'Default'
export const FRUITS_COLLECTION = 'Fruits'

export const CharactersList = [
  {
    name: 'Blue Cap Boy',
    fileName: 1,
    collection: DEFAULT_COLLECTION,
  },
  {
    name: 'Ponytail Girl',
    fileName: 2,
    collection: DEFAULT_COLLECTION,
  },
  {
    name: 'Avocado',
    fileName: 4,
    collection: FRUITS_COLLECTION,
  },
  {
    name: 'Detective',
    fileName: 10,
    collection: DEFAULT_COLLECTION,
  },
  {
    name: 'Raincoat',
    fileName: 21,
    collection: DEFAULT_COLLECTION,
  },
  {
    name: 'Ghost',
    fileName: 22,
    collection: DEFAULT_COLLECTION,
  },
  // {
  //   name: 'Red Suit Pirate',
  //   fileName: '3',
  //   collection: 'Sunken Ship',
  // },
  // {
  //   name: 'Avocado',
  //   fileName: '4',
  //   collection: 'SlothFi',
  // },
  // {
  //   name: 'Red American Football Player',
  //   fileName: '5',
  //   collection: 'Sports',
  // },
]
