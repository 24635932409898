import React, { useMemo } from 'react'
import { animated, useTransition } from 'react-spring'
import styled from 'styled-components'

const DialogMessage = styled.div`
  font-size: 28px;

  @media (max-width: 700px) {
    font-size: 24px;
  }
`

const Text = ({ message, trail = 35, onMessageEnded = () => {}, forceShowFullMessage = false }) => {
  const items = useMemo(
    () =>
      message
        .trim()
        .split('')
        .map((letter, index) => ({
          item: letter,
          key: index,
        })),
    [message]
  )

  const transitions = useTransition(items, {
    trail,
    from: { display: 'none' },
    enter: { display: '' },
    onRest: (status, controller, item) => {
      if (item.key === items.length - 1) {
        onMessageEnded()
      }
    },
  })

  return (
    <DialogMessage>
      {forceShowFullMessage && <span>{message}</span>}
      {!forceShowFullMessage &&
        transitions((styles, { item, key }) => (
          <animated.span key={key} style={styles}>
            {item}
          </animated.span>
        ))}
    </DialogMessage>
  )
}

export default Text
