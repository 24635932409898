import styled from 'styled-components'
import { MenuWrapper } from '../Menu'
import Border from '../../components/shared/Border'
import { useAppSelector } from '../../hooks'
import { useState } from 'react'
import { setAltCursors } from '../../stores/PrefStore'
import store from '../../stores'
import CloseIcon from '@mui/icons-material/Close';

const Body = styled.div`
  background: rgb(248, 248, 248) none repeat scroll 0% 0%;
  border-bottom: 4px solid rgb(216, 213, 237);
  padding: 20px;
  height: 100%;

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
  }
  h1 {
    text-align: center;
  }
  .control {
    display: flex;
    font-size: 24px;
    width: 100%;

    .type {
      text-align: left;
      width: 50%;
    }
    .value {
      text-align: right;
      width: 50%;
    }
    .value.with-option {
      cursor: pointer;
    }
  }
`

export default function ControlsMenu({ selectedMenu, setSelectedMenu }: any) {
  const cursorState = useAppSelector((state) => state.pref.altCursors)
  const [currentAltCursorState, setCurrentAltCursorState] = useState(cursorState)

  return (
    <MenuWrapper>
      <Border>
        <Body>
          <div className="close-button" onClick={() => setSelectedMenu('')}>
            <CloseIcon/>
          </div>
          <h1>Controls</h1>

          <div className="control">
            <div className="type">Movement:</div>
            <div
              className="value with-option"
              onClick={() => {
                store.dispatch(setAltCursors(!currentAltCursorState))
                setCurrentAltCursorState(!currentAltCursorState)
              }}
            >
               {currentAltCursorState ? 'WASD Keys' : 'Arrow Keys'} 
            </div>
          </div>
          <div className="control">
            <div className="type">Interaction:</div>
            <div
              className="value"
            >
              E
            </div>
          </div>     
          <div className="control">
            <div className="type">Skate:</div>
            <div
              className="value"
            >
             Shift +{currentAltCursorState ? ' WASD Keys' : ' Arrow Keys'}
            </div>
          </div>          
          <div className="control">
            <div className="type">Skip Conversation:</div>
            <div
              className="value"
            >
              Space
            </div>
          </div>
          <div className="control">
            <div className="type">Chat Shortcut:</div>
            <div
              className="value"
            >
              C
            </div>
          </div>
          <div className="control">
            <div className="type">Tablet Shortcut:</div>
            <div
              className="value"
            >
              T
            </div>
          </div>          <div className="control">
            <div className="type">Menu Shortcut:</div>
            <div
              className="value"
            >
              M
            </div>
          </div>      <div className="control">
            <div className="type">Exit Menu:</div>
            <div
              className="value"
            >
              Escape
            </div>
          </div>
        </Body>
      </Border>
    </MenuWrapper>
  )
}
