import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector, useWallet, useContract } from '../../hooks'
import { setMyPlayerProps } from '../../stores/UserStore'
import { useOutfits, Outfit } from '../../hooks/useOutfits'
import { useInventory } from '../../hooks/useInventory'
import { CharactersList } from '../../constants/player-sprites'
import LoaderCat from '../../components/LoaderCat'

import { parseNumber } from '../../helpers'

import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import { CONTRACTS } from '../../constants/contracts'
import { useERC1155utils } from '../../hooks/useERC1155utils'

const Container = styled.div`
  padding: 30px 0;
  font-family: 'VT323';
  height: calc(100% - 60px);
  overflow: scroll;
`

const CharacterSection = styled.div`
  display: flex;
  position: relative;
  margin: 0 auto;
  height: auto;
  flex-wrap: wrap;
  @media (max-width: 700px) {
    padding: 10px;
    margin-right: 0px;
    margin-bottom: 10px;
  }
`

const PictureSection = styled.div`
  text-align: center;
  width: auto;
  margin: 0 10px 5px;
  cursor: pointer;
  p {
    font-size: 24px;
    padding: 0px;
    margin: 0px;
  }
  img {
    display: block;
    width: 180px;
    height: 180px;
    padding: 0px;
    border: 4px solid rgb(57, 57, 59);
  }

  @media (max-width: 700px) {
    img {
      display: block;
      width: 90px;
      height: 90px;
    }
  }
`

const EmptyText = styled.p`
  margin: 5px;
  font-size: 30px;
  color: #555;
  text-align: center;
`

export default function OutfitSelector({ active, setActive, options }: any) {
  const inputRef = useRef<HTMLInputElement>(null)
  const user = useAppSelector((state) => state.user)
  const [outfitsNonEquipped, setOutfitsNonEquipped] = useState<Outfit[]>([])

  const { account, isDisabled } = useWallet()
  const contractERC1155Utils = useContract(CONTRACTS.ERC1155_UTILS)
  const { contractOutfits, getOutfitsByIds } = useOutfits()
  const { changeEquippedItem, loading: loadingInventory } = useInventory()
  const { checkTokensOwnedByPlayer } = useERC1155utils()

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (account && contractERC1155Utils) {
      const getNonEquippedOutfits = async () => {
        // TODO: We should fixed this in the future, i think its not scalable
        const charactersToCheck = CharactersList.map((item) => parseNumber(item.fileName))

        const tokenIdsOwnedByPlayer = await checkTokensOwnedByPlayer(
          charactersToCheck,
          CONTRACTS.OUTFITS
        )
        const outfitsOwnedByPlayer = await getOutfitsByIds(tokenIdsOwnedByPlayer)
        if (outfitsOwnedByPlayer) {
          setOutfitsNonEquipped(outfitsOwnedByPlayer)
        }
      }
      getNonEquippedOutfits()
    }
  }, [account, checkTokensOwnedByPlayer, contractERC1155Utils, getOutfitsByIds])

  useEffect(() => {
    if (user.focused) {
      inputRef.current?.focus()
    }
  }, [user.focused])

  const handleSave = (id: number) => async () => {
    try {
      if (!isDisabled) {
        await changeEquippedItem(id, contractOutfits)
      }

      dispatch(
        setMyPlayerProps({
          texture: CharactersList.find((currentOutfit) => currentOutfit.fileName === id)?.name,
        })
      )
    } catch (error) {
      console.log(error)
    }

    setActive(options.find((option) => option.value === 'profile'))
  }

  if (isDisabled) {
    return (
      <Container>
        <CharacterSection>
          {loadingInventory ? (
            <LoaderCat withDialog={false} />
          ) : user.ownedOutfits.length ? (
            user.ownedOutfits.map((outfit) => (
              <PictureSection onClick={handleSave(outfit)} key={outfit}>
                <img
                  src={
                    '/assets/character/profile-pictures/' +
                    CharactersList.find((currentOutfit) => currentOutfit.fileName === outfit)
                      ?.fileName +
                    '.png'
                  }
                  alt={
                    CharactersList.find((currentOutfit) => currentOutfit.fileName === outfit)?.name
                  }
                />
                <p>
                  #
                  {
                    CharactersList.find((currentOutfit) => currentOutfit.fileName === outfit)
                      ?.fileName
                  }
                </p>
              </PictureSection>
            ))
          ) : (
            <EmptyText> - Don't have any outfit not equipped - </EmptyText>
          )}
        </CharacterSection>
      </Container>
    )
  } else {
    return (
      <Container>
        <CharacterSection>
          {loadingInventory ? (
            <LoaderCat withDialog={false} />
          ) : outfitsNonEquipped.length ? (
            outfitsNonEquipped.map((outfit) => (
              <PictureSection onClick={handleSave(outfit.id)} key={outfit.id}>
                <img src={outfit.image} alt={outfit.name} />
                <p>#{outfit.id}</p>
              </PictureSection>
            ))
          ) : (
            <EmptyText> - Don't have any outfit not equipped - </EmptyText>
          )}
        </CharacterSection>
      </Container>
    )
  }
}
