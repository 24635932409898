import Container = Phaser.GameObjects.Container;
import { Scene } from "phaser";
import { BattleButton } from "./battle-button";
import { BattleTeam } from "../team";
import { Item } from "../items";

export class DefimonItemsUI extends Container {
    private static readonly ITEMS_PER_LINE = 7;
    private static readonly LEFT_MARGIN = 500;
    private static readonly BUTTONS_SIZE = 75;
    private static readonly HALF_SIZE = 75 / 2;
    private static readonly BUTTONS_GAP = 15;

    constructor(team: BattleTeam, onItemPicked: (object: Item<any>) => void, onChoiceCancelled: () => void, scene: Scene) {
        super(scene);

        let n = 0;
        let currentX = DefimonItemsUI.LEFT_MARGIN;
        let currentY = 0;
        team.inventory.forEach((item, amount) => {
            if (!item.canBeUsedInBattle() || amount <= 0) return

            const button = new BattleButton(DefimonItemsUI.BUTTONS_SIZE, DefimonItemsUI.BUTTONS_SIZE, item.simpleName.replaceAll(" ", "\n"), 0xffffff, "#444455", scene);
            button.registerCallback(() => onItemPicked(item));

            const badge = scene.add.circle(0, button.y - DefimonItemsUI.HALF_SIZE, DefimonItemsUI.BUTTONS_SIZE / 5, 0x44bbff)
            const badgeLabel = scene.add.text(0, button.y - DefimonItemsUI.HALF_SIZE, amount.toString(), {color: "#ffffff"})

            if (n % DefimonItemsUI.ITEMS_PER_LINE === DefimonItemsUI.ITEMS_PER_LINE - 1) {
                currentY -= DefimonItemsUI.BUTTONS_SIZE + DefimonItemsUI.BUTTONS_GAP
                currentX = DefimonItemsUI.LEFT_MARGIN
            }
            button.x = currentX
            badge.x = currentX + DefimonItemsUI.HALF_SIZE
            button.y += currentY
            badge.y += currentY
            badgeLabel.x = badge.x - 10;
            badgeLabel.y = badge.y - 10;
            currentX += DefimonItemsUI.BUTTONS_SIZE + DefimonItemsUI.BUTTONS_GAP

            this.add(button);
            this.add(badge);
            this.add(badgeLabel);
            n++;
        })

        const cancelButton = new BattleButton(DefimonItemsUI.BUTTONS_SIZE, DefimonItemsUI.BUTTONS_SIZE, "CANCEL", 0xff3333, "#ffffff", scene);
        cancelButton.registerCallback(() => onChoiceCancelled());
        cancelButton.x = DefimonItemsUI.LEFT_MARGIN -DefimonItemsUI.BUTTONS_SIZE * 2 - DefimonItemsUI.BUTTONS_GAP
        this.add(cancelButton);

        const size = team.inventory.distinctSize();
        this.setSize(size * DefimonItemsUI.BUTTONS_SIZE + (size - 1) * DefimonItemsUI.BUTTONS_GAP, DefimonItemsUI.BUTTONS_SIZE);

        scene.add.existing(this);
    }
}