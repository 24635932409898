import React from 'react'
import styled from 'styled-components'
import { useSprite } from 'react-sprite-animator'

export enum MonsterState {
  Idle = 'IDLE',
  Special1 = 'SPECIAL1',
  Special2 = 'SPECIAL2',
}

interface MonsterProps {
  id: number
  size?: number
  scale?: number
  direction?: string
  state?: MonsterState
}
const Container = styled.div`
  transform: ${(props) => (props.direction === 'left' ? 'rotateY(0deg)' : 'rotateY(180deg)')};
`
const MonsterDiv = styled.div`
  width: 200px;
  height: 200px;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  @media (max-width: 1500px) {   
  
  }
`
export default function Monster({ id, size, scale, direction, state }: MonsterProps) {
  const idle = useSprite({
    sprite: `/assets/monsters/sprites/${id}_${0}.png`,
    width: size ? size : 50,
    height: size ? size : 50,
    scale: 0.25,
    fps: 15 * 0.6,
  })
  const special1 = useSprite({
    sprite: `/assets/monsters/sprites/${id}_${1}.png`,
    width: size ? size : 50,
    height: size ? size : 50,
    scale: 0.25,
    fps: 15 * 0.6,
  })

  if (state === MonsterState.Special1) {
    return (
      <Container direction={direction}>
        <MonsterDiv style={special1} />
      </Container>
    )
  } else {
    return (
      <Container direction={direction}>
        <MonsterDiv style={idle} />
      </Container>
    )
  }
}
