import {State} from "../../../../utils/states/state";
import {DialogueManager} from "../DialogueManager";

export class TextAppearingState implements State {
    public isFinished: boolean = false;

    private textToDisplay: string = '';
    private currentIndex = 0;
    private timeBeforeNextLetter;

    constructor(private readonly dialogueManager: DialogueManager) {
    }

    onEnter(): void {
        this.currentIndex = 0;
        this.timeBeforeNextLetter = DialogueManager.TEXT_SPEED;
        this.isFinished = false;

        this.textToDisplay = this.dialogueManager.text.getWrappedText(this.dialogueManager.textsToDisplay[0]).join('\n');
        this.dialogueManager.textsToDisplay.splice(0, 1);
        this.dialogueManager.text.text = this.textToDisplay[this.currentIndex];
    }

    onExit(): void {
    }

    tick(dt: number): void {
        if (this.currentIndex >= this.textToDisplay.length - 1) return;

        this.timeBeforeNextLetter -= dt;
        if (this.timeBeforeNextLetter > 0) return;

        this.dialogueManager.text.text += this.textToDisplay[++this.currentIndex];

        if (this.currentIndex >= this.textToDisplay.length - 1) this.isFinished = true;
    }
}