import Filters from 'bad-words'

interface FilterNameConstructor {
  minLength?: number
  maxLength?: number
  [key: string]: any
}
class FilterName extends Filters {
  _maxLength: number
  _minLength: number

  _notStartedWithAlphabetic: RegExp = new RegExp(/^[^a-zA-Z]/g)
  _notValidName: RegExp = new RegExp(/[^a-zA-Z0-9\\.\-\\_]/g);

  constructor({ minLength = 3, maxLength = 18, ...props }: FilterNameConstructor = {}) {
    super(props)
    this._maxLength = maxLength
    this._minLength = minLength
  }

  containsBadWord(value: string) {
    return (
      this.list.filter((word) => {
        const wordExp = new RegExp(word.replace(/(\W)/g, '\\$1'), 'gi')
        return !this.exclude.includes(word.toLowerCase()) && wordExp.test(value)
      }).length > 0 || false
    )
  }

  isValid(value: string) {
    if (value.length < this._minLength) {
      throw new Error(`Min Length should be ${this._minLength}`)
    }
    if (value.length > this._maxLength) {
      throw new Error(`Max Length should be ${this._maxLength}`)
    }
    if (value.includes(' ')) {
      throw new Error(`White spaces not allowed`)
    }
    // if (value.match(this._notStartedWithAlphabetic)) {
    //   throw new Error(`Name should start with alphabetic character`)
    // }
    if (value.match(this._notValidName)) {
      throw new Error(`Special characters not allowed`)
    }
    if (this.containsBadWord(value)) {
      throw new Error(`Offensive words not allowed`)
    }
    return true
  }
}

export default FilterName
