import { useEffect } from 'react'
import styled from 'styled-components'
import { useAppSelector } from '../hooks'
import store from '../stores'
import { setScreenFlash } from '../stores/ScreenFlashStore'

const FabWrapper = styled.div`
  margin-top: auto;
  margin-bottom: 0px;
  position: fixed;
  width: 100%;
  height: 100%;
`

export default function ScreenFlash() {
  const isFlashing = useAppSelector((state) => state.screenFlash.isFlashing)
  const iterations = useAppSelector((state) => state.screenFlash.iterations)

  useEffect(() => {
    if (iterations > 0) {
      setTimeout(function () {
        store.dispatch(
          setScreenFlash({
            isFlashing: false,
          })
        )
      }, 1000 * iterations * 0.3)
    }
  }, [iterations])

  return (
    <FabWrapper
      className="blink"
      style={{
        animationIterationCount: iterations,
        background: isFlashing ? 'rgba(0, 0, 0, 0.65)' : 'rgba(0, 0, 0, 0)',
      }}
    ></FabWrapper>
  )
}
