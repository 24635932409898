class NetworkBlockchain {
  private httpEndpoint: string

  constructor() {
    this.httpEndpoint =
      process.env.NODE_ENV === 'production'
        ? `http://${process.env.REACT_APP_GAME_BACKEND}`
        : `${window.location.protocol}//${window.location.hostname}:2567`
  }

  // method to mint an ERC1155 with dev wallet
  async mintERC1155(contractERC1155: string, receiver: string, tokenId: number) {
    const data = { contractERC1155, receiver, tokenId }
    await fetch(`${this.httpEndpoint}/mint`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers:{
        'Content-Type': 'application/json'
      }
    })
  }
}

export default new NetworkBlockchain()
