import Container = Phaser.GameObjects.Container;
import {Scene} from "phaser";
import store from "../../stores";

export type KeyHint = Readonly<{
    keyName: string;
    altKeyName: string;
    keyColor: number;
    keyCode: number;
    altKeyCode: number;
    buttonImage: string;
    symbolImage: string;
}>;

export class CorrectSeqMiniGameKeyHint extends Container {
    private readonly keySymbolPressed: Phaser.GameObjects.Image;
    private ongoingTimeout?: NodeJS.Timeout;


    constructor(private readonly keyHint: KeyHint, scene: Scene, validateKey: () => void) {
        super(scene);
        const { altCursors } = store.getState().pref

        const keySymbol = scene.add.image(0, 0, keyHint.buttonImage);
        this.keySymbolPressed = scene.add.image(0, 0, keyHint.buttonImage + 'Pressed');
        this.keySymbolPressed.visible = false;
        const keyNameLabel = scene.add.text(0, 0, altCursors ? keyHint.altKeyName : keyHint.keyName, {color: "#ffeea1", fontFamily: 'rubik'});

        keyNameLabel.y = 20;
        keyNameLabel.x = -keyNameLabel.width / 2

        this.add(keySymbol);
        this.add(this.keySymbolPressed);
        this.add(keyNameLabel)

        this.setSize(keySymbol.width, keySymbol.height);
        this.setInteractive().on('pointerdown', () => {
            this.triggerPressVisuals(keyHint)
            validateKey()
        });

        scene.add.existing(this)
    }

    triggerPressVisuals(keyHint: KeyHint) {
        if (keyHint !== this.keyHint) return;

        this.keySymbolPressed.visible = true;
        if (this.ongoingTimeout) clearTimeout(this.ongoingTimeout);
        this.ongoingTimeout = setTimeout(() => {
            this.ongoingTimeout = undefined;
            this.keySymbolPressed.visible = false;
        }, 250);
    }
}