import React from 'react'
import styled from 'styled-components'

import Dialog from './Dialog'

const LoadingCat = styled.img`
  max-width: 300px;
  max-height: 300px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`

const LoadingText = styled.p`
  margin: 5px;
  font-size: 30px;
  color: #555;
  text-align: center;
`

const WrapperLoader = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`

const LoaderCat = ({textLoading = 'Loading...', withDialog = false}) => {
  const Wrapper = withDialog ? Dialog : WrapperLoader
  return (
    <Wrapper>
      <LoadingCat src="/assets/monsters/idle/1.gif" />
      <LoadingText>{textLoading}</LoadingText>
    </Wrapper >
  );
}

export default LoaderCat;