import React from 'react'

import styled from 'styled-components'

const Container = styled.div`
  padding: 30px 0;
  font-family: 'VT323';
`
export default function Inventory() {
  return (
    <Container>
      <h1>Coming Soon</h1>
    </Container>
  )
}
