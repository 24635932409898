import React, { useState, useEffect } from 'react'
import logo from '../assets/logo.png'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector, useWallet } from '../hooks'

import phaserGame from '../PhaserGame'
import Bootstrap from '../scenes/Bootstrap'
import { setNotification } from '../stores/NotificationStore'
import Network from '../services/Network'
import Border from '../components/shared/Border'
import { getRandomNumberFromArray } from '../helpers/dataHelpers'
import { setRoomNumber } from '../stores/GameStore'

const Backdrop = styled.div`
  padding: 0;
  height: 100%;
  flex-direction: column;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
`
// const Wrapper = styled.form`
//   padding: 0px;
//   margin: 0px;
//   text-align: center;
//   position: relative;
//   background: black;
//   font-family: 'VT323';
//   z-index: 2;
//   display: flex;
//   flex-direction: column;
//   z-index: 99;
//   color: #555;
//   width: 600px;
//   height: auto;
//   @media (max-width: 700px) {
//     width: calc(100% - 20px);
//     height: auto;
//     min-height: 300px;
//   }
// `

// const Logo = styled.img`
//   width: 100%;
//   margin: 0 auto;
// `
const LogoNew = styled.img`
  width: 100%;
  max-width: 60%;
  margin: 0 auto;

  @media (max-width: 700px) {
    max-width: 100%;
  }
`
const SubTitle = styled.h3`
  width: 100%;
  font-size: 24px;
  color: rgb(34, 32, 52);
  font-family: 'VT323';
  text-align: center;
`

// const SocialIcons = styled.div`
//   width: 100%;
//   padding: 40px 0 0;
//   img {
//     width: 32px;
//     margin: 0 10px;
//   }
// `
const RelativeContainer = styled.div`
  position: relative;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: -moz-available;
  width: 400px;

  @media (max-width: 700px) {
    width: calc(100% - 40px);
  }
`
const CustomButtonBackdrop = styled.div`
  position: absolute;
  opacity: 0.9;
  margin: 0 auto;
  font-family: 'VT323';
  width: 100%;
`

const CustomButton = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  z-index: 99;
  padding: 5px 15px;
  background-color: #fff;
  font-size: 30px;
  text-align: center;
  color: #000;
  :hover {
    background-color: #ddd;
    cursor: pointer;
  }

  @media (max-width: 700px) {
    font-size: 24px;
  }
`

const CustomButtonBody = styled.div`
  background: rgb(248, 248, 248) none repeat scroll 0% 0%;
  border-bottom: 4px solid rgb(216, 213, 237);
  padding: 0px;

  @media (max-width: 700px) {
    padding: 0px;
  }
`

const SocialIconsNew = styled.div`
  width: auto;
  position: fixed;
  bottom: 20px;
  display: flex;
  align-items: center;
  img {
    width: 32px;
    margin: 0 10px;
  }
`
export default function RoomSelectionDialog_NewStyle() {
  // Show/Hide Loader in the Connect Wallet Button
  const [loading, setloading] = useState(false)
  const serverConnected = useAppSelector((state) => state.user.serverConnected)
  const { active, login, error, isDisabled } = useWallet()
  const dispatch = useAppDispatch()

  useEffect(() => {
    // Replace true with data checks to check if we received a wallet information that we will store in web3 context
    if (active && serverConnected) {
      const bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap
      Network.joinOrCreatePublic('room-1')
        .then(() => bootstrap.launchGame())
        .catch((error) => console.error(error))
    }
  }, [active, isDisabled, serverConnected])

  useEffect(() => {
    if (error) {
      dispatch(
        setNotification({
          content: error,
          type: 'disappearing-error',
          timer: 3
        })
      )
      setloading(false)
    }
  }, [dispatch, error])

  useEffect(() => {
    const handleKeyPressed = (e) => {
      if (['Enter'].includes(e.code)) {
        if (!loading && serverConnected) {
          handleConnect()
        }
      }
    }
    window.addEventListener('keydown', handleKeyPressed)

    return () => window.removeEventListener('keydown', handleKeyPressed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, serverConnected])

  const handleConnect = () => {
    // Handle Connection with Metamask here
    // Wait for user input
    if (!isDisabled) {
      setloading(true)
      login()
    } else if (isDisabled && serverConnected) {
      setloading(true)
      const bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap

      const roomNumber = getRandomNumberFromArray([1,2,3])
 
      dispatch(setRoomNumber(roomNumber))
      Network.joinOrCreatePublic(`room-${roomNumber}`)
        .then(() => bootstrap.launchGame())
        .catch((error) => console.error(error))
    }
  }

  return (
    <Backdrop>
      <LogoNew src={logo} alt="logo" />
      <SubTitle>V. 0.1.9</SubTitle>
      <RelativeContainer>
        <CustomButtonBackdrop>
          <Border>
            <CustomButtonBody>
              {loading ? (
                <CustomButton color="secondary" aria-label="showMenu" background="white">
                  Loading...
                </CustomButton>
              ) : !serverConnected ? (
                <CustomButton
                  color="secondary"
                  aria-label="showMenu"
                  background="white"
                >
                  Connecting to Server...
                </CustomButton>
              ) : (
                <CustomButton
                  color="secondary"
                  aria-label="showMenu"
                  onClick={handleConnect}
                  background="white"
                >
                  Insert Coin
                </CustomButton>
              )}
            </CustomButtonBody>
          </Border>
        </CustomButtonBackdrop>
      </RelativeContainer>

      <SocialIconsNew>
        <a href="https://twitter.com/TheDeFimons" target="_blank" rel="noopener noreferrer">
          <img src="/shared/social_twitter.png" alt="twitter" />
        </a>
        <a href="https://discord.gg/defimons" target="_blank" rel="noopener noreferrer">
          <img src="/shared/social_discord.png" alt="discord" />
        </a>
      </SocialIconsNew>
    </Backdrop>
  )
}