import Phaser from 'phaser'
import { BackgroundMode } from '../../../types/BackgroundMode'
import store from '../stores'
import { setRoomJoined } from '../stores/RoomStore'
import { setCurrentMap } from '../stores/GameStore'
import { gameConfig } from '../assets/game-data'
import network from '../services/Network'
import {
  loadBackgroundAssets,
  loadItemsAssets,
  loadNPCSkinAssets,
  loadPlayerSkinAssets,
} from './methods'
import {
  loadDefimonsSpritesheets,
  loadMapEffectsAssets,
  loadMiniGameAssets,
} from './methods/assets'

export default class Bootstrap extends Phaser.Scene {
  currentScene?: any
  private preloadComplete = false

  constructor() {
    super('bootstrap')
  }

  preload() {
    loadBackgroundAssets(this)
    loadPlayerSkinAssets(this)
    loadNPCSkinAssets(this)
    loadMiniGameAssets(this)
    loadDefimonsSpritesheets(this)
    loadMapEffectsAssets(this)
    loadItemsAssets(this)

    this.load.on('complete', () => (this.preloadComplete = true))
  }

  create() {
    this.launchBackground(store.getState().game.backgroundMode)
  }

  private launchBackground(backgroundMode: BackgroundMode) {
    this.scene.launch('background', { backgroundMode })
  }

  launchGame() {
    this.launchBackground(BackgroundMode.BLACK)

    if (!this.preloadComplete) return

    // update Redux state
    store.dispatch(setRoomJoined(true))
    this.scene.launch('game', {
      currentMap: gameConfig.defaultMap,
      onLeave: this.handleSceneChange,
      previousMap: null,
    })
    this.currentScene = this.scene.get('game')
    network.webRTC?.checkPreviousPermission()
  }

  changeBackgroundMode(backgroundMode: BackgroundMode) {
    this.scene.stop('background')
    this.launchBackground(backgroundMode)
  }

  handleSceneChange = async (scene: any, currentScene: any, newScene: any) => {
    this.scene.stop('game')

    store.dispatch(setCurrentMap(newScene))

    this.scene.launch('game', {
      currentMap: newScene,
      onLeave: this.handleSceneChange,
      previousMap: currentScene,
    })

    this.currentScene = this.scene.get('game')
  }
}
