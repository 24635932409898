import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import phaserGame from '../PhaserGame'
import Game from '../scenes/Game'

export const prefSlice = createSlice({
  name: 'pref',
  initialState: {
    altCursors: true,
    music: true,
    soundEffects: true,
    autoSkating: false,
    zoom: true,
  },
  reducers: {
    setAltCursors: (state, action: PayloadAction<boolean>) => {
      state.altCursors = action.payload
      storePrefs(state)
    },
    setSkating: (state, action: PayloadAction<boolean>) => {
      state.autoSkating = action.payload
      storePrefs(state)
    },
    setMusic: (state, action: PayloadAction<boolean>) => {
      const game = phaserGame.scene.keys.game as Game

      if (action.payload === true) {
        game.music.resume()
      } else {
        game.music.pause()
      }

      state.music = action.payload
      storePrefs(state)
    },
    setSoundEffects: (state, action: PayloadAction<boolean>) => {
      state.soundEffects = action.payload
      storePrefs(state)
    },
    setZoom: (state, action: PayloadAction<boolean>) => {
      const game = phaserGame.scene.keys.game as Game

      if (action.payload === true) {
        game.cameras.main.zoom = 1.4
      } else {
        game.cameras.main.zoom = 1.2
      }

      state.zoom = action.payload
      storePrefs(state)
    },
  },
})

export const { setAltCursors, setMusic, setSoundEffects, setSkating, setZoom } = prefSlice.actions

export default prefSlice.reducer

function storePrefs(state) {
  const PreferencesObject = {
    music: state.music,
    soundEffects: state.soundEffects,
    altCursors: state.altCursors,
    autoSkating: state.autoSkating,
    zoom: state.zoom,
  }
  localStorage.setItem('defimons_prefs', btoa(JSON.stringify(PreferencesObject)))
}
