import store from '../stores'
import {
  completeFruitsInTheBasket,
  startFruitsInTheBasket,
  updateFruitsInTheBasket,
} from './FruitsInTheBasket'
import { completePickNLock, startPickNLock, updatePickNLock } from './PickNLock'
import { startPrisonersOfYorokobi, updatePrisonersOfYorokobi } from './PrisonersOfYorokobi'
import { startSkateNCollect, updateSkateNCollect } from './SkateNCollect'
import { completeFindingGoop, startFindingGoop, updateFindingGoop } from './FindingGoop'
import { completeLikeAGhost, startLikeAGhost, updateLikeAGhost } from './LikeAGhost'
import { completeSettlersMint, startSettlersMint, updateSettlersMint } from './SettlersMint'

export const QUESTS_LIST = [
  {
    name: 'Prisoners of Yorokobi',
    id: 1,
  },
  {
    name: `Skate N' Collect`,
    id: 2,
  },
  {
    name: `Pick N' Lock`,
    id: 3,
    checkpoints: [`trash-bin-hint`, `scissors-hint`, `completed-minigame`],
  },
  {
    name: `Fruits in the basket`,
    id: 4,
  },
  {
    name: `Finding Goop`,
    id: 5,
    checkpoints: [`find-ken-hint`, `first-interaction`, `dream-ended`],
  },
  {
    name: `Like a Ghost`,
    id: 6,
    checkpoints: [`read-xray-hint`, `information-collected`],
  },
  {
    name: `Settlers Mint`,
    id: 7,
    checkpoints: [`unlock-toolbox`, `cafe-papers`, `clothing-papers`, `collected-all`],
  },
]

export function startSpecificQuest() {
  const currentQuest = store.getState().quest.currentQuest.id

  if (currentQuest === 1) {
    startPrisonersOfYorokobi()
  } else if (currentQuest === 2) {
    startSkateNCollect()
  } else if (currentQuest === 3) {
    startPickNLock()
  } else if (currentQuest === 4) {
    startFruitsInTheBasket()
  } else if (currentQuest === 5) {
    startFindingGoop()
  } else if (currentQuest === 6) {
    startLikeAGhost()
  } else if (currentQuest === 7) {
    startSettlersMint()
  }
}

export function updateQuests() {
  const currentQuest = store.getState().quest.currentQuest.id

  if (currentQuest === 1) {
    updatePrisonersOfYorokobi()
  } else if (currentQuest === 2) {
    updateSkateNCollect()
  } else if (currentQuest === 3) {
    updatePickNLock()
  } else if (currentQuest === 4) {
    updateFruitsInTheBasket()
  } else if (currentQuest === 5) {
    updateFindingGoop()
  } else if (currentQuest === 6) {
    updateLikeAGhost()
  } else if (currentQuest === 7) {
    updateSettlersMint()
  }
}

export function completeSpecificQuest() {
  const currentQuest = store.getState().quest.currentQuest.id

  if (currentQuest === 3) {
    completePickNLock()
  } else if (currentQuest === 4) {
    completeFruitsInTheBasket()
  } else if (currentQuest === 5) {
    completeFindingGoop()
  } else if (currentQuest === 6) {
    completeLikeAGhost()
  } else if (currentQuest === 7) {
    completeSettlersMint()
  }
}
