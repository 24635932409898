import store from '../../stores'
import { startConversation } from '../../stores/MessageStore'

import { NPCdata } from '../npc-data'

export default function FiremanGoro(npc, scene, dialogue?: number) {
  const conversation = 0
  if (npc.playerId === 'fireman-goro') {
    store.dispatch(
      startConversation({
        npc: NPCdata.find((current) => current.id === npc.playerId),
        conversation: dialogue ? dialogue : conversation,
        messageNumber: 1,
      })
    )
  }
}
