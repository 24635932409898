import { DetectorNpcDirection } from '../characters/Detector'
import { NPCType } from '../dialogue/npc-data'
import getItem from '../helpers/game/getItem'
import getNPC from '../helpers/game/getNPC'
import Info from '../items/Info'
import phaserGame from '../PhaserGame'
import Bootstrap from '../scenes/Bootstrap'
import Game from '../scenes/Game'
import { spawnDetectorNPC } from '../scenes/methods/spawn'
import store from '../stores'
import {  MessageType, openMessageDialog } from '../stores/MessageStore'
import { setNotification } from '../stores/NotificationStore'
import { completeCheckpoint, exitQuest } from '../stores/QuestStore'
import { setMyPlayerProps } from '../stores/UserStore'

const data = [
  {
    name: 'Doctor',
    texture: 'doctor-npc',
    id: 'doctor',
    type: NPCType.DETECTOR,
    spawnPoint: {
      x: 900,
      y: 350,

      frame: 'left',
    },
    pathfinding: [
      {
        direction: DetectorNpcDirection.LEFT,
        duration: 3000,
        range: 50,
      },
      {
        direction: DetectorNpcDirection.DOWN,
        duration: 3000,
        range: 150,
      },
      {
        direction: DetectorNpcDirection.RIGHT,
        duration: 3000,
        range: 150,
      },

    ],
  },
  {
    name: 'Doctor',
    texture: 'doctor-npc',
    id: 'doctor',
    type: NPCType.DETECTOR,
    spawnPoint: {
      x: 900,
      y: 500,

      frame: 'down',
    },
    pathfinding: [
      {
        direction: DetectorNpcDirection.UP,
        duration: 3000,
        range: 150,
      },
      {
        direction: DetectorNpcDirection.DOWN,
        duration: 3000,
        range: 150,
      },
      {
        direction: DetectorNpcDirection.LEFT,
        duration: 3000,
        range: 200,
      },
    ],
  },
  {
    name: 'Doctor',
    texture: 'doctor-npc',
    id: 'doctor',
    type: NPCType.DETECTOR,
    spawnPoint: {
      x: 478,
      y: 509,

      frame: 'down',
    },
    pathfinding: [
      {
        direction: DetectorNpcDirection.DOWN,
        duration: 3000,
        range: 150,
      },
      {
        direction: DetectorNpcDirection.LEFT,
        duration: 3000,
        range: 150,
      },
    ],
  },
  {
    name: 'Doctor',
    texture: 'doctor-npc',
    id: 'doctor',
    type: NPCType.DETECTOR,
    spawnPoint: {
      x: 277,
      y: 339,

      frame: 'down',
    },
    pathfinding: [
      {
        direction: DetectorNpcDirection.RIGHT,
        duration: 3000,
        range: 150,
      },
      {
        direction: DetectorNpcDirection.DOWN,
        duration: 3000,
        range: 150,
      },
      {
        direction: DetectorNpcDirection.UP,
        duration: 3000,
        range: 150,
      },
    ],
  },
]

// Start
export function startLikeAGhost(state?: any) {
  const checkpoints = store.getState().quest.checkpoints

  const __readXRayHint = checkpoints.find(
    (checkpoint: any) => checkpoint.name === `read-xray-hint`
  )?.status

  if (!__readXRayHint) {
    store.dispatch(completeCheckpoint(`read-xray-hint`))
    store.dispatch(
      setNotification({
        content: `Go upstairs`,
        type: `hint`,
        timer: 3,
      })
    )
  }
}

// Update
export function updateLikeAGhost() {
  let game = phaserGame.scene.keys.game as Game
  const bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap
  let userName = store.getState().user.name

  if (game.currentMap === 'yorokobiHospitalSecond') {
    if (!getNPC('doctor')) {
      const xray = getItem('info-xray-hospital-second') as Info

      xray.callback = () => {
        store.dispatch(
          openMessageDialog({
            messages: [
              `Health Status normal...
            Stamina Status normal...
            Attack and Special Attack Status normal...`,
              `Defense and Special Defence Status normal...
          Speed lower than usual...
          Patient seems to have suffered Freeze status recently`,
            ],
            characterName: userName,
            onDialogEnded:
            {
              type: 'function',
              func:  () => {
                store.dispatch(completeCheckpoint(`information-collected`))
                store.dispatch(
                  setNotification({
                    content: `Talk to Doctor Keisuke`,
                    type: `hint`,
                    timer: 3,
                  })
                )
              },
            },
            messageType: MessageType.single,
          })
        )
      }
      if (store.getState().pref.music) {
        game.music.stop()
        game.music = game.sound.add('sneak-peek', {
          volume: 0.2,
          loop: true,
        })
        game.music.play()
      }

      for (let i = 0; i < data.length; i++) {
        spawnDetectorNPC(
          data[i],
          () => {
            if (store.getState().pref.soundEffects) {
              game.soundEffects = game.sound.add('caught', {
                volume: 0.05,
              })
              game.soundEffects.play()
            }
            store.dispatch(exitQuest(6))
            store.dispatch(
              setNotification({
                content: `Quest Failed`,
                type: `failed`,
                timer: 3,
              })
            )
            bootstrap.handleSceneChange(game, 'yorokobiHospitalSecond', 'yorokobiHospital')
          },
          data[i].pathfinding
        )
      }
    }
  }
}

// Complete
export function completeLikeAGhost() {
  store.dispatch(
    setMyPlayerProps({
      texture: 'Ghost',
    })
  )
}
