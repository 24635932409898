import { ContractInterface } from 'ethers'
import { NETWORK, NETWORKS } from './chain'

// Abi Contracts
import ERC1155UtilsAbi from '../abi/ERC1155Utils.json'
import FriendsListAbi from '../abi/FriendsList.json'
import InitialSetupAbi from '../abi/InitialSetup.json'
import InventoryAbi from '../abi/Inventory.json'
import OutfitsAbi from '../abi/Outfits.json'
import OutfitFaucetAbi from '../abi/OutfitFaucet.json'
import ProfileAbi from '../abi/Profile.json'

type ContractData = {
  address: string
  abi: ContractInterface
}

export enum CONTRACTS {
  ERC1155_UTILS = "ERC1155Utils",
  FRIENDS_LIST = 'FriendsList',
  INITIAL_SETUP = 'InitialSetup',
  INVENTORY = 'Inventory',
  OUTFITS = 'Outfits',
  OUTFIT_FAUCET = 'OutfitFaucet',
  PROFILE= 'Profile',
}

const contracts = {
  [NETWORKS.MAINNET]: {},
  [NETWORKS.TESTNET]: {
    [CONTRACTS.ERC1155_UTILS]: {
      address: '0x94Aa38122BE483c33D2761c15bfFa8EAee13477E',
      abi: ERC1155UtilsAbi.abi,
    },
    [CONTRACTS.FRIENDS_LIST]: {
      address: '0x9802C3E73D362e04Af72C28Fd2FaaCBBcF24De1A',
      abi: FriendsListAbi.abi,
    },
    [CONTRACTS.INITIAL_SETUP]: {
      address: '0xB8dDC8e0950D4082C5d22C3B3D7322D738E2e02d',
      abi: InitialSetupAbi.abi,
    },
    [CONTRACTS.INVENTORY]: {
      address: '0x9BcAFb106A2e1749949947d948Eac34b3d93d796',
      abi: InventoryAbi.abi,
    },
    [CONTRACTS.PROFILE]: {
      address: '0x1B226cb40ECfFD94D4b0f13B65Bcc5466E742F28',
      abi: ProfileAbi.abi,
    },
    [CONTRACTS.OUTFITS]: {
      address: '0x4cBfD50CCA4b904Cd2c34B15a5e58656a5A5c134',
      abi: OutfitsAbi.abi,
    },
    [CONTRACTS.OUTFIT_FAUCET]: {
      address: '0x78B86b77ac43395d95fA5481FEa47582Bc1f9eCe',
      abi: OutfitFaucetAbi.abi,
    },
  },
}

export const getContract = (contract: CONTRACTS): ContractData => contracts[NETWORK][contract]
