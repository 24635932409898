import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { setFocused, setTouch } from '../../stores/UserStore'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { CharactersList } from '../../constants/player-sprites'
import { setActiveMenu } from '../../stores/MenuStore'
import Border from '../../components/shared/Border'
import Game from '../../scenes/Game'
import phaserGame from '../../PhaserGame'

const Backdrop = styled.div`
  margin-top: auto;
  margin-bottom: 0px;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 11;
`

const NameContainer = styled.div`
  margin-top: auto;
  margin-bottom: 0px;
  position: fixed;
  top: 20px;
  opacity: 0.9;
  min-width: 240px;
  font-family: 'VT323';
  font-size: 28px;
  line-height: 30px;
  left: 90px;
  z-index: 10;

  @media (max-width: 700px) {
    display: none;
  }
`

// const PillContainer = styled.div`

// left: 70px;
// top: 37px;
// height: 40px;
// position: absolute;
// display: flex;
// .pill {
//   font-size: 18px;
//   width: 90px;
//   padding: 0px 5px;
//   border-radius: 0.5rem;
//   border: single 0.75rem #555;
//   margin-left: 10px;
//   background-color: #ffffff;
//   background: #fff;
//   box-shadow: inset 0 3px 0 -1px #999, inset 0 -4px 0 -2px #999, inset 4px 0 0 -2px #999,
//     inset -4px 0 0 -2px #999;
//   border: solid;
//   text-align: right;
//   display: flex;

//   .icon {
//     border: 0px;
//     box-shadow: 0px;
//     background: none;
//     position: fixed;
//   }
//   p {
//     padding: 0px;
//     margin: 0px;
//     right: 0px;
//     width: 100%;
//   }
// }
// @media (max-width: 700px) {
//   display:none;
// }
// `

const HealthBar = styled.div`
  img {
    width: 75px;
    height: 75px;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    z-index: 99;
    background-color: #fff;
    color: #000;
    :hover {
      background-color: #ddd;
      cursor: pointer;
    }
  }

  @media (max-width: 700px) {
    img {
      width: 54px;
      height: 54px;
    }
  }
`

const Body = styled.div`
  background: rgb(248, 248, 248) none repeat scroll 0% 0%;
  padding: 0px;

  @media (max-width: 700px) {
    padding: 0px;
  }
`

export default function TabletButton() {
  const inputRef = useRef<HTMLInputElement>(null)
  const isGameScene = useAppSelector((state) => state.game.isGameScene)
  const isMultiplayer = useAppSelector((state) => state.game.isMultiplayer)
  const focused = useAppSelector((state) => state.user.focused)
  const activeMenu = useAppSelector((state) => state.menu.activeMenu)
  const messageDialogOpen = useAppSelector((state) => state.message.messageDialogOpen)
  const name = useAppSelector((state) => state.user.name)
  const texture = useAppSelector((state) => state.user.texture)
  const timerIsCounting = useAppSelector((state) => state.timer.isCounting)
  const currentScene = phaserGame.scene.keys.game as Game
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (focused) {
      inputRef.current?.focus()
    }
  }, [focused])

  if (isGameScene) return <></>
  return (
    <>
      {['chat', ''].includes(activeMenu) ? (
        <>
          <Backdrop>
            <>
              <Border>
                <Body>
                  <HealthBar>
                    <img
                      src={
                        '/assets/character/profile-pictures/' +
                        CharactersList.find((outfit) => outfit.name === texture)?.fileName +
                        '.png'
                      }
                      alt="profile"
                      onClick={() => {
                        if (timerIsCounting || messageDialogOpen || !isMultiplayer) {
                          return
                        } else {
                          dispatch(setActiveMenu('tablet'))
                          dispatch(setFocused(true))
                          dispatch(setTouch(false))

                          currentScene.resetNotification()
                        }
                      }}
                    />
                  </HealthBar>
                </Body>
              </Border>
            </>
          </Backdrop>

          <NameContainer>
            <Border>
              <Body>
                <div
                  style={{
                    padding: '0px 20px',
                  }}
                >
                  {name}
                </div>
              </Body>
            </Border>
          </NameContainer>
        </>
      ) : (
        <></>
      )}
    </>
  )
}
