import styled from 'styled-components'

export const Wrapper = styled.div`
  width:100%;
  display: flex;
  flex-wrap: wrap;
`

export default function MenuOptionsList(props: any) {
  return <Wrapper >{props.children}</Wrapper>
}
