import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    activeMenu: '',
  },
  reducers: {
    setActiveMenu: (state, action: PayloadAction<string>) => {
      state.activeMenu = action.payload
    },
  },
})

export const { setActiveMenu } = menuSlice.actions

export default menuSlice.reducer
