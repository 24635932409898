import Container = Phaser.GameObjects.Container;
import GeometryMask = Phaser.Display.Masks.GeometryMask;
import Game from '../../scenes/Game'
import {CorrectSeqMiniGameHints} from './CorrectSeqMiniGameHints'
import {randomRange} from '../../utils/math'
import {CorrectSeqMiniGameMovingFruit} from './CorrectSeqMiniGameMovingKey'
import {KeyHint} from './CorrectSeqMiniGameKeyHint'
import {Gauge} from '../../utils/gauge'
import {setFocused, setTouch} from '../../stores/UserStore'
import store from '../../stores'
import {setActiveMenu} from '../../stores/MenuStore'
import phaserGame from '../../PhaserGame'
import {gameConfig} from '../../assets/game-data'

const keysData: KeyHint[] = [
    {
        keyName: 'Left',
        altKeyName: 'A',
        keyColor: 0x008dd5,
        keyCode: Phaser.Input.Keyboard.KeyCodes.LEFT,
        altKeyCode: Phaser.Input.Keyboard.KeyCodes.A,
        buttonImage: 'correctSeqButtonApple',
        symbolImage: 'correctSeqFruitApple',
    },
    {
        keyName: 'Up',
        altKeyName: 'W',
        keyColor: 0xdfbbb1,
        keyCode: Phaser.Input.Keyboard.KeyCodes.UP,
        altKeyCode: Phaser.Input.Keyboard.KeyCodes.W,
        buttonImage: 'correctSeqButtonBanana',
        symbolImage: 'correctSeqFruitBanana',
    },
    {
        keyName: 'Right',
        altKeyName: 'D',
        keyColor: 0xf56476,
        keyCode: Phaser.Input.Keyboard.KeyCodes.RIGHT,
        altKeyCode: Phaser.Input.Keyboard.KeyCodes.D,
        buttonImage: 'correctSeqButtonPineapple',
        symbolImage: 'correctSeqFruitPineapple',
    },
    {
        keyName: 'Down',
        altKeyName: 'S',
        keyColor: 0xe43f6f,
        keyCode: Phaser.Input.Keyboard.KeyCodes.DOWN,
        altKeyCode: Phaser.Input.Keyboard.KeyCodes.S,
        buttonImage: 'correctSeqButtonCherry',
        symbolImage: 'correctSeqFruitCherry',
    },
]

export class CorrectSeqMiniGame extends Container {
    private static readonly POPUP_WIDTH = 300
    private static readonly POPUP_HEIGHT = 75
    private static readonly MAX_SHAKE_OFFSET = 2.5
    private static readonly FRUIT_SPEED = 0.5

    private static readonly MIN_TIME_BETWEEN_KEY_GENERATION = 200
    private static readonly MAX_TIME_BETWEEN_KEY_GENERATION = 1000
    private static readonly SHAKE_DURATION_MILLIS = 50

    private static readonly MARKER_PERCENT_POSITION = 0.3

    private static readonly VALIDATION_RANGE = 0.1
    private static readonly PERCENT_LOST_PER_ERROR = 0.2
    private static readonly PERCENT_LOST_PER_MISSED = 0.05
    private static readonly PERCENT_GAINED_PER_SUCCESS = 0.1

    private readonly keysMask: GeometryMask
    private shape: Phaser.GameObjects.Graphics

    private movingFruits: CorrectSeqMiniGameMovingFruit[] = []
    private leftKey: Phaser.Input.Keyboard.Key
    private topKey: Phaser.Input.Keyboard.Key
    private rightKey: Phaser.Input.Keyboard.Key
    private bottomKey: Phaser.Input.Keyboard.Key

    private readonly healthGauge: Gauge
    private readonly growthGauge: Gauge
    private readonly slider: Phaser.GameObjects.Image
    private readonly successLabel: Phaser.GameObjects.Image
    private readonly failureLabel: Phaser.GameObjects.Image

    private remainingTimeBeforeNextKeyGeneration: number = 0
    private isOver = false

    constructor(scene: Game) {
        super(scene)

        const popupBackground = scene.add.image(0, 0, 'correctSeqBg')
        this.add(popupBackground)

        this.slider = scene.add.image(
            0,
            CorrectSeqMiniGame.POPUP_HEIGHT / 2 + 20,
            'correctSeqSlider'
        )
        this.add(this.slider)

        this.successLabel = scene.add.image(
            0,
            CorrectSeqMiniGame.POPUP_HEIGHT / 2 + 20,
            'correctSeqButtonSuccess'
        )
        this.successLabel.visible = false
        this.add(this.successLabel)

        this.failureLabel = scene.add.image(
            0,
            CorrectSeqMiniGame.POPUP_HEIGHT / 2 + 20,
            'correctSeqButtonFailure'
        )
        this.failureLabel.visible = false
        this.add(this.failureLabel)

        this.healthGauge = new Gauge('correctSeqBarFrame', 'correctSeqBarInnerHealth', 1, scene)
        this.healthGauge.setPosition(0, -CorrectSeqMiniGame.POPUP_HEIGHT + 20)
        this.add(this.healthGauge)
        const healthLabel = scene.add.text(this.healthGauge.x - this.healthGauge.width / 2 + 7, this.healthGauge.y - this.healthGauge.height - 9, 'Patience', {fontFamily: 'rubik'});
        this.add(healthLabel)

        this.growthGauge = new Gauge('correctSeqBarFrame', 'correctSeqBarInnerGrowth', 0, scene)
        this.growthGauge.setPosition(0, -CorrectSeqMiniGame.POPUP_HEIGHT / 3 + 20)
        this.add(this.growthGauge)
        const growthLabel = scene.add.text(this.growthGauge.x - this.growthGauge.width / 2 + 7, this.growthGauge.y - this.growthGauge.height - 9, 'Progress', {fontFamily: 'rubik'});
        this.add(growthLabel)

        this.setupMusic()

        const sliderPadding = 0.075
        this.shape = scene.make.graphics({x: 0, y: 0})
        this.shape.beginPath().fillRect(this.slider.width * sliderPadding, 0, this.slider.width * (1 - sliderPadding), this.slider.height)
        this.keysMask = this.shape.createGeometryMask()

        const correctSeqMiniGameHints = new CorrectSeqMiniGameHints(keysData, scene, keyHint => this.validateKey(altCursors ? keyHint.altKeyCode : keyHint.keyCode));
        correctSeqMiniGameHints.setPosition(-50, 175)
        this.add(correctSeqMiniGameHints)

        scene.add.existing(this)

        const {altCursors} = store.getState().pref

        this.leftKey = scene.input.keyboard.addKey(
            altCursors ? keysData[0].altKeyCode : keysData[0].keyCode
        )
        this.topKey = scene.input.keyboard.addKey(
            altCursors ? keysData[1].altKeyCode : keysData[1].keyCode
        )
        this.rightKey = scene.input.keyboard.addKey(
            altCursors ? keysData[2].altKeyCode : keysData[2].keyCode
        )
        this.bottomKey = scene.input.keyboard.addKey(
            altCursors ? keysData[3].altKeyCode : keysData[3].keyCode
        )
        this.leftKey.on('down', () => {
                correctSeqMiniGameHints.triggerPressVisuals(keysData[0]);
                this.validateKey(altCursors ? keysData[0].altKeyCode : keysData[0].keyCode);
            }
        )
        this.topKey.on('down', () => {
                correctSeqMiniGameHints.triggerPressVisuals(keysData[1]);
                this.validateKey(altCursors ? keysData[1].altKeyCode : keysData[1].keyCode);
            }
        )
        this.rightKey.on('down', () => {
                correctSeqMiniGameHints.triggerPressVisuals(keysData[2]);
                this.validateKey(altCursors ? keysData[2].altKeyCode : keysData[2].keyCode);
            }
        )
        this.bottomKey.on('down', () => {
                correctSeqMiniGameHints.triggerPressVisuals(keysData[3]);
                this.validateKey(altCursors ? keysData[3].altKeyCode : keysData[3].keyCode);
            }
        )

        this.depth = 1000000
    }

    private setupMusic() {
        const game = phaserGame.scene.keys.game as Game
        if (store.getState().pref.music) {
            game.music.stop()
            game.music = game.sound.add('clock-ticking', {
                volume: 0.2,
                loop: true,
            })
            game.music.play()
        }
    }

    update(delta: number) {
        if (this.isOver) return

        this.shape.setPosition(
            this.x + this.slider.x - this.slider.width / 2,
            this.y + this.slider.y - this.slider.height / 2
        )

        this.growthGauge.update()
        this.healthGauge.update()

        this.movingFruits.forEach((k) => k.update(delta))

        this.remainingTimeBeforeNextKeyGeneration -= delta

        if (this.remainingTimeBeforeNextKeyGeneration > 0) return

        this.generateFruit()
        this.scheduleNextKeyGeneration()
    }

    private validateKey(keyCode: number) {
        for (let i = 0; i < this.movingFruits.length; i++) {
            const movingKey = this.movingFruits[i]

            const {altCursors} = store.getState().pref

            if (!altCursors && movingKey.fruitHint.keyCode !== keyCode) continue
            if (altCursors && movingKey.fruitHint.altKeyCode !== keyCode) continue

            if (
                movingKey.currentPercent >= CorrectSeqMiniGame.MARKER_PERCENT_POSITION - CorrectSeqMiniGame.VALIDATION_RANGE / 2 &&
                movingKey.currentPercent <= CorrectSeqMiniGame.MARKER_PERCENT_POSITION + CorrectSeqMiniGame.VALIDATION_RANGE
            ) {
                this.keySuccess(CorrectSeqMiniGame.PERCENT_GAINED_PER_SUCCESS)
                this.successShake()
                this.removeMovingKey(movingKey)
                return
            }
        }

        this.keyFail(CorrectSeqMiniGame.PERCENT_LOST_PER_ERROR)
        this.failureShake()
    }

    private successShake() {
        const timeline = this.scene.tweens.createTimeline()
        timeline.add({
            targets: this,
            y: '+=' + CorrectSeqMiniGame.MAX_SHAKE_OFFSET,
            duration: CorrectSeqMiniGame.SHAKE_DURATION_MILLIS,
        })
        timeline.add({
            targets: this,
            y: '-=' + CorrectSeqMiniGame.MAX_SHAKE_OFFSET,
            duration: CorrectSeqMiniGame.SHAKE_DURATION_MILLIS,
        })
        timeline.play()
    }

    private failureShake() {
        const timeline = this.scene.tweens.createTimeline()
        const destX = this.x
        const destY = this.y
        this.scene.tweens.timeline({
            loop: 1,
            tweens: [
                {
                    targets: this,
                    x: destX + CorrectSeqMiniGame.MAX_SHAKE_OFFSET,
                    ease: 'Linear',
                    duration: 30,
                },
                {
                    targets: this,
                    x: destX,
                    ease: 'Linear',
                    duration: 30,
                },
            ],
        })

        this.scene.tweens.timeline({
            loop: 1,
            tweens: [
                {
                    targets: this,
                    y: destY + CorrectSeqMiniGame.MAX_SHAKE_OFFSET,
                    ease: 'Linear',
                    duration: 30,
                },
                {
                    targets: this,
                    y: destY,
                    ease: 'Linear',
                    duration: 30,
                },
            ],
        })
        timeline.play()
    }

    private generateFruit() {
        const halfWidth = CorrectSeqMiniGame.POPUP_WIDTH / 2
        const movingFruit = new CorrectSeqMiniGameMovingFruit(
            -halfWidth,
            halfWidth,
            CorrectSeqMiniGame.POPUP_HEIGHT / 2 + 20,
            CorrectSeqMiniGame.FRUIT_SPEED,
            keysData[randomRange(0, keysData.length - 1)],
            this,
            this.scene
        )
        movingFruit.setMask(this.keysMask)
        this.add(movingFruit)
        this.movingFruits.push(movingFruit)
    }

    private scheduleNextKeyGeneration() {
        this.remainingTimeBeforeNextKeyGeneration = randomRange(
            CorrectSeqMiniGame.MIN_TIME_BETWEEN_KEY_GENERATION,
            CorrectSeqMiniGame.MAX_TIME_BETWEEN_KEY_GENERATION
        )
    }

    private keySuccess(amountEarned: number) {
        const game = phaserGame.scene.keys.game as Game
        this.growthGauge.modifyPercent(amountEarned)
        if (store.getState().pref.soundEffects) {
            game.soundEffects = game.sound.add('unlock-correct', {
                volume: 0.05,
            })
            game.soundEffects.play()
        }
        if (this.growthGauge.getPercent() >= 0.99) {
            this.success()
        }
    }

    private keyFail(amountLost: number) {
        const game = phaserGame.scene.keys.game as Game
        this.healthGauge.modifyPercent(-amountLost)
        if (store.getState().pref.soundEffects) {
            game.soundEffects = game.sound.add('unlock-wrong', {
                volume: 0.05,
            })
            game.soundEffects.play()
        }
        if (this.healthGauge.getPercent() <= 0) {
            this.fail()
        }
    }

    triggerKeyFail(movingKey: CorrectSeqMiniGameMovingFruit) {
        this.keyFail(CorrectSeqMiniGame.PERCENT_LOST_PER_MISSED)
        this.removeMovingKey(movingKey)
    }

    removeMovingKey(movingKey: CorrectSeqMiniGameMovingFruit) {
        const index = this.movingFruits.indexOf(movingKey)
        if (index !== -1) this.movingFruits.splice(index, 1)
        movingKey.destroy(false)
    }

    private clearMovingKeys() {
        this.movingFruits.forEach((mf) => mf.destroy(false))
        this.movingFruits = []
    }

    private success() {
        const game = phaserGame.scene.keys.game as Game
        this.clearMovingKeys()
        this.destroyKeys()
        this.slider.visible = false
        this.successLabel.visible = true
        this.isOver = true
        if (store.getState().pref.soundEffects) {
            game.soundEffects = game.sound.add('unlock-won', {
                volume: 0.05,
            })
            game.soundEffects.play()
        }
        setTimeout(() => {
            this.emit('success')
            this.close()
        }, 1000)
    }

    private fail() {
        const game = phaserGame.scene.keys.game as Game
        this.clearMovingKeys()
        this.destroyKeys()
        this.slider.visible = false
        this.failureLabel.visible = true
        this.isOver = true
        if (store.getState().pref.soundEffects) {
            game.soundEffects = game.sound.add('unlock-lose', {
                volume: 0.05,
            })
            game.soundEffects.play()
        }
        setTimeout(() => {
            this.emit('fail')
            this.close()
        }, 1000)
    }

    private close() {
        const game = phaserGame.scene.keys.game as Game
        if (store.getState().pref.music) {
            game.music.stop()
            game.music = game.sound.add(`${gameConfig.maps[game.map.config.sceneName].music[0].key}`, {
                volume: 0.2,
                loop: true,
            })
            game.music.play()
        }
        this.emit('destroyed')
        this.destroy(true)
        store.dispatch(setFocused(false))
        store.dispatch(setTouch(true))
        store.dispatch(setActiveMenu(''))
    }

    private destroyKeys() {
        this.leftKey.destroy()
        this.topKey.destroy()
        this.rightKey.destroy()
        this.bottomKey.destroy()
    }
}
