import getNPC from '../helpers/game/getNPC'
import phaserGame from '../PhaserGame'
import Game from '../scenes/Game'
import { handleCorrectPathMiniGame } from '../scenes/methods/minigames'
import store from '../stores'
import { setMyPlayerProps } from '../stores/UserStore'

// Start
export function startFruitsInTheBasket() {
  const game = phaserGame.scene.keys.game as Game

  game.myPlayer.setCursorsIsDownToFalse()
  handleCorrectPathMiniGame(
    'name',
    () => {
      // SUCCESS
      game.handleConversation(game.myPlayer, getNPC('helper-akira'), 2)
    },
    () => {
      // FAILURE
      game.handleConversation(game.myPlayer, getNPC('helper-akira'), 1)
    },
    () => {}
  )
}

// Update
export function updateFruitsInTheBasket() {}

// Complete
export function completeFruitsInTheBasket() {
  store.dispatch(
    setMyPlayerProps({
      texture: 'Avocado',
    })
  )
}

// Utils
