import getNPC from '../helpers/game/getNPC'
import phaserGame from '../PhaserGame'
import Game from '../scenes/Game'
import { Coin, getCoin } from '../items/Coin'
import store from '../stores'
import { getCompletedQuests } from '../stores/QuestStore'
import { setCounting } from '../stores/TimerStore'
import { setWarningText } from '../stores/WarningTextStore'
import { setMessageDisabled } from '../stores/MessageStore'

const coins = [
  { x: 3948, y: 1850 },
  { x: 3948, y: 1700 },
  { x: 3948, y: 1550 },
  { x: 3948, y: 1400 },
  { x: 3948, y: 1250 },
  { x: 3948, y: 1100 },
  { x: 3948, y: 950 },
  { x: 3948, y: 800 },
  { x: 3948, y: 650 },
  { x: 3948, y: 500 },
  { x: 3834, y: 460 },
  { x: 3684, y: 460 },
  { x: 3535, y: 460 },
  { x: 3384, y: 460 },
  { x: 3234, y: 460 },
  { x: 3147, y: 519 },
  { x: 3135, y: 669 },
  { x: 3135, y: 819 },
  { x: 3135, y: 969 },
  { x: 3135, y: 1128 },
  { x: 2985, y: 1128 },
  { x: 2835, y: 1128 },
  { x: 2685, y: 1128 },
  { x: 2535, y: 1128 },
  { x: 2385, y: 1128 },
  { x: 2235, y: 1128 },
  { x: 2085, y: 1128 },
  { x: 1935, y: 1128 },
  { x: 1785, y: 1128 },
  { x: 1635, y: 1128 },
  { x: 1485, y: 1128 },
  { x: 1335, y: 1128 },
  { x: 1185, y: 1128 },
  { x: 1035, y: 1128 },
  { x: 956, y: 1225 },
  { x: 956, y: 1375 },
  { x: 956, y: 1525 },
  { x: 956, y: 1675 },
  { x: 956, y: 1800 },
  { x: 1106, y: 1800 },
  { x: 1256, y: 1800 },
  { x: 1406, y: 1800 },
  { x: 1556, y: 1800 },
  { x: 1706, y: 1800 },
  { x: 1887, y: 1800 },
  { x: 2037, y: 1800 },
  { x: 2187, y: 1800 },
  { x: 2337, y: 1800 },
  { x: 2487, y: 1800 },
  { x: 2637, y: 1800 },
  { x: 2787, y: 1800 },
  { x: 2937, y: 1800 },
  { x: 3087, y: 1800 },
  { x: 3237, y: 1800 },
  { x: 3387, y: 1800 },
  { x: 3537, y: 1800 },
  { x: 3687, y: 1800 },
  { x: 3837, y: 1800 },
  { x: 3937, y: 1800 },
]

// Start
export function startSkateNCollect(state?:any) {
  const game = phaserGame.scene.keys.game as Game


  store.dispatch(setMessageDisabled(true))
  store.dispatch(setWarningText('READY'))
  setTimeout(() => {
    store.dispatch(setWarningText('SET'))
  }, 1000)

  new Coin(game, coins[0].x, coins[0].y, 'coin', '0', spawnNextCoin)
  new Coin(game, coins[1].x, coins[1].y, 'coin', '1', spawnNextCoin).setDisabled(true)
}

// Update
export function updateSkateNCollect() {
  const { time, isCounting } = store.getState().timer

  if (time === 0 && isCounting) {
    for (let i = 0; i < coins.length; i++) {
      removeCoin(i.toString())
    }
    store.dispatch(setCounting(false))
    store.dispatch(setWarningText('FAILED'))
    store.dispatch(setMessageDisabled(false))
    setTimeout(() => {
      store.dispatch(setWarningText(''))
    }, 2000)
  }
}

// Utils
function spawnNextCoin(currentCoin: Coin) {
  let isCompleted = getCompletedQuests().find((quest: any) => quest === 2)
  const game = phaserGame.scene.keys.game as Game
  const currentNumber = parseInt(currentCoin.name)

  removeCoin(currentCoin.name)

  if (!isCompleted) {
    // Enable Next Coin
    const nextCoin = getCoin(game, (currentNumber + 1).toString())
    if (nextCoin) {
      nextCoin.setDisabled(false)
    } else {
      // Finish Mission
      store.dispatch(setCounting(false))
      store.dispatch(setMessageDisabled(false))

      for (let i = 0; i < coins.length; i++) {
        const currentCoin = getCoin(game, i.toString())

        if (currentCoin) {
          game.collectibleGroup.remove(currentCoin, true, true)
        }
      }
      game.handleConversation(game.myPlayer, getNPC('young-hunter'), 4)

      return
    }

    // Spawn Next to Next Coin
    if (coins[currentNumber + 2] && !getCoin(game, (currentNumber + 2).toString())) {
      new Coin(
        game,
        coins[currentNumber + 2].x,
        coins[currentNumber + 2].y,
        'coin',
        (currentNumber + 2).toString(),
        spawnNextCoin
      ).setDisabled(true)
    }
  }
}


function removeCoin(name:string){
  const game = phaserGame.scene.keys.game as Game
  const currentCoin = getCoin(game, name)

  if (currentCoin) {
    game.collectibleGroup.remove(currentCoin, true, true)
  }
}