/* eslint-disable no-loop-func */
/**
 * Generates map
 */

import { gameConfig } from '../../assets/game-data'
import Chair from '../../items/Chair'
import Computer from '../../items/Computer'
import Unlockable from '../../items/Unlockable'
import TeleportZone from '../../items/TeleportZone'
import Whiteboard from '../../items/Whiteboard'
import store from '../../stores'
import Game from '../Game'
import AnimatedTile, { TilesetTileData } from './AnimatedTiles'
import { Coin } from '../../items/Coin'
import Info from '../../items/Info'

interface IMapConfig {
  scene: Game
  sceneName: string
  key: string
  json: any
  tilesets: ITileset[]
  spawnPoint: {
    x: number
    y: number
  }
}

export interface ITileset {
  name: string
  key: string
}

export default class Map {
  scene: Game
  config: any

  constructor(config: IMapConfig) {
    this.scene = config.scene // the scene this map belongs to
    this.config = config
    this.createMap()
    this.checkAndPlayMusic()
    this.addSoundEffects()
  }

  createMap() {
    // create tile map
    this.scene.currentTilemap = this.scene.make.tilemap({ key: this.config.key })
    // add tileset image to map

    let tileArray: any = []
    for (let i = 0; i < gameConfig.maps[this.config.sceneName].tilesets.length; i++) {
      const tileset = this.scene.currentTilemap.addTilesetImage(
        gameConfig.maps[this.config.sceneName].tilesets[i].name,
        gameConfig.maps[this.config.sceneName].tilesets[i].key
      )
      tileArray.push(tileset)
    }

    const belowLayer = this.scene.currentTilemap.createLayer('Below Player', tileArray, 0, 0)
    const aboveWorldLayer = this.scene.currentTilemap.createLayer('Above World', tileArray, 0, 0)
    const belowWorldLayer = this.scene.currentTilemap.createLayer('Below World', tileArray, 0, 0)
    const aboveLayer = this.scene.currentTilemap.createLayer('Above Player', tileArray, 0, 0)

    this.scene.worldLayer = this.scene.currentTilemap.createLayer('World', tileArray, 0, 0)
    this.scene.worldLayer.setCollisionByProperty({ collides: true })

    // By default, everything gets depth sorted on the screen in the order we created things. Here, we
    // want the "Above Player" layer to sit on top of the player, so we explicitly give it a depth.
    // Higher depths will sit on top of lower depth objects.
    aboveWorldLayer.setDepth(10)
    aboveLayer.setDepth(10)
    belowWorldLayer.setDepth(-10)
    belowLayer.setDepth(-10)

    // Get animated tiles
    for (let i = 0; i < tileArray.length; i++) {
      const tileData = tileArray[i].tileData as TilesetTileData
      for (let tileid in tileData) {
        this.scene.currentTilemap.layers.forEach((layer: any) => {
          if (layer.tilemapLayer.type === 'StaticTilemapLayer') return
          layer.data.forEach((tileRow) => {
            tileRow.forEach((tile) => {
              // Typically `firstgid` is 1, which means tileid starts from 1.
              // Tiled's tileid starts from 0.
              if (tile.index - tileArray[i].firstgid === parseInt(tileid, 10)) {
                if (tileData[tileid].animation) {
                  this.scene.animatedTiles.push(
                    new AnimatedTile(tile, tileData[tileid].animation, tileArray[i].firstgid)
                  )
                }
              }
            })
          })
        })
      }
    }

    this.mapObjects()
  }

  mapObjects() {
    // import chair objects from Tiled map to Phaser
    this.scene.chairGroup = this.scene.physics.add.staticGroup({ classType: Chair })
    // import computers objects from Tiled map to Phaser
    this.scene.computerGroup = this.scene.physics.add.staticGroup({ classType: Computer })
    // import whiteboards objects from Tiled map to Phaser
    this.scene.whiteboardGroup = this.scene.physics.add.staticGroup({ classType: Whiteboard })
    // import unlockables objects from Tiled map to Phaser
    this.scene.unlockableGroup = this.scene.physics.add.staticGroup({ classType: Unlockable })
    // import collectible objects from Tiled map to Phaser
    this.scene.collectibleGroup = this.scene.physics.add.group({ classType: Coin })
    // import info objects from Tiled map to Phaser
    this.scene.infoGroup = this.scene.physics.add.staticGroup({ classType: Info })

    const objectLayer = this.scene.currentTilemap.getObjectLayer('Objects')

    if (objectLayer) {
      objectLayer.objects.forEach((obj, i) => {
        // Chairs/Seats

        if (obj.type === 'chair') {
          const item = this.scene.addObjectFromTiled(
            this.scene.chairGroup,
            obj,
            'chairs',
            'chair'
          ) as Chair
          item.itemDirection = obj.properties[0].value
        }
        // Computers
        else if (obj.type === 'computer') {
          const item = this.scene.addObjectFromTiled(
            this.scene.computerGroup,
            obj,
            'computers',
            'computer'
          ) as Computer
          const id = `${i}`
          item.id = id
          this.scene.computerMap.set(id, item)
        }
        // Whiteboards
        else if (obj.type === 'whiteboard') {
          const item = this.scene.addObjectFromTiled(
            this.scene.whiteboardGroup,
            obj,
            'whiteboards',
            'whiteboard'
          ) as Whiteboard
          const id = `${i}`
          item.id = id
          this.scene.whiteboardMap.set(id, item)
        }
        // unlockables
        else if (obj.type === 'unlockable') {
          const splitStr = obj.name.trim().split('-')

          if (splitStr[0] === 'dumpster') {
            const item = this.scene.addObjectFromTiled(
              this.scene.unlockableGroup,
              obj,
              'dumpsters',
              'dumpster'
            ) as Unlockable

            item.isOpen = obj.properties[0].value
            item.name = obj.name
          } else if (splitStr[0] === 'worksite') {
            const item = this.scene.addObjectFromTiled(
              this.scene.unlockableGroup,
              obj,
              'worksite-toolboxes',
              'worksite-toolbox'
            ) as Unlockable

            item.isOpen = obj.properties[0].value
            item.name = obj.name
          }
        } else if (obj.type === 'info') {
          const { x, y, width, height } = obj

          const infoItem = new Info(this.scene, x!, y!, width!, height!).setOrigin(0)
          infoItem.name = obj.name
          infoItem.info = obj.properties[0].value

          this.scene.infoGroup.add(infoItem)
        }
      })
    }

    // import teleport zone objects from Tiled map to phaser
    this.scene.teleportZoneGroup = this.scene.physics.add.staticGroup({ classType: TeleportZone })
    const teleportZoneLayer = this.scene.currentTilemap.getObjectLayer('Worlds')

    teleportZoneLayer?.objects.forEach((object) => {
      const { x, y, width, height } = object

      const teleportTo = object.name
      const teleportZone = new TeleportZone(
        this.scene,
        x!,
        y!,
        width!,
        height!,
        teleportTo
      ).setOrigin(0)
      this.scene.teleportZoneGroup.add(teleportZone)
    })
  }

  checkAndPlayMusic() {
    // this.scene.sound.pauseOnBlur = false

    // check if scene has background music value set
    if (
      gameConfig.maps[this.config.sceneName].music &&
      gameConfig.maps[this.config.sceneName].music[0]
    ) {
      // check if music is already running and is the same
      if (this.scene.music && this.scene.music.key) {
        if (this.scene.music.key === gameConfig.maps[this.config.sceneName].music[0].key) {
          return
        } else {
          // pause current music and play new one
          this.scene.music.stop()
          this.playMusic()
        }
      } else {
        // play music if not already running
        this.playMusic()
      }
    }
  }

  playMusic(key?: any) {
    // Add music to scene
    this.scene.music = this.scene.sound.add(
      key ? key : `${gameConfig.maps[this.config.sceneName].music[0].key}`,
      {
        volume: 0.2,
        loop: true,
      }
    )

    // Play if music is enabled

    if (!this.scene.sound.locked) {
      // already unlocked so play
      this.scene.music.play()
    } else {
      // wait for 'unlocked' to fire and then play
      this.scene.sound.once(Phaser.Sound.Events.UNLOCKED, () => {
        this.scene.music.play()
      })
    }

    const { music } = store.getState().pref

    if (!music) {
      this.scene.music.pause()
    }
  }

  addSoundEffects() {}
}
