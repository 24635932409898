export interface NPCData {
  id: string
  name: string
  key: string
}

export enum NPCType {
  NORMAL = 'NORMAL',
  GOOP = 'GOOP',
  DETECTOR = 'DETECTOR',
}

export const NPCdata: NPCData[] = [
  {
    id: 'construction-worker-tommy',
    key: 'constructionWorkerTommy',
    name: 'Construction Worker Tommy',
  },
  {
    id: 'uncle-tony',
    key: 'uncleTony',
    name: 'Uncle Tony',
  },
  {
    id: 'lost-pirate',
    key: 'lostPirate',
    name: 'Lost Pirate',
  },
  {
    id: 'young-hunter',
    key: 'youngHunter',
    name: 'Young Hunter',
  },
  {
    id: 'nurse-mary',
    key: 'nurseMary',
    name: 'Nurse Mary',
  },
  {
    id: 'doctor-keisuke',
    key: 'doctorKeisuke',
    name: 'Doctor Keisuke',
  },
  {
    id: 'grocer-haruka',
    key: 'grocerHaruka',
    name: 'Grocer Haruka',
  },
  {
    id: 'banker-masahi',
    key: 'bankerMasahi',
    name: 'Banker Masahi',
  },
  {
    id: 'banker-megumi',
    key: 'bankerMegumi',
    name: 'Banker Megumi',
  },
  {
    id: 'officer-ken',
    key: 'officerKen',
    name: 'Officer Ken',
  },
  {
    id: 'doctor-yamato',
    key: 'doctorYamato',
    name: 'Doctor Yamato',
  },
  { id: 'fireman-goro', key: 'firemanGoro', name: 'Fireman Goro' },
  { id: 'prisoner', key: 'prisoner', name: 'Prisoner' },
  { id: 'helper-akira', key: 'helperAkira', name: 'Helper Akira' },
  { id: 'baker-toro', key: 'bakerToro', name: 'Baker Toro' },
  { id: 'salesman-eticha', key: 'salesmanEticha', name: 'Salesman Eticha' },
  { id: 'salesman-kenechi', key: 'salesmanKenechi', name: 'Salesman Kenechi' },
  { id: 'samurai-musashi', key: 'samuraiMusashi', name: 'Samurai Musashi' },
]
