import React from 'react'
import HomeIcon from '@mui/icons-material/Home'
import MenuOption from '../../components/menu/MenuOption'
import phaserGame from '../../PhaserGame'
import Bootstrap from '../../scenes/Bootstrap'
import { useAppDispatch } from '../../hooks'
import { setFocused, setTouch } from '../../stores/UserStore'

export default function TeleportButton({ setActiveMenu }) {
  const dispatch = useAppDispatch()
  const bootstrapHandler = phaserGame.scene.keys.bootstrap as Bootstrap

  return (
    <MenuOption
      onClick={() => {
        bootstrapHandler.launchGame()
        dispatch(setActiveMenu(''))
        dispatch(setFocused(false))
        dispatch(setTouch(true))
      }}
      icon={<HomeIcon />}
      text={'Teleport Home'}
    />
  )
}
