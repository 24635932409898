import React, { createRef, useEffect } from 'react'
import { Route, Switch, HashRouter, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import Dialog from '../../components/Dialog'
import '../../i18n'

import ApplicationUpdater from '../../stores/Bank/application/updater'
import ListsUpdater from '../../stores/Bank/lists/updater'
import MulticallUpdater from '../../stores/Bank/multicall/updater'
import TransactionUpdater from '../../stores/Bank/transactions/updater'
import UserUpdater from '../../stores/Bank/user/updater'

import Swap from './Swap'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import Pools from './Pools'
import PoolsArchived from './Pools/Archived'
import Manage from './Pools/Manage'
import Treasury from './Treasury'
import AddLiquidity from './AddLiquidity'
import {
  OpenClaimAddressModalAndRedirectToSwap,
  RedirectPathToSwapOnly,
  RedirectToSwap,
} from './Swap/redirects'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from './AddLiquidity/redirects'
import RemoveLiquidity from './RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import MigrateV1 from './MigrateV1'
import MigrateV1Exchange from './MigrateV1/MigrateV1Exchange'
import RemoveV1Exchange from './MigrateV1/RemoveV1Exchange'

import { PIT_SETTINGS } from '../../constants/bank'
import { setFocused, setTouch } from '../../stores/UserStore'
import { setActiveMenu } from '../../stores/MenuStore'
import { useAppDispatch } from '../../hooks'

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
`

const FabWrapper = styled.div`
  margin-top: auto;
  margin-bottom: 0px;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
`

const Container = styled.div`
  padding: 0px;
  margin: 0px;
  text-align: center;
  position: relative;
  background: black;
  z-index: 2;
  display: flex;
  flex-direction: column;
  z-index: 99;
  color: #555;
  max-width: 800px;
  min-height: 450px;
  max-height: 80%;
  width: 100%;
  height: 100%;
  @media (max-width: 700px) {
    width: calc(100% - 20px);
    height: auto;
    min-height: 300px;
  }
`

const WrapperPage = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
`

function Updaters() {
  return (
    <>
      <ListsUpdater />
      <UserUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
    </>
  )
}

export let historyRef: any = createRef()

function Bank() {
  const { chainId } = useWeb3React()
  const history = useHistory()
  historyRef.current = history
  const dispatch = useAppDispatch()
  const pitSettings = chainId ? PIT_SETTINGS[chainId] : undefined

  useEffect(() => {
    return () => {
      const url = document.location.href
      window.history.pushState({}, '', url.split('/#')[0])
    }
  }, [])

  return (
    <>
      <Updaters />
      <Backdrop>
        <FabWrapper
          onClick={() => {
            dispatch(setActiveMenu(''))
            dispatch(setFocused(false))
            dispatch(setTouch(true))
            history.push('/')
          }}
        />
        <Container>
          <Dialog minimalPadding>
            <WrapperPage>
              <Switch>
                <Route exact strict path="/swap" component={Swap} />
                <Route
                  exact
                  strict
                  path="/claim"
                  component={OpenClaimAddressModalAndRedirectToSwap}
                />
                <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
                <Route exact strict path="/find" component={PoolFinder} />
                <Route exact strict path="/pool" component={Pool} />
                <Route exact strict path="/pools" component={Pools} />
                <Route exact strict path="/pools/archived" component={PoolsArchived} />
                <Route exact strict path={pitSettings.path} component={Treasury} />
                <Route exact strict path="/create" component={RedirectToAddLiquidity} />
                <Route exact path="/add" component={AddLiquidity} />
                <Route
                  exact
                  path="/add/:currencyIdA"
                  component={RedirectOldAddLiquidityPathStructure}
                />
                <Route
                  exact
                  path="/add/:currencyIdA/:currencyIdB"
                  component={RedirectDuplicateTokenIds}
                />
                <Route exact path="/create" component={AddLiquidity} />
                <Route
                  exact
                  path="/create/:currencyIdA"
                  component={RedirectOldAddLiquidityPathStructure}
                />
                <Route
                  exact
                  path="/create/:currencyIdA/:currencyIdB"
                  component={RedirectDuplicateTokenIds}
                />
                <Route exact strict path="/remove/v1/:address" component={RemoveV1Exchange} />
                <Route
                  exact
                  strict
                  path="/remove/:tokens"
                  component={RedirectOldRemoveLiquidityPathStructure}
                />
                <Route
                  exact
                  strict
                  path="/remove/:currencyIdA/:currencyIdB"
                  component={RemoveLiquidity}
                />
                <Route exact strict path="/migrate/v1" component={MigrateV1} />
                <Route exact strict path="/migrate/v1/:address" component={MigrateV1Exchange} />
                <Route exact strict path="/staking/:currencyIdA/:currencyIdB" component={Manage} />
                <Route component={RedirectPathToSwapOnly} />
              </Switch>
            </WrapperPage>
          </Dialog>
        </Container>
      </Backdrop>
    </>
  )
}

function BankRouter() {
  return (
    <HashRouter basename="/bank/">
      <Bank />
    </HashRouter>
  )
}

export default BankRouter
