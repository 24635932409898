import { useEffect, useMemo, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Contract } from 'ethers'

import { CONTRACTS, getContract } from '../constants/contracts';
import { getContract as getContractByAddress } from '../utils/bank'

export const useContract = (contractName: CONTRACTS, readOnly = false) => {
  const [contract, setContract] = useState<Contract>()
  const { account, library } = useWeb3React()

  useEffect(() => {
    const contractData = getContract(contractName)
    if (account && library && contractData) {
      const signerOrProvider = readOnly ? library : library.getSigner()
      if (contractData.abi && contractData.address) {
        const contractInstance = new Contract(contractData.address, contractData.abi, signerOrProvider)
        setContract(contractInstance)
      }
    }
  },[account, contractName, library, readOnly])

  return contract
}

export const useContractByAddress = (address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null => {
  const { account, library } = useWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContractByAddress(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}