import { registerKeys } from '.'
import { UnlockMiniGame } from '../../mini-games/unlock/UnlockMiniGame'
import phaserGame from '../../PhaserGame'
import store from '../../stores'
import { setActiveMenu } from '../../stores/MenuStore'
import { setFocused, setTouch } from '../../stores/UserStore'
import Game from '../Game'
import { CorrectSeqMiniGame } from '../../mini-games/correct-seq/CorrectSeqMiniGame'

export function handleUnlockMiniGame(
  name: string,
  steps: number,
  onSuccess: () => void,
  onDestroyed: () => void
) {
  const game = phaserGame.scene.keys.game as Game

  store.dispatch(setFocused(false))
  store.dispatch(setTouch(false))
  store.dispatch(setActiveMenu('mini-game'))

  let unlockMiniGame = new UnlockMiniGame(steps, game.myPlayer, game, name)

  // on close
  unlockMiniGame.on('destroyed', () => {
    onDestroyed()
    registerKeys()
    game.removeGameObject(unlockMiniGame)
  })

  // on success
  unlockMiniGame.on('success', () => {
    registerKeys()
    onSuccess()
  })

  // push game object
  game.gameObjects.push(unlockMiniGame)
}

export function handleCorrectPathMiniGame(
  name: string,
  onSuccess: () => void,
  onFailure: () => void,
  onDestroyed: () => void
) {
  const game = phaserGame.scene.keys.game as Game

  game.handlePlayerInteraction(false)
  game.resetNotification()
  store.dispatch(setFocused(false))
  store.dispatch(setTouch(false))
  store.dispatch(setActiveMenu('mini-game'))

  let correctSeqMiniGame = new CorrectSeqMiniGame(game)
  // TODO : replace this with a proper UI scene where it can be centered
  correctSeqMiniGame.setPosition(game.myPlayer.x, game.myPlayer.y - 75)

  correctSeqMiniGame.on('destroyed', () => {
    onDestroyed()
    registerKeys()
    game.removeGameObject(correctSeqMiniGame)
  })

  correctSeqMiniGame.on('fail', () => {
    onFailure()
  })

  correctSeqMiniGame.on('success', () => {
    registerKeys()
    onSuccess()
  })

  game.gameObjects.push(correctSeqMiniGame)
}
