import Container = Phaser.GameObjects.Container;
import Image = Phaser.GameObjects.Image;
import {Scene} from "phaser";

export class UnlockMiniGameMarkerUI extends Container {
    private readonly markerOn: Image;
    private readonly markerOff: Image;

    constructor(scene: Scene) {
        super(scene);

        this.markerOn = scene.add.image(0, 0, 'unlockMiniGameMarkerOn');
        this.markerOff = scene.add.image(0, 0, 'unlockMiniGameMarkerOff');

        this.add(this.markerOn);
        this.add(this.markerOff);

        this.turnOff();

        scene.add.existing(this);
    }

    turnOff() {
        this.markerOn.visible = false;
        this.markerOff.visible = true;
    }

    turnOn() {
        this.markerOn.visible = true;
        this.markerOff.visible = false;
    }
}
