import { useAppSelector } from '../../hooks'
import BattleSystem from '../../interfaces/battle'
import { GameState } from '../../stores/GameStore'

export default function BattleSceneUI() {
  const gameState = useAppSelector((state) => state.game.gameState)
  const loggedIn = useAppSelector((state) => state.user.loggedIn)
  if (gameState === GameState.WildPvE && loggedIn) {
    return <BattleSystem />
  } else return <></>
}
