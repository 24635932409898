import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const screenFlashSlice = createSlice({
  name: 'screenFlash',
  initialState: {
    isFlashing: false,
    iterations: 0,
  },
  reducers: {
    setScreenFlash: (
      state,
      action: PayloadAction<{
        isFlashing: boolean
        iterations?: number
      }>
    ) => {
      state.isFlashing = action.payload.isFlashing

      if (action.payload.isFlashing === true) {
        state.iterations = action.payload.iterations ? action.payload.iterations : 1
      } else {
        state.iterations = 0
      }
    },
  },
})

export const { setScreenFlash } = screenFlashSlice.actions

export default screenFlashSlice.reducer
