import { AttackType } from "./defimons";

export class DefimonSkill {
  static readonly PUN = new DefimonSkill("Pun", "Makes a pun so bad it hurts the opponent.", AttackType.NORMAL, 25, 0.75, 9)
  static readonly SLAP = new DefimonSkill("Slap", "An humiliating slap.", AttackType.NORMAL, 10, 0.5, 2)
  static readonly SPLIT = new DefimonSkill("Split", "Halves the opponent's salary", AttackType.SPECIAL, 20, 1, 20)
  static readonly SPILL = new DefimonSkill("Spill", "Spill iced tea on opponent's jean.", AttackType.NORMAL, 2.5, 0.9, 4.90)
  static readonly SCREAM = new DefimonSkill("Scream", "Sings an anime opening with an painful voice", AttackType.NORMAL, 15, 1, 3.5)

  constructor(public readonly name: string,
              public readonly description: string,
              public readonly attackType: AttackType,
              public readonly power: number,
              public readonly accuracy: number,
              public readonly spCost: number
  ) {
  }
}

