import styled from 'styled-components'
import TabletButton from '../../interfaces/buttons/TabletButton'
import { useAppSelector } from '../../hooks'
import { GameState } from '../../stores/GameStore'

const Backdrop = styled.div`
  padding: 0;
  height: 100%;
  width: 100%;
  flex-direction: column;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
`

const Wrapper = styled.form`
  padding: 10px 10px;
  text-align: center;
  position: relative;
  background: black;
  font-family: 'VT323';
  z-index: 2;
  margin: 20px;
  display: flex;
  flex-direction: column;
  z-index: 99;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: double 0.75rem #555;
  box-shadow: inset 0 0.25rem 0 #555, 0 0.25rem 0 #555;
  background-color: #fff;
  color: #555;
  width: 600px;
  min-height: 200px;
  @media (max-width: 700px) {
    margin: 20px;
    width: calc(100% - 10px);
    height: auto;
    min-height: 300px;
    padding: 20px;
  }
`

const QuizImage = styled.img`
  max-width: 250px;
  max-height: 250px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  filter: gray;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
`

const Heading = styled.p`
  margin: 5px;
  font-size: 30px;
  color: #555;
  text-align: center;
`

const ButtonRow = styled.div`
  display: flex;
  width: 100%;
  padding-top: 10px;
`

const Button = styled.div`
  text-align: center;
  position: relative;
  background: black;
  font-family: 'VT323';
  z-index: 2;
  margin: 5px;
  display: flex;
  flex-direction: column;
  z-index: 99;
  padding: 5px;
  border-radius: 0.5rem;
  border: double 2px #555;
  box-shadow: inset 0 1px 0 #555, 0 1px 0 #555;
  background-color: #fff;
  color: #555;
  font-size: 26px;
  width: 50%;
`

export default function GuessThatDefimonUI() {
  const gameState = useAppSelector((state) => state.game.gameState)

  if (gameState === GameState.GuessThatDefimon) {
    return (
      <>
        <TabletButton />

        <Backdrop>
          <Wrapper>
            <Heading>Guess that Defimon</Heading>
            <QuizImage src="/assets/guess-the-defimon/1.png" />
            <ButtonRow>
              <Button>Cat</Button>
              <Button>Fox</Button>
            </ButtonRow>
            <ButtonRow>
              <Button>Manticore</Button>
              <Button>Beaver</Button>
            </ButtonRow>
          </Wrapper>
        </Backdrop>
      </>
    )
  } else return <></>
}
