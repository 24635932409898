import Phaser from 'phaser'
import Player from './Player'
import isTouchScreen from '../utils/isTouchScreen'
import NPCPathFinder from './NPCPathFinder'
import MyPlayer from './MyPlayer'
import { getValuesClosestToPointFive, get_fromToDirectionValues } from '../util'

export default class NPC extends Player {
  private targetPosition: [number, number]
  private lastUpdateTimestamp?: number
  private playContainerBody: Phaser.Physics.Arcade.Body
  private dialogBox!: Phaser.GameObjects.Container
  public lastContainerX = 0 // these 'last' and 'current' container values are used to determine if the NPC is moving (to set respective animations)
  public lastContainerY = 0
  public currentContainerX = 0
  public currentContainerY = 0
  public npcPathFinder: NPCPathFinder | null = null
  public pathFindingData: any

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    id: string,
    name: string,
    map: string,
    frame?: string | number,
    pathFindingData?: any
  ) {
    super(scene, x, y, texture, id, null, name, false, false, map, frame)
    this.targetPosition = [x, y]

    this.playerName.setText(name)
    this.setDepth(-1)
    this.playContainerBody = this.playerContainer.body as Phaser.Physics.Arcade.Body
    this.pathFindingData = pathFindingData;
    this.handlePathFinding()
  }

  onOpenPlayerDialogue(myPlayer: MyPlayer): void {
    this.facePlayer(myPlayer)

    if (this.npcPathFinder) {
      this.npcPathFinder.onOpenPlayerDialogue(myPlayer)
    }
  }

  facePlayer(myPlayer: MyPlayer): void {
    const fromPos = new Phaser.Math.Vector2(this.x, this.y)
    const toPos_player = new Phaser.Math.Vector2(myPlayer.x, myPlayer.y)
    const [, , npcToPlayerDirection] = get_fromToDirectionValues(fromPos, toPos_player)

    const [rvx, rvy] = getValuesClosestToPointFive(npcToPlayerDirection.x, npcToPlayerDirection.y)
    if (rvx > 0) {
      this.play(`${this.playerTexture}_idle_right`, true)
    } else if (rvx < 0) {
      this.play(`${this.playerTexture}_idle_left`, true)
    } else if (rvy > 0) {
      this.play(`${this.playerTexture}_idle_down`, true)
    } else if (rvy < 0) {
      this.play(`${this.playerTexture}_idle_up`, true)
    }
  }

  handlePathFinding(): void {
   if (this.pathFindingData) {
      this.npcPathFinder = new NPCPathFinder(this, this.pathFindingData)
    }
  }

  onOverlapDialog() {
    if (isTouchScreen()) {
      this.updateDialogBubble('Tap button to interact')
    } else {
      this.updateDialogBubble('Press E to interact')
    }
  }

  // add texts into dialog box container
  setDialogBox(text: string) {
    const innerText = this.scene.add
      .text(0, 0, text)
      .setFontFamily('Arial')
      .setFontSize(12)
      .setColor('#000000')

    // set dialogBox slightly larger than the text in it
    const dialogBoxWidth = innerText.width + 4
    const dialogBoxHeight = innerText.height + 2
    const dialogBoxX = this.x - dialogBoxWidth * 0.5
    const dialogBoxY = this.y + this.height * 0.5

    this.dialogBox.add(
      this.scene.add
        .graphics()
        .fillStyle(0xffffff, 1)
        .fillRoundedRect(dialogBoxX, dialogBoxY, dialogBoxWidth, dialogBoxHeight, 3)
        .lineStyle(1.5, 0x000000, 1)
        .strokeRoundedRect(dialogBoxX, dialogBoxY, dialogBoxWidth, dialogBoxHeight, 3)
    )
    this.dialogBox.add(innerText.setPosition(dialogBoxX + 2, dialogBoxY))
  }
  updateNPC(field: string, value: number | string | boolean) {
    switch (field) {
      case 'name':
        if (typeof value === 'string') {
          this.playerName.setText(value)
        }
        break

      case 'x':
        if (typeof value === 'number') {
          this.targetPosition[0] = value
        }
        break

      case 'y':
        if (typeof value === 'number') {
          this.targetPosition[1] = value
        }
        break

      case 'anim':
        if (typeof value === 'string') {
          this.anims.play(value, true)
        }
        break
    }
  }

  updatePlayerNamePosition(): void {
    this.playerName.setPosition(this.x, this.y - 32)
  }

  destroy(fromScene?: boolean) {
    this.playerContainer.destroy()
    this.playerName.destroy()
    super.destroy(fromScene)
  }

  /** preUpdate is called every frame for every game object. */
  preUpdate(t: number, dt: number) {
    super.preUpdate(t, dt)

    // if Phaser has not updated the canvas (when the game tab is not active) for more than 1 sec
    // directly snap player to their current locations
    if (this.lastUpdateTimestamp && t - this.lastUpdateTimestamp > 750) {
      this.lastUpdateTimestamp = t
      this.x = this.targetPosition[0]
      this.y = this.targetPosition[1]
      this.playerContainer.x = this.targetPosition[0]
      this.playerContainer.y = this.targetPosition[1] - 30
      return
    }

    this.lastUpdateTimestamp = t
    this.setDepth(-1) // change player.depth based on player.y

    const speed = 200 // speed is in unit of pixels per second
    const delta = (speed / 1000) * dt // minimum distance that a player can move in a frame (dt is in unit of ms)
    let dx = this.targetPosition[0] - this.x
    let dy = this.targetPosition[1] - this.y

    this.lastContainerX = this.currentContainerX
    this.lastContainerY = this.currentContainerY

    // if the player is close enough to the target position, directly snap the player to that position
    if (Math.abs(dx) < delta) {
      this.x = this.targetPosition[0]
      this.playerContainer.x = this.targetPosition[0]
      dx = 0
    }
    if (Math.abs(dy) < delta) {
      this.y = this.targetPosition[1]
      this.playerContainer.y = this.targetPosition[1] - 30
      dy = 0
    }

    // if the player is still far from target position, impose a constant velocity towards it
    let vx = 0
    let vy = 0
    if (dx > 0) vx += speed
    else if (dx < 0) vx -= speed
    if (dy > 0) vy += speed
    else if (dy < 0) vy -= speed

    //set last position
    this.currentContainerX = this.playerContainer.x
    this.currentContainerY = this.playerContainer.y

    // update character velocity
    this.setVelocity(vx, vy)
    this.body.velocity.setLength(speed)
    // also update playerNameContainer velocity
    this.playContainerBody.setVelocity(vx, vy)
    this.playContainerBody.velocity.setLength(speed)

    if (this.npcPathFinder) {
      this.npcPathFinder.preUpdate(t, dt)
    }
  }
}
