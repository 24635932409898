import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { dialogueConfig } from '../dialogue/dialogue-data'
import { NPCData } from '../dialogue/npc-data'
// import phaserGame from '../PhaserGame'
// import Game from '../maps/Intro'

export enum MessageType {
  single = 'SINGLE',
  options = 'OPTIONS',
}

interface MessageState {
  isDisabled: boolean
  messageDialogOpen: boolean
  messages: any
  characterName: string
  onDialogEnded: any
  currentNPC: NPCData | null | undefined
  conversation: number
  messageType: MessageType
}

const initialState: MessageState = {
  isDisabled: false,
  messageDialogOpen: false,
  messages: [],
  characterName: '',
  onDialogEnded: null,
  currentNPC: null,
  conversation: 0,
  messageType: MessageType.single,
}

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    startConversation: (
      state,
      action: PayloadAction<{ npc: any; conversation: number; messageNumber: number }>
    ) => {
      // const game = phaserGame.scene.keys.game as Game

      state.currentNPC = action.payload.npc

      if (state.currentNPC) {
        const dialogData = dialogueConfig[state.currentNPC.key]
        state.characterName = state.currentNPC.name
        state.messageDialogOpen = true
        state.messageType = MessageType.single
        state.conversation = action.payload.conversation
        state.messages =
          dialogData[action.payload.conversation].elements[action.payload.messageNumber].npc
        state.onDialogEnded = dialogData[action.payload.conversation].elements[
          action.payload.messageNumber
        ].character
          ? dialogData[action.payload.conversation].elements[action.payload.messageNumber].character
          : null
      }
    },
    openMessageDialog: (
      state,
      action: PayloadAction<{
        messages: any
        characterName: string
        onDialogEnded: any
        messageType: MessageType
      }>
    ) => {
      // const game = phaserGame.scene.keys.game as Game

      state.messageDialogOpen = true

      state.messages = action.payload.messages
      state.messageType = MessageType.single
      state.characterName = action.payload.characterName
      state.onDialogEnded = action.payload.onDialogEnded
      state.messageType = action.payload.messageType
    },
    closeMessageDialog: (state) => {
      // Tell server the Message dialog is closed.
      state.messageDialogOpen = false
      state.messages = []
      state.characterName = ''
      state.onDialogEnded = null
      state.currentNPC = null
    },
    setMessageType: (state, action: PayloadAction<MessageType>) => {
      state.messageType = action.payload
    },
    setMessageDisabled: (state, action: PayloadAction<boolean>) => {
      state.isDisabled = action.payload
    },
  },
})

export const {
  closeMessageDialog,
  openMessageDialog,
  startConversation,
  setMessageType,
  setMessageDisabled,
} = messageSlice.actions

export default messageSlice.reducer
