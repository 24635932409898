import phaserGame from '../../PhaserGame'
import Game from '../../scenes/Game'

export default function getDistance(npcId: string) {
  const currentScene = phaserGame.scene.keys.game as Game

  const npc = Object.values(currentScene.npcs.children.entries).find(
    (npc: any) => npc.playerId === npcId
  )?.body.position
  const player = currentScene.myPlayer

  if (npc) {
    return Math.hypot(npc.x - player.x, npc.y - player.y)
  } else return 0
}
