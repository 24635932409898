import { Effect } from "./effect";
import { DefimonBattleStatsModifier, DefimonStatsBaseData } from "./stats";
import { DefimonSkill } from "./skills";
import { getRandomNumberFromArray } from "../helpers/dataHelpers";

export enum AttackType {
  NORMAL, SPECIAL
}

export class Defimon {
  constructor(readonly name: string,
              readonly skills: DefimonSkill[],
              readonly statsBaseData: DefimonStatsBaseData,
              readonly id: number
  ) {
  }

  getSpeed(): number {
    return this.statsBaseData.speed
  }

  getTotalHealthPoints(): number {
    return this.statsBaseData.health
  }

  getTotalStaminaPoints() {
    return this.statsBaseData.stamina
  }
}

export class BattleDefimon extends Defimon {
  private readonly statsModifier: DefimonBattleStatsModifier
  private appliedEffects: Effect[] = [];
  private healthPoints: number
  private staminaPoints: number

  constructor(defimon: Defimon) {
    super(defimon.name, defimon.skills, defimon.statsBaseData, defimon.id)

    this.healthPoints = this.statsBaseData.health
    this.staminaPoints = this.statsBaseData.stamina
    this.statsModifier = new DefimonBattleStatsModifier(0, 0, 0, 0, 0, 0, 0)
  }

  isDead(): boolean {
    return this.healthPoints <= 0
  }

  getSpeed(): number {
    return super.getSpeed() + this.statsModifier.speedModifier
  }

  getTotalHealthPoints(): number {
    return super.getTotalHealthPoints() + this.statsModifier.healthModifier
  }

  getTotalStaminaPoints(): number {
    return super.getTotalStaminaPoints() + this.statsModifier.staminaModifier
  }

  getHealthPercent(): number {
    return this.healthPoints / this.getTotalHealthPoints();
  }

  getStaminaPercent(): number {
    return this.staminaPoints / this.getTotalStaminaPoints();
  }

  pickRandomSkill() : DefimonSkill {
    return  getRandomNumberFromArray(this.skills)
  }

  tickEffects() {
    this.appliedEffects = this.appliedEffects.filter(e => e.tick(this))
  }

  takeAttack(skill: DefimonSkill, defimon: BattleDefimon) {
    let damageDealt: number = skill.power;
    switch (skill.attackType) {
      case AttackType.NORMAL:
        damageDealt *= (1 + defimon.statsBaseData.attack) * this.statsBaseData.defense
        break
      case AttackType.SPECIAL:
        damageDealt *= (1 + defimon.statsBaseData.specialAttack) * this.statsBaseData.specialDefence
        break
    }
    this.healthPoints -= damageDealt
    console.log(this.name + " lost " + damageDealt + " HP. (" + this.healthPoints + " / " + this.statsBaseData.health + " HP left)")
  }

  restoreHealth(healthPoints: number) {
    this.healthPoints += healthPoints
  }

  restoreHealthCompletely() {
    this.healthPoints = this.statsBaseData.health
  }

  restoreStamina(staminaPoints: number) {
    this.staminaPoints += staminaPoints
  }

  restoreStaminaCompletely() {
    this.staminaPoints = this.statsBaseData.stamina
  }

  spendStamina(cost: number) {
    this.staminaPoints -= cost
  }

  gambleSuccessfulAttack(skill: DefimonSkill): boolean {
    return Math.random() < skill.accuracy * (this.statsBaseData.accuracy / this.statsBaseData.evasion)
  }
}
