import { BigNumber } from "ethers"

export const parseNumber = (value: BigNumber | number | string): number => {
  if (value === null || value === undefined) return value;

  if (BigNumber.isBigNumber(value)) return value.toNumber()

  switch (typeof value) {
    case 'string':
      return parseInt(value)
  
    default:
      return value
  }
}

export function getRandomNumberBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const findInMap = (map, val) => {
  for (let v of map.values()) {
    if (v.name === val) {
      return true
    }
  }
  return false
}

export function getRandomNumberFromArray(array){
  return array[Math.floor(Math.random()*array.length)];
}