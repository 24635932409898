import { useState } from 'react'
import styled from 'styled-components'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import Navigation from '../../components/tablet/Navigation'
import Profile from './Profile'
import FriendsList from './FriendsList'
import Inventory from './Inventory'
import Encyclopedia from './Encyclopedia'
import OutfitSelector from './OutfitSelector'
import { useAppDispatch } from '../../hooks'
import { setActiveMenu } from '../../stores/MenuStore'
import { setFocused, setTouch } from '../../stores/UserStore'
import Border from '../../components/shared/Border'
import QuestsList from './QuestsList'

const Backdrop = styled.div`
  font-family: 'VT323';
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
`

const FabWrapper = styled.div`
  margin-top: auto;
  margin-bottom: 0px;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
`

const Body = styled.div`
  background: rgb(248, 248, 248) none repeat scroll 0% 0%;
  border-bottom: 4px solid rgb(216, 213, 237);
  padding: 20px;
  height: 100%;
`

export const MenuWrapper = styled.div`
  position: fixed;
  padding: 0;
  margin: 0;
  height: calc(100% - 80px);
  width: 450px;
  @media (max-width: 700px) {
    width: 100%;
    height: 100%;
  }
`

const options = [
  { value: 'profile', label: 'Profile' },
  { value: 'friends', label: 'Friends' },
  { value: 'quests', label: 'Quests' },
  { value: 'inventory', label: 'Inventory' },
  { value: 'outfit-selector', label: 'Outfits' },
  { value: 'close', label: 'Close Tablet' },
]

export default function Tablet() {
  const [activePage, setactivePage] = useState(options[0])
  const dispatch = useAppDispatch()

  let page: JSX.Element

  if (activePage.value === 'profile') {
    page = <Profile active={activePage} setActive={setactivePage} options={options} />
  } else if (activePage.value === 'friends') {
    page = <FriendsList />
  } else if (activePage.value === 'inventory') {
    page = <Inventory />
  } else if (activePage.value === 'quests') {
    page = <QuestsList />
  } else if (activePage.value === 'encyclopedia') {
    page = <Encyclopedia />
  } else if (activePage.value === 'outfit-selector') {
    page = <OutfitSelector active={activePage} setActive={setactivePage} options={options} />
  } else {
    page = <></>
  }

  return (
    <Backdrop>
      <FabWrapper
        onClick={() => {
          dispatch(setActiveMenu(''))
          dispatch(setFocused(false))
          dispatch(setTouch(true))
        }}
      />
      <MenuWrapper>
        <Border>
          <Body>
            <Navigation active={activePage} setActive={setactivePage} options={options} />
            {page}
          </Body>
        </Border>
      </MenuWrapper>
    </Backdrop>
  )
}
