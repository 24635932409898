import { getRandomNumberFromArray } from '../../helpers/dataHelpers'
import store from '../../stores'
import { startConversation } from '../../stores/MessageStore'
import { getCompletedQuests } from '../../stores/QuestStore'

import { NPCdata } from '../npc-data'

export default function HelperAkira(npc, scene, dialogue?: number) {
  // RELATED QUESTS ARE  = [4]
  let completedQuests = getCompletedQuests()
  
  let conversation = 0 // STARTS 4TH QUEST

  if (completedQuests && completedQuests.includes(4)) {
    conversation = getRandomNumberFromArray([3])
  }

  if (npc.playerId === 'helper-akira') {
    store.dispatch(
      startConversation({
        npc: NPCdata.find((current) => current.id === npc.playerId),
        conversation: dialogue ? dialogue : conversation,
        messageNumber: 1,
      })
    )
  }
}
