import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const warningTextSlice = createSlice({
  name: 'warningText',
  initialState: {
    warningText: '',
  },
  reducers: {

    setWarningText: (state, action: PayloadAction<string>) => {
      state.warningText = action.payload
    },
  },
})

export const {  setWarningText } = warningTextSlice.actions

export default warningTextSlice.reducer
