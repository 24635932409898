import {State} from "./state";

import Map = Phaser.Structs.Map;

export class StateMachine {
    private static EMPTY_TRANSITION: Transition[] = [];

    private currentState?: State;
    // keys should be of type State, but there's no way to properly type this in typescript
    // instead we use state.constructor.name
    private readonly transitions: Map<any, Transition[]> = new Map([]);
    private readonly anyTransitions: Transition[] = [];
    private currentTransitions: Transition[] = [];

    constructor(private readonly debug: boolean = false) {
    }

    public is(state: State): boolean {
        return this.currentState === state;
    }

    public tick(dt: number): void {
        const transition = this.getTransition();
        if (transition !== null) {
            this.setState(transition.to);
        }

        if (this.currentState) this.currentState.tick(dt)
    }

    public setState(state: State): void {
        if (this.currentState) {
            if (this.debug) console.log('Exiting state ' + this.currentState.constructor.name);
            this.currentState.onExit();
        }
        this.currentState = state;

        this.currentTransitions = this.transitions.get(this.currentState.constructor.name);
        if (this.currentTransitions === undefined) this.currentTransitions = StateMachine.EMPTY_TRANSITION;

        if (this.debug) console.log('Entering state ' + this.currentState.constructor.name);
        this.currentState.onEnter();
    }

    public addTransition(from: State, to: State, predicate: () => boolean): void {
        let transitions = this.transitions.get(from.constructor.name);
        if (transitions === undefined) {
            transitions = [];
            this.transitions.set(from.constructor.name, transitions);
        }

        transitions.push(new Transition(to, predicate));
    }

    public addAnyTransition(state: State, predicate: () => boolean): void {
        this.anyTransitions.push(new Transition(state, predicate));
    }

    private getTransition(): Transition | null {
        for (let anyTransition of this.anyTransitions) {
            if (anyTransition.condition()) return anyTransition;
        }

        for (let currentTransition of this.currentTransitions) {
            if (currentTransition.condition()) return currentTransition;
        }

        return null;
    }
}

class Transition {
    public condition: () => boolean;
    public to: State;

    constructor(to: State, condition: () => boolean) {
        this.to = to;
        this.condition = condition;
    }
}