import { CONTRACTS, getContract } from "../constants/contracts";

const setApproval = async(account, contract, contractToApprove: CONTRACTS) => {
  const contractToApproveAddress = getContract(contractToApprove).address
  if (contractToApproveAddress) {
    const isApproved = await contract.isApprovedForAll(account, contractToApproveAddress)
    if (!isApproved) {
      const tx = await contract.setApprovalForAll(contractToApproveAddress, true)
      await tx.wait()
    }
  }
}

export default setApproval