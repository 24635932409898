import store from '../stores'
import { setActiveMenu } from '../stores/MenuStore'
import { setFocused, setInteractionAvailable, setTouch } from '../stores/UserStore'
import BakerToro from './npc/BakerToro'
import BankerMasahi from './npc/BankerMasahi'
import BankerMegumi from './npc/BankerMegumi'
import ConstructionWorkerTommy from './npc/ConstructionWorkerTommy'
import DoctorKeisuke from './npc/DoctorKeisuke'
import DoctorYamato from './npc/DoctorYamato'
import FiremanGoro from './npc/FiremanGoro'
import Goop from './npc/Goop'
import GrocerHaruka from './npc/GrocerHaruka'
import HelperAkira from './npc/HelperAkira'
import LostPirate from './npc/LostPirate'
import NurseMary from './npc/NurseMary'
import OfficerKen from './npc/OfficerKen'
import Prisoner from './npc/Prisoner'
import SalesmanEticha from './npc/SalesmanEticha'
import SalesmanKenechi from './npc/SalesmanKenechi'
import SamuraiMusashi from './npc/SamuraiMusashi'
import UncleTony from './npc/UncleTony'
import YoungHunter from './npc/YoungHunter'

export default function handleConversation(npc, map, dialogue?: number) {
  store.dispatch(setFocused(true))
  store.dispatch(setTouch(false))
  store.dispatch(setInteractionAvailable(false))
  store.dispatch(setActiveMenu(''))
  BankerMasahi(npc, map, dialogue)
  BankerMegumi(npc, map, dialogue)
  ConstructionWorkerTommy(npc, map, dialogue)
  DoctorKeisuke(npc, map, dialogue)
  DoctorYamato(npc, map, dialogue)
  FiremanGoro(npc, map, dialogue)
  GrocerHaruka(npc, map, dialogue)
  LostPirate(npc, map, dialogue)
  NurseMary(npc, map, dialogue)
  OfficerKen(npc, map, dialogue)
  UncleTony(npc, map, dialogue)
  YoungHunter(npc, map, dialogue)
  Prisoner(npc, map, dialogue)
  HelperAkira(npc, map, dialogue)
  Goop(npc, map, dialogue)
  BakerToro(npc, map, dialogue)
  SalesmanEticha(npc, map, dialogue)
  SalesmanKenechi(npc, map, dialogue)
  SamuraiMusashi(npc, map, dialogue)
}
