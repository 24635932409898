export function sanitizeId(id: string) {
  let sanitized = id

  if (sanitized.length > 9 && sanitized.endsWith('-ss')) {
    sanitized = sanitized.substring(0, sanitized.length - 3)
  }

  return sanitized.replace(/[^0-9a-z]/gi, 'G')
}

const colorArr = [
  '#7bf1a8',
  '#ff7e50',
  '#9acd32',
  '#daa520',
  '#ff69b4',
  '#c085f6',
  '#1e90ff',
  '#5f9da0',
]

// determine name color by first character charCode
export function getColorByString(string: string) {
  return colorArr[Math.floor(string.charCodeAt(0) % colorArr.length)]
}

export function getAvatarString(name: string) {
  const part = name.split(' ')
  return part.length < 2 ? part[0][0] : part[0][0] + part[1][0]
}

export function get_fromToDirectionValues(fromPos: Phaser.Math.Vector2, toPos: Phaser.Math.Vector2): [Phaser.Math.Vector2, Phaser.Math.Vector2, Phaser.Math.Vector2] {
  const toPos_copy = new Phaser.Math.Vector2(toPos.x, toPos.y);
  const npcToPlayerDirection = toPos_copy.subtract(fromPos).normalize();

  return [toPos, fromPos, npcToPlayerDirection];
}

// This function returns the values in such a way that the closest value to 0.5 is set to 1, and the other to 0.
// Use case: playing the correct animation based on move direction
export function getValuesClosestToPointFive(valueX: number, valueY: number): [number, number] {
  const valueX_positive = Math.abs(valueX);
  const valueY_positive = Math.abs(valueY);

  const valueX_distanceToPointFive = 0.5 - valueX_positive;
  const valueY_distanceToPointFive = 0.5 - valueY_positive;

  if(valueX_distanceToPointFive < valueY_distanceToPointFive) {
    return [valueX * 1, 0];
  } else {
    return [0, valueY * 1];
  }
}
