import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import MenuIcon from '@mui/icons-material/Menu'
import { setFocused, setTouch } from '../../stores/UserStore'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { setActiveMenu } from '../../stores/MenuStore'
import Border from '../../components/shared/Border'
import phaserGame from '../../PhaserGame'
import Game from '../../scenes/Game'

const Backdrop = styled.div`
  margin-top: auto;
  margin-bottom: 0px;
  position: fixed;
  top: 20px;
  right: 20px;
  opacity: 0.9;
`

const Button = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  z-index: 99;
  padding: 13px 15px;
  background-color: #fff;
  color: #000;
  :hover {
    background-color: #ddd;
    cursor: pointer;
  }
`

const Body = styled.div`
  background: rgb(248, 248, 248) none repeat scroll 0% 0%;
  border-bottom: 4px solid rgb(216, 213, 237);
  padding: 0px;

  @media (max-width: 700px) {
    padding: 0px;
  }
`

export default function MenuButton() {
  const inputRef = useRef<HTMLInputElement>(null)
  const isGameScene = useAppSelector((state) => state.game.isGameScene)
  const focused = useAppSelector((state) => state.user.focused)
  const isMultiplayer = useAppSelector((state) => state.game.isMultiplayer)
  const activeMenu = useAppSelector((state) => state.menu.activeMenu)
  const messageDialogOpen = useAppSelector((state) => state.message.messageDialogOpen)
  const timerIsCounting = useAppSelector((state) => state.timer.isCounting)
  const currentScene = phaserGame.scene.keys.game as Game
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (focused) {
      inputRef.current?.focus()
    }
  }, [focused])

  if (isGameScene || messageDialogOpen || !isMultiplayer) return <></>

  return (
    <Backdrop>
      {['chat', ''].includes(activeMenu) && !timerIsCounting  ? (
        <>
          <Border>
            <Body>
              <Button
                color="secondary"
                aria-label="showMenu"
                onClick={() => {
                  dispatch(setActiveMenu('menu'))
                  dispatch(setFocused(true))
                  dispatch(setTouch(false))
                  currentScene.resetNotification()
                }}
              >
                <MenuIcon />
              </Button>
            </Body>
          </Border>
        </>
      ) : (
        <></>
      )}
    </Backdrop>
  )
}
