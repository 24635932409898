export const METHODS = {
  WALLET_ADD_CHAIN: 'wallet_addEthereumChain',
  WALLET_SWITCH_CHAIN: 'wallet_switchEthereumChain'
}

export const SUPPORTED_BROWSERS = ['chrome', 'chromium', 'firefox'];

export const ERROR_METAMASK = {
  USER_DENIED_TRANSACTION: 4001,
  NOT_FOUND_CHAIN: 4902
}