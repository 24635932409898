import Container = Phaser.GameObjects.Container;
import {KeyHint, CorrectSeqMiniGameKeyHint} from "./CorrectSeqMiniGameKeyHint";
import {Scene} from "phaser";

export class CorrectSeqMiniGameHints extends Container {
    private readonly leftKeyHint: CorrectSeqMiniGameKeyHint;
    private readonly topKeyHint: CorrectSeqMiniGameKeyHint;
    private readonly rightKeyHint: CorrectSeqMiniGameKeyHint;
    private readonly bottomKeyHint: CorrectSeqMiniGameKeyHint;

    constructor(keyHints: KeyHint[], scene: Scene, validateKey: (keyHint: KeyHint) => void) {
        super(scene);

        if (keyHints.length !== 4) throw Error('Need 4 key hints, only has ' + keyHints.length);

        this.leftKeyHint = new CorrectSeqMiniGameKeyHint(keyHints[0], scene, () => validateKey(keyHints[0]));
        this.topKeyHint = new CorrectSeqMiniGameKeyHint(keyHints[1], scene, () => validateKey(keyHints[1]));
        this.rightKeyHint = new CorrectSeqMiniGameKeyHint(keyHints[2], scene, () => validateKey(keyHints[2]));
        this.bottomKeyHint = new CorrectSeqMiniGameKeyHint(keyHints[3], scene, () => validateKey(keyHints[3]));

        this.leftKeyHint.setPosition(0, 0);
        this.topKeyHint.setPosition(50, -35);
        this.rightKeyHint.setPosition(100, 0);
        this.bottomKeyHint.setPosition(50, 35);

        this.add(this.leftKeyHint);
        this.add(this.topKeyHint);
        this.add(this.rightKeyHint);
        this.add(this.bottomKeyHint);

        scene.add.existing(this);
    }

    triggerPressVisuals(keyHint: KeyHint) {
        this.leftKeyHint.triggerPressVisuals(keyHint);
        this.rightKeyHint.triggerPressVisuals(keyHint);
        this.topKeyHint.triggerPressVisuals(keyHint);
        this.bottomKeyHint.triggerPressVisuals(keyHint);
    }
}