import React from 'react'
import styled from 'styled-components'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { closeWhiteboardDialog } from '../../stores/WhiteboardStore'
import { setFocused, setTouch } from '../../stores/UserStore'
import { setActiveMenu } from '../../stores/MenuStore'

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Wrapper = styled.div`
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  background: #222639;
  border-radius: 5px;
  padding: 16px;
  color: #eee;
  position: relative;
  display: flex;
  flex-direction: column;

  .close {
    position: absolute;
    top: 16px;
    right: 16px;
  }
`

const WhiteboardWrapper = styled.div`
  flex: 1;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 50px;
  iframe {
    width: 100%;
    height: 100%;
  }
`

export default function Whiteboard() {
  const whiteboardUrl = useAppSelector((state) => state.whiteboard.whiteboardUrl)
  const dispatch = useAppDispatch()
  return (
    <Backdrop>
      <Wrapper>
        <IconButton
          aria-label="close dialog"
          className="close"
          onClick={() => {
            dispatch(closeWhiteboardDialog())
            dispatch(setActiveMenu(''))
            dispatch(setFocused(false))
            dispatch(setTouch(true))
          }}
        >
          <CloseIcon />
        </IconButton>
        {whiteboardUrl && (
          <WhiteboardWrapper>
            <iframe title="white board" src={whiteboardUrl} />
          </WhiteboardWrapper>
        )}
      </Wrapper>
    </Backdrop>
  )
}
