import NPC from './NPC'
import Phaser from 'phaser'
import phaserGame from '../PhaserGame'
import Game from '../scenes/Game'

export enum DetectorNpcDirection {
  LEFT,
  UP,
  RIGHT,
  DOWN,
}

export type SequenceItem = Readonly<{
  direction: DetectorNpcDirection
  duration: number
  range: number
}>

export class DetectorNpc extends NPC {
  private static readonly DETECTOR_RAY_THICKNESS = 100

  private currentSequenceItemIndex: number = 0
  private remainingTime: number

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    id: string,
    name: string,
    map: string,
    private readonly onPlayerDetected: () => void,
    private readonly sequence: SequenceItem[],
    frame?: string | number
  ) {
    super(scene, x, y, texture, id, name, map, frame)

    if (sequence.length === 0) throw Error("Can't create a detector NPC with no sequence")

    this.remainingTime = sequence[0].duration
    this.updateSpriteDirection(sequence[0].direction)
  }

  preUpdate(t: number, dt: number) {
    super.preUpdate(t, dt)

    this.updateSequence(dt)
    this.performDetection()
  }

  private performDetection() {
    const game = phaserGame.scene.keys.game as Game
    const player = game.myPlayer
    switch (this.sequence[this.currentSequenceItemIndex].direction) {
      case DetectorNpcDirection.LEFT:
        if (Math.abs(player.y - this.y) > DetectorNpc.DETECTOR_RAY_THICKNESS / 2) return
        if (player.x > this.x) return
        if (Math.abs(player.x - this.x) > this.sequence[this.currentSequenceItemIndex].range) return
        return this.onPlayerDetected()
      case DetectorNpcDirection.UP:
        if (Math.abs(player.x - this.x) > DetectorNpc.DETECTOR_RAY_THICKNESS / 2) return
        if (player.y > this.y) return
        if (Math.abs(player.y - this.y) > this.sequence[this.currentSequenceItemIndex].range) return
        return this.onPlayerDetected()
      case DetectorNpcDirection.RIGHT:
        if (Math.abs(player.y - this.y) > DetectorNpc.DETECTOR_RAY_THICKNESS / 2) return
        if (player.x < this.x) return
        if (Math.abs(player.x - this.x) > this.sequence[this.currentSequenceItemIndex].range) return
        return this.onPlayerDetected()
      case DetectorNpcDirection.DOWN:
        if (Math.abs(player.x - this.x) > DetectorNpc.DETECTOR_RAY_THICKNESS / 2) return
        if (player.y < this.y) return
        if (Math.abs(player.y - this.y) > this.sequence[this.currentSequenceItemIndex].range) return
        return this.onPlayerDetected()
    }
  }

  private updateSequence(dt: number) {
    this.remainingTime -= dt
    if (this.remainingTime > 0) return

    this.currentSequenceItemIndex++
    if (this.currentSequenceItemIndex >= this.sequence.length) {
      this.currentSequenceItemIndex = 0
    }
    const newSequenceItem = this.sequence[this.currentSequenceItemIndex]
    this.remainingTime = newSequenceItem.duration

    this.updateSpriteDirection(newSequenceItem.direction)
  }

  private updateSpriteDirection(direction: DetectorNpcDirection) {
    switch (direction) {
      case DetectorNpcDirection.LEFT:
        this.play(`${this.playerTexture}_idle_left`, true)
        break
      case DetectorNpcDirection.UP:
        this.play(`${this.playerTexture}_idle_up`, true)
        break
      case DetectorNpcDirection.RIGHT:
        this.play(`${this.playerTexture}_idle_right`, true)
        break
      case DetectorNpcDirection.DOWN:
        this.play(`${this.playerTexture}_idle_down`, true)
        break
    }
  }
}
