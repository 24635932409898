import MyPlayer from '../../characters/MyPlayer'
import store from '../../stores'
import network from '../../services/Network'
import phaserGame from '../../PhaserGame'
import Game from '../Game'
import {
  handleCollectibleOverlap,
  handleItemSelectorOverlap,
  handleNPCOverlap,
  handlePlayerOverlap,
  handlePlayerTeleportZoneOverlap,
} from '.'
import OtherPlayer from '../../characters/OtherPlayer'
import { IPlayer } from '../../../../types/IOfficeState'
import NPC from '../../characters/NPC'
import { gameConfig } from '../../assets/game-data'
import Goop from '../../characters/Goop'
import { DetectorNpc } from '../../characters/Detector'
import { NPCType } from '../../dialogue/npc-data'

export interface INPCConfig {
  name: string
  texture: string
  id: string
  type: NPCType
  spawnPoint: {
    x: number
    y: number
    frame?: string
  }
  pathFindingData?: any
}

export function spawnMyPlayer(x: number, y: number, frame: string) {
  const game = phaserGame.scene.keys.game as Game
  const { name, texture, videoConnected, loggedIn } = store.getState().user
  // create MyPlayer class
  game.myPlayer = new MyPlayer(
    game,
    x,
    y,
    texture || 'Blue Cap Boy',
    network.mySessionId || '',
    network.webRTCId,
    name,
    loggedIn,
    videoConnected,
    game.mapName
  )

  // set camera to follow
  game.cameras.main.startFollow(game.myPlayer, true)

  // handle interactions with teleports
  game.physics.add.overlap(
    game.myPlayer,
    game.teleportZoneGroup,
    handlePlayerTeleportZoneOverlap,
    undefined,
    game
  )

  // handle interactions with NPCs
  game.physics.add.overlap(game.myPlayer, game.npcs, handleNPCOverlap, undefined, game)

  // handle interactions with players
  game.physics.add.overlap(game.myPlayer, game.otherPlayers, handlePlayerOverlap, undefined, game)

  // handle interactions with items
  game.physics.add.overlap(
    game.playerSelector,
    [
      game.chairGroup,
      game.computerGroup,
      game.whiteboardGroup,
      game.unlockableGroup,
      game.infoGroup,
    ],
    handleItemSelectorOverlap,
    undefined,
    game
  )

  // handle interactions with collectibles
  game.physics.add.overlap(
    game.myPlayer,
    game.collectibleGroup,
    handleCollectibleOverlap,
    undefined,
    game
  )

  // set anims
  const parts: any = game.myPlayer.anims.currentAnim.key.split('_')
  parts[2] = frame
  game.myPlayer.play(parts.join('_'), true)
}

export function spawnOtherPlayer(data: IPlayer, key: string) {
  const game = phaserGame.scene.keys.game as Game
  const otherPlayer = new OtherPlayer(
    game,
    data.x,
    data.y,
    data.anim.split('_')[0],
    key,
    data.webRTCId,
    data.name,
    data.readyToConnect,
    data.videoConnected,
    data.map
  )

  game.otherPlayers.add(otherPlayer)
  game.otherPlayerMap.set(key, otherPlayer)
}

export function spawnNPC(data: INPCConfig) {
  const game = phaserGame.scene.keys.game as Game
  const npc = new NPC(
    game,
    data.spawnPoint.x,
    data.spawnPoint.y,
    data.texture,
    data.id,
    data.name,
    game.mapName,
    undefined,
    data.pathFindingData
  )

  const parts: any = npc.anims.currentAnim.key.split('_')
  parts[2] = data.spawnPoint.frame ? data.spawnPoint.frame : 'down'
  npc.play(parts.join('_'), true)

  game.npcs.add(npc)
}

export function spawnGoop(data: INPCConfig) {
  const game = phaserGame.scene.keys.game as Game
  const npc = new Goop(
    game,
    data.spawnPoint.x,
    data.spawnPoint.y,
    data.texture,
    data.id,
    data.name,
    game.mapName,
    undefined,
    data.pathFindingData
  )

  const parts: any = npc.anims.currentAnim.key.split('_')
  parts[2] = data.spawnPoint.frame ? data.spawnPoint.frame : 'down'
  npc.play(parts.join('_'), true)

  if (game.npcs) {
    game.npcs.add(npc)
  }
}

export function spawnDetectorNPC(data: INPCConfig, callBack: any, pathFindingData: any) {
  const game = phaserGame.scene.keys.game as Game

  const npc = new DetectorNpc(
    game,
    data.spawnPoint.x,
    data.spawnPoint.y,
    data.texture,
    data.id,
    data.name,
    game.mapName,
    () => callBack(),
    pathFindingData,
    undefined
  )

  const parts: any = npc.anims.currentAnim.key.split('_')
  parts[2] = data.spawnPoint.frame ? data.spawnPoint.frame : 'down'
  npc.play(parts.join('_'), true)

  if (game.npcs) {
    game.npcs.add(npc)
  }
}

export function spawnNPCs() {
  const game = phaserGame.scene.keys.game as Game
  const npcData: INPCConfig[] = gameConfig.maps[game.currentMap].npcs

  for (let i = 0; i < npcData.length; i++) {
    spawnNPC(npcData[i])
  }
}

export async function spawnOtherPlayers() {
  const game = phaserGame.scene.keys.game as Game
  const isMultiplayer = store.getState().game.isMultiplayer
  const data = await network.players()

  if (data && isMultiplayer) {
    for (const [key, value] of data.entries()) {
      if (key !== game.myPlayer.playerId && value.map === game.mapName) {
        spawnOtherPlayer(value, key)
      }
    }
  }
}
