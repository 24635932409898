import { Team } from "../battles/team";
import { Defimon } from "../battles/defimons";
import { BattleManager } from "../battles/battle-manager";
import { DefimonStatsBaseData } from "../battles/stats";
import { DefimonSkill } from "../battles/skills";
import { ActiveBattleDefimonUI } from "../battles/ui/active-battle-defimon-ui";
import { PlayerControlsUI } from "../battles/ui/player-controls-ui";
import { MonstersList } from "../constants/monsters";
import { Item } from "../battles/items";
import {DialogueManager} from "../battles/ui/dialogue/DialogueManager";

export type IDefimonBattleSceneData = Readonly<{
  playerName: string
}>;

export enum ActionType {
  ATTACK = "ATTACK",
  SWAP = "SWAP DEFIMON",
  ITEM = "USE OBJECT",
  RUNAWAY = "RUN AWAY !",
}

export default class DefimonBattle extends Phaser.Scene {
  static KEY = 'DefimonBattle'

  private battleManager!: BattleManager;

  constructor() {
    super(DefimonBattle.KEY);
  }

  init() {
  }

  preload() {
  }

  async create(data: IDefimonBattleSceneData) {
    const viewportWidth = this.cameras.main.width;
    const viewportHeight = this.cameras.main.height;
    const playerDefimonXPercent = 0.29;
    const playerDefimonYPercent = 0.675;
    const opponentDefimonXPercent = 0.72;
    const opponentDefimonYPercent = 0.65;

    this.add.rectangle(viewportWidth / 2, viewportHeight / 2, viewportWidth, viewportHeight, 0x000000);
    const bg = this.add.image(viewportWidth / 2, viewportHeight / 2, 'fight-background');
    const scale = viewportWidth / bg.width;
    bg.setScale(scale);

    const playerControlsUI = new PlayerControlsUI(this)
    playerControlsUI.setPosition(viewportWidth - playerControlsUI.width, viewportHeight - playerControlsUI.height);

    const playerDefimonUI = new ActiveBattleDefimonUI(viewportWidth * 0.25, 100, playerDefimonXPercent * viewportWidth, playerDefimonYPercent * viewportHeight, true, scale, this);
    playerDefimonUI.setPosition(playerDefimonUI.width, playerDefimonUI.height);
    const opponentDefimonUI = new ActiveBattleDefimonUI(viewportWidth * 0.25, 100, opponentDefimonXPercent * viewportWidth, opponentDefimonYPercent * viewportHeight, false, scale, this);
    opponentDefimonUI.setPosition(viewportWidth - playerDefimonUI.width, playerDefimonUI.height);

    const playerTeam = new Team(data.playerName,
      new Defimon("Kahi", [DefimonSkill.SPILL, DefimonSkill.PUN], new DefimonStatsBaseData(
        75, 100, .5, .25, .5, .9, 0.8,
      ), MonstersList.KAHI.id),
      new Defimon("Toadle", [DefimonSkill.SCREAM, DefimonSkill.PUN], new DefimonStatsBaseData(
        90, 80, .15, .50, .1, .85, 0.6
      ), MonstersList.TOADLE.id),
    )

    const opponentTeam = new Team("Jesse from Team Planet",
      new Defimon("Marubee", [DefimonSkill.SLAP, DefimonSkill.SPLIT], new DefimonStatsBaseData(
        100, 50, .25, .75, 1, .75, 0.75, 0.9
      ), MonstersList.MARUBEE.id),
      new Defimon("Spwider", [DefimonSkill.SLAP], new DefimonStatsBaseData(
        100, 50, .25, .35, 1.5, .95, 0.5, 0.9
      ), MonstersList.SPWIDER.id),
    )

    playerTeam.addToInventory(Item.RING_LUCK, 3)
    playerTeam.addToInventory(Item.HEALTH_PACK_S, 13)
    playerTeam.addToInventory(Item.STAMINA_PACK_L, 1)
    playerTeam.addToInventory(Item.COMBO_PACK_L, 3)
    playerTeam.addToInventory(Item.STAMINA_PACK_M, 4)
    playerTeam.addToInventory(Item.HEALTH_PACK_M, 6)
    playerTeam.addToInventory(Item.COMBO_PACK_M, 17)
    playerTeam.addToInventory(Item.SAKURABO_FRUIT, 4)
    playerTeam.addToInventory(Item.BANANA_FRUIT, 5)
    playerTeam.addToInventory(Item.RINGO_FRUIT, 10)
    playerTeam.addToInventory(Item.MERON_FRUIT, 9)
    playerTeam.addToInventory(Item.PINA_FRUIT, 7)

    Object.values(MonstersList).forEach(defimon => this.anims.create({
      key: `defimon_${defimon.id}_idle`,
      frames: this.anims.generateFrameNumbers(`defimon_${defimon.id}`, {
        start: 0,
        end: defimon.nbFrames - 1
      }),
      frameRate: 8,
      repeat: -1
    }))

    const dialogueManager = new DialogueManager(this);
    dialogueManager.x = dialogueManager.width / 2 + viewportWidth * 0.05;
    dialogueManager.y = viewportHeight - dialogueManager.height / 2;

    this.battleManager = new BattleManager(playerTeam, opponentTeam, playerDefimonUI, opponentDefimonUI, playerControlsUI, dialogueManager, this)
  }

  update(delta: number) {
    this.battleManager.tick(delta);
    // this.playerHealthGauge.setPercent(this.battleManager.playerTeam.currentActiveDefimon.getHealthPercent())
    // this.opponentHealthGauge.setPercent(this.battleManager.opponentTeam.currentActiveDefimon.getHealthPercent())
    // this.playerHealthGauge.update()
    // this.opponentHealthGauge.update()

    // this.currentPlayerDefimonName.text = this.battleManager.playerTeam.currentActiveDefimon.name
    // this.currentOpponentDefimonName.text = this.battleManager.opponentTeam.currentActiveDefimon.name
  }
}