export class DefimonStatsBaseData {
  constructor(readonly health: number,
              readonly stamina: number,
              readonly attack: number,
              readonly defense: number,
              readonly specialAttack: number,
              readonly specialDefence: number,
              readonly speed: number,
              readonly accuracy: number = 1,
              readonly evasion: number = 1) {
  }
}

export class DefimonBattleStatsModifier {
  constructor(readonly healthModifier: number,
              readonly staminaModifier: number,
              readonly attackModifier: number,
              readonly defenseModifier: number,
              readonly specialAtkModifier: number,
              readonly specialDefModifier: number,
              readonly speedModifier: number) {
  }
}

