import getItem from '../helpers/game/getItem'
import { getPageCollectible, Page } from '../items/Page'
import Unlockable from '../items/Unlockable'
import phaserGame from '../PhaserGame'
import Game from '../scenes/Game'
import store from '../stores'
import { MessageType, openMessageDialog } from '../stores/MessageStore'
import { completeCheckpoint } from '../stores/QuestStore'

let collectedPapers = 0
const collectedPaperMessages = [
  `Settlers mint contains information about the original 9500 settlers and their apartments. There are four types of apartments: Small, Medium, Large and Penthouses.`,
  `Larger apartments provide more space for furniture and more slots for monster staking. All apartments owners are eligible for monster raffles, item airdrops etc.`,
  `Apartments allow players to lend their monster staking slots to other players for passive income, thus creating a sustainable economy based on demand.`,
]

let status = [false, false, false]

// Start
export function startSettlersMint() {}

// Update
export function updateSettlersMint() {
  const game = phaserGame.scene.keys.game as Game
  const checkpoints = store.getState().quest.checkpoints
  let userName = store.getState().user.name

  let messageShown = [collectedPaperMessages[collectedPapers]]

  if (collectedPapers > 1) {
    messageShown = [
      collectedPaperMessages[collectedPapers],
      `I should probably get these papers back to Salesman Eticha. He must be worried!`,
    ]
  }

  // Lock Toolbox if unlocked
  const __unlockToolBox = checkpoints.find(
    (checkpoint: any) => checkpoint.name === `unlock-toolbox`
  )?.status

  if (game.currentMap === 'outsideYorokobiTown' && !__unlockToolBox) {
    const toolbox = getItem('worksite-toolbox-1') as Unlockable
    if (toolbox.isUnlocked) {
      toolbox.isUnlocked = false
      toolbox.isOpen = false
      toolbox.setFrame(0)
    }
  }

  const toolboxGame = game.gameObjects.find(
    (gameObject: any) => gameObject.name === 'worksite-toolbox-1-unlock'
  )
  if (toolboxGame && !__unlockToolBox) {
    toolboxGame.on('success', () => {
      status[0] = true
    })
  }

  if (status[0] && !__unlockToolBox) {
    setTimeout(() => {}, 500)
    store.dispatch(completeCheckpoint(`unlock-toolbox`))
    store.dispatch(
      openMessageDialog({
        messages: messageShown,
        characterName: userName,
        onDialogEnded: {
          type: 'function',
          func: () => {
            collectedPapers++
          },
        },
        messageType: MessageType.single,
      })
    )
  }

  // Spawn papers in Clothing store
  const __clothingPapers = checkpoints.find(
    (checkpoint: any) => checkpoint.name === `clothing-papers`
  )?.status

  if (game.currentMap === 'yorokobiClothingStore' && !__clothingPapers) {
    if (status[1]) {
      setTimeout(() => {}, 500)
      store.dispatch(completeCheckpoint(`clothing-papers`))
      store.dispatch(
        openMessageDialog({
          messages: messageShown,
          characterName: userName,
          onDialogEnded: {
            type: 'function',
            func: () => {
              collectedPapers++
            },
          },
          messageType: MessageType.single,
        })
      )
    } else {
      new Page(game, 986, 501, 'page', '0', () => {
        status[1] = true

        const thisPage = getPageCollectible(game, '0')
        if (thisPage) {
          game.collectibleGroup.remove(thisPage, true, true)
        }
      })
    }
  }

  // Spawn papers in Clothing store
  const __cafePapers = checkpoints.find(
    (checkpoint: any) => checkpoint.name === `cafe-papers`
  )?.status

  if (game.currentMap === 'yorokobiCafe' && !__cafePapers) {
    if (status[2]) {
      setTimeout(() => {}, 500)
      store.dispatch(completeCheckpoint(`cafe-papers`))
      store.dispatch(
        openMessageDialog({
          messages: messageShown,
          characterName: userName,
          onDialogEnded: {
            type: 'function',
            func: () => {
              collectedPapers++
            },
          },
          messageType: MessageType.single,
        })
      )
    } else {
      new Page(game, 709, 442, 'page', '1', () => {
        status[2] = true

        const thisPage = getPageCollectible(game, '1')
        if (thisPage) {
          game.collectibleGroup.remove(thisPage, true, true)
        }
      })
    }
  }

  const __collectedAll = checkpoints.find(
    (checkpoint: any) => checkpoint.name === `collected-all`
  )?.status

  // If all three have been collected
  if (__cafePapers && __clothingPapers && __unlockToolBox && !__collectedAll) {
    store.dispatch(completeCheckpoint(`collected-all`))
    store.dispatch(
      openMessageDialog({
        messages: messageShown,
        characterName: userName,
        onDialogEnded: {
          type: 'function',
          func: () => {},
        },
        messageType: MessageType.single,
      })
    )
  }
}

// Complete
export function completeSettlersMint() {}

// Utils
