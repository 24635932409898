import {State} from "../../../../utils/states/state";
import {DialogueManager} from "../DialogueManager";

export class StaticState implements State {
    constructor(private readonly dialogueManager: DialogueManager) {
    }

    onEnter(): void {
        this.dialogueManager.dialogueFinishedCallback(this.dialogueManager.currentLineIndex);
        this.dialogueManager.currentLineIndex++;
    }

    onExit(): void {
    }

    tick(dt: number): void {
    }

}