import Phaser from 'phaser'

export const phaserEvents = new Phaser.Events.EventEmitter()

export enum Event {
  PLAYER_JOINED = 'player-joined',
  PLAYER_UPDATED = 'player-updated',
  PLAYER_LEFT = 'player-left',
  PLAYER_DISCONNECTED = 'player-disconnected',
  MY_PLAYER_READY = 'my-player-ready',
  MY_PLAYER_NAME_CHANGE = 'my-player-name-change',
  MY_PLAYER_TEXTURE_CHANGE = 'my-player-texture-change',
  MY_PLAYER_VIDEO_CONNECTED = 'my-player-video-connected',
  ITEM_USER_ADDED = 'item-user-added',
  ITEM_USER_REMOVED = 'item-user-removed',
  UPDATE_DIALOG_BUBBLE = 'update-dialog-bubble',
  WEATHER_UPDATED = 'weather-updated',
  PLAYER_MOVED_UP = 'player-moved-up',
  PLAYER_MOVED_DOWN = 'player-moved-down',
  PLAYER_MOVED_LEFT = 'player-moved-left',
  PLAYER_MOVED_RIGHT = 'player-moved-right',
  PLAYER_CHANGED_MAP = 'player-changed-map',
}
