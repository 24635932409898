import { PlayerBehavior } from '../../../../types/PlayerBehavior'
import Item from '../../items/Item'
import phaserGame from '../../PhaserGame'
import store from '../../stores'
import { setInteractionAvailable } from '../../stores/UserStore'
import Game from '../Game'

export function handleNPCOverlap(myPlayer, npc) {
  const game = phaserGame.scene.keys.game as Game
  const { interactionAvailable } = store.getState().user
  const { messageDialogOpen, isDisabled } = store.getState().message

  // Make sure timer is not ON
  if (!isDisabled) {
    if (Phaser.Input.Keyboard.JustDown(game.keyE) || game.playerInteraction) {
      npc.clearDialogBubble()
      game.resetNotification()
      game.handleConversation(myPlayer, npc)
    } else {
      if (!messageDialogOpen) {
        npc.onOverlapDialog()
        if (!interactionAvailable) {
          store.dispatch(setInteractionAvailable(true))
          // After 2 seconds, clear the interaction button
          window.setTimeout(() => {
            store.dispatch(setInteractionAvailable(false))
          }, 1000)
        }
      }
    }
  }
}

export function handlePlayerOverlap(myPlayer, otherPlayer) {
  otherPlayer.makeCall()
}

export function handleItemSelectorOverlap(playerSelector, selectionItem) {
  const game = phaserGame.scene.keys.game as Game
  const currentItem = playerSelector.selectedItem as Item

  // currentItem is undefined if nothing was perviously selected
  if (currentItem) {
    // if the selection has not changed, do nothing
    if (currentItem === selectionItem) {
      return
    }
    // if selection changes, clear pervious dialog
    if (game.myPlayer.playerBehavior !== PlayerBehavior.SITTING) {
      currentItem.clearDialogBox()
    }
  }

  // set selected item and set up new dialog
  playerSelector.selectedItem = selectionItem
  selectionItem.onOverlapDialog()
  store.dispatch(setInteractionAvailable(true))
}

export function handleCollectibleOverlap(playerSelector, collectible) {
  if (collectible.type === 'coin') {
    collectible.onOverlap()
  } else if (collectible.type === 'page') {
    collectible.onOverlap()
  }
}

export function handlePlayerTeleportZoneOverlap(myPlayer, teleportZone) {
  const game = phaserGame.scene.keys.game as Game
  const currentScene = game.sceneData.currentMap
  const nextScene = teleportZone.teleportTo
  game.otherPlayerMap.clear()
  game.hasWeather = false
  game.registry.destroy()

  game.sceneData.onLeave(game, currentScene, nextScene)
}
