import React, { useMemo } from 'react'
import { animated, useTransition } from 'react-spring'
import styled from 'styled-components'

const BattleStatus = styled.div`
  font-size: 36px;
  color: #000;
  text-align: left;
`
const Status = ({ message, trail = 35 }) => {
  const items = useMemo(
    () =>
      message
        .trim()
        .split('')
        .map((letter, index) => ({
          item: letter,
          key: index,
        })),
    [message]
  )

  const transitions = useTransition(items, {
    trail,
    from: { display: 'none' },
    enter: { display: '' },
    onRest: (status, controller, item) => {},
  })

  return (
    <BattleStatus>
      {transitions((styles, { item, key }) => (
        <animated.span key={key} style={styles}>
          {item}
        </animated.span>
      ))}
    </BattleStatus>
  )
}

export default Status
