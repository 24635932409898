import { useAppSelector } from '../hooks'
import isTouchScreen from '../utils/isTouchScreen'
import ReactNipple from 'react-nipple'
import phaserGame from '../PhaserGame'
import Game from '../scenes/Game'

export default function VirtualPad() {
  const focused = useAppSelector((state) => state.user.focused)
  const touchEnabled = useAppSelector((state) => state.user.touchEnabled)
  const loggedIn = useAppSelector((state) => state.user.loggedIn)
  const activeMenu = useAppSelector((state) => state.menu.activeMenu)
  const messageDialogOpen = useAppSelector((state) => state.message.messageDialogOpen)
  const game = phaserGame.scene.keys.game as Game

  if (focused || !isTouchScreen() || !touchEnabled || messageDialogOpen) {
    return <></>
  } else if (loggedIn && activeMenu === '') {
    return (
      <div
        style={{
          pointerEvents: 'auto',
        }}
      >
        <ReactNipple
          options={{ mode: 'dynamic', size: 150, position: { bottom: '50%', right: '50%' } }}
          style={{
            width: '100%',
            height: 'calc(100% - 220px)',
            position: 'fixed',
            bottom: 100,
            top: 120,
            right: 0,
          }}
          size={200}
          onEnd={(evt, data) => {
            game.playerMovement = { up: false, down: false, left: false, right: false }
          }}
          onMove={(evt, data) => {
            if (data?.direction?.angle === 'left') {
              game.playerMovement.left = true
            } else {
              game.playerMovement.left = false
            }

            if (data?.direction?.angle === 'right') {
              game.playerMovement.right = true
            } else {
              game.playerMovement.right = false
            }

            if (data?.direction?.angle === 'up') {
              game.playerMovement.up = true
            } else {
              game.playerMovement.up = false
            }
            if (data?.direction?.angle === 'down') {
              game.playerMovement.down = true
            } else {
              game.playerMovement.down = false
            }
          }}
        />
      </div>
    )
  } else return <></>
}
