import Container = Phaser.GameObjects.Container;
import {Scene} from "phaser";
import MyPlayer from "../../characters/MyPlayer";

export class FogOfWarController extends Container {
    private static readonly FOG_OPACITY = 0.9;
    private static readonly FOG_SIZE = 2;

    private readonly fog: Phaser.GameObjects.RenderTexture;
    private readonly vision: Phaser.GameObjects.Image;

    private isActive = false;

    constructor(private readonly player: MyPlayer, scene: Scene) {
        super(scene);

        // make a RenderTexture that is the size of the screen
        this.fog = scene.make.renderTexture({
            width: scene.cameras.main.width,
            height: scene.cameras.main.height
        }, true);

        // fill it with black
        this.fog.fill(0x000000, FogOfWarController.FOG_OPACITY);

        this.fog.depth = 9999999;

        this.vision = scene.make.image({
            x: player.x,
            y: player.y,
            key: 'fogOfWarMask',
            add: false
        });
        this.vision.scale = FogOfWarController.FOG_SIZE;

        this.fog.mask = new Phaser.Display.Masks.BitmapMask(scene, this.vision);
        this.fog.mask.invertAlpha = true;

        this.disableFogOfWar();
    }

    update() {
        if (!this.active) return;
        this.fog.setPosition(this.scene.cameras.main.scrollX, this.scene.cameras.main.scrollY);
        this.vision.setPosition(this.player.x, this.player.y);
    }

    activateFogOfWar() {
        this.isActive = true;
        this.fog.visible = true;
    }

    disableFogOfWar() {
        this.isActive = false;
        this.fog.visible = false;
    }
}