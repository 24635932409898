import phaserGame from '../../PhaserGame'
import Game from '../../scenes/Game'

export default function getItem(itemId: string) {
  const currentScene = phaserGame.scene.keys.game as Game

  const splitStr = itemId.trim().split('-')

  if (['dumpster', 'worksite'].includes(splitStr[0])) {
    const item = Object.values(currentScene.unlockableGroup.children.entries).find(
      (current_item: any) => current_item.name === itemId
    )

    return item
  } else if (splitStr[0] === 'info') {
    console.log(Object.values(currentScene.infoGroup.children.entries))
    const item = Object.values(currentScene.infoGroup.children.entries).find(
      (current_item: any) => current_item.name === itemId
    )

    return item
  }
}
