import React from 'react'
import styled from 'styled-components'
import Border from './shared/Border'

const Container = styled.div`
  padding: 0px;
  margin: 0px;
  text-align: center;
  position: relative;
  background: black;
  font-family: 'VT323';
  z-index: 2;
  display: flex;
  flex-direction: column;
  z-index: 99;
  color: #555;
  max-height: 80px;
  width: 100%;
  font-size: 24px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`
const Input = styled.input`
  position: absolute;
  display: block;
  inset: 0px;
  width: 100%;
  cursor: pointer;
  background: red none repeat scroll 0% 0%;
`

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;

  background: ${(props) =>
      props.disabled ? '#A3F7B5' : props.background === 'white' ? '#ffffff' : '#40c9a2'}
    none repeat scroll 0% 0%;
  border-bottom: 4px solid
    ${(props) => (props.disabled ? '#40c9a2' : props.background === 'white' ? '#555' : '#2f9c95')};
  padding: 4px 30px;
  color: ${(props) =>
    props.disabled ? '#555' : props.background === 'white' ? '#000000' : '#fff'};
`

interface SubmitButtonProps {
  children: React.ReactNode
  disabled?: boolean
  onClick?: Function
  [key: string]: any
}

export default function SubmitButton({children, disabled = false, onClick, ...props}: SubmitButtonProps) {
  return (
    <Container disabled={disabled} onClick={() => (!disabled && onClick ? onClick() : null)}>
      <Input type="submit" />
      <Border>
        <ButtonContent disabled={disabled} {...props}>
          {children}
        </ButtonContent>
      </Border>
    </Container>
  )
}
