import styled from 'styled-components'
import { setActiveMenu } from '../../stores/MenuStore'
import { useAppDispatch } from '../../hooks'
import Border from '../shared/Border'
import Select from 'react-select'
import { setFocused, setTouch } from '../../stores/UserStore'

const MenuRow = styled.div`
  display: flex;
  .mobile {
    display: none;
  }
  @media (max-width: 900px) {
    .web {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
`

const ButtonRow = styled.div`
  padding: 0px;
  margin: 0px;
  text-align: center;
  position: relative;
  background: black;
  font-family: 'VT323';
  z-index: 2;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  color: #555;
  max-height: 80px;
  width: 100%;
  font-size: 24px;

  .react-select-container {
    font-family: 'VT323';
    font-weight: 800;
    font-size: 30px;
    line-height: 30px;
    width: 100%;
    margin: 0px 10px 0 0;
  }
  h1 {
    font-family: 'VT323';
    font-weight: 800;
    font-size: 30px;
    line-height: 30px;
    padding: 0px;
    margin: 0px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 900px) {
    .react-select-container {
      font-size: 24px;
      line-height: 24px;
    }
    h1 {
      font-size: 24px;
      line-height: 24px;
    }
  }
`

export default function Navigation({ active, setActive, options }: any) {
  const dispatch = useAppDispatch()

  return (
    <MenuRow>
      <ButtonRow>
        <Border>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            value={active}
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={false}
            name="color"
            options={options}
            onChange={(value: any) => {
              if (value.value === 'close') {
                dispatch(setActiveMenu(''))
                dispatch(setFocused(false))
                dispatch(setTouch(true))
              } else {
                setActive(value)
              }
            }}
          />
        </Border>
      </ButtonRow>
    </MenuRow>
  )
}
