import styled from 'styled-components'

import ConnectionLostSquid from '../../assets/connection-lost-squid.png'
const Backdrop = styled.div`
  background: #000;
  height: 100%;
  position: fixed;
  font-family: 'VT323';
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`

const Container = styled.div`
  width: 100%;
`

const Hint = styled.div`
  padding: 20px;
  display: flex;
  width: 100%;
  flex-direction: column;
  img {
    width: 400px;
    padding: 0 20px;
    margin: 0 auto;
  }
  p {
    font-size: 30px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  @media (max-width: 700px) {
    img {
      width: 100%;
      padding: 20px;
    }

    p {
      width: 100%;
      text-align: center;
    }
  }
`

export default function ErrorScreen({ content }) {
  return (
    <>
      <Backdrop>
        <Container>
          <Hint>
            <img src={ConnectionLostSquid} alt="hint" />
            <p>{content}</p>
          </Hint>
        </Container>
      </Backdrop>
    </>
  )
}
