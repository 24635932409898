import { ChainId, Currency, ETHER, HARMONY, WETH } from '@slothfi/bank-sdk'
import { SUPPORTED_HARMONY_CHAIN_ID } from '../constants/chain'

export default function baseCurrencies(chainId: ChainId | undefined): Currency[] {
  const currencies: Currency[] = []

  if (chainId) {
    switch (chainId) {
      case 1666600000:
      case 1666700000:
        currencies.push(HARMONY)
        currencies.push(WETH[chainId])
        break
      default:
        currencies.push(ETHER)
        currencies.push(WETH[chainId])
        break
    }
  } else {
    currencies.push(ETHER)
    currencies.push(WETH[SUPPORTED_HARMONY_CHAIN_ID as ChainId])
  }

  return currencies
}
