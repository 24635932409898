import {State} from "../../utils/states/state";
import {BattleManager} from "../battle-manager";

export class BattleStartingState implements State {
    public isIntroOver: boolean = false;

    constructor(private readonly battleManager: BattleManager) {
    }

    onEnter(): void {
        this.isIntroOver = false;

        this.battleManager.playerControlsUI.visible = false;
        this.battleManager.playerDefimonUI.visible = false;
        this.battleManager.opponentDefimonUI.visible = false;

        this.battleManager.dialogueManager.showDialogue([
            `${this.battleManager.opponentTeam.name} wants to fight!`,
            `${this.battleManager.opponentTeam.name} sends out ${this.battleManager.opponentTeam.currentActiveDefimon.name}!`,
            `Go! ${this.battleManager.playerTeam.currentActiveDefimon.name}!`,
            `What will ${this.battleManager.playerTeam.currentActiveDefimon.name} do?`],
        i => {
            if (i === 1) {
                this.battleManager.opponentDefimonUI.switchDefimon(this.battleManager.opponentTeam.currentActiveDefimon);
                this.battleManager.opponentDefimonUI.visible = true;
            }
            if (i === 2) {
                this.battleManager.playerDefimonUI.switchDefimon(this.battleManager.playerTeam.currentActiveDefimon);
                this.battleManager.playerDefimonUI.visible = true;
            }
            this.isIntroOver = i === 3;
        }
        );
    }

    onExit(): void {
    }

    tick(): void {
    }

}