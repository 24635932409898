import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import { useAppSelector, useAppDispatch, useContract, useWallet } from '../hooks'
import { useOutfits } from '../hooks/useOutfits'
import { logIn, setLoggedIn, setMyPlayerProps } from '../stores/UserStore'
import { InputBase } from '@mui/material'
import { CONTRACTS } from '../constants/contracts'
import { ERROR_METAMASK } from '../constants/metamask'
import { whitelist } from '../constants/words'
import Network from '../services/Network'
import { setNotification } from '../stores/NotificationStore'
import FilterName from '../utils/filterName'
import Dialog from '../components/Dialog'
import LoaderCat from '../components/LoaderCat'
import SubmitButton from '../components/SubmitButton'
import { findInMap, parseNumber } from '../helpers/dataHelpers'
import { CharactersList } from '../constants/player-sprites'

SwiperCore.use([Navigation])

const Backdrop = styled.div`
  padding: 0;
  height: 100%;
  flex-direction: column;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
`
const Wrapper = styled.div`
  padding: 0px;
  margin: 0px;
  text-align: center;
  position: relative;
  background: black;
  font-family: 'VT323';
  z-index: 2;
  display: flex;
  flex-direction: column;
  z-index: 99;
  color: #555;
  width: 600px;
  height: 450px;
  @media (max-width: 700px) {
    width: calc(100% - 20px);
    height: auto;
    min-height: 300px;
  }
`

const Title = styled.p`
  margin: 5px;
  font-size: 30px;
  color: rgb(34, 32, 52);
  text-align: center;
`

const SubTitle = styled.h3`
  width: 100%;
  font-size: 24px;
  color: rgb(34, 32, 52);
  text-align: center;
`
const ErrorTitle = styled.h3`
  width: 100%;
  font-size: 20px;
  color: red;
  padding-top: 5px;
  text-align: center;
`

const Content = styled.div`
  display: flex;

  margin: 36px 0;

  @media (max-width: 700px) {
    margin: 10px 0 30px;
    flex-direction: column;
  }
`

const Left = styled.div`
  margin-right: 48px;

  --swiper-navigation-size: 24px;

  .swiper-container {
    width: 180px;
    height: 180px;
    border: 4px solid rgb(57, 57, 59);
    overflow: hidden;
  }

  .swiper-slide {
    width: 100%;
    height: 100%;
    background: #dbdbe0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: #000;
    font-weight: 800;
  }
  @media (max-width: 700px) {
    margin-right: 0px;
    margin-bottom: 10px;
  }
`

const Right = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 700px) {
    width: 100% !important;
  }
`

const InputTextField = styled(InputBase)`
  color: #000 !important;
  -webkit-text-fill-color: #000 !important;
  fill: #000 !important;
  input {
    color: #000;
    -webkit-text-fill-color: #000 !important;
    fill: #000 !important;
    border: solid 0.05rem #555;
    padding: 10px;
    border: 4px solid rgb(57, 57, 59);
  }
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .Mui-disabled {
    color: rgba(85, 85, 85, 0.3);
    border: 1px solid rgba(85, 85, 85, 0.3);
  }
`

// Initialize FilterName
const filterName = new FilterName({ exclude: whitelist })

export default function LoginDialog() {
  const { account, isDisabled } = useWallet()

  const [name, setName] = useState<string>(Math.random().toString(36).slice(2))
  const [isValidName, setIsValidName] = useState<boolean>(false)
  const [errorName, setErrorName] = useState<string | null>(null)
  const [avatarIndex, setAvatarIndex] = useState<number>(0)
  const [loading, setloading] = useState(true)
  const mapLoaded = useAppSelector((state) => state.game.mapLoaded)
  const inputRef = useRef<HTMLInputElement>(null)

  const contractInitialSetup = useContract(CONTRACTS.INITIAL_SETUP)
  const contractProfile = useContract(CONTRACTS.PROFILE)
  const contractInventory = useContract(CONTRACTS.INVENTORY)
  const { defaultOutfits, loading: loadingOutfits } = useOutfits(true)

  const dispatch = useAppDispatch()
  const roomJoined = useAppSelector((state) => state.room.roomJoined)

  // This is temp, until contracts are enabled
  const codedUser = localStorage.getItem('defimons_user')
 
  // Check if user has profile
  useEffect(() => {
    if (contractProfile && contractInventory && mapLoaded && defaultOutfits.length && !isDisabled) {
      const userProfile = async () => {
        try {
          const name = await contractProfile.addressToName(account)
          if (account && name) {
            const items = await contractInventory.getEquippedItemsOf(account)
            const tokenId = items[0]?.tokenId
            // Leave Network if Player Already Exists
            const room = Network.room
            if (room && findInMap(room.state.players, name)) {
              Network.leave()
              return
            }
            setName(name)
            // Get name from tokenId or default outfit
            const characterByDefault = CharactersList[0].name
            const characterName =
              defaultOutfits.find((outfit) => outfit.id === parseNumber(tokenId))?.name ||
              characterByDefault
            dispatch(setMyPlayerProps({ name, texture: characterName, walletAddress: account }))
            dispatch(setLoggedIn(true))
            dispatch(logIn())
          } else {
            setloading(false)
          }
        } catch (error) {
          // Handle error when fail contract call
          console.log(error)
        }
      }
      userProfile()
    }
  }, [account, contractInventory, contractProfile, defaultOutfits, dispatch, isDisabled, mapLoaded])


  // Check if user is stored in cache 
  useEffect(() => {
    if (codedUser && mapLoaded && isDisabled) {
      const user = JSON.parse(atob(codedUser))
      if (user.name && user.texture && typeof user.name === 'string' && typeof user.texture === 'string') {
      setName(user.name)
      dispatch(setMyPlayerProps({ name:user.name, texture: user.texture, walletAddress: 'Not Connected' }))
      dispatch(setLoggedIn(true))
      dispatch(logIn())
    }
    } else if(isDisabled && mapLoaded){
      setloading(false)
    }
  }, [codedUser, dispatch, isDisabled, mapLoaded, name])
  
  const handleChange = async (event: React.FormEvent<HTMLInputElement>) => {
    let isFilterValid = false
    const name = event.currentTarget.value
    setName(name)
    setErrorName(null)
    setIsValidName(false)
    try {
      isFilterValid = filterName.isValid(name)
    } catch (error: any) {
      setErrorName(error.message)
    }

    if (isFilterValid && contractProfile && !isDisabled) {
      try {
        const isTakenName = await contractProfile.nameTaken(name)
        if (isTakenName) {
          setErrorName('Name is not avalaible')
        } else {
          setIsValidName(true)
        }
      } catch (error) {
        console.log(error)
        setErrorName('Something went wrong')
      }
    }
    else if(isFilterValid){

      setIsValidName(true)
    }
  }

  const handleSubmit = async () => {
    if (contractInitialSetup && !errorName && isValidName && !isDisabled) {
      try {
        setloading(true)
        const tokenId = defaultOutfits[avatarIndex].id

        const tx = await contractInitialSetup.setupProfile(name, tokenId)
        await tx.wait()

        if (roomJoined && account) {
          dispatch(
            setMyPlayerProps({
              name,
              texture: defaultOutfits[avatarIndex].name,
              walletAddress: account,
            })
          )
          dispatch(setLoggedIn(true))
          dispatch(logIn())
        }
      } catch (error: any) {
        if (error.code === ERROR_METAMASK.USER_DENIED_TRANSACTION) {
          dispatch(
            setNotification({
              content: 'User denied Transaction.',
              type: 'error',
            })
          )
        } else {
          console.log(error)
        }
        setloading(false)
      }
    } else {
      if (roomJoined) {
        dispatch(
          setMyPlayerProps({
            name,
            texture: 'Blue Cap Boy',
            walletAddress: 'Not Connected',
          })
        )
        dispatch(setLoggedIn(true))
        dispatch(logIn())


      }
    }
  }

  const showLoader = isDisabled ? loading : loading || loadingOutfits

  return (
    <Backdrop>
      {showLoader ? (
        <Wrapper>
          <LoaderCat withDialog />
        </Wrapper>
      ) : (
        <Wrapper>
          <Dialog>
            <Title>Create your profile</Title>
            <Content>
              <Left>
                <SubTitle>Select an avatar</SubTitle>
                <Swiper
                  // install Swiper modules
                  navigation
                  spaceBetween={0}
                  slidesPerView={1}
                  onSlideChange={async (swiper) => {
                    setAvatarIndex(swiper.activeIndex)
                  }}
                >
                  {isDisabled ? (
                    <SwiperSlide key={'Blue Cap Boy'}>
                      <img src={'/assets/character/profile-pictures/1.png'} alt={'Blue Cap Boy'} />
                    </SwiperSlide>
                  ) : (
                    defaultOutfits.map((avatar) => (
                      <SwiperSlide key={avatar.name}>
                        <img src={avatar.image} alt={avatar.name} />
                      </SwiperSlide>
                    ))
                  )}
                </Swiper>
              </Left>
              <Right>
                <SubTitle>Enter your nickname</SubTitle>

                <InputTextField
                  autoFocus
                  inputRef={inputRef}
                  fullWidth
                  max={15}
                  placeholder="Enter your nickname"
                  value={name}
                  onChange={handleChange}
                />
                {/* <ErrorTitle>Custom names have been temporarily disabled</ErrorTitle> */}
                {errorName && <ErrorTitle>{errorName}</ErrorTitle>}
              </Right>
            </Content>
            <Bottom>
              <SubmitButton disabled={Boolean(errorName || !isValidName)} onClick={handleSubmit}>
                Create Profile
              </SubmitButton>
            </Bottom>
          </Dialog>
        </Wrapper>
      )}
    </Backdrop>
  )
}
