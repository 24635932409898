import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const timerSlice = createSlice({
  name: 'timer',
  initialState: {
    isCounting: false,
    time: 0,
  },
  reducers: {
    setCounting: (state, action: PayloadAction<boolean>) => {
      state.isCounting = action.payload
    },
    setTime: (state, action: PayloadAction<number>) => {
      state.time = action.payload
    },
  },
})

export const { setCounting, setTime } = timerSlice.actions

export default timerSlice.reducer
