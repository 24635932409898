export type TilesetTileData = { [key: number]: { animation?: TileAnimationData } }
export type TileAnimationData = Array<{ duration: number; tileid: number }>

export default class AnimatedTile {
  // reference to the tilemap tile to animate
  private tile: Phaser.Tilemaps.Tile

  // the data needed for animating the tile
  private tileAnimationData: TileAnimationData

  // the starting index of the first tile index the tileset of the tile contains
  private firstgid: number

  // the elapsed time that loops between 0 and max animation duration
  private elapsedTime: number

  // the length of animation in ms
  private animationDuration: number

  /**
   * @param {Phaser.Tilemaps.Tile} tile - the tile to animate
   * @param {TileAnimationData} tileAnimationData  - the animation data
   * @param {number} firstgid - the starting index of the first tile index the tileset of the tile contains
   */
  constructor(tile: Phaser.Tilemaps.Tile, tileAnimationData: any, firstgid: number) {
    this.tile = tile
    this.tileAnimationData = tileAnimationData
    this.firstgid = firstgid
    this.elapsedTime = 0
    // assuming the duration is uniform across all frames
    this.animationDuration = tileAnimationData[0].duration * tileAnimationData.length
  }

  /**
   * Update the tile if necessary. This method should be called every frame.
   * @param {number} delta - the delta time in ms since the last frame
   */
  public update(delta: number): void {
    this.elapsedTime += delta
    this.elapsedTime %= this.animationDuration

    const animatonFrameIndex = Math.floor(this.elapsedTime / this.tileAnimationData[0].duration)

    this.tile.index = this.tileAnimationData[animatonFrameIndex].tileid + this.firstgid
  }
}
