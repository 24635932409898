import phaserGame from '../../PhaserGame'
import Game from '../../scenes/Game'

interface IPosition {
  x: number
  y: number
}

export function getNPCPosition(npcId: string): IPosition {
  const currentScene = phaserGame.scene.keys.game as Game

  const npc = Object.values(currentScene.npcs.children.entries).find(
    (npc: any) => npc.playerId === npcId
  )?.body.position

  return npc ? npc : { x: 0, y: 0 }
}

export function getPlayerPosition(): IPosition {
  const currentScene = phaserGame.scene.keys.game as Game

  const player = currentScene.myPlayer

  return player ? { x: player.x, y: player.y } : { x: 0, y: 0 }
}
