/**
 * Loads all images, based on passed Scene
 * called in Game scene's preload()
 */

import { gameConfig } from '../../assets/game-data'
import store from '../../stores'

export default class ImageLoader {
  scene: any
  sceneName: any
  constructor(scene: any) {
    const { currentMap } = store.getState().game
    this.scene = scene
    this.sceneName = currentMap
    this.loadBackground(this.sceneName)
    this.loadAnimations(this.sceneName)
  }

  // loads images, based on passed scene name
  loadBackground(sceneName) {
    for (let i = 0; i < gameConfig.maps[sceneName].tilesets.length; i++) {
      if (!this.scene.textures.exists(gameConfig.maps[sceneName].tilesets[i].key)) {
        this.scene.load.image(
          gameConfig.maps[sceneName].tilesets[i].key,
          `assets/tilesets/${gameConfig.maps[sceneName].tilesets[i].name}.png`
        )
      }
    }

    this.scene.load.tilemapTiledJSON(
      gameConfig.maps[sceneName].name,
      gameConfig.maps[sceneName].json
    )
  }

  // loads animation tiles, based on passed scene name
  loadAnimations(sceneName) {
    for (let i = 0; i < gameConfig.maps[sceneName].anims.length; i++) {
      this.scene.load.spritesheet(
        gameConfig.maps[sceneName].anims[i].key,
        `assets/anims/${gameConfig.maps[sceneName].anims[i].name}.png`,
        {
          frameWidth: 32,
          frameHeight: 32,
        }
      )
    }
  }
}
