import phaserGame from '../PhaserGame'
import Game from '../scenes/Game'

export class Page extends Phaser.Physics.Arcade.Sprite {
  isDisabled!: boolean
  isVisible!: boolean
  isCollected!: boolean
  type!: string
  callback!: any

  constructor(scene: Game, x: number, y: number, frame: string, name: string, callback?: any) {
    super(scene, x, y, frame)
    scene.add.existing(this)
    scene.collectibleGroup.add(this)
    this.name = name
    this.callback = callback
    this.type = 'page'
    this.play('page')
  }

  onOverlap() {
    const game = phaserGame.scene.keys.game as Game

    game.myPlayer.setCursorsIsDownToFalse()
    game.myPlayer.play(`${game.myPlayer.playerTexture}_idle_down`, true)
    this.scene.tweens.add({
      targets: this,
      alpha: 0,
      duration: 200,
      ease: 'Cubic.easeOut',
    })

    this.isCollected = true

    this.isVisible = false
    this.visible = false

    if (this.callback) {
      this.callback(this)
    }
  }
}
export function getPageCollectible(scene: Game, name: string): Page {
  const page = Object.values(scene.collectibleGroup.children.entries).find(
    (page: any) => page.name === name
  ) as Page

  return page
}
