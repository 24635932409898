import React from 'react'
import MenuOption from '../../components/menu/MenuOption'
import phaserGame from '../../PhaserGame'
import Bootstrap from '../../scenes/Bootstrap'
import { useAppDispatch } from '../../hooks'
import { logOut, setFocused, setMyPlayerProps, setTouch } from '../../stores/UserStore'
import SaveIcon from '@mui/icons-material/Save';
import { setRoomJoined } from '../../stores/RoomStore'

export default function ResetProfileButton({ setActiveMenu }) {
  const dispatch = useAppDispatch()
  const bootstrapHandler = phaserGame.scene.keys.bootstrap as Bootstrap

  return (
    <MenuOption
      onClick={() => {
        dispatch(setRoomJoined(false))
        dispatch(logOut())
        dispatch(setMyPlayerProps({
          name:''
        }))
        localStorage.removeItem('defimons_user');
        bootstrapHandler.launchGame()
        dispatch(setActiveMenu(''))
        dispatch(setFocused(false))
        dispatch(setTouch(true))
      }}
      icon={<SaveIcon />}
      text={'Reset Profile'}
    />
  )
}
