import {State} from "../../../../utils/states/state";
import {DialogueManager} from "../DialogueManager";

export class WaitingForPlayerInputState implements State {
    public hasSkipped: boolean = false;

    constructor(private readonly dialogueManager: DialogueManager) {
    }

    onEnter(): void {
        this.hasSkipped = false;
        this.dialogueManager.setInteractive().on('pointerdown', () => {
            this.dialogueManager.dialogueFinishedCallback(this.dialogueManager.currentLineIndex);
            this.dialogueManager.currentLineIndex++;
            this.hasSkipped = true;
        })
    }

    onExit(): void {
        this.dialogueManager.off('pointerdown');
    }

    tick(dt: number): void {
    }
}