import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const tabletSlice = createSlice({
  name: 'tablet',
  initialState: {
    showTablet: false,
    tab: 0,
  },
  reducers: {
    setOpenTablet: (state, action: PayloadAction<boolean>) => {
      state.showTablet = action.payload
    },
    setCurrentTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload
    },
  },
})

export const { setOpenTablet, setCurrentTab } = tabletSlice.actions

export default tabletSlice.reducer
