/* eslint-disable jsx-a11y/alt-text */
import styled from 'styled-components'
import Border from '../../components/shared/Border'
import Monster, { MonsterState } from '../../components/battle/Monster'
import Controls from '../../components/battle/Controls'
import { PlayerStats, EnemyStats } from '../../components/battle/Stats'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../hooks'
import Game from '../../scenes/Game'
import phaserGame from '../../PhaserGame'
import { MonstersList } from '../../constants/monsters'

const Backdrop = styled.div`
  padding: 0;
  height: 100%;
  flex-direction: column;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
`

const FabWrapper = styled.div`
  margin-top: auto;
  margin-bottom: 0px;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
`

const Wrapper = styled.div`
  padding: 0px;
  margin: 0px;
  text-align: center;
  position: relative;
  background: black;
  font-family: 'VT323';
  z-index: 2;
  display: flex;
  flex-direction: column;
  z-index: 99;
  color: #555;
  width: 1400px;
  height: 780px;
  animation: fade-in 0.5s;
  @media (max-width: 1500px) {
    width: 1300px;
    height: 650px;
  }
  @media (max-width: 1300px) {
    width: 1050px;
    height: 550px;
  }
  @media (max-width: 1100px) {
    width: 780px;
    height: 450px;
  }
`

const MainContainer = styled.div`
  display: relative;
  height: 100%;
`

const SceneContainer = styled.div`
  background: rgb(248, 248, 248) none repeat scroll 0% 0%;
  border-bottom: 4px solid rgb(216, 213, 237);
  height: 100%;
  padding: 0px;
  overflow: hidden;

  img {
    image-rendering: pixelated;
    image-rendering: -moz-crisp-edges;
    image-rendering: crisp-edges;
  }

  .background {
    width: 100%;
    left: 0px;
    top: -50px;
    position: relative;
  }

  @media (max-width: 1500px) {
    .background {
      top: -100px;
    }
  }
  @media (max-width: 1300px) {
    .background {
      top: -100px;
    }
  }
  @media (max-width: 1100px) {
    .background {
      top: -50px;
    }
  }
`

const MonstersContainer = styled.div`
  position: relative;
`
const MonsterPlayerContainer = styled.div`
  position: absolute;
  z-index: 1000000;
  top: ${(props) => (props.size === 75 ? '275px' : '370px')};
  transition: left 0.8s ease-out;
  left: ${(props) => (props.isIntro ? '-400px' : props.size === 75 ? '240px' : '290px')};
  @media (max-width: 1500px) {
    top: ${(props) => (props.size === 75 ? '180px' : '280px')};
    left: ${(props) => (props.isIntro ? '-400px' : props.size === 75 ? '220px' : '260px')};
  }
`
const MonsterEnemyContainer = styled.div`
  position: absolute;
  z-index: 1000000;
  bottom: ${(props) => (props.size === 75 ? '230px' : '330px')};
  transition: right 0.8s ease-out;
  right: ${(props) => (props.isIntro ? '-400px' : props.size === 75 ? '240px' : '290px')};

  @media (max-width: 1500px) {
    top: ${(props) => (props.size === 75 ? '140px' : '240px')};
    right: ${(props) => (props.isIntro ? '-400px' : props.size === 75 ? '190px' : '260px')};
  }
  @media (max-width: 1300px) {
    top: ${(props) => (props.size === 75 ? '230px' : '330px')};
    right: ${(props) => (props.isIntro ? '-400px' : props.size === 75 ? '240px' : '290px')};
  }
  @media (max-width: 1100px) {
    top: ${(props) => (props.size === 75 ? '230px' : '330px')};
    right: ${(props) => (props.isIntro ? '-400px' : props.size === 75 ? '240px' : '290px')};
  }
`

export enum ControlSelection {
  Idle = 'IDLE',
  Main = 'MAIN',
  Attacks = 'ATTACKS',
}

export default function BattleSystem() {
  const [activeCombatants, setActiveCombatants] = useState({
    player: MonstersList.KAHI,
    enemy: MonstersList.YETI2,
  })
  const [playerHealth, setPlayerHealth] = useState(activeCombatants.player.maxHealth);
  const [enemyHealth, setEnemyHealth] = useState(activeCombatants.enemy.maxHealth)
  const [playerState, setPlayerState] = useState(MonsterState.Idle)
  const [enemyState, setEnemyState] = useState(MonsterState.Idle)
  const [timer, setTimer] = useState(7)
  const [animation, setAnimation] = useState('')
  const [triggerIntro, settriggerIntro] = useState({
    player: true,
    enemy: true,
  })
  const name = useAppSelector((state) => state.user.name)
  const [status, setStatus] = useState(
    `YOUNG HUNTER sent out ${activeCombatants.enemy.name.toUpperCase()}!`
  )
  
  console.log(setPlayerHealth,setEnemyHealth,setPlayerState,setEnemyState)
  
  
  const [controlSelection, setControlSelection] :any= useState(null)
  const musicState = useAppSelector((state) => state.pref.music)
  const game = phaserGame.scene.keys.game as Game

  useEffect(() => {
    if (timer > 0) {
      setTimeout(function () {
        setTimer(timer - 1)
      }, 1000)
    }
  }, [timer])

  useEffect(() => {
    if (timer === 7) {
      if (musicState) {
        game.music.stop()
        game.map.playMusic('battle-encounter')
      }
    } else if (timer === 4) {
      if (musicState) {
        game.music.stop()
        game.map.playMusic('battle')
      }
    }
    if (timer === 6) {
      game.scene.stop('game')
      game.scene.stop('background')
    } else if (timer === 4) {
      const temp = triggerIntro
      temp.enemy = false
      settriggerIntro(temp)
    } else if (timer === 2) {
      const temp = triggerIntro
      temp.player = false
      settriggerIntro(temp)
      setStatus(`${name.toUpperCase()} sent out ${activeCombatants.player.name.toUpperCase()}!`)
    } else if (timer === 1) {
      setStatus(`What will ${activeCombatants.player.name.toUpperCase()} do?`)
      setControlSelection(ControlSelection.Main)
    }
  }, [
    activeCombatants.player.name,
    game.map,
    game.music,
    game.scene,
    musicState,
    name,
    timer,
    triggerIntro,
  ])

  return (
    <Backdrop>
      <FabWrapper
        onClick={() => {}}
        className="blink"
        style={{
          animationIterationCount: '2',
        }}
      />
      {timer <= 5 ? (
        <Wrapper>
          <MainContainer>
            <Border>
              <SceneContainer>
                <PlayerStats
                  name={activeCombatants.player.name}
                  level={activeCombatants.player.level}
                  health={playerHealth}
                  maxHealth={activeCombatants.player.maxHealth}
                />
                <EnemyStats
                  name={activeCombatants.enemy.name}
                  level={activeCombatants.enemy.level}
                  health={enemyHealth}
                  maxHealth={activeCombatants.enemy.maxHealth}
                />
                <MonstersContainer>
                  <MonsterPlayerContainer
                    isIntro={triggerIntro.player}
                    size={activeCombatants.player.size}
                  >
                    <Monster
                      id={activeCombatants.player.id}
                      size={activeCombatants.player.size}
                      direction="right"
                      state={playerState}
                    />
                  </MonsterPlayerContainer>
                  <MonsterEnemyContainer
                    isIntro={triggerIntro.enemy}
                    size={activeCombatants.enemy.size}
                  >
                    <div className={animation}>
                      <Monster
                        id={activeCombatants.enemy.id}
                        size={activeCombatants.enemy.size}
                        direction="left"
                        state={enemyState}
                      />
                    </div>
                  </MonsterEnemyContainer>
                </MonstersContainer>
                <img src="/assets/background/fight_snow.png" className="background" />
                <Controls
                  combatants={activeCombatants}
                  setCombatants={setActiveCombatants}
                  animation={animation}
                  setAnimation={setAnimation}
                  status={status}
                  setStatus={setStatus}
                  controlSelection={controlSelection}
                  setControlSelection={setControlSelection}
                />
              </SceneContainer>
            </Border>
          </MainContainer>
        </Wrapper>
      ) : (
        <></>
      )}
    </Backdrop>
  )
}
