import { BattleDefimon, Defimon } from "./defimons";

enum ItemType {
  // value: in-battle use
  // 0 / 1 means false / true
  HEALTH = 1,
  STAMINA = 1,
  COMBO = 1,
  REVIVE = 0,
  STATUS = 1,
  MORALITY = 0,
  IN_BATTLE = 1,
  SCRAP = 0,
  FARMING = 0,
  MISCELLANEOUS = 0,
}

class SeasonSpan {
  static readonly SPRING = new SeasonSpan("20/03", "20/06")
  static readonly SUMMER = new SeasonSpan("21/03", "22/09")
  static readonly AUTUMN = new SeasonSpan("23/09", "20/12")
  static readonly WINTER = new SeasonSpan("21/12", "19/03")
  private readonly startDate: Date
  private readonly endDate: Date

  constructor(private readonly start: string, private readonly end: string) {
    const startSplit: string[] = start.split("/")
    const endSplit: string[] = end.split("/")
    this.startDate = new Date(new Date().getUTCFullYear(), +startSplit[0], +startSplit[1])
    this.endDate = new Date(new Date().getUTCFullYear(), +endSplit[0], +endSplit[1])
    this.startDate.setHours(0, 0, 0, 0)
    this.endDate.setHours(0, 0, 0, 0)
  }

  static currentSeason(): SeasonSpan {
    const today = new Date()
    const seasons = [this.WINTER, this.AUTUMN, this.SUMMER, this.SPRING]

    for (let i = 0; i < seasons.length; i++) {
      const season = seasons[i]
      if (season.endDate < today) return season
    }
    return this.WINTER
  }
}

export class Item<T> {
  static readonly HEALTH_PACK_S = new Item<BattleDefimon>("Health Pack (S)", "Health Pack (S)", "Restore 25 HP", ItemType.HEALTH, d => d.restoreHealth(25))
  static readonly HEALTH_PACK_M = new Item<BattleDefimon>("Health Pack (M)", "Health Pack (M)", "Restore 50 HP", ItemType.HEALTH, d => d.restoreHealth(50))
  static readonly HEALTH_PACK_L = new Item<BattleDefimon>("Health Pack (L)", "Health Pack (L)", "Restore Full HP", ItemType.HEALTH, d => d.restoreHealthCompletely())

  static readonly STAMINA_PACK_S = new Item<BattleDefimon>("StaminaPack (S)", "StaminaPack (S)", "Restore 25 SP", ItemType.STAMINA, d => d.restoreStamina(20))
  static readonly STAMINA_PACK_M = new Item<BattleDefimon>("StaminaPack (M)", "StaminaPack (M)", "Restore 50 SP", ItemType.STAMINA, d => d.restoreStamina(25))
  static readonly STAMINA_PACK_L = new Item<BattleDefimon>("StaminaPack (L)", "StaminaPack (L)", "Restore Full SP", ItemType.STAMINA, d => d.restoreStaminaCompletely())

  static readonly COMBO_PACK_S = new Item<BattleDefimon>("Combo Pack (S)", "Combo Pack (S)", "Restore 15 HP and 15 SP", ItemType.COMBO, d => {
    d.restoreHealth(15)
    d.restoreStamina(15)
  }, true, false)
  static readonly COMBO_PACK_M = new Item<BattleDefimon>("Combo Pack (M)", "Combo Pack (M)", "Restore 40 HP and 20 SP", ItemType.COMBO, d => {
    d.restoreHealth(40)
    d.restoreStamina(20)
  }, true, false)
  static readonly COMBO_PACK_L = new Item<BattleDefimon>("Combo Pack (L)", "Combo Pack (L)", "Restore Full HP and Full SP", ItemType.COMBO, d => {
    d.restoreHealthCompletely()
    d.restoreStaminaCompletely()
  }, true, false)

  static readonly OKAMI_CRYSTAL = new Item<Defimon>("Crystal of Okami", "Cure for Burn", "Cure a Defimon of its burn", ItemType.STATUS, d => {}, false)
  static readonly TATARIGAMI_CRYSTAL = new Item<Defimon>("Crystal of Tatarigami", "Cure for Curse", "Cure a Defimon of its curse", ItemType.STATUS, d => {}, false)
  static readonly AMATERASU_CRYSTAL = new Item<Defimon>("Crystal of Amaterasu", "Cure for Sleep", "Wake up a Defimon from its sleep", ItemType.STATUS, d => {}, false)
  static readonly KOJIN_CRYSTAL = new Item<Defimon>("Crystal of Kojin", "Cure for Freeze", "Cure a Defimon of its freeze", ItemType.STATUS, d => {}, false)
  static readonly GAMA_CRYSTAL = new Item<Defimon>("Crystal of Gama", "Cure for Poison", "Remove poison from a Defimon", ItemType.STATUS, d => {}, false)
  static readonly ACALA_CRYSTAL = new Item<Defimon>("Crystal of Acala", "Cure for Haziness", "Cures a Defimon of haziness", ItemType.STATUS, d => {}, false)

  static readonly RINGO_SEED = new Item<Defimon>("Ringo Seed", "Apple Seed", "Grows 1 Apple in 24 hours", ItemType.FARMING, d => {}, true, false, SeasonSpan.SUMMER)
  // ...

  static readonly RING_LUCK = new Item<BattleDefimon>("Ring of Luck","","Retry taming a wild Defimon", ItemType.IN_BATTLE, d => {}, true, false)
  static readonly RINGO_FRUIT = new Item<BattleDefimon>("Ringo Fruit", "Apple","Restores 75% health, at the risk of getting a status", ItemType.IN_BATTLE, d => {})
  static readonly BANANA_FRUIT = new Item<BattleDefimon>("Banana Fruit","Banana","Increases XP gained during battle", ItemType.IN_BATTLE, d => {})
  static readonly SAKURABO_FRUIT = new Item<BattleDefimon>("Sakurabo Fruit","Cherry","Reduces damage taken when hit by a critical hit", ItemType.IN_BATTLE, d => {})
  static readonly PINA_FRUIT = new Item<BattleDefimon>("Pina Fruit","Pineapple","Increases Speed for current battle", ItemType.IN_BATTLE, d => {})
  static readonly MERON_FRUIT = new Item<BattleDefimon>("Meron Fruit","Melon","Prevents HP from falling below 1", ItemType.IN_BATTLE, d => {})
  static readonly QUICK_LEAF = new Item<BattleDefimon>("Quick Leaf","","Allows Defimon to move first for 3 turns regardless of speed", ItemType.IN_BATTLE, d => {}, true, false)

  constructor(private readonly name: string,
              readonly simpleName: string,
              readonly description: string,
              readonly type: ItemType,
              readonly behaviour : (o:T) => void = _ => {},
              readonly shoppable = true,
              readonly craftable = true,
              readonly season ?: SeasonSpan) {
    if (simpleName === "") this.simpleName = name
  }

  isAvailableThisSeason(): boolean {
    return this.season === undefined || this.season === SeasonSpan.currentSeason()
  }

  canBeUsedInBattle() {
    return this.type === 1;
  }
}

