import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    content: '',
    type: '',
    timer: 0,
    imageURI: '',
  },
  reducers: {
    setNotification: (
      state,
      action: PayloadAction<{ content?: string; type?: string; imageURI?: string; timer?: number }>
    ) => {
      if (action.payload.content || action.payload.content === '') {
        state.content = action.payload.content
      }
      if (action.payload.type || action.payload.type === '') {
        state.type = action.payload.type
      }
      if (action.payload.timer) {
        state.timer = action.payload.timer
      }
      if (action.payload.imageURI || action.payload.imageURI === '') {
        state.imageURI = action.payload.imageURI
      }
    },
  },
})

export const { setNotification } = notificationSlice.actions

export default notificationSlice.reducer
