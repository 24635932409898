export type IGameUISceneData = Readonly<{}>;

export default class GameUI extends Phaser.Scene {
    static KEY = 'GameUI'

    constructor() {
        super(GameUI.KEY);
    }

    init() {

    }

    preload() {

    }

    async create(data: IGameUISceneData) {

    }
}