import Phaser from 'phaser'
import phaserGame from '../PhaserGame';
import Game from '../scenes/Game';
import store from '../stores';
import { setMessageDisabled } from '../stores/MessageStore';
import MyPlayer from './MyPlayer';
import NPC from './NPC'

export default class Goop extends NPC {

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    id: string,
    name: string,
    map: string,
    frame?: string | number,
    pathFindingData?: any
  ) {
    super(scene, x, y, texture, id, name, map, frame)

    this.playerId = id;
    this.playerName.setText(name)
    this.setDepth(-1)
    this.pathFindingData = pathFindingData;
  }


  onOpenPlayerDialogue(myPlayer: MyPlayer): void {
    this.facePlayer(myPlayer)
    this.finishConversation()
  }
  
  finishConversation(): void {
    let game = phaserGame.scene.keys.game as Game
    const tempy = this.y
    this.play(`${this.playerTexture}_goop`, true)
    if (store.getState().pref.soundEffects) {
      game.soundEffects = game.sound.add('disintegrate', {
        volume: 0.05,
      })
      game.soundEffects.play()
    }
    this.scale = 2
    this.updateNPC('y',tempy - 10)
    store.dispatch(setMessageDisabled(true))

    setTimeout(() => {
      this.destroy(true)
      store.dispatch(setMessageDisabled(false))
    }, 6000);
  }

}
