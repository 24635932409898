import styled from 'styled-components'
import MicNoneIcon from '@mui/icons-material/MicNone'
import network from '../../services/Network'
import { useAppSelector } from '../../hooks'
import Border from '../../components/shared/Border'
import MicOffIcon from '@mui/icons-material/MicOff'
import MicIcon from '@mui/icons-material/Mic'
import { useState } from 'react'

const Button = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  z-index: 99;
  width: 100%;
  padding: 13px 15px;
  background-color: #fff;
  color: #000;
  :hover {
    background-color: #ddd;
    cursor: pointer;
  }
`

const FabWrapper = styled.div`
  margin-top: auto;
  margin-bottom: 0px;
  position: fixed;
  left: 20px;
  bottom: 90px;
  opacity: 0.9;
`

const Body = styled.div`
  background: rgb(248, 248, 248) none repeat scroll 0% 0%;
  border-bottom: 4px solid rgb(216, 213, 237);
  padding: 0px;

  @media (max-width: 700px) {
    padding: 0px;
  }
`

export default function ProximityAudioButton() {
  const [status, setStatus] = useState('not-connected')
  const activeMenu = useAppSelector((state) => state.menu.activeMenu)
  const videoConnected = useAppSelector((state) => state.user.videoConnected)
  const messageDialogOpen = useAppSelector((state) => state.message.messageDialogOpen)
  const isMultiplayer = useAppSelector((state) => state.game.isMultiplayer)

  if (messageDialogOpen || !isMultiplayer) return <></>

  return (
    <FabWrapper>
      {!activeMenu ? (
        <>
          <Border>
            <Body>
              <Button
                color="secondary"
                onClick={() => {
                  if (status === 'not-connected') {
                    network.webRTC?.getUserMedia()
                    setStatus('on')
                  } else if (status === 'on' && videoConnected) {
                    setStatus('off')
                    network.webRTC?.handleAudioTrack()
                  } else {
                    setStatus('on')
                    network.webRTC?.handleAudioTrack()
                  }
                }}
              >
                {status === 'not-connected' ? (
                  <MicNoneIcon />
                ) : status === 'on' ? (
                  <MicIcon />
                ) : (
                  <MicOffIcon />
                )}
              </Button>
            </Body>
          </Border>
        </>
      ) : (
        <></>
      )}
    </FabWrapper>
  )
}
