import Dialog from '../../components/Dialog'
import { MenuWrapper, MenuTitle,  } from '../Menu'
import Border from '../../components/shared/Border'
import styled from 'styled-components'
import MenuOption from '../../components/menu/MenuOption'

export const Content = styled.div`
  position: relative;
  height: auto;
  align-items: center;
  padding: 12px;
  font-size: 24px;
  max-width: 350px;
  max-height: 500px;
  overflow: scroll;
  background: #fff;
  box-shadow: #999 0px 0px 0px 1px, #555 0px 0px 0px 2px, #999 0px 0px 0px 3px;

  @media (max-width: 700px) {
    min-width: auto;
  }
`

export default function ReleaseNotesMenu({ selectedMenu, setSelectedMenu }: any) {
  return (
    <MenuWrapper>
      <Dialog>
        <MenuTitle>Release Notes</MenuTitle>

          <Border>
            <Content>
              <b style={{ textAlign: 'center' }}>Version:- 0.1.5</b>
              <br /> 1. Buildings added - Pet Store, Property Store, Cafe, Clothing Store
              <br /> 2. Profile Contracts V.1
              <br /> 3. Character Outfit NFT Minting V.1
              <br /> 4. Weather System - Rain
              <br /> 5. NPCs Added (8)
              <br /> 6. Improved Room functionality, requires less calls to backend
              <br /> 7. Image compression and sound compression - Overall load reduced from 7MB to
              ~600KB
              <br /> 8. Improved UI Design
            </Content>
          </Border>
        <MenuOption onClick={() => setSelectedMenu('')}>Back to Main Menu</MenuOption>
      </Dialog>
    </MenuWrapper>
  )
}
