import Container = Phaser.GameObjects.Container
import { UnlockMiniGameMarkerUI } from './UnlockMiniGameMarkerUI'
import MyPlayer from '../../characters/MyPlayer'
import Game from '../../scenes/Game'
import { UnlockMiniGameBallUI } from './UnlockMiniGameBallUI'
import store from '../../stores'
import { setFocused, setTouch } from '../../stores/UserStore'
import { setActiveMenu } from '../../stores/MenuStore'
import phaserGame from '../../PhaserGame'
import { gameConfig } from '../../assets/game-data'
import { handleKeys } from '../../scenes/methods'
import isTouchScreen from '../../utils/isTouchScreen'

enum Direction {
  left = -1,
  right = 1,
}

export class UnlockMiniGame extends Container {
  private static readonly WIDTH = 204
  private static readonly BAR_WIDTH = 136

  private static readonly START_SPEED = 0.1

  private static readonly SUCCESS_WINDOW_PERCENTAGE = 15

  private static readonly HORIZONTAL_SHAKE_OFFSET = 2
  private static readonly HORIZONTAL_SHAKE_DURATION_MILLIS = 50

  private static readonly SUCCESS_PAUSE_DURATION_MILLIS = 100

  private readonly ball: UnlockMiniGameBallUI
  private readonly markers: UnlockMiniGameMarkerUI[] = []

  private currentPercent = 0
  private currentDirection = Direction.right
  private currentSpeed = 0.1
  private currentLevel = 0

  private isPaused = false
  private pauseTimeRemainingMillis = 0
  private clickKey!: Phaser.Input.Keyboard.Key
  private closeKey!: Phaser.Input.Keyboard.Key

  constructor(
    private readonly steps: number,
    private readonly player: MyPlayer,
    scene: Game,
    name: string
  ) {
    super(scene)

    const bar = this.addBar(scene)
    this.ball = this.addBall(scene)
    this.name = name
    this.addMarkers(scene, bar)
    this.addCloseButton(scene, bar)
    scene.add.existing(this)

    this.setupContainer()
    this.setupMusic()
    this.setupKeys()
  }
  private setupContainer() {
    this.x = this.player.x
    this.y = this.player.y - 75
    this.depth = 100000
    this.setSize(UnlockMiniGame.WIDTH, 100)
  }

  private setupMusic() {
    const game = phaserGame.scene.keys.game as Game
    if (store.getState().pref.music) {
      game.music.stop()
      game.music = game.sound.add('clock-ticking', {
        volume: 0.2,
        loop: true,
      })
      game.music.play()
    }
  }

  private setupKeys() {
    this.clickKey = this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE)
    this.closeKey = this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ESC)

    this.clickKey.on('down', () => {
      this.clicked()
    })

    this.closeKey.on('down', () => {
      this.close()
    })

    this.scene.input.on('pointerdown', (event) => {
      if (isTouchScreen()) {
        this.clicked()
      } else {
        if (event.button === 0) {
          this.clicked()
        }
      }
    })
  }

  private addCloseButton(scene: Game, bar: Phaser.GameObjects.Image) {
    const xButton = scene.add.image(
      UnlockMiniGame.WIDTH / 2 - 37,
      -bar.height + 2,
      'unlockMiniGameXButton'
    )
    xButton.setInteractive().on('pointerdown', () => this.close())
    this.add(xButton)
  }

  private addMarkers(scene: Game, bar: Phaser.GameObjects.Image) {
    for (let i = 0; i < this.steps; i++) {
      const marker = new UnlockMiniGameMarkerUI(scene)
      marker.x = -UnlockMiniGame.WIDTH / 2 + 35 + 26 * i
      marker.y = -bar.height + 4
      this.markers.push(marker)
      this.add(marker)
    }
  }

  private addBall(scene: Game): UnlockMiniGameBallUI {
    const ball = new UnlockMiniGameBallUI(scene)
    this.add(ball)
    return ball
  }

  private addBar(scene: Game) {
    const splitStr = this.name.trim().split('-')

    const bar = scene.add.image(
      0,
      0,
      splitStr[0] === 'dumpster' ? 'unlockMiniGameBarTrashbin' : 'unlockMiniGameBarWorksite'
    )
    this.add(bar)
    return bar
  }

  update(delta: number) {
    if (this.tryUpdatePause(delta)) return

    this.currentPercent += this.currentSpeed * this.currentDirection * delta

    this.updateDirection()
    this.updateBallPosition()
  }

  private shake(direction: Direction) {
    const timeline = this.scene.tweens.createTimeline()
    timeline.add({
      targets: this,
      x: `${direction === Direction.right ? '+' : '-'}=${UnlockMiniGame.HORIZONTAL_SHAKE_OFFSET}`,
      ease: 'Circ.easeOut',
      duration: UnlockMiniGame.HORIZONTAL_SHAKE_DURATION_MILLIS,
    })
    timeline.add({
      targets: this,
      x: `${direction === Direction.right ? '-' : '+'}=${UnlockMiniGame.HORIZONTAL_SHAKE_OFFSET}`,
      ease: 'Circ.easeIn',
      duration: UnlockMiniGame.HORIZONTAL_SHAKE_DURATION_MILLIS,
    })
    timeline.play()
  }

  private updateBallPosition() {
    this.ball.x = this.computeBallXFromPercent()
  }

  private computeBallXFromPercent() {
    const barWidthMinusBall = UnlockMiniGame.BAR_WIDTH - 8
    return -barWidthMinusBall / 2 + barWidthMinusBall * (this.currentPercent / 100)
  }

  private updateDirection() {
    if (this.currentDirection === Direction.right) {
      if (this.currentPercent > 100) {
        this.changeDirectionToLeft()
      }
    } else if (this.currentDirection === Direction.left) {
      if (this.currentPercent < 0) {
        this.changeDirectionToRight()
      }
    }
  }

  private changeDirectionToRight() {
    this.currentPercent = -this.currentPercent
    this.currentDirection = Direction.right
    this.shake(Direction.left)
  }

  private changeDirectionToLeft() {
    this.currentPercent = 100 - (this.currentPercent - 100)
    this.currentDirection = Direction.left
    this.shake(Direction.right)
  }

  private tryUpdatePause(delta: number) {
    if (!this.isPaused) return false

    this.pauseTimeRemainingMillis -= delta
    if (this.pauseTimeRemainingMillis <= 0) {
      this.unpause()
      return false
    }

    return true
  }

  clicked() {
    const game = phaserGame.scene.keys.game as Game
    const halfSuccessWindow = UnlockMiniGame.SUCCESS_WINDOW_PERCENTAGE / 2
    if (
      this.currentPercent < 50 - halfSuccessWindow ||
      this.currentPercent > 50 + halfSuccessWindow
    ) {
      this.ball.triggerWrong(100)
      if (store.getState().pref.soundEffects) {
        game.soundEffects = game.sound.add('unlock-wrong', {
          volume: 0.05,
        })
        game.soundEffects.play()
      }
      return this.reset()
    }

    this.success()
  }

  private success() {
    const game = phaserGame.scene.keys.game as Game
    if (this.markers[this.currentLevel]) {
      this.markers[this.currentLevel].turnOn()
    }
    this.currentLevel++
    this.currentSpeed += UnlockMiniGame.START_SPEED / 2
    if (store.getState().pref.soundEffects) {
      game.soundEffects = game.sound.add('unlock-correct', {
        volume: 0.05,
      })
      game.soundEffects.play()
    }
    if (this.currentLevel >= this.steps) {
      this.completed()
    } else {
      this.ball.triggerHighlight()
      this.pause(UnlockMiniGame.SUCCESS_PAUSE_DURATION_MILLIS)
    }
  }

  private completed() {
    const game = phaserGame.scene.keys.game as Game
    store.dispatch(setFocused(false))
    store.dispatch(setTouch(true))
    store.dispatch(setActiveMenu(''))
    handleKeys(true)
    if (store.getState().pref.soundEffects) {
      game.soundEffects = game.sound.add('unlock-won', {
        volume: 0.05,
      })
      game.soundEffects.play()
    }
    this.pause(10000000)
    this.ball.permanentHighlight()
    const destX = this.x
    const destY = this.y
    this.scene.tweens.timeline({
      loop: 10,
      tweens: [
        {
          targets: this,
          x: destX + 3,
          ease: 'Linear',
          duration: 30,
        },
        {
          targets: this,
          x: destX,
          ease: 'Linear',
          duration: 30,
        },
      ],
    })

    this.scene.tweens
      .timeline({
        loop: 10,
        tweens: [
          {
            targets: this,
            y: destY + 3,
            ease: 'Linear',
            duration: 30,
          },
          {
            targets: this,
            y: destY,
            ease: 'Linear',
            duration: 30,
          },
        ],
      })
      .setCallback('onComplete', () => {
        this.emit('success')
        this.close()
      })
  }

  pause(pauseTimeRemainingMillis: number) {
    this.isPaused = true
    this.pauseTimeRemainingMillis = pauseTimeRemainingMillis
  }

  unpause() {
    this.isPaused = false
  }

  reset() {
    this.currentLevel = 0
    this.currentSpeed = UnlockMiniGame.START_SPEED
    this.markers.forEach((m) => m.turnOff())
  }

  close() {
    const game = phaserGame.scene.keys.game as Game
    if (store.getState().pref.music) {
      game.music.stop()
      game.music = game.sound.add(`${gameConfig.maps[game.map.config.sceneName].music[0].key}`, {
        volume: 0.2,
        loop: true,
      })
      game.music.play()
    }
    this.emit('destroyed')
    this.clickKey.destroy()
    this.closeKey.destroy()
    this.destroy(true)
    game.input.removeListener('pointerdown')
    store.dispatch(setFocused(false))
    store.dispatch(setTouch(true))
    store.dispatch(setActiveMenu(''))
  }
}
