import Phaser from 'phaser'
import { gameConfig } from '../assets/game-data'

export const createObjectAnims = (sceneName: string, anims: Phaser.Animations.AnimationManager) => {
  const animsFrameRate = 15

  for (let i = 0; i < gameConfig.maps[sceneName].anims.length; i++) {
    anims.create({
      key: `${gameConfig.maps[sceneName].anims[i].key}`,
      frames: anims.generateFrameNames(`${gameConfig.maps[sceneName].anims[i].key}`, {
        start: 0,
        end: 9,
      }),
      repeat: -1,
      frameRate: animsFrameRate * 0.6,
    })
  }

  // setting coin animation
  anims.create({
    key: 'coin_rotate',
    frames: anims.generateFrameNumbers('coins', {
      start: 0,
      end: 5,
    }),
    frameRate: 10,
    yoyo: true,
    repeat: -1,
  })

  anims.create({
    key: 'page',
    frames: anims.generateFrameNumbers('page', {
      start: 0,
      end: 0,
    }),
    frameRate: 10,
    yoyo: true,
    repeat: -1,
  })
}
