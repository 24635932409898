import React from 'react'

import styled from 'styled-components'
// import Button from '../../components/Button'
// import { shortenAddress } from '../../helpers'
// import { useWallet } from '../../hooks'

const Container = styled.div`
  padding: 30px 5px;
  font-family: 'VT323';

  height: calc(100% - 60px);
  overflow: scroll;
`

const List = styled.div`
  @media (max-width: 700px) {
    padding: 10px;
    margin-right: 0px;
  }
`

// const Item = styled.div`
//   text-align: center;
//   box-shadow: 0 0 0 1px black;
//   margin-bottom: 10px;

//   display: flex;
//   img {
//     width: 90px;
//     height: 90px;
//     padding: 10px;
//   }

//   .info {
//     padding: 10px 0;
//     width: -moz-available;
//     text-align: left;

//     h2 {
//       padding: 0px;
//       margin: 0px;
//       font-weight: bold;
//       font-size: 24px;
//     }
//   }
//   .buttons {
//     width: auto;
//     padding: 10px;

//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }

//   @media (max-width: 900px) {
//   }
// `

export default function FriendsList() {
  // const { account } = useWallet()
  return (
    <Container>
      {/* <List>
        <Item>
          <img src={'/assets/character/profile-pictures/' + 1 + '.png'} alt={'1'} />
          <div className="info">
            <h2>Friend_1</h2>
            <h3>{account ? shortenAddress(account) : 'Wallet not connected'}</h3>
          </div>
          <div className="buttons">
            <Button>View</Button>
          </div>
        </Item>
        <Item>
          <img src={'/assets/character/profile-pictures/' + 2 + '.png'} alt={'1'} />
          <div className="info">
            <h2>Friend_2</h2>
            <h3>{account ? shortenAddress(account) : 'Wallet not connected'}</h3>
          </div>
          <div className="buttons">
            <Button>View</Button>
          </div>
        </Item>
        <Item>
          <img src={'/assets/character/profile-pictures/' + 3 + '.png'} alt={'1'} />
          <div className="info">
            <h2>Friend_3</h2>
            <h3>{account ? shortenAddress(account) : 'Wallet not connected'}</h3>
          </div>
          <div className="buttons">
            <Button>View</Button>
          </div>
        </Item>
        <Item>
          <img src={'/assets/character/profile-pictures/' + 4 + '.png'} alt={'1'} />
          <div className="info">
            <h2>Friend_4</h2>
            <h3>{account ? shortenAddress(account) : 'Wallet not connected'}</h3>
          </div>
          <div className="buttons">
            <Button>View</Button>
          </div>
        </Item>
        <Item>
          <img src={'/assets/character/profile-pictures/' + 5 + '.png'} alt={'1'} />
          <div className="info">
            <h2>Friend_5</h2>
            <h3>{account ? shortenAddress(account) : 'Wallet not connected'}</h3>
          </div>
          <div className="buttons">
            <Button>View</Button>
          </div>
        </Item>
        <Item>
          <img src={'/assets/character/profile-pictures/' + 6 + '.png'} alt={'1'} />
          <div className="info">
            <h2>Friend_6</h2>
            <h3>{account ? shortenAddress(account) : 'Wallet not connected'}</h3>
          </div>
          <div className="buttons">
            <Button>View</Button>
          </div>
        </Item>
        <Item>
          <img src={'/assets/character/profile-pictures/' + 7 + '.png'} alt={'1'} />
          <div className="info">
            <h2>Friend_7</h2>
            <h3>{account ? shortenAddress(account) : 'Wallet not connected'}</h3>
          </div>
          <div className="buttons">
            <Button>View</Button>
          </div>
        </Item>
        <Item>
          <img src={'/assets/character/profile-pictures/' + 8 + '.png'} alt={'1'} />
          <div className="info">
            <h2>Friend_8</h2>
            <h3>{account ? shortenAddress(account) : 'Wallet not connected'}</h3>
          </div>
          <div className="buttons">
            <Button>View</Button>
          </div>
        </Item>
      </List> */}

      <List>
        <h1>Coming Soon</h1>
      </List>
    </Container>
  )
}
