import { providers } from 'ethers'

import { RPC_PROVIDER_URL } from '../constants/chain'

export const getLibraryProvider = (provider?) => {
  if (provider) {
    return new providers.Web3Provider(provider);
  }

  return new providers.JsonRpcProvider(RPC_PROVIDER_URL);
}
