import { getRandomNumberFromArray } from '../../helpers/dataHelpers'
import store from '../../stores'
import { startConversation } from '../../stores/MessageStore'
import { getCompletedQuests } from '../../stores/QuestStore'

import { NPCdata } from '../npc-data'

export default function SalesmanEticha(npc, scene, dialogue?: number) {
  let completedQuests = getCompletedQuests()
  let currentQuest = store.getState().quest.currentQuest
  let checkpoints = store.getState().quest.checkpoints

  let conversation = 1 // STARTS 7TH QUEST

  if (completedQuests && completedQuests.includes(7)) {
    conversation = getRandomNumberFromArray([0])
  }

  if (currentQuest) {
    if (currentQuest.id === 7) {
      conversation = 2
      if (checkpoints.find((checkpoint: any) => checkpoint.name === `collected-all`)?.status) {
        conversation = 3
      }
    }
  }

  if (npc.playerId === 'salesman-eticha') {
    store.dispatch(
      startConversation({
        npc: NPCdata.find((current) => current.id === npc.playerId),
        conversation: dialogue ? dialogue : conversation,
        messageNumber: 1,
      })
    )
  }
}
