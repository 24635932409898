import React, { useEffect } from 'react'
import store from '../../stores'
import { closeMessageDialog, startConversation } from '../../stores/MessageStore'
import { setFocused, setTouch } from '../../stores/UserStore'
import styled from 'styled-components'
import { useAppSelector } from '../../hooks'
import { setActiveMenu } from '../../stores/MenuStore'
import { historyRef } from '../../interfaces/bank'
import { openInNewTab } from '../../helpers/openInNewTab'
import { completeQuest, exitQuest, startQuest } from '../../stores/QuestStore'
import { setCounting, setTime } from '../../stores/TimerStore'
import { setWarningText } from '../../stores/WarningTextStore'
import { setNotification } from '../../stores/NotificationStore'
import { completeSpecificQuest, QUESTS_LIST, startSpecificQuest } from '../../quests'
import { GameState, setGameState } from '../../stores/GameStore'

const OptionDiv = styled.div`
  cursor: pointer;
  margin: 0 0 5px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid transparent;
  :hover {
    border: 1px solid #000;
  }

  font-size: 28px;
  @media (max-width: 700px) {
    font-size: 24px;
  }
`
export default function Option({ currentKey, response, followup, setResponse }) {
  let conversation = useAppSelector((state) => state.message.conversation)
  let npc = useAppSelector((state) => state.message.currentNPC)
  const handleOptionSelect = () => {
    setResponse({})

    // Theres no follow up, end conversation
    if (!followup || followup === null) {
      store.dispatch(setFocused(false))
      store.dispatch(setTouch(true))
      store.dispatch(closeMessageDialog())
    }
    // Follow up is a dialogue, continue conversation
    else if (typeof followup === 'number') {
      if (npc) {
        const currentConversation = conversation
        const currentNPC = npc

        store.dispatch(closeMessageDialog())

        store.dispatch(
          startConversation({
            npc: currentNPC,
            conversation: currentConversation,
            messageNumber: followup,
          })
        )
      }
    }
    // Follow up opens an interface
    else if (followup.interface) {
      store.dispatch(closeMessageDialog())

      // Interface is bank/DEX
      if (followup.interface === 'bank') {
        store.dispatch(setActiveMenu('bank'))

        if (historyRef && historyRef.current) {
          if (followup.page === 'swap') {
            historyRef.current.replace('/swap')
          } else if (followup.page === 'pool') {
            historyRef.current.replace('/pool')
          } else if (followup.page === 'farm') {
            historyRef.current.replace('/pools')
          } else {
            historyRef.current.replace('/treasury')
          }
        }
      }
    }
    // Follow up triggers a battle
    else if (followup.battle && followup.battle === 'enemy') {
      store.dispatch(closeMessageDialog())
      store.dispatch(setGameState(GameState.WildPvE))
      store.dispatch(setFocused(true))
      store.dispatch(setTouch(false))
    }
    // Follow up opens an link
    else if (followup.link) {
      store.dispatch(setFocused(false))
      store.dispatch(setTouch(true))
      store.dispatch(closeMessageDialog())
      openInNewTab(followup.link)
    }
    // Follow up starts a quest
    else if (followup.quest) {
      store.dispatch(closeMessageDialog())
      store.dispatch(
        startQuest({
          id: followup.quest,
          startedAt: new Date().toString(),
        })
      )
      startSpecificQuest()

      // Follow up has a timer, start timer
      if (followup.timer) {
          setTimeout(() => {
            store.dispatch(setWarningText(''))
            store.dispatch(setFocused(false))
            store.dispatch(setTouch(true))
            store.dispatch(setTime(followup.timer))
            store.dispatch(setCounting(true))
          }, 1000 * followup.wait)
    
      } else {
        store.dispatch(setFocused(false))
        store.dispatch(setTouch(true))
      }
    }
    // Follow up completes the quest
    else if (followup.completeQuest) {
      const questData = QUESTS_LIST.find((quest: any) => quest.id === followup.completeQuest)
      store.dispatch(closeMessageDialog())
      store.dispatch(setFocused(false))
      store.dispatch(setTouch(true))

      completeSpecificQuest()

      store.dispatch(completeQuest(followup.completeQuest))
      store.dispatch(
        setNotification({
          content: questData ? questData.name : '',
          type: 'completed',
        })
      )
    } else if (followup.exitQuest) {
      store.dispatch(closeMessageDialog())
      store.dispatch(setFocused(false))
      store.dispatch(setTouch(true))
      store.dispatch(exitQuest(followup.exitQuest))
    } else {
      console.log('it is a string')
    }
  }

  useEffect(() => {
    const onKeyDown = ({ key }) => {
      if (
        ['1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(key) &&
        Number(key) === Number(currentKey + 1)
      ) {
        handleOptionSelect()
      }
    }

    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <OptionDiv onClick={handleOptionSelect}>
      {currentKey + 1}. {response}
    </OptionDiv>
  )
}
