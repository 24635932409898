import phaserGame from '../../PhaserGame'
import Game from '../Game'

export function registerKeys() {
  const game = phaserGame.scene.keys.game as Game
  game.cursors = game.input.keyboard.createCursorKeys()
  game.altCursors = game.input.keyboard.addKeys({
    up: 'W',
    down: 'S',
    left: 'A',
    right: 'D',
  })
  game.keyE = game.input.keyboard.addKey('E')
  game.keyR = game.input.keyboard.addKey('R')
  game.keyShift = game.input.keyboard.addKey('SHIFT')
  game.input.keyboard.disableGlobalCapture()

  game.resetSceneLoad()
}

export function handleKeys(status: boolean) {
  const game = phaserGame.scene.keys.game as Game
  game.input.keyboard.enabled = status
}
