import Container = Phaser.GameObjects.Container;
import {Scene} from "phaser";
import {BattleButton} from "./battle-button";
import {DefimonSkill} from "../skills";
import {BattleDefimon} from "../defimons";

export class DefimonAttacksUI extends Container {
    private static readonly BUTTONS_WIDTH = 200;
    private static readonly BUTTONS_HEIGHT = 75;
    private static readonly BUTTONS_GAP = 20;

    constructor(defimon: BattleDefimon, onAttackPicked: (attack: DefimonSkill) => void, onChoiceCancelled: () => void,  scene: Scene) {
        super(scene);

        let currentX = 0;
        defimon.skills.forEach(skill => {
            const button = new BattleButton(DefimonAttacksUI.BUTTONS_WIDTH, DefimonAttacksUI.BUTTONS_HEIGHT, skill.name, skill.spCost <= defimon.getTotalStaminaPoints() ? 0xffffff : 0xaaaabb, "#000000", scene);
            if (skill.spCost <= defimon.getTotalStaminaPoints())
                button.registerCallback(() => onAttackPicked(skill));
            button.x = currentX;
            currentX += DefimonAttacksUI.BUTTONS_WIDTH + DefimonAttacksUI.BUTTONS_GAP;

            this.add(button);
        })

        const cancelButton = new BattleButton(DefimonAttacksUI.BUTTONS_HEIGHT, DefimonAttacksUI.BUTTONS_HEIGHT, "CANCEL", 0xff3333, "#ffffff", scene);
        cancelButton.registerCallback(() => onChoiceCancelled());
        cancelButton.x = -DefimonAttacksUI.BUTTONS_HEIGHT * 2 - DefimonAttacksUI.BUTTONS_GAP
        this.add(cancelButton);

        this.setSize(defimon.skills.length * DefimonAttacksUI.BUTTONS_WIDTH + (defimon.skills.length - 1) * DefimonAttacksUI.BUTTONS_GAP, DefimonAttacksUI.BUTTONS_HEIGHT);

        scene.add.existing(this);
    }
}