import { useWeb3React } from '@web3-react/core'
import { useCallback, useState } from 'react'

import { CONTRACTS } from '../constants/contracts';
import setApproval from '../utils/setApproval';
import { useContract } from './useContract';


export const useInventory = () => {
  const { account } = useWeb3React()
  const [loading, setLoading] = useState(false)
  const contractInventory = useContract(CONTRACTS.INVENTORY)

  const changeEquippedItem = useCallback(async (id: number, contractERC1155) => {
    if (account && contractInventory) {
      setLoading(true)
      await setApproval(account, contractERC1155, CONTRACTS.INVENTORY)
      const items = await contractInventory.getEquippedItemsOf(account)
      const tokenId = items[0]?.tokenId
      if (tokenId) {
        const txUnequip = await contractInventory.unequipItem(tokenId, 1)
        await txUnequip.wait()
      }
      const txEquip = await contractInventory.equipItem(id, 1)
      await txEquip.wait()
      setLoading(false)
    }
  }, [account, contractInventory])

  return {
    contractInventory,
    changeEquippedItem,
    loading,
  }
}