import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import CloseIcon from '@mui/icons-material/Close'
import { getColorByString } from '../util'
import { useAppDispatch, useAppSelector } from '../hooks'
import { setFocused, setTouch, stopPlayer } from '../stores/UserStore'
import network from '../services/Network'
import Filter from 'bad-words'
import { whitelist } from '../constants/words'
import { setActiveMenu } from '../stores/MenuStore'
import Border from '../components/shared/Border'
import { setTypingInChat } from '../stores/ChatStore'

const Backdrop = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  height: 300px;
  width: 400px;
  font-family: 'VT323';
  max-height: 50%;
  max-width: 50%;
  pointer-events: auto;
  opacity: 0.95;

  @media (max-width: 700px) {
    max-width: calc(100% - 20px);
    height: 100%;
    width: 100%;
    max-height: calc(100% - 140px);

    bottom: 20px;
    left: 10px;
  }
`

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 99;
  max-width: 37.5rem;
  width: 100%;
  background-color: #fff;
  color: #555;
`

const ChatHeader = styled.div`
  position: relative;
  height: 35px;
  font-family: 'VT323';
  color: #000;
  fill: #000;
  h3 {
    color: #000;
    font-size: 27px;
    text-align: center;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
  }
`

const ChatBox = styled(Box)`
  height: calc(100% - 80px);
  width: 100%;
  overflow: auto;
  padding: 0 10px;
`

const MessageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0px 2px;

  p {
    margin: 0px;
    font-size: 21px;
    font-weight: bold;
    line-height: 1.4;
    overflow-wrap: anywhere;
  }

  span {
    color: #555;
    font-weight: normal;
  }

  .notification {
    color: grey;
    font-weight: normal;
  }

  :hover {
    background: #ddd;
  }
`

const InputWrapper = styled.form`
  box-shadow: 10px 10px 10px #00000018;
  border: 1px solid #42eacb;
  display: flex;
  font-family: 'VT323';
  flex-direction: row;
  background: #ddd;
  color: #000;
  border: solid 0.2rem #555;
  bottom: 0px;
  margin: 0 10px 10px;
`

const InputTextField = styled(InputBase)`
  input {
    padding: 5px;
    color: #000;
    -webkit-text-fill-color: #000 !important;
  }
`

const Body = styled.div`
  background: rgb(248, 248, 248) none repeat scroll 0% 0%;
  border-bottom: 4px solid rgb(216, 213, 237);
  padding: 0px;
  height: 100%;

  @media (max-width: 700px) {
    padding: 0px;
  }
`

const Message = ({ chatMessage, messageType }) => {
  return (
    <MessageWrapper>
      <p
        style={{
          color: getColorByString(chatMessage.author),
        }}
      >
        {chatMessage.author}: <span>{chatMessage.content}</span>
      </p>
    </MessageWrapper>
  )
}

export default function Chat() {
  const [inputValue, setInputValue] = useState('')
  const [timer, setTimer] = useState(0)
  const messagesEndRef = useRef<HTMLDivElement>(null)

  const inputRef = useRef<HTMLInputElement>(null)
  const chatMessages = useAppSelector((state) => state.chat.chatMessages)
  const isMultiplayer = useAppSelector((state) => state.game.isMultiplayer)
  const isTyping = useAppSelector((state) => state.chat.isTyping)
  const dispatch = useAppDispatch()
  const filter = new Filter({ exclude: whitelist, replaceRegex: /[A-Za-z0-9가-힣_]/g })

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      // move focus back to the game
      inputRef.current?.blur()

      dispatch(setActiveMenu(''))
      dispatch(setFocused(false))
      dispatch(setTouch(true))
      dispatch(setTypingInChat(false))
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    // move focus back to the game
    inputRef.current?.blur()
    dispatch(setTypingInChat(false))
    
    if (inputValue) {
      const filteredVal = filter.clean(inputValue.trim())
      const val = filteredVal.substring(0, 128)
      setInputValue('')
      if (val) {
        network.addChatMessage(val)
        // game.myPlayer.updateDialogBubble(val)
        // Set Timer to 5 seconds
        // setTimer(5)
      }
    }
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    if (isTyping) {
      inputRef.current?.focus()
    }
  }, [isTyping])

  // Handle Key Press
  useEffect(() => {
    const onKeyDown = ({ key }) => {
      if (key === 'Enter') {
        dispatch(setFocused(true))
        dispatch(setTypingInChat(true))
      } else if (key === 'Escape') {
        // move focus back to the game
        inputRef.current?.blur()

        dispatch(setActiveMenu(''))
        dispatch(setFocused(false))
        dispatch(setTouch(true))
        dispatch(setTypingInChat(false))
      }
    }

    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [dispatch])

  useEffect(() => {
    if (timer > 0) {
      setTimeout(function () {
        setTimer(timer - 1)
      }, 1000)
    }
  }, [timer])

  useEffect(() => {
    scrollToBottom()
  }, [chatMessages])

  if(isMultiplayer){
    return (
      <Backdrop>
        <Wrapper>
          <Border>
            <Body>
              <ChatHeader>
                <h3>Chat</h3>
                <IconButton
                  aria-label="close dialog"
                  className="close"
                  onClick={() => {
                    dispatch(setActiveMenu(''))
                    dispatch(setFocused(false))
                    dispatch(setTouch(true))
                  }}
                  size="small"
                  style={{
                    fill: '#000',
                    color: '#000',
                    cursor: 'pointer',
                    margin: '5px 5px 0 0',
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </ChatHeader>
              <ChatBox>
                {chatMessages.map(({ messageType, chatMessage }, index) => (
                  <Message chatMessage={chatMessage} messageType={messageType} key={index} />
                ))}
                <div ref={messagesEndRef} />
              </ChatBox>
              <InputWrapper onSubmit={handleSubmit}>
                <InputTextField
                  inputRef={inputRef}
                  autoFocus={isTyping}
                  fullWidth
                  value={inputValue}
                  placeholder="Press Enter to chat"
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                  disabled={timer > 0}
                  onBlur={() => {
                    dispatch(setFocused(false))
                    dispatch(setTypingInChat(false))
                  }}
                  onFocus={() => {
                    dispatch(stopPlayer())
                    dispatch(setFocused(true))
                    dispatch(setTypingInChat(true))
                  }}
                />
              </InputWrapper>
              {/* {timer > 0 ? `Slowmode: ${timer} seconds` : 'Slowmode is enabled.'} */}
            </Body>
          </Border>
        </Wrapper>
      </Backdrop>
    )
  } else return <></>
}
