import Container = Phaser.GameObjects.Container;
import {Scene} from "phaser";

export class BattleButton extends Container {
    constructor(width: number, height: number, label: string, backgroundColor: number, labelColor: string, scene: Scene) {
        super(scene);

        const background = scene.add.rectangle(0, 0, width, height, backgroundColor);
        const labelText = scene.add.text(0, 0, label, {color: labelColor})
        labelText.setPosition(-labelText.width / 2, -labelText.height / 2);

        this.add(background);
        this.add(labelText);

        scene.add.existing(this);

        this.setSize(width, height);
    }

    registerCallback(onClicked: () => void) {
        this.setInteractive().on("pointerdown", () => {
            onClicked();
        });
    }

    unregisterCallbacks() {
        this.off("pointerdown");
    }
}