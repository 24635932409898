import { CharactersList } from '../../constants/player-sprites'
import { NPCList } from '../../constants/npc-sprites'
import { loadFont } from '../../utils/fonts'
import { MonstersList } from '../../constants/monsters'

export function loadBackgroundAssets(game: any) {
  game.load.atlas(
    'cloud_day',
    'assets/background/cloud_day.png',
    'assets/background/cloud_day.json'
  )
  game.load.image('backdrop_day', 'assets/background/backdrop_day.png')
  game.load.image('sun_moon', 'assets/background/sun_moon.png')

  game.load.image('backdrop', 'assets/background/backdrop.png')

  game.load.image('fight-background', 'assets/background/fight.png')
}

export function loadItemsAssets(game: any) {
  game.load.spritesheet('raindrop', 'assets/items/raindrop.png', {
    frameWidth: 8,
    frameHeight: 8,
  })
  game.load.spritesheet('chairs', 'assets/items/chair.png', {
    frameWidth: 32,
    frameHeight: 64,
  })
  game.load.spritesheet('whiteboards', 'assets/items/whiteboard.png', {
    frameWidth: 64,
    frameHeight: 64,
  })
  game.load.spritesheet('dumpsters', 'assets/items/dumpster.png', {
    frameWidth: 64,
    frameHeight: 96,
  })
  game.load.spritesheet('worksite-toolboxes', 'assets/items/worksite-toolbox.png', {
    frameWidth: 64,
    frameHeight: 96,
  })
  game.load.spritesheet('coins', 'assets/items/coin.png', {
    frameWidth: 32,
    frameHeight: 32,
  })
  game.load.spritesheet('page', 'assets/items/page.png', {
    frameWidth: 32,
    frameHeight: 32,
  })
}

export function loadPlayerSkinAssets(game: any) {
  for (let i = 0; i < CharactersList.length; i++) {
    game.load.spritesheet(
      `${CharactersList[i].name}`,
      `assets/character/player/${CharactersList[i].fileName}.png`,
      {
        frameWidth: 40,
        frameHeight: 58,
      }
    )
  }
}

export function loadNPCSkinAssets(game: any) {
  for (let i = 0; i < NPCList.length; i++) {
    game.load.spritesheet(
      `${NPCList[i].name}-npc`,
      `assets/character/npc/n${NPCList[i].fileName}.png`,
      {
        frameWidth: 40,
        frameHeight: 58,
      }
    )
  }

  game.load.spritesheet(`police-npc-goop`, `assets/character/npc/n1-goop.png`, {
    frameWidth: 50,
    frameHeight: 50,
  })
}

function loadCorrectSeqMiniGameAssets(game: any) {
  game.load.image('correctSeqBarFrame', 'assets/mini-games/correct-seq/bar_frame.png')
  game.load.image('correctSeqBarInnerGrowth', 'assets/mini-games/correct-seq/bar_inner_growth.png')
  game.load.image('correctSeqBarInnerHealth', 'assets/mini-games/correct-seq/bar_inner_health.png')
  game.load.image('correctSeqBg', 'assets/mini-games/correct-seq/bg.png')
  game.load.image('correctSeqButtonApple', 'assets/mini-games/correct-seq/button_apple.png')
  game.load.image(
    'correctSeqButtonApplePressed',
    'assets/mini-games/correct-seq/button_apple_pressed.png'
  )
  game.load.image('correctSeqButtonBanana', 'assets/mini-games/correct-seq/button_banana.png')
  game.load.image(
    'correctSeqButtonBananaPressed',
    'assets/mini-games/correct-seq/button_banana_pressed.png'
  )
  game.load.image('correctSeqButtonCherry', 'assets/mini-games/correct-seq/button_cherry.png')
  game.load.image(
    'correctSeqButtonCherryPressed',
    'assets/mini-games/correct-seq/button_cherry_pressed.png'
  )
  game.load.image('correctSeqButtonFailure', 'assets/mini-games/correct-seq/button_failure.png')
  game.load.image('correctSeqButtonPineapple', 'assets/mini-games/correct-seq/button_pineapple.png')
  game.load.image(
    'correctSeqButtonPineapplePressed',
    'assets/mini-games/correct-seq/button_pineapple_pressed.png'
  )
  game.load.image('correctSeqButtonSuccess', 'assets/mini-games/correct-seq/button_success.png')
  game.load.image('correctSeqFruitApple', 'assets/mini-games/correct-seq/fruit_apple.png')
  game.load.image('correctSeqFruitBanana', 'assets/mini-games/correct-seq/fruit_banana.png')
  game.load.image('correctSeqFruitCherry', 'assets/mini-games/correct-seq/fruit_cherry.png')
  game.load.image('correctSeqFruitPineapple', 'assets/mini-games/correct-seq/fruit_pineapple.png')
  game.load.image('correctSeqSlider', 'assets/mini-games/correct-seq/slider.png')
  loadFont('rubik', 'assets/fonts/Rubik-VariableFont_wght.ttf')
}

function loadUnlockMiniGameAssets(game: any) {
  game.load.image('unlockMiniGameBall', 'assets/mini-games/unlock/ball.png')
  game.load.image('unlockMiniGameBallHighlighted', 'assets/mini-games/unlock/ball_highlighted.png')
  game.load.image('unlockMiniGameBallWrong', 'assets/mini-games/unlock/ball_wrong.png')
  game.load.image('unlockMiniGameBarTrashbin', 'assets/mini-games/unlock/bar_trashbin.png')
  game.load.image('unlockMiniGameBarWorksite', 'assets/mini-games/unlock/bar_worksite.png')
  game.load.image('unlockMiniGameMarkerOff', 'assets/mini-games/unlock/marker_off.png')
  game.load.image('unlockMiniGameMarkerOn', 'assets/mini-games/unlock/marker_on.png')
  game.load.image('unlockMiniGameXButton', 'assets/mini-games/unlock/x_button.png')
}

export function loadMiniGameAssets(game: any) {
  loadUnlockMiniGameAssets(game)
  loadCorrectSeqMiniGameAssets(game)
}

export function loadMapEffectsAssets(game: any) {
  game.load.image('fogOfWarMask', 'assets/map-effects/fog-of-war/fog_of_war_mask.png')
}

export function loadDefimonsSpritesheets(game: any) {
  Object.values(MonstersList).forEach((monster) => {
    game.load.spritesheet(`defimon_${monster.id}`, `assets/monsters/sprites/${monster.id}_0.png`, {
      frameWidth: monster.frameSize,
      frameHeight: monster.frameSize,
    })
  })
}
