import React from 'react'

import styled from 'styled-components'
import { QUESTS_LIST } from '../../quests'
import { getCompletedQuests } from '../../stores/QuestStore'

const Container = styled.div`
  padding: 30px 5px;
  font-family: 'VT323';

  height: calc(100% - 60px);
  overflow: scroll;
`

const List = styled.div`
  @media (max-width: 700px) {
    padding: 10px;
    margin-right: 0px;
  }
`

const Item = styled.div`
  box-shadow: 0 0 0 1px black;
  margin-bottom: 10px;

  display: flex;
  padding: 5px;
  h2 {
    padding: 0px;
    margin: 0px;
    font-size: 32px;
    font-weight: bold;
  }
  h4 {
    padding: 0px;
    margin: 0px;
    font-size: 20px;
  }

  @media (max-width: 900px) {
  }
`

export default function QuestsList() {
  const completedQuests = getCompletedQuests()
  return (
    <Container>
      <List>
        {completedQuests && completedQuests.length > 0 ? (
          completedQuests.map((questId) => (
            <Item key={questId}>
              <div className="info">
                <h2>{QUESTS_LIST.find((quest: any) => quest.id === questId)?.name}</h2>
                <h4>Status: Completed</h4>
              </div>
            </Item>
          ))
        ) : (
          <Item>
            <div className="info">
              <h2>No Quests Found</h2>
            </div>
          </Item>
        )}
      </List>
    </Container>
  )
}
