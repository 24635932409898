import { IPlayer } from '../../../../types/IOfficeState'
import { ItemType } from '../../../../types/Items'
import OtherPlayer from '../../characters/OtherPlayer'
import phaserGame from '../../PhaserGame'
import store from '../../stores'
import { setWeather } from '../../stores/RoomStore'
import Game from '../Game'

export function handlePlayerJoined(newPlayer: IPlayer, id: string) {
  const isMultiplayer = store.getState().game.isMultiplayer
  const game = phaserGame.scene.keys.game as Game

  // Check if map name is same and player doesn't already exist
  if (newPlayer.map === game.mapName && !game.otherPlayerMap.has(id) && isMultiplayer) {
    const otherPlayer = new OtherPlayer(
      game,
      newPlayer.x,
      newPlayer.y,
      newPlayer.anim.split('_')[0],
      id,
      newPlayer.webRTCId,
      newPlayer.name,
      newPlayer.readyToConnect,
      newPlayer.videoConnected,
      newPlayer.map
    )

    // add spawn
    game.otherPlayers.add(otherPlayer)

    // add player to map
    game.otherPlayerMap.set(id, otherPlayer)
  }
}

// function to remove the player who left from the otherPlayer group
export function handlePlayerLeft(id: string) {
  const isMultiplayer = store.getState().game.isMultiplayer
  const game = phaserGame.scene.keys.game as Game
  if (
    game.otherPlayerMap &&
    game.otherPlayerMap.has(id) &&
    game.otherPlayers &&
    game.otherPlayers.scene &&
    isMultiplayer
  ) {
    const otherPlayer = game.otherPlayerMap.get(id)
    if (!otherPlayer) return

    game.otherPlayers.remove(otherPlayer, true, true)

    game.otherPlayerMap.delete(id)
  }
}

export function handlePlayerChangedMap(newPlayer: IPlayer, id: string) {
  const isMultiplayer = store.getState().game.isMultiplayer
  const game = phaserGame.scene.keys.game as Game
  if (game.otherPlayerMap.has(id) && isMultiplayer) {
    const otherPlayer = game.otherPlayerMap.get(id)
    if (!otherPlayer) return

    game.otherPlayers.remove(otherPlayer, true, true)

    game.otherPlayerMap.delete(id)
  }
}

// function to update target position upon receiving player updates
export function handlePlayerUpdated(field: string, value: number | string, id: string) {
  const game = phaserGame.scene.keys.game as Game
  const otherPlayer = game.otherPlayerMap.get(id)

  if (field === 'name') {
    otherPlayer?.updateOtherPlayer(field, value)
  } else {
    otherPlayer?.updateOtherPlayer(field, value)
  }
}

export function handleItemUserAdded(playerId: string, itemId: string, itemType: ItemType) {
  const game = phaserGame.scene.keys.game as Game

  if (itemType === ItemType.COMPUTER) {
    const computer = game.computerMap.get(itemId)
    computer?.addCurrentUser(playerId)
  } else if (itemType === ItemType.WHITEBOARD) {
    const whiteboard = game.whiteboardMap.get(itemId)
    whiteboard?.addCurrentUser(playerId)
  }
}

export function handleItemUserRemoved(playerId: string, itemId: string, itemType: ItemType) {
  const game = phaserGame.scene.keys.game as Game

  if (itemType === ItemType.COMPUTER) {
    const computer = game.computerMap.get(itemId)
    computer?.removeCurrentUser(playerId)
  } else if (itemType === ItemType.WHITEBOARD) {
    const whiteboard = game.whiteboardMap.get(itemId)
    whiteboard?.removeCurrentUser(playerId)
  }
}

export function handleChatMessageAdded(playerId: string, content: string) {
  const game = phaserGame.scene.keys.game as Game
  const otherPlayer = game.otherPlayerMap.get(playerId)
  otherPlayer?.updateDialogBubble(content)
}

export function handleWeatherChange(value: boolean) {
  store.dispatch(setWeather(value))
}


export function handleMyPlayerReady() {
  const game = phaserGame.scene.keys.game as Game
  game.myPlayer.readyToConnect = true
}

export function handleMyVideoConnected() {
  const game = phaserGame.scene.keys.game as Game
  game.myPlayer.videoConnected = true
}