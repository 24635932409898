import Container = Phaser.GameObjects.Container;
import Image = Phaser.GameObjects.Image;
import {Scene} from "phaser";

export class UnlockMiniGameBallUI extends Container {
    private static readonly HIGHLIGHT_DURATION_MILLIS = 100;

    private readonly ball: Image;
    private readonly ballHighlighted: Image;
    private readonly ballWrong: Image;

    constructor(scene: Scene) {
        super(scene);

        this.ball = scene.add.image(0, 0, 'unlockMiniGameBall');
        this.ballHighlighted = scene.add.image(0, 0, 'unlockMiniGameBallHighlighted');
        this.ballWrong = scene.add.image(0, 0, 'unlockMiniGameBallWrong');

        this.add(this.ball);
        this.add(this.ballHighlighted);
        this.add(this.ballWrong);

        this.ballHighlighted.alpha = 0;
        this.ballWrong.visible = false;

        scene.add.existing(this);
    }

    triggerHighlight() {
        this.ballHighlighted.alpha = 1;
        this.scene.tweens.add({
            targets: this.ballHighlighted,
            alpha: 0,
            ease: 'Linear',
            duration: UnlockMiniGameBallUI.HIGHLIGHT_DURATION_MILLIS
        })
    }

    triggerWrong(duration: number) {
        this.ballWrong.visible = true;
        setTimeout(() => this.ballWrong.visible = false, duration);
    }

    permanentHighlight() {
        this.ballHighlighted.alpha = 0.8;
    }
}