import React from 'react'
import styled from 'styled-components'
import Border from './shared/Border'

const Container = styled.div`
  display: relative;
  height: 100%;
`

const Body = styled.div`
  background: rgb(248, 248, 248) none repeat scroll 0% 0%;
  border-bottom: 4px solid rgb(216, 213, 237);
  height: calc(100% - 12px);
  padding: 16px 44px 32px;

  @media (max-width: 700px) {
    padding: 16px 20px 32px;
  }
`

const BodyWithMinimalPadding = styled.div`
  background: rgb(248, 248, 248) none repeat scroll 0% 0%;
  border-bottom: 4px solid rgb(216, 213, 237);
  height: calc(100% - 12px);
  padding: 5px 10px 7px;

  @media (max-width: 700px) {
    padding: 16px 20px 32px;
  }
`

const BodyWithNoPadding = styled.div`
  background: rgb(248, 248, 248) none repeat scroll 0% 0%;
  border-bottom: 4px solid rgb(216, 213, 237);
  height: calc(100% - 12px);
  padding: 0px;

  @media (max-width: 700px) {
    padding: 0px;
  }
`

export default function Dialog(props: any) {
  return (
    <Container>
      <Border>
        <div style={{ position: 'relative' }}>
          <div
            className="Dpad--NoShow"
            style={{ height: '4px', background: 'rgb(208, 207, 223) none repeat scroll 0% 0%,' }}
          ></div>
          <div
            className="Dpad--NoShow"
            style={{ height: '4px', background: 'rgb(176, 173, 201) none repeat scroll 0% 0%' }}
          ></div>
          <div
            className="Dpad--NoShow"
            style={{ height: '4px', background: 'rgb(77, 75, 96) none repeat scroll 0% 0%' }}
          ></div>
          <div
            className="Dpad--NoShow"
            style={{
              position: 'absolute',
              left: '4px',
              bottom: '4px',
              height: '4px',
              width: '4px',
              background: 'rgb(34, 32, 51) none repeat scroll 0% 0%',
            }}
          ></div>
          <div
            className="Dpad--NoShow"
            style={{
              position: 'absolute',
              left: '12px',
              bottom: '4px',
              height: '4px',
              width: '4px',
              background: 'rgb(34, 32, 51) none repeat scroll 0% 0%',
            }}
          ></div>
          <div
            className="Dpad--NoShow"
            style={{
              position: 'absolute',
              left: '20px',
              bottom: '4px',
              height: '4px',
              width: '4px',
              background: 'rgb(34, 32, 51) none repeat scroll 0% 0%',
            }}
          ></div>
        </div>
        {props.minimalPadding ? (
          <BodyWithMinimalPadding>
            <>{props.children}</>
          </BodyWithMinimalPadding>
        ) : (
          <Body>
            <>{props.children}</>
          </Body>
        )}
      </Border>
    </Container>
  )
}

export function DialogWithoutHeader(props: any) {
  return (
    <Container>
      <Border>
        {props.padding === 'minimal' ? (
          <BodyWithMinimalPadding>
            <>{props.children}</>
          </BodyWithMinimalPadding>
        ) : props.padding === 'no' ? (
          <BodyWithNoPadding>
            <>{props.children}</>
          </BodyWithNoPadding>
        ) : (
          <Body>
            <>{props.children}</>
          </Body>
        )}
      </Border>
    </Container>
  )
}
