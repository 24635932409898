import { useWeb3React } from '@web3-react/core'
import { useCallback } from 'react'
import { BigNumber } from "ethers"

import { CONTRACTS, getContract } from "../constants/contracts";
import { useContract } from './useContract';
import { parseNumber } from "../helpers";

type tokenId = BigNumber | number

export const useERC1155utils = () => {
  const { account } = useWeb3React()
  const contractERC1155utils = useContract(CONTRACTS.ERC1155_UTILS)

  const checkTokensOwnedByPlayer = useCallback(async ( 
    tokens: tokenId[], 
    contract: CONTRACTS
  ): Promise<number[]> => {
    if (account && contractERC1155utils) {
      const contractAddress = getContract(contract).address
      const data = await contractERC1155utils.getBalancesOf(contractAddress, tokens, account)
    
      return data.reduce((acc, curr, i) => curr.toNumber() > 0 ? [...acc, parseNumber(tokens[i])] : acc, []);
    } else {
      return []
    }
  }, [account, contractERC1155utils])

  return {
    contractERC1155utils,
    checkTokensOwnedByPlayer,
  }
}