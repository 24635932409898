import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CharactersList } from '../constants/player-sprites'

import network from '../services/Network'
import phaserGame from '../PhaserGame'
import Game from '../scenes/Game'
import { handleKeys } from '../scenes/methods'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    name: '',
    texture: 'Blue Cap Boy',
    walletAddress: '',
    ownedOutfits: [1],
    sessionId: '',
    loggedIn: false,
    serverConnected: false,
    videoConnected: false,
    focused: false,
    touchEnabled: true,
    interactionAvailable: false,
    inGamePosition: { x: 0, y: 0 },
  },
  reducers: {
    setMyPlayerProps: (
      state,
      action: PayloadAction<{ name?: string; texture?: string; walletAddress?: string }>
    ) => {
      const currentScene = phaserGame.scene.keys.game as Game
      const { name, texture, walletAddress } = action.payload

      if (name && name !== state.name) {
        state.name = name
        currentScene?.myPlayer.setPlayerName(state.name)
      }

      if (texture && texture !== state.texture) {
        const outfitData = CharactersList.find((outfit: any) => outfit.name === state.texture)

        state.texture = texture
        currentScene?.myPlayer.setPlayerTexture(state.texture)

        if (
          outfitData?.fileName &&
          !state.ownedOutfits.find((outfit: any) => outfit === outfitData.fileName)
        ) {
          state.ownedOutfits.push(outfitData.fileName)
        }
      }
      if (walletAddress && walletAddress !== state.walletAddress) {
        state.walletAddress = walletAddress
      }

      storeUserInCache(state)
    },
    reinstatePlayerProps: (
      state,
      action: PayloadAction<{ name: string; texture: string; walletAddress: string }>
    ) => {
      const currentScene = phaserGame.scene.keys.game as Game

      if (action.payload.name !== state.name) {
        state.name = action.payload.name
        currentScene?.myPlayer.setPlayerName(state.name)
      }

      if (action.payload.texture !== state.texture) {
        state.texture = action.payload.texture
        currentScene?.myPlayer.setPlayerTexture(state.texture)
      }

      state.loggedIn = true
      handleKeys(true)
    },

    setServerConnected: (state, action: PayloadAction<boolean>) => {
      state.serverConnected = action.payload
    },

    setSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload
    },

    setUserName: (state, action: PayloadAction<string>) => {
      state.name = action.payload
    },
    setWalletAddress: (state, action: PayloadAction<string>) => {
      state.walletAddress = action.payload
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload
    },
    setTouch: (state, action: PayloadAction<boolean>) => {
      state.touchEnabled = action.payload
    },
    setInteractionAvailable: (state, action: PayloadAction<boolean>) => {
      state.interactionAvailable = action.payload
    },
    logIn: (state) => {
      state.loggedIn = true

      handleKeys(true)
      network.readyToConnect()
    },
    logOut: (state) => {
      state.loggedIn = false

     handleKeys(false)
    },
    setPlayerTexture: (state, action: PayloadAction<string>) => {
      state.texture = action.payload
    },
    setFocused: (state, action: PayloadAction<boolean>) => {
      action.payload ? handleKeys(false) : handleKeys(true)
      state.focused = action.payload
    },
    setVideoConnected: (state, action: PayloadAction<boolean>) => {
      state.videoConnected = action.payload
    },
    stopPlayer: () => {
      const currentScene = phaserGame.scene.keys.game as Game
      handleKeys(true)
      // update character velocity
      currentScene.myPlayer.setVelocity(0, 0)
      currentScene.myPlayer.playContainerBody.setVelocity(0, 0)

      handleKeys(false)
    },
    setInGamePosition: (state, action: PayloadAction<{ x: number; y: number }>) => {
      return { ...state, inGamePosition: action.payload }
    },
  },
})

export const {
  setMyPlayerProps,
  setSessionId,
  setLoggedIn,
  setServerConnected,
  setUserName,
  setPlayerTexture,
  reinstatePlayerProps,
  setWalletAddress,
  setVideoConnected,
  logOut,
  setInteractionAvailable,
  setTouch,
  logIn,
  setFocused,
  stopPlayer,
  setInGamePosition,
} = userSlice.actions

export default userSlice.reducer

function storeUserInCache(state) {
  const UserObject = {
    name: state.name,
    texture: state.texture,
    ownedOutfits: state.ownedOutfits,
  }
  localStorage.setItem('defimons_user', btoa(JSON.stringify(UserObject)))
}
